import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {AuthReduxState} from "core/redux/auth/AuthReduxState";
import {
    SetAuthStatus,
    HandleLoginResponseType,
    DoLogoutType,
    DoSwitchAccountType,
    DoChangePasswordType,
    SetAuthStatusPayload,
    OnLoggedOut,
    DoLogOffType,
    DoLogOff,
    DoLogoutConfirmationType,
    DoCreateRelatedLeadType,
    DoLoginToAccountType,
    SetLoginAccountAction,
    SetLoginAccountActionPayload,
    SetRelatedLeadUsername,
    SetRelatedLeadUsernamePayload,
    GetSecuredDataType,
    GetUnmaskingSecuredFieldVerificationCodeType,
    SetVerificationMethods,
    SetVerificationMethodsPayload,
    SetVerificationMethod,
    VerificationMethodPayload,
    SetVerificationIsLimitReached,
    SetVerificationIsLimitReachedPayload,
    SetVerificationCodeIssued,
    SetVerificationCodeIssuedPayload, SetVerificationCodeError, SetVerificationCodeErrorPayload,
} from "core/redux/auth/AuthReduxActions";
import AuthEngine from "core/engine/AuthEngine";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {TradingAccountAction} from "enum/TradingAccountAction";
import {AutoLogin} from "core/engine/AutoLogin";
import {LoginAccountType} from "protocol/auth/LoginAccountType";
import {VerificationMethodType} from "enum/VerificationMethodType";

const initialState = (): AuthReduxState => {
    return {
        loginAccountAction: TradingAccountAction.None,
        loginAccountType: LoginAccountType.CFD,
        autoLoginBrand: AutoLogin.brand,
        pleXopUser: {},
        T2FaVerificationEnabled: true,
        verificationMethodType: VerificationMethodType.Email,
        verificationMethods: [],
    };
};

export default class AuthReducer extends Reducer<AuthReduxState> {

    private static _instance: AuthReducer;

    private constructor() {
        super();
        const authEngine: AuthEngine = Platform.engine(ServiceType.Auth);
        this._middlewareActions.set(HandleLoginResponseType, authEngine.handleLoginResponse);
        this._middlewareActions.set(DoLogoutConfirmationType, authEngine.doConfirmLogout);
        this._middlewareActions.set(DoLogoutType, authEngine.doLogout);
        this._middlewareActions.set(DoLogOffType, authEngine.doLogOff);
        this._middlewareActions.set(DoSwitchAccountType, authEngine.doSwitchAccount);
        this._middlewareActions.set(DoChangePasswordType, authEngine.doChangePassword);
        this._middlewareActions.set(DoCreateRelatedLeadType, authEngine.doCreateRelatedLead);
        this._middlewareActions.set(DoLoginToAccountType, authEngine.doLoginToAccount);
        this._middlewareActions.set(GetSecuredDataType, authEngine.doGetSecuredData);
        this._middlewareActions.set(GetUnmaskingSecuredFieldVerificationCodeType, authEngine.doFetchUnmaskingSecuredFieldVerificationCode);
    }

    public static instance(): AuthReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "auth";
    }

    protected setup(builder: ReducerBuilder<AuthReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: AuthReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    loggedOut: true
                };
            })
            .handle(SetAuthStatus, (state: AuthReduxState, {payload}: Action<SetAuthStatusPayload>) => {
                return Object.assign({}, state, payload.authStatus);
            })
            .handle(DoLogOff, (state: AuthReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    loggedOff: true
                });
            })
            .handle(SetLoginAccountAction, (state: AuthReduxState, {payload}: Action<SetLoginAccountActionPayload>) => {
                return Object.assign({}, state, {
                    loginAccountAction: payload.action
                });
            })
            .handle(SetVerificationCodeIssued, (state: AuthReduxState, {payload}: Action<SetVerificationCodeIssuedPayload>) => {
                return Object.assign({}, state, {
                    verificationCodeIssued: payload.issued
                });
            })
            .handle(SetRelatedLeadUsername, (state: AuthReduxState, {payload}: Action<SetRelatedLeadUsernamePayload>) => {
                return Object.assign({}, state, {
                    RelatedLeadUsername: payload.username
                });
            })
            .handle(SetVerificationMethods, (state: AuthReduxState, {payload}: Action<SetVerificationMethodsPayload>) => {
                return Object.assign({}, state, {
                    verificationMethods: payload.methods
                });
            })
            .handle(SetVerificationMethod, (state: AuthReduxState, {payload}: Action<VerificationMethodPayload>) => {
                return Object.assign({}, state, {
                    verificationMethodType: payload.method
                });
            })
            .handle(SetVerificationIsLimitReached, (state: AuthReduxState, {payload}: Action<SetVerificationIsLimitReachedPayload>) => {
                return Object.assign({}, state, {
                    isLimitReached: payload.IsLimitReached
                });
            })
            .handle(SetVerificationCodeError, (state: AuthReduxState, {payload}: Action<SetVerificationCodeErrorPayload>) => {
                return Object.assign({}, state, {
                    errorVerificationCode: payload.error
                });
            });
    }
}
