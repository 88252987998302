import moment from "moment-mini";
import {Engine} from "platform/engine/Engine";
import Platform from "platform/Platform";
import {StoreState} from "core/redux/StoreState";
import Utils from "platform/util/Utils";
import {ServiceType} from "enum/ServiceType";
import {
    SetAccountLogs,
    SetAccountLogsPending, SetPortfolioTables,
    SetPortfolioTabPayload
} from "core/redux/portfolio/PortfolioReduxActions";
import {PortfolioType} from "enum/PortfolioType";
import {GetAccountLogRequest} from "protocol/account/GetAccountLogRequest";
import {AccountState} from "core/state/AccountState";
import DateTimeFormat from "core/format/DateTimeFormat";
import {FilterDateState, FilterDateType} from "core/redux/filter/FilterReduxState";
import {FilterUtil} from "core/util/FilterUtil";
import {XhrUtil} from "core/util/XhrUtil";
import {AccountLog} from "protocol/account/AccountLog";
import {PortfolioDateFilterPayload} from "core/redux/filter/FilterReduxActions";
import {DealingLogType} from "enum/DealingLogType";
import {TSMap} from "typescript-map";
import {StorageKey} from "enum/StorageKey";
import {PortfolioTableState} from "core/redux/portfolio/PortfolioReduxState";
import {GetPLByAccountIdAndDateRangeRequest} from "protocol/account/GetPLByAccountIdAndDateRangeRequest";
import {HttpReject} from "platform/network/http/Http";
import {PLAccountLog, GetPLByAccountIdAndDateRangeResponse} from "protocol/account/GetPLByAccountIdAndDateRangeResponse";
import {ReChartDataEntry} from "core/chart/ReChartDataEntry";
import {RouteType} from "core/router/Routes";
import {GetAccountLogResponse} from "protocol/account/GetAccountLogResponse";

export default class PortfolioEngine extends Engine {

    private static _instance: PortfolioEngine;

    public static instance(): PortfolioEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        const tables: TSMap<PortfolioType, PortfolioTableState> = await this.deserializeLS();
        Platform.dispatch(SetPortfolioTables({tables}));
    }

    private deserializeLS = async (): Promise<TSMap<PortfolioType, PortfolioTableState>> => {
        const tables: TSMap<PortfolioType, PortfolioTableState> = await PortfolioType.deserialize(StorageKey.PortfolioSort);
        return Promise.resolve(tables);
    }

    public onSetPortfolioTab = (payload: SetPortfolioTabPayload): void => {
        if (payload.portfolioType === PortfolioType.ActivityLog) {
            const filter: FilterDateState = Platform.reduxState<StoreState>().filter.portfolioDateFilter.get(PortfolioType.ActivityLog);
            this.fetchActivityLog(filter.dateType, filter.from, filter.to);
        }
    }

    public onSetPortfolioDateFilter = (payload: PortfolioDateFilterPayload): void => {
        if (payload.portfolioType === PortfolioType.ActivityLog && payload.routeName !== RouteType.FollowedAccounts && payload.routeName !== RouteType.FollowedAccount) {
            this.fetchActivityLog(payload.dateType, payload.from, payload.to);
        }
    }

    private fetchActivityLog = (dateType: FilterDateType, from: moment.Moment, to: moment.Moment): void => {
        const range: { from: Date, to: Date } = FilterUtil.getDateRange(dateType, from, to);
        Platform.dispatch(SetAccountLogsPending({pending: true}));
        this.fetchDealingLogs(range.from, range.to, []).then((logs: AccountLog[]) => {
                this._logger.debug("Received account logs: " + logs.length);
                Platform.dispatch(SetAccountLogsPending({pending: false}));
                logs.sort((l1: AccountLog, l2: AccountLog) => Utils.compareNumber(l2.DateTime, l1.DateTime));
                Platform.dispatch(SetAccountLogs({logs}));
            })
            .catch(() => {
                this._logger.error("Failed fetch account logs");
                Platform.dispatch(SetAccountLogsPending({pending: false}));
            });
    }

    private fetchDealingLogs = async (from: Date, to: Date, filterActionTypes: DealingLogType[]): Promise<AccountLog[]> => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const request: GetAccountLogRequest = {
            AccountId: accountState.accountId,
            from: DateTimeFormat.formatServerDate(from),
            to: DateTimeFormat.formatServerDate(to),
            filterActionTypes
        };
        const answer: [HttpReject, GetAccountLogResponse] = await Utils.to(XhrUtil.sendAuthenticated(request, "WebProfitServer/AccountStatesService.svc/json/GetAccountDealingLog"));
        return answer[1]?.Logs || [];
    }

    public doFetchAccountEquityLogs = async (from: Date, to: Date) => {
        const answer: [HttpReject, AccountLog[]] = await Utils.to(this.fetchDealingLogs(from, to, [DealingLogType.WithdrawableEquity]));
        if (Utils.isArrayNotEmpty(answer[1])) {
            const equityLogs: AccountLog[] = answer[1].sort((l1: AccountLog, l2: AccountLog) => Utils.compareNumber(l1.DateTime, l2.DateTime));
            if (Utils.isArrayNotEmpty(equityLogs)) {
                const {WithdrawableEquity} = Platform.reduxState<StoreState>().account;
                if (WithdrawableEquity) {
                    equityLogs.push({
                        WithdrawableEquity,
                        DateTime: new Date().getTime()
                    });
                }
            }
            return equityLogs;
        }
        return [];
    }

    public static doFetchPLAccountTransactions = async (from: Date, to: Date): Promise<ReChartDataEntry[]> => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const request: GetPLByAccountIdAndDateRangeRequest = {
            accountId: accountState.accountId,
            startDate: DateTimeFormat.formatServerDate(from),
            endDate: DateTimeFormat.formatServerDate(to)
        };
        const answer: [HttpReject, GetPLByAccountIdAndDateRangeResponse] = await Utils.to(XhrUtil.sendAuthenticated(request, "TradeServer/TransactionsProvider.svc/json/GetPLByAccountIdAndDateRange"));
        const entries: ReChartDataEntry[] = (answer[1] || []).map((transaction: PLAccountLog) => {
            return {
                Amount: transaction.Amount,
                Date: DateTimeFormat.parseServer(transaction.Date).toDate().getTime()
            };
        });
        const {TotalPL} = Platform.reduxState<StoreState>().account;
        if (Utils.isNotNull(TotalPL)) {
            entries.push({
                Amount: TotalPL,
                Date: new Date().getTime()
            });
        }
        return entries;
    }
}
