import {BrandType} from "platform/enum/BrandType";
import {HandleLoginResponsePayload} from "core/redux/auth/AuthReduxActions";

export class AutoLogin {

    private static _brand: BrandType;
    private static _payload: HandleLoginResponsePayload;

    private constructor() {
    }

    public static get brand(): BrandType {
        return this._brand;
    }

    public static set brand(value: BrandType) {
        this._brand = value;
    }

    public static get payload(): HandleLoginResponsePayload {
        return this._payload;
    }

    public static set payload(value: HandleLoginResponsePayload) {
        this._payload = value;
    }
}
