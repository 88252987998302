import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import SymbolsEngine from "core/engine/SymbolsEngine";
import SymbolsReducer from "core/redux/symbols/SymbolsReducer";
import {SymbolsState} from "core/state/SymbolsState";

export class SymbolsService implements Service<SymbolsEngine, SymbolsState> {

    private static _instance: SymbolsService;

    private constructor() {
    }

    public static instance(): SymbolsService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Symbols;
    }

    public reducers(): Array<Reducer<any>> {
        return [SymbolsReducer.instance()];
    }

    public engine(): SymbolsEngine {
        return SymbolsEngine.instance();
    }

    public state(): SymbolsState {
        return SymbolsState.instance();
    }
}
