import {TSMap} from "typescript-map";
import {PortfolioTableState} from "core/redux/portfolio/PortfolioReduxState";
import Utils from "platform/util/Utils";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import {StorageKey} from "enum/StorageKey";
import Platform from "platform/Platform";

enum PortfolioType {

    OpenTrades = "openTrades",
    EntryOrders = "entryOrders",
    ClosedTrades = "closedTrades",
    OrdersHistory = "ordersHistory",
    ActivityLog = "activityLog",
    FinancialSummary = "financialSummary",
    AvailableFeatures = "availableFeatures"
}

namespace PortfolioType {

    export function serialize(key: StorageKey, tables: TSMap<string, PortfolioTableState>): void {
        Platform.storage().setItem(key, JSON.stringify(tables));
    }

    export async function deserialize(key: StorageKey): Promise<TSMap<PortfolioType, PortfolioTableState>> {
        const tables: TSMap<PortfolioType, PortfolioTableState> = PortfolioType.base();
        const portfolioSortJson: string = await Platform.storage().getItem(key);
        if (!Utils.isObjectEmpty(portfolioSortJson)) {
            try {
                const portfolioSort: object = JSON.parse(portfolioSortJson);
                Object.keys(portfolioSort).forEach((portfolioType: PortfolioType) => {
                    const table: any = portfolioSort[portfolioType];
                    const tableSort: PortfolioTableState = {
                        activeColumn: table.activeColumn,
                        sort: new TSMap<TradeColumn, SortDirection>()
                    };
                    Object.keys(table.sort).forEach((column: TradeColumn) => {
                        tableSort.sort.set(column, table.sort[column]);
                    });
                    tables.set(portfolioType, tableSort);
                });
            } catch (e) {
            }
        }
        return tables;
    }

    export function base(): TSMap<PortfolioType, PortfolioTableState> {
        const tables: TSMap<PortfolioType, PortfolioTableState> = new TSMap();
        tables.set(PortfolioType.OpenTrades, {
            activeColumn: TradeColumn.CreatedDate,
            sort: new TSMap<TradeColumn, SortDirection>([
                [TradeColumn.CreatedDate, SortDirection.Desk]
            ])
        });
        tables.set(PortfolioType.EntryOrders, {
            activeColumn: TradeColumn.CreatedDate,
            sort: new TSMap<TradeColumn, SortDirection>([
                [TradeColumn.CreatedDate, SortDirection.Desk]
            ])
        });
        tables.set(PortfolioType.ClosedTrades, {
            activeColumn: TradeColumn.CloseDate,
            sort: new TSMap<TradeColumn, SortDirection>([
                [TradeColumn.CloseDate, SortDirection.Desk]
            ])
        });
        tables.set(PortfolioType.OrdersHistory, {
            activeColumn: TradeColumn.CloseDate,
            sort: new TSMap<TradeColumn, SortDirection>([
                [TradeColumn.CloseDate, SortDirection.Desk]
            ])
        });
        tables.set(PortfolioType.ActivityLog, {
            activeColumn: TradeColumn.Time,
            sort: new TSMap<TradeColumn, SortDirection>([
                [TradeColumn.Time, SortDirection.Desk]
            ])
        });
        return tables;
    }
}

export {PortfolioType};
