import {TSMap} from "typescript-map";
import Platform from "platform/Platform";
import {StorageKey} from "enum/StorageKey";
import Utils from "platform/util/Utils";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import {TableSortState} from "core/redux/common/TableSortState";

type TableCategoryLSData = {

    tables: TSMap<string, TableSortState>;
};

enum SymbolCategory {

    All = "all",
    MyWatchlist = "symbolsMyWatchlist",
    FX = "FX",
    Stock = "Stock",
    Commodity = "Commodity",
    Crypto = "Crypto",
    Index = "Index",
    NotSet = "NotSet",
    Derivatives = "Derivatives"
}

namespace SymbolCategory {

    export function serialize(key: StorageKey, tables: TSMap<string, TableSortState>): void {
        Platform.storage().setItem(key, JSON.stringify(tables));
    }

    export async function deserialize(key: StorageKey): Promise<TSMap<string, TableSortState>> {
        const tables: TSMap<string, TableSortState> = new TSMap();
        const symbolsSortJson: string = await Platform.storage().getItem(key);
        if (!Utils.isObjectEmpty(symbolsSortJson)) {
            try {
                const symbolsSort: object = JSON.parse(symbolsSortJson);
                Object.keys(symbolsSort).forEach((category: string) => {
                    const table: any = symbolsSort[category];
                    const tableSort: TableSortState = {
                        activeColumn: table.activeColumn,
                        sort: new TSMap<TradeColumn, SortDirection>()
                    };
                    Object.keys(table.sort).forEach((column: TradeColumn) => {
                        tableSort.sort.set(column, table.sort[column]);
                    });
                    tables.set(category, tableSort);
                });
                return tables;
            } catch (e) {
            }
        } else {
            tables.set(SymbolCategory.MyWatchlist, defaultTableState());
        }
        return Promise.resolve(tables);
    }

    export function defaultTableState(): TableSortState {
        return {
            activeColumn: TradeColumn.Symbol,
            sort: new TSMap<TradeColumn, SortDirection>([
                [TradeColumn.Symbol, SortDirection.Ask]
            ])
        };
    }

    export function order(category: string): number {
        if (category) {
            switch (category) {
                case SymbolCategory.All:
                    return -1;
                case SymbolCategory.MyWatchlist:
                    return 0;
                case SymbolCategory.Derivatives:
                    return 1;
                case SymbolCategory.Stock:
                    return 2;
                case SymbolCategory.Index:
                    return 3;
                case SymbolCategory.Commodity:
                    return 4;
                case SymbolCategory.FX:
                    return 5;
                case SymbolCategory.Crypto:
                    return 6;
                case SymbolCategory.NotSet:
                    return 7;
            }
        }
        return 8;
    }
}

export {
    SymbolCategory,
    TableCategoryLSData
};
