export enum LocalizationKeys {

    KYCComplianceFooterDeclarationProfessional = "KYCCompliance-FooterDeclarationProfessional",
    KYCComplianceServiceAgreement = "KYCCompliance-ServiceAgreement",
    KYCComplianceTitle = "KYCCompliance-Title",
    WebProfit_Common_WebProfit_Common_NonProtectedTradingDeclaration_Appropriate = "WebProfit_Common-WebProfit_Common_NonProtectedTradingDeclaration_Appropriate",
    WebProfit_Common_WebProfit_Common_NonProtectedTradingDeclaration_Inappropriate = "WebProfit_Common-WebProfit_Common_NonProtectedTradingDeclaration_Inappropriate",
    WebProfit_Messages_WebProfit_Messages_RollingContractTermAndConditionLink = "WebProfit_Messages-WebProfit_Messages_RollingContractTermAndConditionLink",
    WebProfit_StatusBar_WebProfit_StatusBar_TermsAndConditions = "WebProfit_StatusBar-WebProfit_StatusBar_TermsAndConditions",
}
