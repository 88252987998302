enum DayOfWeek {
  Sunday = "Sun",
  Monday = "Mon",
  Tuesday = "Tue",
  Wednesday = "Wed",
  Thursday = "Thu",
  Friday = "Fri",
  Saturday = "Sat",
}

export { DayOfWeek };
