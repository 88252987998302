import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import DealReducer from "core/redux/deal/DealReducer";
import DealEngine from "core/engine/DealEngine";
import SignalsReducer from "core/redux/deal/SignalsReducer";

export class DealService implements Service<DealEngine, any> {

    private static _instance: DealService;

    private constructor() {
    }

    public static instance(): DealService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Deal;
    }

    public reducers(): Array<Reducer<any>> {
        return [DealReducer.instance(), SignalsReducer.instance()];
    }

    public engine(): DealEngine {
        return DealEngine.instance();
    }

    public state(): any {
        return undefined;
    }
}
