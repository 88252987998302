import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import SettingsEngine from "core/engine/SettingsEngine";
import SettingsReducer from "core/redux/settings/SettingsReducer";

export class SettingsService implements Service<SettingsEngine, any> {

    private static _instance: SettingsService;

    private constructor() {
    }

    public static instance(): SettingsService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Settings;
    }

    public reducers(): Array<Reducer<any>> {
        return [SettingsReducer.instance()];
    }

    public engine(): SettingsEngine {
        return SettingsEngine.instance();
    }

    public state(): any {
        return undefined;
    }
}
