import {TSMap} from "typescript-map";
import {Logger} from "platform/logger/Logger";
import {WinManager} from "platform/integration/win/WinManager";
import {Win} from "platform/integration/win/Win";
import Utils from "platform/util/Utils";
import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IMessageHandler} from "platform/handler/MessageHandler";
import {CloseMeParameters} from "platform/integration/message/IntegrationWinClose";
import {ConnectionProvider} from "platform/integration/AbstractIntegration";

export enum WinHandshakeType {
    POST_MESSAGE = "post_message",
    ORIGIN = "origin"
}

export enum WinCloseReason {
    PLATFORM,
    POST_MESSAGE,
    CONFIRM_POST_MESSAGE
}

export interface WinCloseParams extends CloseMeParameters {

    reason?: WinCloseReason;
}

export interface WinManageParams {

    uid: string;
    provider: ConnectionProvider;
    handshakeType?: WinHandshakeType;
    onReady?: () => void;
    onClose?: (params?: WinCloseParams) => void;
    onReadyTimeout?: () => void;
    confirmClose?: () => Promise<any>;
}

export class Windows {

    private static _logger: Logger = Logger.Of("Windows");
    private static _wins: TSMap<string, WinManager> = new TSMap<string, WinManager>();
    private static _handlers: IMessageHandler<IntegrationMessage>[] = [];

    public static register(win: Win, manageParams: WinManageParams): WinManager {
        Utils.checkNotNull(manageParams);
        if (!this._wins.has(manageParams.uid)) {
            this._logger.debug("Register Win with uid: " + manageParams.uid);
            const winManager: WinManager = new WinManager(win, Object.assign({}, manageParams, {
                onClose: (params?: WinCloseParams) => {
                    if (this._wins.has(manageParams.uid)) {
                        this._logger.debug("UnRegister Win with uid: " + manageParams.uid);
                        this._wins.delete(manageParams.uid);
                    }
                    if (Utils.isNotNull(manageParams.onClose)) {
                        manageParams.onClose(params);
                    }
                }
            }));
            this._handlers.forEach((handler: IMessageHandler<IntegrationMessage>) => {
                winManager.setHandler(handler);
            });
            this._wins.set(manageParams.uid, winManager);
            return winManager;
        } else {
            this._logger.warn("Already has Win with uid: " + manageParams.uid);
        }
        return null;
    }

    public static unregister(uid: string): void {
        const winManager: WinManager = this._wins.get(uid);
        if (Utils.isNotNull(winManager)) {
            this._logger.debug("UnRegister Win with uid: " + uid);
            this._wins.delete(uid);
            winManager.close();
        }
    }

    public static has(uid: string): boolean {
        return this._wins.has(uid);
    }

    public static shutdown(): void {
        if (Utils.isMapNotEmpty(this._wins)) {
            this._logger.info("Shutdown");
            this._wins.forEach((winManager: WinManager) => {
                setTimeout(() => {
                    winManager.close();
                }, 0);
            });
            this._wins.clear();
        }
    }

    public static sendMessage(message: IntegrationMessage, uid: string): void {
        const winManager: WinManager = this._wins.get(uid);
        if (Utils.isNotNull(winManager)) {
            winManager.sendMessage(message);
        }
    }

    public static sendToAll(message: IntegrationMessage): void {
        if (Utils.isMapNotEmpty(this._wins)) {
            this._logger.debug("Send to all: " + message.classId);
            this._wins.forEach((winManager: WinManager) => {
                winManager.sendMessage(message);
            });
        }
    }

    public static setHandler(handler: IMessageHandler<IntegrationMessage>): void {
        this._handlers.push(handler);
    }
}
