import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {OnAccountChanged, OnLoggedOut,} from "core/redux/auth/AuthReduxActions";
import {SetPositionsOrders, SetPositionsOrdersPayload, SortTrades} from "core/redux/trades/TradesReduxActions";
import {PortfolioReduxState, PortfolioTableState} from "core/redux/portfolio/PortfolioReduxState";
import {PortfolioType} from "enum/PortfolioType";
import {
    PortfolioCounterPayload,
    SetAccountLogs,
    SetAccountLogsPayload,
    SetAccountLogsPending,
    SetAccountLogsPendingPayload, SetPortfolioCounter,
    SetPortfolioFilterCounter,
    SetPortfolioTab, SetPortfolioTables, SetPortfolioTablesPayload,
    SetPortfolioTabPayload,
    SetPortfolioTabType,
    SetPortfolioTradeColumn,
    SetPortfolioTradeColumnPayload,
    TogglePortfolioColumnSort,
} from "core/redux/portfolio/PortfolioReduxActions";
import {TSMap} from "typescript-map";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import Utils from "platform/util/Utils";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {SetPortfolioDateFilterType, OnFilterResetType} from "core/redux/filter/FilterReduxActions";
import PortfolioEngine from "core/engine/PortfolioEngine";
import {StorageKey} from "enum/StorageKey";
import {Routes} from "core/router/Routes";

const initialState = (): PortfolioReduxState => {
    return {
        activePortfolioTab: PortfolioType.OpenTrades,
        counters: new TSMap(),
        accountLogs: [],
        tables: new TSMap<PortfolioType, PortfolioTableState>()
    }
};

export default class PortfolioReducer extends Reducer<PortfolioReduxState> {

    private static _instance: PortfolioReducer;

    public static instance(): PortfolioReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const portfolioEngine: PortfolioEngine = Platform.engine(ServiceType.Portfolio);
        this._middlewareActions.set(SetPortfolioTabType, portfolioEngine.onSetPortfolioTab);
        this._middlewareActions.set(SetPortfolioDateFilterType, portfolioEngine.onSetPortfolioDateFilter);
        this._middlewareActions.set(OnFilterResetType, portfolioEngine.onSetPortfolioDateFilter);
    }

    public get name(): string {
        return "portfolio";
    }

    protected setup(builder: ReducerBuilder<PortfolioReduxState>): void {
        builder
            .init({
                ...initialState()
            })
            .handle(OnLoggedOut, (state: PortfolioReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    tables: state.tables
                };
            })
            .handle(OnAccountChanged, (state: PortfolioReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    tables: state.tables
                };
            })
            .handle(SetPortfolioTables, (state: PortfolioReduxState, {payload}: Action<SetPortfolioTablesPayload>) => {
                return Object.assign({}, state, {
                    tables: payload.tables
                });
            })
            .handle(SetPortfolioTab, (state: PortfolioReduxState, {payload}: Action<SetPortfolioTabPayload>) => {
                return Object.assign({}, state, {
                    activePortfolioTab: payload.portfolioType
                });
            })
            .handle(SetPositionsOrders, (state: PortfolioReduxState, {payload}: Action<SetPositionsOrdersPayload>) => {
                const newState: PortfolioReduxState = Utils.merge({}, state);
                newState.counters.set(PortfolioType.OpenTrades, Object.assign({}, newState.counters.get(PortfolioType.OpenTrades), {
                    count: payload.OpenPositions ? payload.OpenPositions.length : 0
                }));
                newState.counters.set(PortfolioType.EntryOrders, Object.assign({}, newState.counters.get(PortfolioType.EntryOrders), {
                    count: payload.EntryOrders ? payload.EntryOrders.length : 0
                }));
                return newState;
            })
            .handle(SetPortfolioFilterCounter, (state: PortfolioReduxState, {payload}: Action<PortfolioCounterPayload>) => {
                const newState: PortfolioReduxState = Utils.merge({}, state);
                newState.counters.set(payload.portfolioType, Object.assign({}, newState.counters.get(payload.portfolioType), {
                    filterCount: payload.value
                }));
                return newState;
            })
            .handle(SetPortfolioTradeColumn, (state: PortfolioReduxState, {payload}: Action<SetPortfolioTradeColumnPayload>) => {
                const newState: PortfolioReduxState = Utils.merge({}, state);
                if (!Routes.IsPartnerRoute(payload.routeName)) {
                    const portfolioTab = state.activePortfolioTab;
                    const tableState: PortfolioTableState = newState.tables.get(portfolioTab);
                    tableState.activeColumn = payload.tradeColumn;
                    tableState.sort.set(payload.tradeColumn, SortDirection.Ask);
                    PortfolioType.serialize(StorageKey.PortfolioSort, newState.tables);
                    setTimeout(() => {
                        Platform.dispatch(SortTrades({
                            portfolioType: portfolioTab,
                            column: payload.tradeColumn,
                            sortDirection: SortDirection.Ask,
                            routeName: payload.routeName
                        }));
                    }, 0);
                }
                return newState;
            })
            .handle(TogglePortfolioColumnSort, (state: PortfolioReduxState, {payload}: Action<SetPortfolioTradeColumnPayload>) => {
                const newState: PortfolioReduxState = Utils.merge({}, state);
                if (!Routes.IsPartnerRoute(payload.routeName)) {
                    const portfolioTab = state.activePortfolioTab;
                    const tableState: PortfolioTableState = newState.tables.get(portfolioTab);
                    const sort: TSMap<TradeColumn, SortDirection> = tableState.sort;
                    const sortDirection: SortDirection = sort.get(payload.tradeColumn);
                    const resultDirection: SortDirection = sortDirection ? SortDirection.reverse(sortDirection) : SortDirection.Ask;
                    sort.set(payload.tradeColumn, resultDirection);
                    PortfolioType.serialize(StorageKey.PortfolioSort, newState.tables);
                    setTimeout(() => {
                        Platform.dispatch(SortTrades({
                            portfolioType: portfolioTab,
                            column: payload.tradeColumn,
                            sortDirection: resultDirection,
                            routeName: payload.routeName
                        }));
                    }, 0);
                }
                return newState;
            })
            .handle(SetPortfolioCounter, (state: PortfolioReduxState, {payload}: Action<PortfolioCounterPayload>) => {
                const newState: PortfolioReduxState = Utils.merge({}, state);
                newState.counters.set(payload.portfolioType, {
                    count: payload.value
                });
                return newState;
            })
            .handle(SetAccountLogs, (state: PortfolioReduxState, {payload}: Action<SetAccountLogsPayload>) => {
                const newState: PortfolioReduxState = Utils.merge({}, state);
                newState.accountLogs = payload.logs;
                newState.counters.set(PortfolioType.ActivityLog, Object.assign({}, newState.counters.get(PortfolioType.ActivityLog), {
                    count: payload.logs ? payload.logs.length : 0
                }));
                return newState;
            })
            .handle(SetAccountLogsPending, (state: PortfolioReduxState, {payload}: Action<SetAccountLogsPendingPayload>) => {
                return Object.assign({}, state, {
                    accountLogsPending: payload.pending
                });
            });
    }
}
