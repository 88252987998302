import {ActionCreatorDefinition, createAction} from "redux-ts";
import {NotificationTab} from "enum/NotificationTab";
import {UIInAppNotification} from "protocol/notification/UIInAppNotification";
import {BooleanPayload} from "core/redux/StoreActions";
import {NotificationMessage} from "core/redux/inbox/InboxReduxState";

export const SetNotificationTabType: string =  "SetNotificationTab";
export const SetInboxMessagesType: string = "SetInboxMessages";
export const SetInboxMessagesLoadingType: string = "SetInboxMessagesLoading";
export const UpdateInboxMessageType: string = "UpdateInboxMessage";
export const AddInboxMessageType: string =  "AddInboxMessage";
export const RemoveInboxMessageType: string =  "RemoveInboxMessage";
export const SetInboxUnreadCountType: string =  "SetInboxUnreadCount";
export const ResetInboxUnreadCountType: string =  "ResetInboxUnreadCount";
export const SetInboxMessagesOpenedType: string =  "SetInboxMessagesOpenedCount";
export const ExecuteInboxCTAType: string = "ExecuteInboxCTA";
export const SetShowNotificationMessageType: string = "SetShowNotificationMessage";

export interface SetNotificationTabPayload {

    tab: NotificationTab;
}

export interface SetInboxMessagesPayload {

    isInit?: boolean;
    messages: UIInAppNotification[];
    total: number;
}

export interface SetInboxMessagesLoadingPayload {

    loading: boolean;
}

export interface SetInboxUnreadCountPayload {

    count: number;
}

export interface UpdateInboxMessagePayload {

    message: UIInAppNotification;
}

export interface InboxMessagePayload {

    message: UIInAppNotification;
}

export interface SetShowNotificationMessagePayload extends NotificationMessage {

}


export const SetNotificationTab: ActionCreatorDefinition<SetNotificationTabPayload, any> = createAction<SetNotificationTabPayload>(SetNotificationTabType);
export const SetInboxMessages: ActionCreatorDefinition<SetInboxMessagesPayload, any> = createAction<SetInboxMessagesPayload>(SetInboxMessagesType);
export const SetInboxMessagesLoading: ActionCreatorDefinition<SetInboxMessagesLoadingPayload, any> = createAction<SetInboxMessagesLoadingPayload>(SetInboxMessagesLoadingType);
export const UpdateInboxMessage: ActionCreatorDefinition<UpdateInboxMessagePayload, any> = createAction<UpdateInboxMessagePayload>(UpdateInboxMessageType);
export const AddInboxMessage: ActionCreatorDefinition<InboxMessagePayload, any> = createAction<InboxMessagePayload>(AddInboxMessageType);
export const RemoveInboxMessage: ActionCreatorDefinition<InboxMessagePayload, any> = createAction<InboxMessagePayload>(RemoveInboxMessageType);
export const SetInboxUnreadCount: ActionCreatorDefinition<SetInboxUnreadCountPayload, any> = createAction<SetInboxUnreadCountPayload>(SetInboxUnreadCountType);
export const ResetInboxUnreadCount: ActionCreatorDefinition<any, any> = createAction<any>(ResetInboxUnreadCountType);
export const SetInboxMessagesOpened: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetInboxMessagesOpenedType);
export const ExecuteInboxCTA: ActionCreatorDefinition<InboxMessagePayload, any> = createAction<InboxMessagePayload>(ExecuteInboxCTAType);
export const SetShowNotificationMessage: ActionCreatorDefinition<SetShowNotificationMessagePayload, any> = createAction<SetShowNotificationMessagePayload>(SetShowNotificationMessageType);
