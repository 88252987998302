import {ActionCreatorDefinition, createAction} from "redux-ts";
import {Deal} from "platform/protocol/trading/Deal";
import {Exposure} from "protocol/trade/Exposure";
import {Trade} from "core/redux/trades/TradesReduxState";
import {PortfolioType} from "enum/PortfolioType";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import {TSMap} from "typescript-map";

export const SetPositionsOrdersType: string =  "SetPositionsOrders";
export const SetTradesType: string =  "SetTrades";
export const SortTradesType: string =  "SortTrades";
export const SelectDealType: string = "SelectDeal";

export interface SetPositionsOrdersPayload {

    OpenPositions: Deal[];
    EntryOrders: Deal[];
    Exposures: Exposure[];
}

export interface SetTradesPayload {

    OpenPositionsMap: {[key: number]: Deal};
    EntryOrdersMap: {[key: number]: Deal};
    OverviewPositions: Deal[];
    EntryOrders: Deal[];
    trades: Trade[];
    selectedDeals: TSMap<PortfolioType, Deal>;
}

export interface SortTradesPayload {

    portfolioType: PortfolioType;
    column: TradeColumn;
    sortDirection: SortDirection;
    routeName?: string;
}

export interface SelectDealPayload {

    portfolioType: PortfolioType;
    id: number;
}

export const SetPositionsOrders: ActionCreatorDefinition<SetPositionsOrdersPayload, any> = createAction<SetPositionsOrdersPayload>(SetPositionsOrdersType);
export const SetTrades: ActionCreatorDefinition<SetTradesPayload, any> = createAction<SetTradesPayload>(SetTradesType);
export const SortTrades: ActionCreatorDefinition<SortTradesPayload, any> = createAction<SortTradesPayload>(SortTradesType);
export const SelectDeal: ActionCreatorDefinition<SelectDealPayload, any> = createAction<SelectDealPayload>(SelectDealType)
