import {Engine} from "platform/engine/Engine";
import {XhrUtil} from "core/util/XhrUtil";
import {SetAuthStatusPayload} from "core/redux/auth/AuthReduxActions";
import Platform from "platform/Platform";
import {StoreState} from "core/redux/StoreState";
import Utils from "platform/util/Utils";
import {HttpReject} from "platform/network/http/Http";
import {BadRequestResponse} from "protocol/BadRequestResponse";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import {
    AddSubscribedSymbols,
    FailedFetchSymbols,
    RemoveSubscribedSymbol, SelectSymbol,
    SetSortedSymbols,
    SetSubscribedSymbols,
    SetSymbols, SetSymbolsCounters,
    SetSymbolTablesLS,
    SetSymbolTradeColumnPayload, SetTopSymbols, SubscribeSymbolsPayload,
    ToggleSymbolColumnSortPayload, UnSubscribeSymbolsPayload
} from "core/redux/symbols/SymbolsReduxActions";
import {TSMap} from "typescript-map";
import {SymbolSubscriptionInfo} from "protocol/symbol/SymbolSubscriptionInfo";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import {Quote} from "platform/protocol/trading/Quote";
import {HideLoader, SetLoader} from "platform/redux/core/CoreActions";
import {LoaderType} from "platform/enum/LoaderType";
import {SymbolCategory, TableCategoryLSData} from "enum/SymbolCategory";
import {StorageKey} from "enum/StorageKey";
import {
    CategoryCounterState,
    SymbolReduxState,
    SymbolSelection,
} from "core/redux/symbols/SymbolReduxState";
import {GetUserSubscribedSymbolsResponse} from "protocol/symbol/GetUserSubscribedSymbolsResponse";
import {QuotesReduxState} from "core/redux/quotes/QuotesReduxState";
import {SymbolTrend} from "platform/protocol/trading/SymbolTrend";
import {GetAllSymbolsResponse} from "protocol/symbol/GetAllSymbolsResponse";
import {ServiceType} from "enum/ServiceType";
import {SetAccountInfoPayload} from "core/redux/account/AccountReduxActions";
import {SetSymbolFilterContinentPayload} from "core/redux/filter/FilterReduxActions";
import {FilterUtil} from "core/util/FilterUtil";
import {FilterSymbolState} from "core/redux/filter/FilterReduxState";
import {SymbolsState} from "core/state/SymbolsState";
import {TableSortState} from "core/redux/common/TableSortState";
import {ReadyState} from "core/state/ReadyState";
import ChartEngine from "core/engine/ChartEngine";
import {ModifySymbolSubscriptionRequest} from "protocol/symbol/ModifySymbolSubscriptionRequest";
import {AccountState} from "core/state/AccountState";
import {GetAllSymbolsRequest} from "protocol/symbol/GetAllSymbolsRequest";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {SetLangCodePayload} from "platform/redux/translation/TranslationActions";
import {LangCode} from "platform/enum/LangCode";

export const SortSymbols = (column: TradeColumn, direction: SortDirection, quotes: TSMap<number, Quote>) => {
    return (s1: TradeSymbol, s2: TradeSymbol): number => {
        const q1: Quote = quotes.get(s1.SymbolId);
        const q2: Quote = quotes.get(s2.SymbolId);
        let result: number = 0;
        switch (column) {
            case TradeColumn.Symbol:
                result = Utils.compareString(s1.text?.toUpperCase(), s2.text?.toUpperCase());
                break;
            case TradeColumn.Price:
                result = q1 && q2 && Utils.compareNumber(q1.ChangeInPercentage, q2.ChangeInPercentage);
                break;
            case TradeColumn.Chart1d:
                result = q1 && q2 && Utils.compareNumber(q1.ChangeInPercentage, q2.ChangeInPercentage);
                break;
            case TradeColumn.Buy:
                result = q1 && q2 && Utils.compareNumber(q1.Ask, q2.Ask);
                break;
            case TradeColumn.Sell:
                result = q1 && q2 && Utils.compareNumber(q1.Bid, q2.Bid);
                break;
            case TradeColumn.Trend:
                const st1: SymbolTrend = q1?.SymbolTrend || {};
                const st2: SymbolTrend = q2?.SymbolTrend || {};
                result = Utils.compareNumber(st1.BuyPositionsPercentage, st2.BuyPositionsPercentage);
                if (result === 0) {
                    result = Utils.compareNumber(st1.SellPositionsPercentage, st2.SellPositionsPercentage);
                }
                break;
            case TradeColumn.MarketCap:
                const marketCap1: number = s1?.marketData?.marketCap || s1?.marketCap;
                const marketCap2: number = s2?.marketData?.marketCap || s2?.marketCap;
                result = Utils.compareNumber(marketCap1, marketCap2);
                break;
            case TradeColumn.Revenue:
                const revenue1: number = s1?.marketData?.revenue || s1?.revenue;
                const revenue2: number = s2?.marketData?.revenue || s2?.revenue;
                result = Utils.compareNumber(revenue1, revenue2);
                break;
            case TradeColumn.Industry:
                const industry1: string = s1?.marketData?.industry || s1?.industry;
                const industry2: string = s2?.marketData?.industry || s2?.industry;
                result = Utils.compareString(industry1?.toUpperCase(), industry2?.toUpperCase());
                break;
        }
        return direction === SortDirection.Ask ? result : -result;
    };
};

export default class SymbolsEngine extends Engine {

    private static _instance: SymbolsEngine;

    public static instance(): SymbolsEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        const data: TableCategoryLSData = await this.deserializeLS();
        Platform.dispatch(SetSymbolTablesLS(data));
    }

    private deserializeLS = async (): Promise<TableCategoryLSData> => {
        const tables: TSMap<string, TableSortState> = await SymbolCategory.deserialize(StorageKey.SymbolsSort);
        return Promise.resolve({tables});
    }

    public doSetAuthStatus = async (payload: SetAuthStatusPayload): Promise<void> => {
        const {connected, accountType} = Platform.reduxState<StoreState>().auth;
        if (connected) {
            if (Utils.isNotNull(payload.authStatus.accountType) && accountType !== payload.authStatus.accountType) {
                await this.doFetchSymbols(LanguageUtil.languageCode());
            }
        }
    }

    public doSetLangCode = async (payload: SetLangCodePayload): Promise<void> => {
        const {symbolsLoaded} = Platform.reduxState<StoreState>().symbols;
        if (symbolsLoaded) {
            await this.doFetchSymbols(payload.langCode);
        }
    }

    public doSetAccountInfo = async (payload: SetAccountInfoPayload): Promise<void> => {
        const {ProfitTradingGroupId} = Platform.reduxState<StoreState>().account;
        const newTradingGroupId: number = payload.accountInfo?.ProfitTradingGroupId;
        if (Utils.greaterThen0(ProfitTradingGroupId) && Utils.greaterThen0(newTradingGroupId) && ProfitTradingGroupId !== newTradingGroupId) {
            this._logger.debug(`Trading group changed from ${ProfitTradingGroupId} to ${newTradingGroupId}`);
            const chartEngine: ChartEngine = Platform.engine(ServiceType.Chart);
            chartEngine.onTradingGroupChange();
            await this.doFetchSymbols(LanguageUtil.languageCode());
        }
    }

    private doFetchSymbols = async (languageCode: LangCode): Promise<void> => {
        this._logger.debug("Fetching subscribed symbols");
        let subscribedSymbolIds: number[] = [];
        const answer: [HttpReject, GetUserSubscribedSymbolsResponse] = await Utils.to(XhrUtil.sendToAccountService({}, "GetUserSubscribedSymbols"));
        if (answer[1]) {
            subscribedSymbolIds = answer[1].SymbolIds || [];
        } else {
            this._logger.debug("Failed fetch subscribed symbols");
        }
        this._logger.debug("Subscribed symbols, size: " + subscribedSymbolIds.length);
        this._logger.debug("Fetching all symbols");
        const request: GetAllSymbolsRequest = {
            languageCode
        };
        XhrUtil.sendToAccountService(request, "GetAllSymbolsv2").then((response: GetAllSymbolsResponse) => {
            const tradeSymbols: TradeSymbol[] = response.Symbols || [];
            this._logger.debug("All Symbols fetched, size: " + tradeSymbols.length);
            const accountState: AccountState = Platform.state(ServiceType.Account);
            const symbolsState: SymbolsState = Platform.state<SymbolsState>(ServiceType.Symbols);
            const {symbolFilter} = Platform.reduxState<StoreState>().filter;
            const quotesReduxState: QuotesReduxState = Platform.reduxState<StoreState>().quotes;
            const symbolReduxState: SymbolReduxState = Platform.reduxState<StoreState>().symbols;
            const symbols: TSMap<number, TradeSymbol> = new TSMap();
            const categories: TSMap<string, TradeSymbol[]> = new TSMap<string, TradeSymbol[]>();
            const counters: TSMap<string, CategoryCounterState> = new TSMap<string, CategoryCounterState>();
            const filterCounters: TSMap<string, number> = new TSMap<string, number>();
            const selectedSymbols: TSMap<string, SymbolSelection> = new TSMap<string, SymbolSelection>();
            const tables: TSMap<string, TableSortState> = symbolReduxState.tables.clone();
            const subscribedSymbols: TSMap<number, SymbolSubscriptionInfo> = new TSMap();
            const overview: TradeSymbol[] = [];
            const myWatchSymbols: TradeSymbol[] = [];
            let myWatchFilterCount: number = 0;
            if (Utils.isArrayNotEmpty(tradeSymbols)) {
                tradeSymbols.forEach((symbol: TradeSymbol) => {
                    let category: TradeSymbol[] = categories.get(symbol.category);
                    if (Utils.isNull(category)) {
                        categories.set(symbol.category, category = []);
                        filterCounters.set(symbol.category, 0);
                        if (symbol.marketData?.category) {
                            symbolsState.setCategoryLocalization(symbol.category, symbol.marketData?.category);
                        }
                        if (!tables.has(symbol.category)) {
                            tables.set(symbol.category, SymbolCategory.defaultTableState());
                        }
                    }
                    const SymbolId: number = parseInt(symbol.symbolId, 10);
                    const Symbol: TradeSymbol = {
                        ...symbol,
                        SymbolId
                    };
                    symbols.set(SymbolId, Symbol);
                    symbolsState.addSymbol(Symbol);
                    category.push(Symbol);
                    const passFilter: boolean = Utils.isNotNull(FilterUtil.filterSymbol(symbolFilter, Symbol));
                    if (subscribedSymbolIds.indexOf(SymbolId) > -1) {
                        subscribedSymbols.set(SymbolId, {
                            AccountId: accountState.accountId,
                            SymbolDisplayName: Symbol.symbol,
                            SymbolId,
                            ViewInBox: true,
                            ViewInGrid: true,
                        });
                        myWatchSymbols.push(Symbol);
                        if (passFilter) {
                            myWatchFilterCount++;
                        }
                    }
                    if (passFilter) {
                        filterCounters.set(symbol.category, filterCounters.get(symbol.category) + 1);
                    }
                });
                categories.forEach((Symbols: TradeSymbol[], categoryName: string) => {
                    counters.set(categoryName, {
                        categoryName,
                        count: Symbols.length,
                        filterCount: filterCounters.get(categoryName)
                    });
                    const tableState: TableSortState = tables.get(categoryName);
                    Symbols.sort(SortSymbols(tableState.activeColumn, tableState.sort.get(tableState.activeColumn), quotesReduxState.quotes));
                    for (let i = 0; i < Symbols.length; i++) {
                        const symbol: TradeSymbol = Symbols[i];
                        if (FilterUtil.filterSymbol(symbolFilter, symbol)) {
                            selectedSymbols.set(categoryName, {
                                symbol,
                                index: 0
                            });
                            break;
                        }
                    }
                });
                for (let i = subscribedSymbolIds.length - 1; i >= 0; i--) {
                    const symbol: TradeSymbol = symbols.get(subscribedSymbolIds[i]);
                    if (symbol) {
                        overview.push(symbol);
                    }
                }
            }
            const myWatchTableState: TableSortState = tables.get(SymbolCategory.MyWatchlist);
            myWatchSymbols.sort(SortSymbols(myWatchTableState.activeColumn, myWatchTableState.sort.get(myWatchTableState.activeColumn), quotesReduxState.quotes));
            categories.set(SymbolCategory.MyWatchlist, myWatchSymbols);
            for (let i = 0; i < myWatchSymbols.length; i++) {
                const myWatchSymbol: TradeSymbol = myWatchSymbols[i];
                if (FilterUtil.filterSymbol(symbolFilter, myWatchSymbol)) {
                    selectedSymbols.set(SymbolCategory.MyWatchlist, {
                        symbol: myWatchSymbol,
                        index: 0
                    });
                    break;
                }
            }
            counters.set(SymbolCategory.MyWatchlist, {
                categoryName: SymbolCategory.MyWatchlist,
                count: myWatchSymbols.length,
                filterCount: myWatchFilterCount
            });
            const countersSorted: TSMap<string, CategoryCounterState> = new TSMap<string, CategoryCounterState>();
            [...counters.keys()].sort((sc1: SymbolCategory, sc2: SymbolCategory) => Utils.compareNumber(SymbolCategory.order(sc1), SymbolCategory.order(sc2)))
                .forEach((sc: SymbolCategory) => {
                    countersSorted.set(sc, counters.get(sc));
                });
            Platform.dispatch(SetSymbols({symbols, overview, categories, counters: countersSorted, selectedSymbols, tables}));
            Platform.dispatch(SetSubscribedSymbols({
                symbols: subscribedSymbols
            }));
            // TODO Get top movers from server once it's ready
            Platform.dispatch(SetTopSymbols({symbols: []}));
            ReadyState.hasSymbols = true;
        }).catch((reject: HttpReject) => {
            this._logger.debug("Failed fetch symbols");
            Platform.dispatch(FailedFetchSymbols({}));
            if (reject && Utils.isNotNull(reject.response)) {
                let response: BadRequestResponse;
                try {
                    response = JSON.parse(reject.response);
                } catch (e) {
                }
                if (Utils.isNull(response) || response.IsSecurityException) {
                    this._logger.warn("Failed fetch all symbols");
                }
            }
        });
    }

    public doSetSymbolFilterContinent = async ({continents}: SetSymbolFilterContinentPayload): Promise<void> => {
        const {symbolFilter} = Platform.reduxState<StoreState>().filter;
        const filter: FilterSymbolState = {
            continents,
            marketStatus: symbolFilter.marketStatus
        };
        const {categories, selectedSymbols} = Platform.reduxState<StoreState>().symbols;
        const counters: TSMap<string, CategoryCounterState> = new TSMap<string, CategoryCounterState>();
        const selections: TSMap<string, {pass: boolean, symbol?: TradeSymbol}> = new TSMap();
        categories.forEach((symbols: TradeSymbol[], categoryName: string) => {
            selections.set(categoryName, {pass: false});
            const selectedSymbol: SymbolSelection = selectedSymbols.get(categoryName);
            const filterCount: number = symbols.filter((s: TradeSymbol) => {
                const passFilter: boolean = Utils.isNotNull(FilterUtil.filterSymbol(filter, s));
                if (passFilter) {
                    const selection: {pass: boolean, symbol?: TradeSymbol} = selections.get(categoryName);
                    if (selectedSymbol?.symbol?.SymbolId === s.SymbolId) {
                        selections.set(categoryName, {pass: true, symbol: s});
                    } else if (!selection.symbol) {
                        selections.set(categoryName, {...selection, ...{symbol: s}});
                    }
                }
                return passFilter;
            }).length;
            counters.set(categoryName, {
                categoryName,
                count: symbols.length,
                filterCount
            })
        });
        const countersSorted: TSMap<string, CategoryCounterState> = new TSMap<string, CategoryCounterState>();
        [...counters.keys()].sort((sc1: SymbolCategory, sc2: SymbolCategory) => Utils.compareNumber(SymbolCategory.order(sc1), SymbolCategory.order(sc2)))
            .forEach((sc: SymbolCategory) => {
                countersSorted.set(sc, counters.get(sc));
            });
        Platform.dispatch(SetSymbolsCounters({counters: countersSorted}));
        selections.forEach((selection: {pass: boolean, symbol?: TradeSymbol}, categoryName: string) => {
            if (!selection.pass && selection.symbol) {
                Platform.dispatch(SelectSymbol({
                    category: categoryName,
                    symbol: selection.symbol
                }));
            }
        });
    }

    public doSetSymbolTradeColumn = (payload: SetSymbolTradeColumnPayload) => {
        const {symbols, quotes} = Platform.reduxState<StoreState>();
        const {category, tradeColumn} = payload;
        this._logger.debug("Set symbol trade column: " + tradeColumn + " for category: " + category);
        const map: TSMap<string, TradeSymbol[]> = symbols.categories;
        const newSymbols: TradeSymbol[] = [...map.get(category)];
        newSymbols.sort(SortSymbols(payload.tradeColumn, SortDirection.Ask, quotes.quotes));
        Platform.dispatch(SetSortedSymbols({
            category,
            tradeColumn,
            sortDirection: SortDirection.Ask,
            symbols: newSymbols
        }));
    }

    public doToggleSymbolTradeColumn = (payload: ToggleSymbolColumnSortPayload) => {
        const {symbols, quotes} = Platform.reduxState<StoreState>();
        const {category, tradeColumn} = payload;
        const tables: TSMap<string, TableSortState> = symbols.tables;
        const tableState: TableSortState = tables.get(category);
        const sort: TSMap<TradeColumn, SortDirection> = tableState.sort;
        const sortDirection: SortDirection = sort.get(payload.tradeColumn);
        const resultDirection: SortDirection = sortDirection ? SortDirection.reverse(sortDirection) : SortDirection.Ask;
        this._logger.debug("Toggle symbol trade column: " + payload.tradeColumn + " for category: " + category + " result direction: " + resultDirection);
        const map: TSMap<string, TradeSymbol[]> = symbols.categories;
        const newSymbols: TradeSymbol[] = [...map.get(category)];
        newSymbols.sort(SortSymbols(payload.tradeColumn, resultDirection, quotes.quotes));
        Platform.dispatch(SetSortedSymbols({
            category,
            tradeColumn,
            sortDirection: resultDirection,
            symbols: newSymbols
        }));
    }

    public doSubscribeSymbols = async (payload: SubscribeSymbolsPayload) => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const subscribedSymbols: TSMap<number, SymbolSubscriptionInfo> = Platform.reduxState<StoreState>().symbols.subscribedSymbols;
        const subscriptions: SymbolSubscriptionInfo[] = [];
        const symbolsToSubscribe: TradeSymbol[] = [];
        payload?.symbols?.forEach((ts: TradeSymbol) => {
            if (!subscribedSymbols.has(ts.SymbolId)) {
                subscriptions.push({
                    AccountId: accountState.accountId,
                    SymbolDisplayName: ts.symbol,
                    SymbolId: ts.SymbolId,
                    ViewInBox: true,
                    ViewInGrid: true,
                });
                symbolsToSubscribe.push(ts);
            }
        });
        if (Utils.isArrayNotEmpty(subscriptions)) {
            this._logger.debug("Try to subscribe for symbols");
            const newSubscriptions: SymbolSubscriptionInfo[] = [...subscribedSymbols.values(), ...subscriptions];
            const request: ModifySymbolSubscriptionRequest = {
                AccountId: accountState.accountId,
                SymbolsView: newSubscriptions
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const answer: [HttpReject, any] = await Utils.to(this.sendToAccountSymbolView(request, "Upsert"));
            Platform.dispatch(HideLoader({}));
            if (answer[0]) {
                this._logger.warn("Failed subscribe symbol");
                XhrUtil.notifyReject(answer[0]);
            } else {
                this._logger.debug("Symbols subscribed");
                const {quotes, filter} = Platform.reduxState<StoreState>();
                const symbols: TSMap<number, SymbolSubscriptionInfo> = subscribedSymbols.clone();
                subscriptions.forEach((subscription: SymbolSubscriptionInfo) => {
                    symbols.set(subscription.SymbolId, subscription);
                });
                Platform.dispatch(SetSubscribedSymbols({
                    symbols
                }));
                Platform.dispatch(AddSubscribedSymbols({symbols: symbolsToSubscribe, quotes: quotes.quotes, symbolFilter: filter.symbolFilter}));
            }
        } else {
            this._logger.debug("Already subscribed for symbols in payload");
        }
    }

    public doUnSubscribeSymbol = async (payload: UnSubscribeSymbolsPayload) => {
        const {SymbolId, symbol} = payload.symbol;
        const subscribedSymbols: TSMap<number, SymbolSubscriptionInfo> = Platform.reduxState<StoreState>().symbols.subscribedSymbols;
        if (subscribedSymbols.has(SymbolId)) {
            this._logger.debug("Try to unsubscribe from symbol: " + symbol);
            const accountState: AccountState = Platform.state(ServiceType.Account);
            const subscriptions: SymbolSubscriptionInfo[] = subscribedSymbols.values().filter((subscribedSymbol: SymbolSubscriptionInfo) => subscribedSymbol.SymbolId !== payload.symbol.SymbolId);
            const request: ModifySymbolSubscriptionRequest = {
                AccountId: accountState.accountId,
                SymbolsView: subscriptions
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const answer: [HttpReject, any] = await Utils.to(this.sendToAccountSymbolView(request, "Upsert"));
            Platform.dispatch(HideLoader({}));
            if (answer[0]) {
                this._logger.warn("Failed unsubscribe symbol");
                XhrUtil.notifyReject(answer[0]);
            } else {
                this._logger.debug("Symbol " + symbol + " unsubscribed");
                const {symbolFilter} = Platform.reduxState<StoreState>().filter;
                const symbols: TSMap<number, SymbolSubscriptionInfo> = subscribedSymbols.clone();
                symbols.delete(SymbolId);
                Platform.dispatch(SetSubscribedSymbols({
                    symbols
                }));
                Platform.dispatch(RemoveSubscribedSymbol({symbol: payload.symbol, symbolFilter}));
            }
        } else {
            this._logger.debug("Already unsubscribed from symbol: " + symbol);
        }
    }

    private async sendToAccountSymbolView(request: any, path: string): Promise<any> {
        return XhrUtil.sendAuthenticated(request, "TradeServer/AccountSymbolViewService.svc/json/" + path);
    }
}
