import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import AuthReducer from "core/redux/auth/AuthReducer";
import AuthEngine from "core/engine/AuthEngine";
import {AuthState} from "core/state/AuthState";

export class AuthService implements Service<AuthEngine, AuthState> {

    private static _instance: AuthService;

    private constructor() {
    }

    public static instance(): AuthService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Auth;
    }

    public reducers(): Array<Reducer<any>> {
        return [AuthReducer.instance()];
    }

    public engine(): AuthEngine {
        return AuthEngine.instance();
    }

    public state(): AuthState {
        return AuthState.instance();
    }
}
