enum CardType {

  Withdrawals = "withdrawals",
  TopUpLimits = "topUpLimits",
  AnnualStatement = "annualStatement",
  MyLeverage = "myLeverage",
  CloseOutMethod = "closeOutMethod",
  EMT = "emt",
}

export default CardType;
