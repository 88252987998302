import {actionTypes} from "redux-router5";
import Utils from "platform/util/Utils";
import {Logger} from "platform/logger/Logger";
import {Action, Dispatch, Middleware, MiddlewareAPI} from "redux";
import Script from "platform/network/script/Script";
import {DPK} from "platform/dpk/Dpk";
import Platform from "platform/Platform";
import {ExecuteDpk} from "platform/redux/dpk/DpkActions";
import {BIEventType} from "enum/BIEventType";
import {AppCuesIdentifyProps, IAppCues} from "core/appCues/IAppCues";

interface AppCuesFlowInfo {

    flowId: string;
    flowName: string;
    flowType: string;
    flowVersion: number;
    id: string;
    localeId: string;
    localeName: string;
    name: string;
    sessionId: number;
    timestamp: number;
}

// documentation: https://docs.appcues.com/article/161-javascript-api
export class AppCues implements IAppCues {

    private readonly _logger: Logger = Logger.Of("AppCues");
    private _appCues: any;
    private _middleware: Middleware;
    private _identifyProps: AppCuesIdentifyProps;
    private _identified: boolean;
    private _startedTotalCount: number = 0;
    private _startedUniqueCount: Set<string> = new Set<string>();

    public load = (flowId: string): void => {
        this._logger.debug("Inject script");
        Script.injectScript("https://fast.appcues.com/40754.js", "appCues", () => {
            this._appCues = (window as any).Appcues;
            if (Utils.isNotNull(this._appCues)) {
                this._logger.debug("Ready");
                this._appCues.on("all", (event: string, payload: any) => {
                    if (event === "step_interacted" && payload?.flowName?.indexOf(DPK) === 0) {
                        Platform.dispatch(ExecuteDpk({dpk: payload?.flowName}));
                    }
                });
                this._appCues.on("flow_started", (payload: AppCuesFlowInfo) => {
                    this._startedTotalCount++;
                    this._startedUniqueCount.add(payload.flowId);
                    Platform.bi().track(BIEventType.WalkThroughStarted, {
                        WTName: payload.flowName,
                        WTId: payload.flowId,
                        TotalWTStarted: this._startedTotalCount,
                        TotalUniqueWTStarted: this._startedUniqueCount.size
                    });
                    const flowName: string = payload.flowName;
                    if (flowName.indexOf("[F]") !== -1) {
                        this.freezeUI();
                    }
                });
                this._appCues.on("flow_completed", (payload: AppCuesFlowInfo) => {
                    Platform.bi().track(BIEventType.WalkThroughCompleted, {
                        WTName: payload.flowName,
                        WTId: payload.flowId,
                        TotalWTStarted: this._startedTotalCount,
                        TotalUniqueWTStarted: this._startedUniqueCount.size
                    });
                    this.unfreezeUI();
                });
                this._appCues.on("flow_skipped", (payload) => {
                    this.unfreezeUI();
                });
                this._appCues.on("step_child_error", () => {
                    this.unfreezeUI();
                });
                if (flowId) {
                    this.identify();
                    this.show(flowId);
                }
            } else {
                this._logger.debug("Failed");
            }
        });
    }

    private cleanFlowName(flowName: string): string {
        let r: string = flowName;
        r = r.replace("[F]", "");
        return r;
    }

    private freezeUI(): void {
        if (document.getElementById("RootElement")) {
            document.getElementById("RootElement").classList.add("appcues-freeze-ui");
        }
    }

    private unfreezeUI(): void {
        if (document.getElementById("RootElement")) {
            document.getElementById("RootElement").classList.remove("appcues-freeze-ui");
        }
    }

    public setIdentify = (value: AppCuesIdentifyProps): void => {
        this._identifyProps = value;
    }

    public identify = (): void => {
        if (this._appCues && !this._identified && this._identifyProps) {
            this._logger.debug("Identify: " + this._identifyProps.userId);
            this._identified = true;
            this._appCues.identify(this._identifyProps.userId, {
                lang: this._identifyProps.langCode
            });
        }
    }

    public show = (id: string): void => {
        if (this._appCues) {
            this._logger.debug("Show flow: " + id);
            this._appCues.show(id);
        }
    }

    public middleware<State>(): Middleware {
        if (Utils.isNull(this._middleware)) {
            this._middleware = (api: MiddlewareAPI<any>) => (next: Dispatch<Action>) => <A extends Action>(action: A): A => {
                if (action.type === actionTypes.TRANSITION_SUCCESS && Utils.isNotNull(this._appCues)) {
                    this._appCues.page();
                }
                return next(action);
            };
        }
        return this._middleware;
    }
}
