import {TranslationLoader} from "platform/translation/TranslationLoader";
import {LangCode} from "platform/enum/LangCode";
import {Http} from "platform/network/http/Http";

export class TranslationLoaderImpl implements TranslationLoader {

    private static _instance: TranslationLoader;
    private _path: string;

    private constructor(path: string) {
        this._path = path;
    }

    public static instance(path?: string): TranslationLoader {
        return this._instance || (this._instance = new this(path));
    }

    public load(langCode: LangCode): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                Http.getJson("resources/translation/" + (this._path ? this._path + "/" : "") + langCode + ".json").then((data) => {
                    resolve(data);
                }).catch((e) => {
                    reject(e);
                });
            }
        );
    }
}
