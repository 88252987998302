import {Logger} from "platform/logger/Logger";
import AppEngine from "core/engine/AppEngine";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {EnvType} from "platform/enum/EnvType";

export class ReadyState {

    private static _logger: Logger = Logger.Of("ReadyState");
    private static _hasSymbols: boolean;
    private static _hasAccountState: boolean;
    private static _hasThirdPartyDisclaimer: boolean;
    private static _hasLDPlatformProps: boolean;
    private static _ready: boolean;

    private constructor() {
    }

    public static get ready(): boolean {
        return this._ready;
    }

    public static set hasSymbols(value: boolean) {
        this._hasSymbols = value;
        this.checkAppReady();
    }

    public static set hasAccountState(value: boolean) {
        this._hasAccountState = value;
        this.checkAppReady();
    }

    public static set hasThirdPartyDisclaimer(value: boolean) {
        this._hasThirdPartyDisclaimer = value;
        this.checkAppReady();
    }

    public static set hasLDPlatformProps(value: boolean) {
        this._hasLDPlatformProps = value;
        this.checkAppReady();
    }

    public static clear(): void {
        this._logger.debug("Clear");
        this.logOut();
        this._hasLDPlatformProps = false;
    }

    public static logOut(): void {
        this._ready = false;
        this._hasSymbols = false;
        this._hasAccountState = false;
        this._hasThirdPartyDisclaimer = false;
    }

    private static checkAppReady(): void {
        this._logger.debug(`Has symbols ${this._hasSymbols} Has account state ${this._hasAccountState} Has third party disclaimer ${this._hasThirdPartyDisclaimer} Has LD platform props ${this._hasLDPlatformProps}`);
        if (!this._ready && this._hasSymbols && this._hasAccountState && this._hasThirdPartyDisclaimer && this._hasLDPlatformProps) {
            this._ready = true;
            if (Platform.environment().type() === EnvType.Web) {
                document.getElementById("RootElement")?.setAttribute("AppReady", "true");
            }
            const appEngine: AppEngine = Platform.engine(ServiceType.App);
            appEngine.tryExecuteDpk();
        }
    }
}
