import {ActionCreatorDefinition, createAction} from "redux-ts";
import {SymbolSignal} from "platform/protocol/trading/SymbolSignal";
import {SignalsSettings, SignalsTabView} from "core/redux/deal/SignalsReduxState";
import {TSMap} from "typescript-map";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import {TableSortState} from "core/redux/common/TableSortState";
import {BooleanPayload} from "core/redux/StoreActions";

export const SetSignalsTablesLSType: string = "SetSignalsTablesLS";
export const FetchCategorySignalsType: string =  "FetchCategorySignals";
export const FetchSymbolSignalsType: string =  "FetchSymbolSignals";
export const SetCategorySignalsType: string =  "SetCategorySignals";
export const SetSymbolSignalsType: string =  "SetSymbolSignals";
export const SetSymbolSignalsTabViewType: string =  "SetSymbolSignalsTabView";
export const SetSignalDetailsType: string =  "SetSignalDetails";
export const UpdateSignalsSettingsType: string =  "UpdateSignalsSettings";
export const ClearSignalsSettingsType: string =  "ClearSignalsSettings";
export const SetSignalsSettingsType: string =  "SetSignalsSettings";
export const SetSignalsTabType: string = "SetSignalsTab";
export const SetSignalsTradeColumnType: string = "SetSignalsTradeColumn";
export const ToggleSignalsColumnSortType: string = "ToggleSignalsColumnSort";
export const SetSortedSignalsType: string = "SetSortedSignals";
export const SelectSignalType: string = "SelectSignal";
export const SetSignalsLoadingType: string = "SetSignalsLoading";
export const FetchSignalByIdType: string = "FetchSignalById";

export interface SetSignalsTablesLSPayload {

    tables: TSMap<string, TableSortState>;
}

export interface FetchCategorySignalsPayload {

    category: string;
}

export interface FetchSymbolSignalsPayload {

    symbolId: number;
}

export interface SetCategorySignalsPayload {

    category: string;
    signals: SymbolSignal[];
}

export interface SetSymbolSignalsPayload {

    signals: SymbolSignal[];
}

export interface SetSymbolSignalsTabViewPayload {

    tabView: SignalsTabView;
}

export interface SetSignalDetailsPayload {

    signal: SymbolSignal;
}

export interface UpdateSignalsSettingsPayload {

    settings: SignalsSettings;
}

export interface SetSignalsSettingsPayload {

    visible: boolean;
}

export interface SetSignalsTabPayload {

    category: string;
}

export interface SetSignalsTradeColumnPayload {

    tradeColumn: TradeColumn;
    category: string;
}

export interface ToggleSignalsColumnSortPayload {

    tradeColumn: TradeColumn;
    category: string;
}

export interface SetSortedSignalsPayload {

    signals: SymbolSignal[];
    category: string;
    tradeColumn: TradeColumn;
    sortDirection: SortDirection;
}

export interface SelectSignalPayload {

    signal: SymbolSignal;
}

export interface SetSignalsLoadingPayload {

    signalsLoaded: boolean;
}

export interface FetchSignalByIdPayload {

    id: number;
    action: () => void;
}

export const SetSignalsTablesLS: ActionCreatorDefinition<SetSignalsTablesLSPayload, any> = createAction<SetSignalsTablesLSPayload>(SetSignalsTablesLSType);
export const FetchCategorySignals: ActionCreatorDefinition<FetchCategorySignalsPayload, any> = createAction<FetchCategorySignalsPayload>(FetchCategorySignalsType)
export const FetchSymbolSignals: ActionCreatorDefinition<FetchSymbolSignalsPayload, any> = createAction<FetchSymbolSignalsPayload>(FetchSymbolSignalsType);
export const SetCategorySignals: ActionCreatorDefinition<SetCategorySignalsPayload, any> = createAction<SetCategorySignalsPayload>(SetCategorySignalsType);
export const SetSymbolSignals: ActionCreatorDefinition<SetSymbolSignalsPayload, any> = createAction<SetSymbolSignalsPayload>(SetSymbolSignalsType);
export const SetSymbolSignalsTabView: ActionCreatorDefinition<SetSymbolSignalsTabViewPayload, any> = createAction<SetSymbolSignalsTabViewPayload>(SetSymbolSignalsTabViewType);
export const SetSignalDetails: ActionCreatorDefinition<SetSignalDetailsPayload, any> = createAction<SetSignalDetailsPayload>(SetSignalDetailsType);
export const UpdateSignalsSettings: ActionCreatorDefinition<UpdateSignalsSettingsPayload, any> = createAction<UpdateSignalsSettingsPayload>(UpdateSignalsSettingsType);
export const ClearSignalsSettings: ActionCreatorDefinition<any, any> = createAction<any>(ClearSignalsSettingsType);
export const SetSignalsSettings: ActionCreatorDefinition<SetSignalsSettingsPayload, any> = createAction<SetSignalsSettingsPayload>(SetSignalsSettingsType);
export const SetSignalsTab: ActionCreatorDefinition<SetSignalsTabPayload, any> = createAction<SetSignalsTabPayload>(SetSignalsTabType);
export const SetSignalsTradeColumn: ActionCreatorDefinition<SetSignalsTradeColumnPayload, any> = createAction<SetSignalsTradeColumnPayload>(SetSignalsTradeColumnType);
export const ToggleSignalsColumnSort: ActionCreatorDefinition<ToggleSignalsColumnSortPayload, any> = createAction<ToggleSignalsColumnSortPayload>(ToggleSignalsColumnSortType);
export const SetSortedSignals: ActionCreatorDefinition<SetSortedSignalsPayload, any> = createAction<SetSortedSignalsPayload>(SetSortedSignalsType);
export const SelectSignal: ActionCreatorDefinition<SelectSignalPayload, any> = createAction<SelectSignalPayload>(SelectSignalType);
export const SetSignalsLoading: ActionCreatorDefinition<SetSignalsLoadingPayload, any> = createAction<SetSignalsLoadingPayload>(SetSignalsLoadingType);
export const FetchSignalById: ActionCreatorDefinition<FetchSignalByIdPayload, any> = createAction<FetchSignalByIdPayload>(FetchSignalByIdType);
