enum TradeColumn {

    // Symbol / Trade columns
    Symbol = "symbol",
    Price = "price",
    Order = "order",
    Direction = "direction",
    TotalProfit = 'totalProfit',
    TakeProfit = "takeProfit",
    StopLoss = "stopLoss",
    MarketRate = "marketRate",
    OpenRate = "openRate",
    CloseRate = "closeRate",
    CreatedDate = "createdDate",
    CloseDate = "closeDate",
    MarketValue = "marketValue",
    MarketCap = "marketCap",
    Revenue = "revenue",
    Industry = "industry",
    // Symbol columns
    Chart1d = "chart1d",
    Sell = "sell",
    Buy = "buy",
    Trend = "trend",
    // Activity log columns
    Time = "time",
    Action = "action",
    Description = "description",
    PerformedBy = "performedBy",
    Balance = "balance",
    Amount = "amount",
    TradingEntityId = "tradingEntityId",
    // Signals
    Title = "title",
    Type = "type",
    Probability = "probability",
    Interval = "interval",
    Added = "added",
    Target = "target",
    Trade = "trade",
    Info = "info",
    PatternTypes = "patternTypes",
    // Alerts
    Actions = "actions",
    Alert = "alert",
    AlertType = "alertType",
    AlertAmount = "alertAmount",
    AlertSell = "alertSell",
    AlertBuy = "alertBuy",
    AlertActions = "alertActions",
    Trigger = "trigger",
    // Other
    Link = "link",
    Icon = "icon",
    ButtonsWithoutPrice = "buttonsWithoutPrice",
    // Positions
    UnitsRate = "unitsRate",
    Date = "date",
    UnitsOpenrate = "unitsOpenRate",
    // CreditCard Declaration
    CardHolder = "cardHolder",
    CardType = "cardType",
    CardNumber = "cardNumber",
    PaymentCurrency = "paymentCurrency",
    PaymentAmount = "paymentAmount",
    AccountCurrency = "accountCurrency",
    AccountAmount = "accountAmount",
}

export {TradeColumn};
