export enum TranslationParam {

    alertType = "alertType",
    amount = "amount",
    brand = "brand",
    date = "date",
    email = "email",
    id = "id",
    max = "max",
    min = "min",
    name = "name",
    percent = "percent",
    symbolDisplayName = "symbolDisplayName",
    total = "total",
    units = "units",
    value = "value",
}
