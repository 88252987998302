export enum ServiceType {

    App = "App",
    Account = "Account",
    Auth = "Auth",
    Login = "Login",
    Symbols = "Symbols",
    Quotes = "Quotes",
    Trades = "Trades",
    Portfolio = "Portfolio",
    Deal = "Deal",
    Filter = "Filter",
    Inbox = "Inbox",
    Chart = "Chart",
    Settings = "Settings",
    Lessons = "Lessons",
    News = "News",
    Alerts = "alerts",
    Router = "router",
    Partner = "partner",
}
