export interface UIInAppNotification {

    Id?: number;
    SentDateTime?: number;
    MessageTitle?: string;
    MessageSubTitle?: string;
    MessageText?: string;
    TitleIcon?: string;
    SubtitleIcon?: string;
    SymbolIconUrl?: string;
    CTAUrl?: string;
    StatusType?: InAppNotificationStatus;
    ElapsedDateTime?: string;
}

export enum InAppNotificationStatus {

    New = 1,
    Unread = 2,
    Clicked = 3,
    Engaged = 4,
    Expired = 5,
    Old = 6,
}
