import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {
    CallMeDoRequestType,
    DoAcceptCreditPromoType,
    DoRegisterUserActivityType,
    OnProcessCreateTradeButtonType,
    OnProcessTopUpButtonType,
    SendUserFeedbackType,
    SetAccountInfo,
    SetAccountInfoPayload,
    SetPromotionOverview,
    SetPromotionOverviewPayload,
    SetPromotionsChanged,
    SetPromotionsChangedPayload,
    TogglePromotionsDetails,
    SetShowReviewModal,
    SetShowReviewModalPayload, DoRateAppType, DoActivateBonusCodeType,
} from "core/redux/account/AccountReduxActions";
import {AccountReduxState} from "core/redux/account/AccountReduxState";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import AccountEngine from "core/engine/AccountEngine";
import {
    OnAccountChanged,
    OnAccountChangedType,
    OnLoggedOut,
    OnLoggedOutType,
    SetAuthStatusType
} from "core/redux/auth/AuthReduxActions";
import {HidePopupType} from "platform/redux/popups/PopupsActions";
import {RemoveBannerType} from "core/redux/app/AppReduxActions";

const initialState = (): AccountReduxState => {
    return {
        ShownMenuItems: 0,
        IsPromotionsChanged: false,
        togglePromotionsDetails: false,
        notCachedAdditionalData: {},
        showReviewModal: false
    };
};

export default class AccountReducer extends Reducer<AccountReduxState> {

    private static _instance: AccountReducer;

    private constructor() {
        super();
        const accountEngine: AccountEngine = Platform.engine(ServiceType.Account);
        this._middlewareActions.set(SetAuthStatusType, accountEngine.doSetAuthStatus);
        this._middlewareActions.set(OnAccountChangedType, accountEngine.onAccountChanged);
        this._middlewareActions.set(OnLoggedOutType, accountEngine.onLoggedOut);
        this._middlewareActions.set(HidePopupType, accountEngine.onHidePopup);
        this._middlewareActions.set(RemoveBannerType, accountEngine.onRemoveBanner);
        this._middlewareActions.set(OnProcessCreateTradeButtonType, accountEngine.doProcessCreateTrade);
        this._middlewareActions.set(OnProcessTopUpButtonType, accountEngine.doProcessTopUp);
        this._middlewareActions.set(DoAcceptCreditPromoType, accountEngine.doAcceptCreditPromo);
        this._middlewareActions.set(DoRegisterUserActivityType, accountEngine.doRegisterUserActivity);
        this._middlewareActions.set(SendUserFeedbackType, accountEngine.sendUserFeedback);
        this._middlewareActions.set(CallMeDoRequestType, accountEngine.doCallMeRequest);
        this._middlewareActions.set(DoRateAppType, accountEngine.doRateApp);
        this._middlewareActions.set(DoActivateBonusCodeType, accountEngine.doActivateBonusCode);
    }

    public static instance(): AccountReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "account";
    }

    protected setup(builder: ReducerBuilder<AccountReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: AccountReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(OnAccountChanged, (state: AccountReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    username: state.username,
                    Currency: state.Currency
                };
            })
            .handle(SetAccountInfo, (state: AccountReduxState, {payload}: Action<SetAccountInfoPayload>) => {
                return Object.assign({}, state, payload.accountInfo, {
                    togglePromotionsDetails: false
                });
            })
            .handle(SetPromotionsChanged, (state: AccountReduxState, {payload}: Action<SetPromotionsChangedPayload>) => {
                return Object.assign({}, state, {
                    IsPromotionsChanged: payload.changed
                });
            })
            .handle(SetPromotionOverview, (state: AccountReduxState, {payload}: Action<SetPromotionOverviewPayload>) => {
                return Object.assign({}, state, {
                    promotionOverview: payload.promotionOverview
                });
            })
            .handle(TogglePromotionsDetails, (state: AccountReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    togglePromotionsDetails: true
                });
            })
            .handle(SetShowReviewModal, (state: AccountReduxState, {payload}: Action<SetShowReviewModalPayload>) => {
                return Object.assign({}, state, {
                    showReviewModal: payload.showModal
                });
            });
    }
}
