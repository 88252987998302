import {ActionCreatorDefinition, createAction} from "redux-ts";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import {SymbolSubscriptionInfo} from "protocol/symbol/SymbolSubscriptionInfo";
import {TSMap} from "typescript-map";
import {TradeColumn} from "enum/TradeColumn";
import {CategoryCounterState, SymbolSelection} from "core/redux/symbols/SymbolReduxState";
import {SortDirection} from "platform/enum/SortDirection";
import {Quote} from "platform/protocol/trading/Quote";
import {FilterSymbolState} from "core/redux/filter/FilterReduxState";
import {TableSortState} from "core/redux/common/TableSortState";

export const SetSymbolTablesLSType: string = "SetSymbolTablesLS";
export const SetSymbolsType: string = "SetSymbols";
export const FailedFetchSymbolsType: string = "FailedFetchSymbols";
export const SetTopSymbolsType: string = "SetTopSymbols";
export const SetSubscribedSymbolsType: string = "SetSubscribedSymbols";
export const SubscribeSymbolsType: string = "SubscribeSymbols";
export const UnSubscribeSymbolType: string = "UnSubscribeSymbol";
export const AddSubscribedSymbolsType: string = "AddSubscribedSymbols";
export const RemoveSubscribedSymbolType: string = "RemoveSubscribedSymbol";
export const SetSymbolTabType: string = "SetSymbolTab";
export const SetSymbolTradeColumnType: string = "SetSymbolTradeColumn";
export const ToggleSymbolColumnSortType: string = "ToggleSymbolColumnSort";
export const SetSortedSymbolsType: string = "SetSortedSymbols";
export const SelectSymbolType: string = "SelectSymbol";
export const AddSymbolToSubscribeType: string = "AddSymbolToSubscribe";
export const ClearSymbolsToSubscribeType: string = "ClearSymbolsToSubscribe";
export const SetSymbolsCountersType: string = "SetSymbolsCounters";

export interface SetSymbolTablesLSPayload {

    tables: TSMap<string, TableSortState>;
}

export interface SetSymbolsPayload {

    symbols: TSMap<number, TradeSymbol>;
    overview: TradeSymbol[];
    categories: TSMap<string, TradeSymbol[]>;
    counters: TSMap<string, CategoryCounterState>;
    selectedSymbols: TSMap<string, SymbolSelection>;
    tables: TSMap<string, TableSortState>;
}

export interface SetTopSymbolsPayload {

    symbols: TradeSymbol[];
}

export interface SetSubscribedSymbolsPayload {

    symbols: TSMap<number, SymbolSubscriptionInfo>;
}

export interface SymbolPayload {

    symbol: TradeSymbol;
}

export interface SymbolsPayload {

    symbols: TradeSymbol[];
}

export interface SubscribeSymbolsPayload extends SymbolsPayload {

    place: string;
}

export interface UnSubscribeSymbolsPayload extends SymbolPayload {

    place: string;
}

export interface AddSubscribedSymbolsPayload extends SymbolsPayload {

    quotes: TSMap<number, Quote>;
    symbolFilter: FilterSymbolState;
}

export interface RemoveSubscribedSymbolsPayload extends SymbolPayload {

    symbolFilter: FilterSymbolState;
}


export interface SetSymbolTabPayload {

    category: string;
}

export interface SetSymbolTradeColumnPayload {

    tradeColumn: TradeColumn;
    category: string;
}

export interface ToggleSymbolColumnSortPayload {

    tradeColumn: TradeColumn;
    category: string;
}

export interface SetSortedSymbolsPayload {

    symbols: TradeSymbol[];
    category: string;
    tradeColumn: TradeColumn;
    sortDirection: SortDirection;
}

export interface SelectSymbolPayload {

    category?: string;
    symbol: TradeSymbol;
}

export interface AddSymbolToSubscribePayload {

    symbol: TradeSymbol;
}

export interface SetSymbolsCountersPayload {

    counters: TSMap<string, CategoryCounterState>;
}

export const SetSymbolTablesLS: ActionCreatorDefinition<SetSymbolTablesLSPayload, any> = createAction<SetSymbolTablesLSPayload>(SetSymbolTablesLSType);
export const SetSymbols: ActionCreatorDefinition<SetSymbolsPayload, any> = createAction<SetSymbolsPayload>(SetSymbolsType);
export const FailedFetchSymbols: ActionCreatorDefinition<any, any> = createAction<any>(FailedFetchSymbolsType);
export const SetTopSymbols: ActionCreatorDefinition<SetTopSymbolsPayload, any> = createAction<SetTopSymbolsPayload>(SetTopSymbolsType);
export const SetSubscribedSymbols: ActionCreatorDefinition<SetSubscribedSymbolsPayload, any> = createAction<SetSubscribedSymbolsPayload>(SetSubscribedSymbolsType);
export const SubscribeSymbols: ActionCreatorDefinition<SubscribeSymbolsPayload, any> = createAction<SubscribeSymbolsPayload>(SubscribeSymbolsType);
export const UnSubscribeSymbol: ActionCreatorDefinition<UnSubscribeSymbolsPayload, any> = createAction<UnSubscribeSymbolsPayload>(UnSubscribeSymbolType);
export const AddSubscribedSymbols: ActionCreatorDefinition<AddSubscribedSymbolsPayload, any> = createAction<AddSubscribedSymbolsPayload>(AddSubscribedSymbolsType);
export const RemoveSubscribedSymbol: ActionCreatorDefinition<RemoveSubscribedSymbolsPayload, any> = createAction<RemoveSubscribedSymbolsPayload>(RemoveSubscribedSymbolType);
export const SetSymbolTab: ActionCreatorDefinition<SetSymbolTabPayload, any> = createAction<SetSymbolTabPayload>(SetSymbolTabType);
export const SetSymbolTradeColumn: ActionCreatorDefinition<SetSymbolTradeColumnPayload, any> = createAction<SetSymbolTradeColumnPayload>(SetSymbolTradeColumnType);
export const ToggleSymbolColumnSort: ActionCreatorDefinition<ToggleSymbolColumnSortPayload, any> = createAction<ToggleSymbolColumnSortPayload>(ToggleSymbolColumnSortType);
export const SetSortedSymbols: ActionCreatorDefinition<SetSortedSymbolsPayload, any> = createAction<SetSortedSymbolsPayload>(SetSortedSymbolsType);
export const SelectSymbol: ActionCreatorDefinition<SelectSymbolPayload, any> = createAction<SelectSymbolPayload>(SelectSymbolType);
export const AddSymbolToSubscribe: ActionCreatorDefinition<AddSymbolToSubscribePayload, any> = createAction<AddSymbolToSubscribePayload>(AddSymbolToSubscribeType);
export const ClearSymbolsToSubscribe: ActionCreatorDefinition<any, any> = createAction<any>(ClearSymbolsToSubscribeType);
export const SetSymbolsCounters: ActionCreatorDefinition<SetSymbolsCountersPayload, any> = createAction<SetSymbolsCountersPayload>(SetSymbolsCountersType);
