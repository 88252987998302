export enum SignalSubPatternType {

    // Key levels sub types
    Resistance = 1,
    Support = 2,

    // Chart Pattern sub types
    AscendingTriangle = 3,
    DescendingTriangle = 4,
    Triangle = 5,
    ChannelDown = 6,
    ChannelDelUp = 7,
    FallingWedge = 8,
    RisingWedge = 9,
    Rectangle = 10,
    HeadAndShoulders = 11,
    InverseHeadAndShoulders = 12,
    DoubleTop = 13,
    DoubleBottom = 14,
    TripleTop = 15,
    TripleBottom = 16,
    Flag = 17,
    Pennant = 18,

    // Fibonacci
    ThreePointRetracement = 19,
    ThreePointExtension = 20,
    ABCD = 21,
    Gartley = 22,
    Butterfly = 23,
    ThreeDrive = 24,
    ConsecutiveCandles = 25,
    BigMovement = 26,
}
