import {Engine} from "platform/engine/Engine";
import {XhrUtil} from "core/util/XhrUtil";
import {
    SetAcademyVideoPayload,
    SetLDAcademyConfiguration,
} from "core/redux/lessons/LessonsReduxActions";
import {HttpReject} from "platform/network/http/Http";
import Utils from "platform/util/Utils";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {LangCode} from "platform/enum/LangCode";
import {AccountState} from "core/state/AccountState";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {
    GetUIConfigurationResponse,
} from "protocol/config/GetUIConfigurationResponse";
import {GetUIConfigurationRequest} from "protocol/config/GetUIConfigurationRequest";
import {SetUrls} from "platform/redux/core/CoreActions";
import {UrlType} from "platform/enum/UrlType";
import {InsertClientPreference, SetUIConfiguration} from "core/redux/settings/SettingsReduxActions";
import {SetAuthStatusPayload} from "core/redux/auth/AuthReduxActions";
import {StoreState} from "core/redux/StoreState";
import {
    SetLDPlatformPropsPayload,
    SetMenuRouteAllowed,
    SetMenuToolAllowed,
    ShowAppCuesFlowPayload
} from "core/redux/app/AppReduxActions";
import {StorageKey} from "enum/StorageKey";
import {Preference} from "core/util/Preference";
import {RouteType} from "core/router/Routes";
import ToolsMenuType from "enum/ToolsMenuType";
import {EnvType} from "platform/enum/EnvType";
import {SetInboxUnreadCount} from "core/redux/inbox/InboxReduxActions";
import {BIEventType} from "enum/BIEventType";
import DateTimeFormat from "core/format/DateTimeFormat";
import {SetPartnerAccountsEnabled} from "core/redux/partner/PartnerReduxActions";

export default class LessonsEngine extends Engine {

    private static _instance: LessonsEngine;
    private _totalNumberVideosWatched: number = 0;
    private _totalNumberUniqueVideosWatched: Set<string> = new Set<string>();

    public static instance(): LessonsEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        this._totalNumberVideosWatched = 0;
        this._totalNumberUniqueVideosWatched.clear();
    }

    public doSetAuthStatus = async (payload: SetAuthStatusPayload) => {
        const {connected, authenticated, accountType} = Platform.reduxState<StoreState>().auth;
        if (connected) {
            if ((!authenticated && payload.authStatus.authenticated) || (accountType && payload.authStatus.accountType && accountType !== payload.authStatus.accountType)) {
                this.doFetchUIConfigurationInternal(true).catch(() => {
                });
            }
        }
    }

    public onSetLDPlatformProps = ({props, walkThroughGroups, walkThrough, academyVideoGroups, academyVideos}: SetLDPlatformPropsPayload): void => {
        if ((Platform.environment().type() === EnvType.Web && Utils.isMapNotEmpty(walkThrough)) || Utils.isMapNotEmpty(academyVideos)) {
            Platform.dispatch(SetMenuRouteAllowed({
                routeType: RouteType.Lessons,
                allowed: true
            }));
            Platform.dispatch(SetMenuToolAllowed({
                toolType: ToolsMenuType.Lessons,
                allowed: true
            }));
        }
        let supportZendesk: {[key: string]: string} = {};
        if (props?.keys?.SupportZendesk) {
            try {
                supportZendesk = JSON.parse(props.keys.SupportZendesk.value);
            } catch (e) {
                this._logger.warn(`Failed parse SupportZendesk config: ${props.keys.SupportZendesk.value}`);
            }
        }
        Platform.dispatch(SetLDAcademyConfiguration({
            walkThroughGroups,
            walkThrough,
            academyVideoGroups,
            academyVideos,
            supportZendesk
        }));
    }

    public doSetAcademyVideo = async ({video, referrer}: SetAcademyVideoPayload) => {
        const preference: Preference<string[]> = Platform.reduxState<StoreState>().settings.preferences.get(StorageKey.AcademyWatchedVideos);
        const ids: string[] = preference?.value || [];
        const id: string = video?.videoId;
        if (id) {
            this._totalNumberVideosWatched++;
            this._totalNumberUniqueVideosWatched.add(video.videoId);
            if (Utils.isNull(preference) || Utils.isArrayEmpty(ids) || ids.indexOf(id) < 0) {
                Platform.dispatch(InsertClientPreference({
                    key: StorageKey.AcademyWatchedVideos,
                    preference: Preference.Of([...ids, id])
                }));
            }
            Platform.bi().track(BIEventType.WatchVideo, {
                VideoName: video.title,
                VideoGroups: video.groups,
                Referrer: referrer,
                TotalNumberOfVideosWatched: this._totalNumberVideosWatched,
                TotalNumberOfUniqueVideosWatched: this._totalNumberUniqueVideosWatched.size,
                StartTime: DateTimeFormat.formatDateTime(new Date())
            });
        }
    }

    public doShowAppCuesFlow = async (payload: ShowAppCuesFlowPayload) => {
        const preference: Preference<string[]> = Platform.reduxState<StoreState>().settings.preferences.get(StorageKey.AcademyPassedWalkThroughS);
        const ids: string[] = preference?.value || [];
        if (payload.id && (Utils.isNull(preference) || Utils.isArrayEmpty(ids) || ids.indexOf(payload.id) < 0)) {
            Platform.dispatch(InsertClientPreference({
                key: StorageKey.AcademyPassedWalkThroughS,
                preference: Preference.Of([...ids, payload.id])
            }));
        }
    }

    public doFetchUIConfiguration = async () => {
        await this.doFetchUIConfigurationInternal();
    }

    private doFetchUIConfigurationInternal = async (useUnreadCount?: boolean) => {
        this._logger.debug("Fetch UI configuration");
        const langCode: LangCode = LanguageUtil.languageCode();
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const request: GetUIConfigurationRequest = {languageCode: langCode, userId: accountState.userId};
        const answer: [HttpReject, GetUIConfigurationResponse] = await Utils.to(this.sendToWebProfitService(request, "GetUIConfiguration"));
        if (answer[0]) {
            this._logger.debug("Failed fetch UI configuration");
        } else {
            const {
                EconomicCalendarUrl,
                TermsAndConditionsUrl,
                AnnualStatementYears,
                ShowShareInformation,
                ShowSwitchToInvest,
                CallMeEnabled,
                AllowFollowedAccounts,
                NewInAppNotificationsTotalCount,
                UploadedDocumentsSendToEmail
            } = answer[1] || {};
            Platform.dispatch(SetUrls({
                urls: [
                    {
                        type: UrlType.EconomicCalendarUrl, url: EconomicCalendarUrl
                    },
                    {
                        type: UrlType.CreditPromoTcUrl, url: TermsAndConditionsUrl
                    }
                ]
            }));
            Platform.dispatch(SetUIConfiguration({
                AnnualStatementYears: AnnualStatementYears || [],
                ShowShareInformation,
                ShowSwitchToInvest,
                CallMeEnabled,
                UploadedDocumentsSendToEmail
            }));
            if (CallMeEnabled) {
                Platform.dispatch(SetMenuToolAllowed({
                    toolType: ToolsMenuType.LiveChat,
                    allowed: false
                }));
                Platform.dispatch(SetMenuToolAllowed({
                    toolType: ToolsMenuType.Support,
                    allowed: true
                }));
            }
            Platform.dispatch(SetPartnerAccountsEnabled({value: AllowFollowedAccounts}));
            if (AllowFollowedAccounts) {
                Platform.dispatch(SetMenuRouteAllowed({
                    routeType: RouteType.FollowedAccounts,
                    allowed: true
                }));
                Platform.dispatch(SetMenuToolAllowed({
                    toolType: ToolsMenuType.FollowedAccounts,
                    allowed: true
                }));
            }
            if (useUnreadCount) {
                Platform.dispatch(SetInboxUnreadCount({
                    count: NewInAppNotificationsTotalCount
                }));
            }
        }
    }

    private sendToWebProfitService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "WebProfitServer/WebProfitClientService.svc/json/" + path, null, true);
    }
}
