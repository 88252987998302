import {TSMap} from "typescript-map";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";

export class SymbolsState {

    private static _instance: SymbolsState;
    private _categoryLocalization: TSMap<string, string> = new TSMap<string, string>();
    private _symbols: TSMap<number, TradeSymbol> = new TSMap<number, TradeSymbol>();
    private _symbolsSearchIds: number[] = [];
    private _symbolsGridIds: number[] = [];
    private _symbolsDealIds: number[] = [];
    private _symbolsChartIds: number[] = [];
    private _openTradeSymbolId: number;

    private constructor() {
    }

    public static instance(): SymbolsState {
        return this._instance || (this._instance = new this());
    }

    public setCategoryLocalization(category: string, localization: string): void {
        this._categoryLocalization.set(category, localization);
    }

    public getCategoryLocalization(category: string): string {
        return this._categoryLocalization.get(category);
    }

    public addSymbol(symbol: TradeSymbol): void {
        this._symbols.set(symbol.SymbolId, symbol);
    }

    public getSymbol(SymbolId: number): TradeSymbol {
        return this._symbols.get(SymbolId);
    }

    public getSymbols(): TSMap<number, TradeSymbol> {
        return this._symbols;
    }

    public set symbolsSearchIds(value: number[]) {
        this._symbolsSearchIds = value;
    }

    public set symbolsGridIds(value: number[]) {
        this._symbolsGridIds = value;
    }

    public get symbolsGridIds(): number[] {
        return this._symbolsGridIds;
    }

    public set symbolsDealIds(value: number[]) {
        this._symbolsDealIds = value;
    }

    public get symbolsChartIds(): number[] {
        return this._symbolsChartIds;
    }

    public set symbolsChartIds(value: number[]) {
        this._symbolsChartIds = value;
    }

    public set openTradeSymbolId(value: number) {
        this._openTradeSymbolId = value;
    }

    public get additionalSymbolsIds(): number[] {
        const ids: number[] = [...this._symbolsSearchIds, ...this._symbolsGridIds, ...this._symbolsDealIds];
        if (this._openTradeSymbolId) {
            ids.push(this._openTradeSymbolId);
        }
        return ids;
    }

    public clear(): void {
        this._symbolsSearchIds = [];
        this._symbolsGridIds = [];
        this._symbolsDealIds = [];
        this._symbolsChartIds = [];
        this._openTradeSymbolId = null;
    }
}
