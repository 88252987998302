import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {OnAccountChanged, OnLoggedOut} from "core/redux/auth/AuthReduxActions";
import {
    SignalsCounter,
    SignalsReduxState,
    SignalsTabView
} from "core/redux/deal/SignalsReduxState";
import {
    ClearSignalsSettings,
    FetchCategorySignalsType,
    FetchSignalByIdType,
    FetchSymbolSignalsType,
    SelectSignal,
    SelectSignalPayload,
    SetCategorySignals,
    SetCategorySignalsPayload,
    SetSignalDetails,
    SetSignalDetailsPayload,
    SetSignalsLoading,
    SetSignalsLoadingPayload,
    SetSignalsSettings,
    SetSignalsSettingsPayload,
    SetSignalsTab,
    SetSignalsTablesLS,
    SetSignalsTablesLSPayload,
    SetSignalsTabPayload,
    SetSignalsTradeColumnType,
    SetSortedSignals,
    SetSortedSignalsPayload,
    SetSymbolSignals,
    SetSymbolSignalsPayload,
    SetSymbolSignalsTabView,
    SetSymbolSignalsTabViewPayload,
    ToggleSignalsColumnSortType,
    UpdateSignalsSettings,
    UpdateSignalsSettingsPayload,
} from "core/redux/deal/SignalsReduxActions";
import {TSMap} from "typescript-map";
import {SymbolSignal} from "platform/protocol/trading/SymbolSignal";
import {SymbolCategory} from "enum/SymbolCategory";
import SignalsEngine from "core/engine/SignalsEngine";
import Utils from "platform/util/Utils";
import {StorageKey} from "enum/StorageKey";
import {SetSubscribedSymbolsType, SetSymbols, SetSymbolsPayload} from "core/redux/symbols/SymbolsReduxActions";
import {SetTradeManagerTab, SetTradeManagerTabPayload} from "core/redux/deal/DealReduxActions";
import {TradeManagerTab} from "enum/TradeManagerTab";
import {DefaultSignalsSettings} from "enum/SignalsSettingsType";
import {TableSortState} from "core/redux/common/TableSortState";
import Platform from "platform/Platform";

const initialState = (): SignalsReduxState => {
    const categories: TSMap<string, SymbolSignal[]> = new TSMap<string, SymbolSignal[]>();
    categories.set(SymbolCategory.MyWatchlist, []);
    const counters: TSMap<string, SignalsCounter> = new TSMap<string, SignalsCounter>();
    counters.set(SymbolCategory.MyWatchlist, {
        categoryName: SymbolCategory.MyWatchlist,
        count: 0
    });
    return {
        activeCategoryTab: SymbolCategory.MyWatchlist,
        categories,
        counters,
        tables: new TSMap(),
        selectedSignals: new TSMap<string, SymbolSignal>(),
        tabView: SignalsTabView.List,
        symbolSignals: [],
        settings: DefaultSignalsSettings,
        signalsLoaded: false,
    };
};

export default class SignalsReducer extends Reducer<SignalsReduxState> {

    private static _instance: SignalsReducer;

    public static instance(): SignalsReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "signals";
    }

    private constructor() {
        super();
        const signalsEngine: SignalsEngine = SignalsEngine.instance();
        this._middlewareActions.set("@@router5/TRANSITION_START", signalsEngine.onChangeRoute);
        this._middlewareActions.set(FetchCategorySignalsType, signalsEngine.doGetCategorySignals);
        this._middlewareActions.set(FetchSymbolSignalsType, signalsEngine.doGetSymbolSignals);
        this._middlewareActions.set(SetSignalsTradeColumnType, signalsEngine.doSetSignalsTradeColumn);
        this._middlewareActions.set(ToggleSignalsColumnSortType, signalsEngine.doToggleSignalsTradeColumn);
        this._middlewareActions.set(SetSubscribedSymbolsType, signalsEngine.doSetSubscribedSymbols);
        this._middlewareActions.set(FetchSignalByIdType, signalsEngine.doFetchSignal);
    }

    protected setup(builder: ReducerBuilder<SignalsReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: SignalsReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    activeCategoryTab: state.activeCategoryTab,
                    tables: state.tables,
                };
            })
            .handle(OnAccountChanged, (state: SignalsReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    activeCategoryTab: state.activeCategoryTab,
                    tables: state.tables,
                };
            })
            .handle(SetSymbols, (state: SignalsReduxState, {payload}: Action<SetSymbolsPayload>) => {
                const newState: SignalsReduxState = Utils.merge({}, state);
                const categories: TSMap<string, SymbolSignal[]> = new TSMap<string, SymbolSignal[]>();
                const counters: TSMap<string, SignalsCounter> = new TSMap<string, SignalsCounter>();
                const tables: TSMap<string, TableSortState> = state.tables.clone();
                newState.categories = categories;
                newState.counters = counters;
                newState.tables = tables;
                payload.counters.keys()?.forEach((category: string) => {
                    categories.set(category, []);
                    counters.set(category, {categoryName: category, count: 0});
                    if (!tables.has(category)) {
                        tables.set(category, SymbolCategory.defaultTableState());
                    }
                });
                return newState;
            })
            .handle(SetCategorySignals, (state: SignalsReduxState, {payload}: Action<SetCategorySignalsPayload>) => {
                const {category, signals} = payload;
                const newState: SignalsReduxState = Utils.merge({}, state);
                newState.categories.set(category, signals);
                newState.counters.set(category, {categoryName: category, count: signals.length});
                return newState;
            })
            .handle(SetSymbolSignals, (state: SignalsReduxState, {payload}: Action<SetSymbolSignalsPayload>) => {
                return Object.assign({}, state, {
                    symbolSignals: payload.signals
                });
            })
            .handle(SetTradeManagerTab, (state: SignalsReduxState, {payload}: Action<SetTradeManagerTabPayload>) => {
                if (payload.tab === TradeManagerTab.Signals) {
                    return Object.assign({}, state, {
                        tabView: SignalsTabView.List,
                    });
                }
                return state;
            })
            .handle(SetSignalsTablesLS, (state: SignalsReduxState, {payload}: Action<SetSignalsTablesLSPayload>) => {
                return Object.assign({}, state, {
                    tables: payload.tables
                });
            })
            .handle(SetSymbolSignalsTabView, (state: SignalsReduxState, {payload}: Action<SetSymbolSignalsTabViewPayload>) => {
                return Object.assign({}, state, {
                    tabView: payload.tabView
                });
            })
            .handle(SetSignalDetails, (state: SignalsReduxState, {payload}: Action<SetSignalDetailsPayload>) => {
                return Object.assign({}, state, {
                    signalDetails: payload.signal
                });
            })
            .handle(UpdateSignalsSettings, (state: SignalsReduxState, {payload}: Action<UpdateSignalsSettingsPayload>) => {
                return Object.assign({}, state, {
                    settings: Utils.merge({}, state.settings, payload.settings)
                });
            })
            .handle(ClearSignalsSettings, (state: SignalsReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    settings: DefaultSignalsSettings
                });
            })
            .handle(SetSignalsSettings, (state: SignalsReduxState, {payload}: Action<SetSignalsSettingsPayload>) => {
                return Object.assign({}, state, {
                    settingsVisible: payload.visible
                });
            })
            .handle(SetSignalsTab, (state: SignalsReduxState, {payload}: Action<SetSignalsTabPayload>) => {
                return Object.assign({}, state, {
                    activeCategoryTab: payload.category
                });
            })
            .handle(SetSortedSignals, (state: SignalsReduxState, {payload}: Action<SetSortedSignalsPayload>) => {
                const {category, tradeColumn, sortDirection, signals} = payload;
                const newState: SignalsReduxState = Utils.merge({}, state);
                const tables: TSMap<string, TableSortState> = newState.tables;
                const tableState: TableSortState = tables.get(category);
                tableState.activeColumn = tradeColumn;
                tableState.sort.set(tradeColumn, sortDirection);
                SymbolCategory.serialize(StorageKey.SignalsSort, tables);
                const map: TSMap<string, SymbolSignal[]> = newState.categories;
                map.set(category, signals);
                return newState;
            })
            .handle(SelectSignal, (state: SignalsReduxState, {payload}: Action<SelectSignalPayload>) => {
                const {signal} = payload;
                const newState: SignalsReduxState = Utils.merge({}, state);
                newState.selectedSignals.set(state.activeCategoryTab, signal);
                return newState;
            })
            .handle(SetSignalsLoading, (state: SignalsReduxState, {payload}: Action<SetSignalsLoadingPayload>) => {
                return Object.assign({}, state, {
                    signalsLoaded: payload.signalsLoaded
                });
            });
    }
}
