import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import {Http, HttpReject} from "platform/network/http/Http";
import {GetClientPreferences} from "protocol/account/GetClientPreferences";
import {GetClientResponse} from "protocol/account/GetClientResponse";
import {InsertClientPreferenceRequest} from "protocol/account/InsertClientPreferenceRequest";
import {BaseResponse} from "protocol/BaseResponse";
import {ClientPreference} from "protocol/account/ClientPreference";
import {Logger} from "platform/logger/Logger";
import Utils from "platform/util/Utils";
import {XhrHeaders} from "platform/network/xhr/XhrHeaders";

export class PreferencesManager {

    private static logger: Logger = Logger.Of("PreferencesManager");

    public static FetchPreferences = async (origin: string, ctoken: string, accountId: number, environmentType: NXEnvironmentType): Promise<ClientPreference[]> => {
        const isDemo: boolean = environmentType === NXEnvironmentType.Demo;
        const request: GetClientPreferences = {
            DemoUserAccountId: isDemo ? accountId : null,
            LiveUserAccountId: isDemo ? null : accountId
        };
        const answer: [HttpReject, string] = await Utils.to(Http.post(
            origin + "WebProfitServer/WebProfitClientService.svc/json/GetWebProfitClientPreferences",
            JSON.stringify(request),
            XhrHeaders.Value(ctoken),
            true
        ));
        if (answer[0]) {
            PreferencesManager.logger.info("Failed to fetch client preferences: " + answer[0].status);
        } else {
            PreferencesManager.logger.info("Fetched client preferences.");
            const response: GetClientResponse = JSON.parse(answer[1].trim());
            return Promise.resolve(response.WebProfitClientPreferences);
        }
        return Promise.resolve([]);
    }

    public static InsertPreference = async (origin: string, ctoken: string, accountId: number, environmentType: NXEnvironmentType, key: string, value: string): Promise<boolean> => {
        const isDemo: boolean = environmentType === NXEnvironmentType.Demo;
        const request: InsertClientPreferenceRequest = {
            DemoUserAccountId: isDemo ? accountId : null,
            LiveUserAccountId: isDemo ? null : accountId,
            PreferenceKey: key,
            PreferenceValue: value
        };
        const answer: [HttpReject, string] = await Utils.to(Http.post(
            origin + "WebProfitServer/WebProfitClientService.svc/json/UpsertWebProfitClientPreference",
            JSON.stringify(request),
            XhrHeaders.Value(ctoken)
            , true
        ));
        if (answer[0]) {
            PreferencesManager.logger.info("Failed to store client preference: " + answer[0].status);
            return Promise.resolve(false);
        } else {
            const response: BaseResponse = JSON.parse(answer[1].trim());
            if (response.SuccessStatus) {
                PreferencesManager.logger.info("Stored client preference for key: " + key);
            }
            return Promise.resolve(response.SuccessStatus);
        }
    }
}
