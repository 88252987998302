import "react-app-polyfill/ie11";
import * as EnTranslations from "../../resources/translations/inject.json";

import * as Bowser from "bowser";
import React from "react";
import {RouterProvider} from "react-router5";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import {ThemeProvider as MuiThemeProvider, createTheme} from "@mui/material";
import {Logger} from "platform/logger/Logger";
import Platform, {SetupResult} from "platform/Platform";
import ConfigManager from "core/configuration/ConfigManager";
import {Configuration} from "core/configuration/Configuration";
import {TranslationLoaderImpl} from "core/translation/TranslationLoaderImpl";
import WebEnvironment from "platform/environment/WebEnvironment";
import {Router5} from "platform/router/router5/Router5";
import {Routes, RouteType} from "core/router/Routes";
import WebUtil from "platform/util/WebUtil";
import {AppService} from "core/service/AppService";
import {Service} from "platform/service/Service";
import {SetAppReady} from "platform/redux/core/CoreActions";
import {TradingAsync, TradingMobileAsync} from "entry/async";
import {AccountService} from "core/service/AccountService";
import {AuthService} from "core/service/AuthService";
import {TradesService} from "core/service/TradesService";
import {PortfolioService} from "core/service/PortfolioService";
import {QuotesService} from "core/service/QuotesService";
import {DealService} from "core/service/DealService";
import {FilterService} from "core/service/FilterService";
import {SymbolsService} from "core/service/SymbolsService";
import {InboxService} from "core/service/InboxService";
import {ChartService} from "core/service/ChartService";
import {SettingsService} from "core/service/SettingsService";
import Script from "platform/network/script/Script";
import {BrandType} from "platform/enum/BrandType";
import {StoreState} from "core/redux/StoreState";
import {MuiFonts} from "platform/font/MuiFonts";
import {LessonsService} from "core/service/LessonsService";
import {NewsService} from "core/service/NewsService";
import {AlertsService} from "core/service/AlertsService";
import {AppCues} from "core/appCues/AppCues";
import {Environment} from "platform/enum/Environment";
import {Locale} from "core/format/Locale";
import {LocaleProviderImpl} from "core/format/LocaleProviderImpl";
import Translations from "platform/translation/Translations";
import {LangCode} from "platform/enum/LangCode";
import {PartnerService} from "core/service/PartnerService";
import {XhrHeaders} from "platform/network/xhr/XhrHeaders";
import {AppCuesManager} from "core/appCues/IAppCues";

const CesLeadToken: string = WebUtil.urlParam("CesLeadToken");
const WalkThroughIdUrl: string = WebUtil.urlParam("wtid");
const logger: Logger = Logger.Of("Boot");
const BowserDetails: Bowser.Parser.Details = WebUtil.BowserDetails();
XhrHeaders.browserName = BowserDetails?.name;
XhrHeaders.browserVersion = BowserDetails?.version;
logger.debug(`Boot at: ${window.navigator.userAgent}. Bowser name: ${BowserDetails?.name}. Bowser version: ${BowserDetails?.version}`);

if (Environment.isProd()) {
    const loadSentry = async () => {
        return await import('@sentry/react');
    };
    const loadTracing = async () => {
        return await import('@sentry/tracing');
    };
    loadSentry().then((Sentry) => {
        loadTracing().then(({BrowserTracing}) => {
            try {
                Sentry.init({
                    dsn: "https://2dfad44969e346df84cb9c736f4abe43@o1178767.ingest.sentry.io/6290643",
                    integrations: [new BrowserTracing()],
                    tracesSampleRate: 1.0,
                });
            } catch (e) {
            }
        });
    });
}

Locale.provider = LocaleProviderImpl.instance();
Translations.preset(LangCode.EN, EnTranslations);
const services: Array<{service: Service<any, any>, props?: any}> = [
    {service: AppService.instance()},
    {service: AccountService.instance()},
    {service: AuthService.instance()},
    {service: SymbolsService.instance()},
    {service: QuotesService.instance()},
    {service: TradesService.instance()},
    {service: PortfolioService.instance()},
    {service: DealService.instance()},
    {service: FilterService.instance()},
    {service: InboxService.instance()},
    {service: ChartService.instance()},
    {service: SettingsService.instance()},
    {service: LessonsService.instance()},
    {service: NewsService.instance()},
    {service: AlertsService.instance()},
    {service: PartnerService.instance()},
];

const appCues: AppCues = new AppCues();
AppCuesManager.AppCues = appCues;

Platform.setup({
    configurationLoader: ConfigManager.loader(),
    translationLoader: TranslationLoaderImpl.instance(),
    environment: WebEnvironment.instance(),
    router: Router5.instance({
        routes: Routes.routes(),
        defaultRoute: RouteType.Symbols,
        defaultParams: WebUtil.getParamsToObject()
    }),
    services,
    middleWares: [appCues.middleware()]
}).then((setupResult: SetupResult) => {
    logger.info("Platform setup finished.");

    const configuration: Configuration = Platform.config<Configuration>();
    const isMobile: boolean = WebUtil.isMobile();
    const brand: BrandType = Platform.reduxState<StoreState>().core.brand;
    logger.info("Platform setup finished. Brand: " + brand);
    Script.injectLink("/css/" + brand + (isMobile ? "-mobile" : "") + ".css");
    appCues.load(WalkThroughIdUrl);

    if (configuration.webPushSDK && configuration.webPushTargetId) {
        Script.injectScript(configuration.webPushSDK, "webPushSDK", () => {
            try {
                if (CesLeadToken) {
                    setTimeout(() => {
                        (window as any).useRex.wpn.init(configuration.webPushTargetId, CesLeadToken);
                    }, 1500);
                } else {
                    logger.info("Skip identify WP sdk. CesLeadToken absent in GET params");
                }
            } catch (e) {
                logger.info("Failed init WP sdk");
            }
        });
    } else {
        logger.info("Skip init WP sdk. Brand config empty");
    }
    Platform.dispatch(SetAppReady({ready: true}));
    const muiTheme = createTheme({
        typography: {
            fontFamily: MuiFonts.get(brand).join(","),
        }
    });
    const root = ReactDOM.createRoot(document.getElementById("RootElement"));
    root.render(
        <Provider store={Platform.store()}>
            <MuiThemeProvider theme={muiTheme}>
                <RouterProvider router={Platform.router().router()}>
                    {WebUtil.isMobile() ? <TradingMobileAsync /> : <TradingAsync />}
                </RouterProvider>
            </MuiThemeProvider>
        </Provider>
    );
}).catch((e) => {
    logger.info("Failed setup platform: " + e);
});
