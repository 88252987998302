import {
    LDAcademyVideo, LDAcademyVideoConfig, LDAcademyVideoGroup,
    LDAppCuesConfig,
    LDAppCuesWalkThrough,
    LDAppCuesWalkThroughGroup,
    LDPlatformProps
} from "core/props/LDPlatformProps";
import Utils from "platform/util/Utils";
import {Logger} from "platform/logger/Logger";
import {TSMap} from "typescript-map";

export class LDUtil {

    private static readonly _logger: Logger = Logger.Of("LDUtil");

    private constructor() {
    }

    public static parseWalkThrough(props: LDPlatformProps, walkThroughGroups: TSMap<number, LDAppCuesWalkThroughGroup>, walkThrough: TSMap<number, LDAppCuesWalkThrough[]>): void {
        if (props?.keys?.AppCuesWalkThrough) {
            try {
                const appCuesConfig: LDAppCuesConfig = JSON.parse(props?.keys?.AppCuesWalkThrough.value);
                if (Utils.isArrayNotEmpty(appCuesConfig.walkThroughGroups)) {
                    appCuesConfig.walkThroughGroups.forEach((group: LDAppCuesWalkThroughGroup) => {
                        walkThroughGroups.set(group.id, group);
                        if (!walkThrough.has(group.id)) {
                            walkThrough.set(group.id, []);
                        }
                    });
                    if (Utils.isArrayNotEmpty(appCuesConfig.walkThrough)) {
                        appCuesConfig.walkThrough.forEach((wt: LDAppCuesWalkThrough) => {
                            if (wt.groups) {
                                wt.groups.forEach((groupId: number) => {
                                    const walkThroughS: LDAppCuesWalkThrough[] = walkThrough.get(groupId);
                                    if (walkThroughS) {
                                        walkThroughS.push(wt);
                                    }
                                });
                            }
                        });
                    }
                }
            } catch (e) {
                LDUtil._logger.info("Failed parse LDAppCuesConfig");
            }
        }
    }

    public static parseVideos(props: LDPlatformProps, academyVideoGroups: TSMap<number, LDAcademyVideoGroup>, academyVideos: TSMap<number, LDAcademyVideo[]>): void {
        if (props?.keys?.AcademyVideos) {
            try {
                const academyVideosConfig: LDAcademyVideoConfig = JSON.parse(props?.keys?.AcademyVideos.value);
                if (Utils.isArrayNotEmpty(academyVideosConfig.academyVideoGroups)) {
                    academyVideosConfig.academyVideoGroups.forEach((group: LDAcademyVideoGroup) => {
                        academyVideoGroups.set(group.id, group);
                        if (!academyVideos.has(group.id)) {
                            academyVideos.set(group.id, []);
                        }
                    });
                    if (Utils.isArrayNotEmpty(academyVideosConfig.academyVideos)) {
                        academyVideosConfig.academyVideos.forEach((av: LDAcademyVideo) => {
                            if (av.groups) {
                                av.groups.forEach((groupId: number) => {
                                    const videos: LDAcademyVideo[] = academyVideos.get(groupId);
                                    if (videos) {
                                        videos.push(av);
                                    }
                                });
                            }
                        });
                    }
                }
            } catch (e) {
                this._logger.warn("Failed parse LDAcademyVideoConfig");
            }
        }
    }

    public static filterWalkThrough(groups: TSMap<number, LDAppCuesWalkThroughGroup>, walkThroughPerGroup: TSMap<number, LDAppCuesWalkThrough[]>): {
        walkThroughGroups: LDAppCuesWalkThroughGroup[],
        walkThrough: TSMap<number, LDAppCuesWalkThrough[]>
    } {
        const walkThroughGroups: LDAppCuesWalkThroughGroup[] = [];
        const walkThrough: TSMap<number, LDAppCuesWalkThrough[]> = walkThroughPerGroup.filter((walkThroughS: LDAppCuesWalkThrough[]) => Utils.isArrayNotEmpty(walkThroughS));
        groups.forEach((group: LDAppCuesWalkThroughGroup) => {
            if (walkThrough.has(group.id)) {
                walkThroughGroups.push(group);
            }
        });
        return {
            walkThroughGroups,
            walkThrough
        };
    }

    public static filterVideos(groups: TSMap<number, LDAcademyVideoGroup>, videosPerGroup: TSMap<number, LDAcademyVideo[]>): {
        academyVideoGroups: LDAcademyVideoGroup[],
        academyVideos: TSMap<number, LDAcademyVideo[]>
    } {
        const academyVideoGroups: LDAcademyVideoGroup[] = [];
        const academyVideos: TSMap<number, LDAcademyVideo[]> = videosPerGroup.filter((videos: LDAcademyVideo[]) => Utils.isArrayNotEmpty(videos));
        groups.forEach((group: LDAcademyVideoGroup) => {
            if (academyVideos.has(group.id)) {
                academyVideoGroups.push(group);
            }
        });
        return {
            academyVideoGroups,
            academyVideos
        };
    }

    public static getWalkThrough(walkThroughId: string, walkThroughS: TSMap<number, LDAppCuesWalkThrough[]>): LDAppCuesWalkThrough {
        if (walkThroughId && walkThroughS) {
            for (let i = 0; i < walkThroughS.values().length; i++) {
                const walkThrough: LDAppCuesWalkThrough[] = walkThroughS.values()[i];
                if (walkThrough) {
                    for (let j = 0; j < walkThrough.length; j++) {
                        if (walkThroughId === walkThrough[j].contentId) {
                            return walkThrough[j];
                        }
                    }
                }
            }
        }
        return null;
    }

    public static getVideo(videoId: string, academyVideos: TSMap<number, LDAcademyVideo[]>): {groupId: number, video: LDAcademyVideo} {
        if (videoId && academyVideos) {
            for (let i = 0; i < academyVideos.values().length; i++) {
                const videos: LDAcademyVideo[] = academyVideos.values()[i];
                if (videos) {
                    for (let j = 0; j < videos.length; j++) {
                        if (videoId === videos[j].videoId) {
                            return {
                                groupId: academyVideos.keys()[i],
                                video: videos[j]
                            };
                        }
                    }
                }
            }
        }
        return null;
    }
}
