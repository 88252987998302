import {PromotionType} from "platform/protocol/enum/PromotionType";
import {PositionPromotion} from "platform/protocol/trading/PositionPromotion";
import Utils from "platform/util/Utils";
import {RebatePlanData, VaultInfo} from "protocol/account/GetAccountStateResponse";
import PromotionOverview from "enum/PromotionOverview";
import Platform from "platform/Platform";
import {SetPromotionOverview} from "core/redux/account/AccountReduxActions";
import {Logger} from "platform/logger/Logger";

let PromotionOverviewIndex: number = -1;
const PromotionOverviewOrder: PromotionOverview[] = [
    PromotionOverview.ProtectedPositions,
    PromotionOverview.SpreadCashback,
    PromotionOverview.InsuredPositions,
    PromotionOverview.RolloverDiscount,
    PromotionOverview.RebatePlan,
    PromotionOverview.Vault
];

export class PromotionUtil {

    private static readonly _logger: Logger = Logger.Of("PromotionUtil");

    private constructor() {
    }

    public static promotionCount = (type: PromotionType, promotions: PositionPromotion[]): number => {
        if (Utils.isArrayNotEmpty(promotions)) {
            const promotion: PositionPromotion = promotions.filter((pp: PositionPromotion) => pp?.PromotionType === type)[0];
            if (promotion) {
                return promotion.RemainingPromotions || promotion.RemainingPromotionsCount;
            }
        }
        return null;
    }

    public static promotionPercentage = (type: PromotionType, promotions: PositionPromotion[]): number => {
        if (Utils.isArrayNotEmpty(promotions)) {
            const promotion: PositionPromotion = promotions.filter((pp: PositionPromotion) => pp?.PromotionType === type)[0];
            if (promotion) {
                return Math.round(promotion.Percentage * 100);
            }
        }
        return null;
    }

    public static promotion = (type: PromotionType, promotions: PositionPromotion[]): PositionPromotion => {
        if (Utils.isArrayNotEmpty(promotions)) {
            const promotion: PositionPromotion = promotions.filter((pp: PositionPromotion) => pp?.PromotionType === type)[0];
            return promotion;
        }
        return null;
    }

    public static hasPromotion = (type: PromotionType, promotions: PositionPromotion[]): boolean => {
        if (Utils.isArrayNotEmpty(promotions)) {
            const promotion: PositionPromotion = promotions.filter((pp: PositionPromotion) => pp?.PromotionType === type)[0];
            return Utils.isNotNull(promotion);
        }
        return false;
    }

    public static isRebatePlanChanged = (prev: RebatePlanData, next: RebatePlanData): boolean => {
        if (Utils.isNotNull(next) && next?.CurrentRebateLevelId !== prev?.CurrentRebateLevelId) {
                PromotionUtil._logger.debug("Rebate plan changed from level: " + prev?.CurrentRebateLevelId + " to level: " + next?.CurrentRebateLevelId);
                return true;
        }
        return false;
    }

    public static isVaultChanged = (prev: VaultInfo, next: VaultInfo): boolean => {
        if (Utils.isNotNull(next) && !Utils.compareObject(prev,next)) {
                PromotionUtil._logger.debug("Vault changed. Prev expiring: " + prev?.IsVaultExpiring + " New expiring: " + next?.IsVaultExpiring
                    + " Prev exp type: " + prev?.VaultExpirationActionType + " Next exp type: " + next?.VaultExpirationActionType);
                    return true;
        }
        return false;
    }

    public static isPromotionsChanged = (prev: PositionPromotion[], next: PositionPromotion[]): boolean => {
        if (Utils.isArrayNotEmpty(next)) {
            if (prev?.length !== next?.length) {
                return true;
            } else {
                const prevPromos: PositionPromotion[] = PromotionUtil.mapPromotions(prev);
                const nextPromos: PositionPromotion[] = PromotionUtil.mapPromotions(next);
                for (let i = 0; i < 4; i++) {
                    if (PromotionUtil.isPromotionChanged(prevPromos[i], nextPromos[i])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private static mapPromotions = (promotions: PositionPromotion[] = []): PositionPromotion[] => {
        const Promotions: PositionPromotion[] = [];
        promotions.forEach((promotion: PositionPromotion) => {
            if (promotion.PromotionType === PromotionType.PROTECTED) {
                Promotions[0] = promotion;
            } else if (promotion.PromotionType === PromotionType.SPREAD_CASHBACK) {
                Promotions[1] = promotion;
            } else if (promotion.PromotionType === PromotionType.POSITION_INSURANCE) {
                Promotions[2] = promotion;
            } else if (promotion.PromotionType === PromotionType.ROLLOVER_DISCOUNT) {
                Promotions[3] = promotion;
            }
        });
        return Promotions;
    }

    private static isPromotionChanged = (prev: PositionPromotion, next: PositionPromotion): boolean => {
        if (Utils.isNotNull(next)) {
            if (prev?.Percentage !== next?.Percentage || prev?.ExpirationDate !== next?.ExpirationDate) {
                PromotionUtil._logger.debug("Promotion " + next?.PromotionType + " changed. Was percent: " + prev?.Percentage + " New percent: " + next?.Percentage
                + " Prev exp date: " + prev?.ExpirationDate + " New exp date: " + next?.ExpirationDate);
                return true;
            }
        }
        return false;
    }

    public static setNextPromotionOverview = (AccountRebateData: RebatePlanData, Vault: VaultInfo, PositionPromotionSettings: PositionPromotion[]): void => {
        let newIndex: number = PromotionOverviewIndex + 1;
        if (newIndex === PromotionOverviewOrder.length) {
            newIndex = 0;
        }
        for (let i = 0; i < PromotionOverviewOrder.length; i++) {
            const promotionOverview: PromotionOverview = PromotionOverviewOrder[newIndex];
            if (PromotionUtil.hasPromotionOverview(promotionOverview, AccountRebateData, Vault, PositionPromotionSettings)) {
                PromotionOverviewIndex = newIndex;
                Platform.dispatch(SetPromotionOverview({promotionOverview}));
                break;
            } else {
                newIndex++;
                if (newIndex === PromotionOverviewOrder.length) {
                    newIndex = 0;
                }
            }
        }
    }

    private static hasPromotionOverview = (promotionOverview: PromotionOverview, AccountRebateData: RebatePlanData, Vault: VaultInfo, PositionPromotionSettings: PositionPromotion[]): boolean => {
        switch (promotionOverview) {
            case PromotionOverview.RebatePlan:
                return !Utils.isObjectEmpty(AccountRebateData);
            case PromotionOverview.Vault:
                return !Utils.isObjectEmpty(Vault);
            default:
                return Utils.isArrayNotEmpty(PositionPromotionSettings) && Utils.isNotNull(PositionPromotionSettings.filter((promotion: PositionPromotion) => PromotionUtil.promotionOverviewByType(promotion.PromotionType) === promotionOverview)[0])
        }
    }

    private static promotionOverviewByType = (promotionType: PromotionType): PromotionOverview => {
        switch (promotionType) {
            case PromotionType.PROTECTED:
                return PromotionOverview.ProtectedPositions;
            case PromotionType.SPREAD_CASHBACK:
                return PromotionOverview.SpreadCashback;
            case PromotionType.POSITION_INSURANCE:
                return PromotionOverview.InsuredPositions;
            case PromotionType.ROLLOVER_DISCOUNT:
                return PromotionOverview.RolloverDiscount;
        }
        return null;
    }
}
