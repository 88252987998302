import Platform from "platform/Platform";
import {Configuration} from "core/configuration/Configuration";
import {EnvType} from "platform/enum/EnvType";

export class ConfigUtil {

    private constructor() {}

    public static UseAddressBarDomain = (url: string): string => {
        if (url && Platform.environment().type() === EnvType.Web && Platform.config<Configuration>().useAddressBarDomain) {
            const parts: string [] = window.location.hostname?.split(".");
            const Url: URL = new URL(url);
            const hostnameParts: string [] = Url.hostname.split(".");
            hostnameParts[hostnameParts.length - 2] = parts[parts.length - 2];
            hostnameParts[hostnameParts.length - 1] = parts[parts.length - 1];
            Url.hostname = hostnameParts.join(".");
            return Url.toString();
        }
        return url;
    }
}
