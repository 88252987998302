import {TSMap} from "typescript-map";
import {LangCode} from "platform/enum/LangCode";
import {AvailableLanguage} from "protocol/config/LoginConfigurationResponse";
import {MobilePlatformType} from "protocol/auth/LoginRequest";
import {BannerType} from "enum/BannerType";

export class AppState {

    private static _instance: AppState;
    private _languages: TSMap<LangCode, AvailableLanguage> = new TSMap();
    private _blockedBanners: BannerType[] = [];
    private _inMemoryKV: TSMap<string, string> = new TSMap();
    private _nativeAppVersion: string;
    private _nativeAppName: string;
    private _nativePackageName: string;
    private _nativePlatformType: string;
    private _nativeMobilePlatformType: MobilePlatformType;
    private _nativePushNotificationToken: string;

    private constructor() {
    }

    public addLanguage(langCode: LangCode, language: AvailableLanguage): void {
        this._languages.set(langCode, language);
    }

    public getLanguage(langCode: LangCode): AvailableLanguage {
        return this._languages.get(langCode);
    }


    public getBlockedBanners(): BannerType[] {
        return this._blockedBanners;
    }

    public setBlockedBanners(value: BannerType[]) {
        this._blockedBanners = value;
    }

    public setInMemoryKV(key: string, value: string): void {
        this._inMemoryKV.set(key, value);
    }

    public getInMemoryKV(key: string): string {
        return this._inMemoryKV.get(key);
    }

    public get nativeAppVersion(): string {
        return this._nativeAppVersion;
    }

    public set nativeAppVersion(value: string) {
        this._nativeAppVersion = value;
    }

    public get nativeAppName(): string {
        return this._nativeAppName;
    }

    public set nativeAppName(value: string) {
        this._nativeAppName = value;
    }

    public get nativePackageName(): string {
        return this._nativePackageName;
    }

    public set nativePackageName(value: string) {
        this._nativePackageName = value;
    }

    public get nativeMobilePlatformType(): MobilePlatformType {
        return this._nativeMobilePlatformType;
    }

    public set nativeMobilePlatformType(value: MobilePlatformType) {
        this._nativeMobilePlatformType = value;
    }

    public get nativePlatformType(): string {
        return this._nativePlatformType;
    }

    public set nativePlatformType(value: string) {
        this._nativePlatformType = value;
    }

    public get nativePushNotificationToken(): string {
        return this._nativePushNotificationToken;
    }

    public set nativePushNotificationToken(value: string) {
        this._nativePushNotificationToken = value;
    }

    public static instance(): AppState {
        return this._instance || (this._instance = new this());
    }
}
