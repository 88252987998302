import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";
import {Quote} from "platform/protocol/trading/Quote";

export class IntegrationChartQuoteState implements IntegrationMessage {

    public classId: number = IntegrationClassId.CHART_QUOTE_STATE;
    public quote: Quote;

    public constructor(quote: Quote) {
        this.quote = quote;
    }
}
