import {SignalPatternType} from "platform/protocol/enum/SignalPatternType";
import {SignalsSettings} from "core/redux/deal/SignalsReduxState";
import {SignalSubPatternType} from "platform/protocol/enum/SignalSubPatternType";

const SignalAvailableSubPatternKeyLevels: SignalSubPatternType[] = [
    SignalSubPatternType.Resistance,
    SignalSubPatternType.Support,
];

const SignalAvailableSubPatternChartPattern: SignalSubPatternType[] = [
    SignalSubPatternType.AscendingTriangle,
    SignalSubPatternType.DescendingTriangle,
    SignalSubPatternType.Triangle,
    SignalSubPatternType.ChannelDown,
    SignalSubPatternType.ChannelDelUp,
    SignalSubPatternType.FallingWedge,
    SignalSubPatternType.RisingWedge,
    SignalSubPatternType.Rectangle,
    SignalSubPatternType.HeadAndShoulders,
    SignalSubPatternType.InverseHeadAndShoulders,
    SignalSubPatternType.DoubleTop,
    SignalSubPatternType.DoubleBottom,
    SignalSubPatternType.TripleTop,
    SignalSubPatternType.TripleBottom,
    SignalSubPatternType.Flag,
    SignalSubPatternType.Pennant,
];

const SignalAvailableSubPatternFibonacci: SignalSubPatternType[] = [
    SignalSubPatternType.ThreePointRetracement,
    SignalSubPatternType.ThreePointExtension,
    SignalSubPatternType.ABCD,
    SignalSubPatternType.Gartley,
    SignalSubPatternType.Butterfly,
    SignalSubPatternType.ThreeDrive,
];

const DefaultSignalsSettings: SignalsSettings = {
    probabilityMin: 60,
    probabilityMax: 100,
    addedMin: 0,
    addedMax: 90,
    intervalMin: 0,
    intervalMax: 6,
    targetMin: 0,
    targetMax: 72,
    patternTypes: [SignalPatternType.KeyLevel, SignalPatternType.ChartPattern],
    filter: {
        breakout: true,
        emerging: false,
        patternSubTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        probability: 60,
        added: 90,
        interval: [0, 6],
        target: [0, 72],
    },
    useProbability: false
};

const SignalsAddedFilterValues: number[] = [1, 10, 20, 30, 60, 120, 240, 480, 720, 1440, 2880, 4320, 5760, 7200, 8640, 10080, 20160, 30240, 40320];
const SignalsIntervalFilterValues: number[] = [15, 30, 60, 120, 240, 480, 1440];

namespace SignalsSettingsType {

    export function deserialize(value: string): SignalsSettings {
        if (value) {
            try {
                const signalsSettings: SignalsSettings = JSON.parse(value);
                if (!signalsSettings.patternTypes) {
                    signalsSettings.patternTypes = DefaultSignalsSettings.patternTypes;
                }
                if (signalsSettings.filter && !signalsSettings.filter.patternSubTypes) {
                    signalsSettings.filter.patternSubTypes = DefaultSignalsSettings.filter.patternSubTypes;
                }
                return signalsSettings;
            } catch (e) {
            }
        }
        return DefaultSignalsSettings;
    }
}

export {
    SignalsSettingsType,
    DefaultSignalsSettings,
    SignalsAddedFilterValues,
    SignalsIntervalFilterValues,
    SignalAvailableSubPatternKeyLevels,
    SignalAvailableSubPatternChartPattern,
    SignalAvailableSubPatternFibonacci
};
