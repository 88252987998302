import {Route} from "router5";
import WebUtil from "platform/util/WebUtil";

export enum RouteType {

    Login = "login",
    SignUp = "signUp",
    ForgotPassword = "forgotPassword",
    ResetPassword = "resetPassword",
    ChangePassword = "changePassword",
    CodeVerification = "codeVerification",
    Verification = "verification",
    VerificationMethods = "verificationMethods",
    Congratulations = "congratulations",
    CheckInbox = "checkInbox",
    HomeScreens = "homeScreens",
    Home = "home",
    AccountOverview = "accountOverview",
    Symbols = "symbols",
    SymbolSearch = "symbolSearch",
    MyTrades = "myTrades",
    Tools = "tools",
    News = "news",
    Article = "article",
    CreateUpdateTrade = "createUpdateTrade",
    EconomicCalendar = "economicCalendar",
    Signals = "signals",
    Lessons = "lessons",
    Promotions = "promotions",
    AccountSettings = "accountSettings",
    Withdrawal = "withdrawal",
    DepositLimits = "depositLimits",
    AlertSymbol = "alertSymbol",
    Filters = "filters",
    CloseTrade = "closeTrade",
    ClosedTrade = "closedTrade",
    Professional = "professional",
    UploadDocument = "uploadDocument",
    Kyc = 'kyc',
    Deposit = "deposit",
    Videos = "videos",
    Feedback = "feedback",
    AddSymbol = "addSymbol",
    ShareInfo = "shareInfo",
    Support = "support",
    Alerts = "alerts",
    CloseOutMethod = "closeOutMethod",
    MyLeverage = "myLeverage",
    LeverageDisclaimer = "leverageDisclaimer",
    Notifications = "notifications",
    ProfileChangePassword = "profileChangePassword",
    AccountDetails = "accountDetails",
    PromotionsDetails = "promotionsDetails",
    OpenTradingAccount = "openTradingAccount",
    WelcomeInvestAccount = "welcomeInvestAccount",
    TransferToCDF = "transferToCDF",
    Logs = "logs",
    TradingAccountSuccess = "tradingAccountSuccess",
    ClosedOrderHistory = "closedOrderHistory",
    ChangeLanguage = "changeLanguage",
    EMT = "emt",
    SignDeclaration = "signDeclaration",
    RecuringDetails = "recuringDetails",
    BiometricAuthentication = "biometricAuthentication",
    PersonalInfo = "personalInfo",
    FollowedAccounts = "followedAccounts",
    FollowedAccount = "followedAccount",
    AddFollowedAccount = "addFollowedAccount",
    RemoveFollowedAccount = "removeFollowedAccount",
    AccountSearch = "accountSearch",
    OpenTradeDetails = "openTradeDetails",
    PendingOrderDetails = "pendingOrderDetails",
    AnnualStatement = "annualStatement",
    AskNotificationPermission = "askNotificationPermission",
}

export class Routes {

    private static COMMON: Route[] = [
        {name: RouteType.AccountOverview, path: "/account-overview?appcue&flow"},
        {name: RouteType.Symbols, path: "/symbols?appcue&flow"},
        {name: RouteType.MyTrades, path: "/my-trades?appcue&flow"},
        {name: RouteType.News, path: "/news?appcue&flow"},
        {name: RouteType.EconomicCalendar, path: "/economic-calendar?appcue&flow"},
        {name: RouteType.Lessons, path: "/lessons?Id&appcue&flow"},
        {name: RouteType.Signals, path: '/signals?appcue&flow'},
        {name: RouteType.Alerts, path: '/alerts?appcue&flow'},
        {name: RouteType.Promotions, path: "/promotions?appcue&flow"},
        {name: RouteType.AccountSettings, path: "/account-settings?appcue&flow"},
        {name: RouteType.FollowedAccounts, path: "/followed-accounts?appcue&flow"},
    ];

    private static DESKTOP: Route[] = [
        ...Routes.COMMON,
    ];

    private static MOBILE: Route[] = [
        ...Routes.COMMON,
        {name: RouteType.SymbolSearch, path: "/symbol-search?appcue&flow"},
        {name: RouteType.Article, path: "/article?Id&appcue&flow"},
        {name: RouteType.CreateUpdateTrade, path: "/cu-trade?SymbolId&DealId&appcue&flow"},
        {name: RouteType.Tools, path: "/tools?appcue&flow"},
        {name: RouteType.Videos, path: '/videos?appcue&flow'},
        {name: RouteType.Withdrawal, path: "/withdrawal?appcue&flow"},
        {name: RouteType.DepositLimits, path: '/deposit-limits?appcue&flow'},
        {name: RouteType.FollowedAccount, path: "/followed-account?Id&appcue&flow"},
    ];

    private static NATIVE: Route[] = [
        ...Routes.MOBILE,
        {name: RouteType.SignUp, path: "/sign-up"},
        {name: RouteType.Login, path: "/login"},
        {name: RouteType.ForgotPassword, path: "/forgot-password"},
        {name: RouteType.ResetPassword, path: "/reset-password"},
        {name: RouteType.ChangePassword, path: "/change-password"},
        {name: RouteType.CodeVerification, path: "/code-verification"},
        {name: RouteType.Verification, path: "/verification"},
        {name: RouteType.HomeScreens, path: "/home-screens"},
        {name: RouteType.Home, path: "/home"},
        {name: RouteType.CloseTrade, path: "/close-trade"},
        {name: RouteType.ClosedTrade, path: "/closed-trade"},
        {name: RouteType.Professional, path: "/become-a-professional"},
        {name: RouteType.UploadDocument, path:"/upload-documents"},
        {name: RouteType.Kyc, path: "/finalize-registration"},
        {name: RouteType.Deposit, path: "/deposit"},
        {name: RouteType.Feedback, path: "/feedback"},
        {name: RouteType.AddSymbol, path: "/add-symbol"},
        {name: RouteType.ShareInfo, path: "/share-info"},
        {name: RouteType.Support, path: "/support"},
        {name: RouteType.CloseOutMethod, path: "/close-out-method"},
        {name: RouteType.MyLeverage, path: "/my-leverage"},
        {name: RouteType.LeverageDisclaimer, path: "/leverage-disclaimer"},
        {name: RouteType.Notifications, path: "/notifications"},
        {name: RouteType.ProfileChangePassword, path: "/profile-change-password"},
        {name: RouteType.AccountDetails, path: "/profile-details"},
        {name: RouteType.PromotionsDetails, path: "/promotions-datails"},
        {name: RouteType.VerificationMethods, path: "/verification-methods"},
        {name: RouteType.OpenTradingAccount, path: "/open-trading-account"},
        {name: RouteType.WelcomeInvestAccount, path: "/welcome-invest-account"},
        {name: RouteType.TransferToCDF, path: "/transfer-to-cdf"},
        {name: RouteType.Logs, path: "/logs"},
        {name: RouteType.TradingAccountSuccess, path: "/trading-account-success"},
        {name: RouteType.ClosedOrderHistory, path: "/closed-order-history"},
        {name: RouteType.ChangeLanguage, path: "/change-language"},
        {name: RouteType.EMT, path: "/emt"},
        {name: RouteType.SignDeclaration, path: "/sign-declaration"},
        {name: RouteType.RecuringDetails, path: "/recuring-details"},
        {name: RouteType.BiometricAuthentication, path: "/biometric-authentication"},
        {name: RouteType.PersonalInfo, path: "/personal-info"},
        {name: RouteType.AddFollowedAccount, path: "/add-followed-account"},
        {name: RouteType.RemoveFollowedAccount, path: "/remove-followed-account"},
        {name: RouteType.AccountSearch, path: "/account-search"},
        {name: RouteType.OpenTradeDetails, path: "/open-trade-details"},
        {name: RouteType.PendingOrderDetails, path: "/pending-order-details"},
        {name: RouteType.AnnualStatement, path: "/annual-statement"},
    ];

    private constructor() {
    }

    public static routes(): Route[] {
        return WebUtil.isMobile() ? Routes.MOBILE : Routes.DESKTOP;
    }

    public static native(): Route[] {
        return  Routes.NATIVE;
    }

    public static IsModalRoute(routeType: RouteType): boolean {
        if (routeType) {
            switch (routeType) {
                case RouteType.SymbolSearch:
                case RouteType.CreateUpdateTrade:
                case RouteType.Withdrawal:
                case RouteType.DepositLimits:
                case RouteType.Filters:
                case RouteType.CloseTrade:
                case RouteType.ClosedTrade:
                case RouteType.Professional:
                case RouteType.UploadDocument:
                case RouteType.Deposit:
                case RouteType.Feedback:
                case RouteType.AddSymbol:
                case RouteType.ShareInfo:
                case RouteType.Support:
                case RouteType.CloseOutMethod:
                case RouteType.MyLeverage:
                case RouteType.LeverageDisclaimer:
                case RouteType.Notifications:
                case RouteType.ProfileChangePassword:
                case RouteType.AlertSymbol:
                case RouteType.AccountDetails:
                    return true;
            }
        }
        return false;
    }

    public static IsToolsRoute(routeType: RouteType): boolean {
        if (routeType) {
            switch (routeType) {
                case RouteType.News:
                case RouteType.Article:
                case RouteType.EconomicCalendar:
                case RouteType.Signals:
                case RouteType.Alerts:
                case RouteType.Lessons:
                case RouteType.AccountSettings:
                case RouteType.FollowedAccounts:
                case RouteType.FollowedAccount:
                case RouteType.Support:
                case RouteType.Logs:
                    return true;
            }
        }
        return false;
    }

    public static IsPartnerRoute(routeType: string): boolean {
        if (routeType) {
            switch (routeType) {
                case RouteType.FollowedAccounts:
                case RouteType.FollowedAccount:
                    return true;
            }
        }
        return false;
    }
}
