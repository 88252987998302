import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import TradesReducer from "core/redux/trades/TradesReducer";
import TradesEngine from "core/engine/TradesEngine";
import ClosedTradesReducer from "core/redux/trades/ClosedTradesReducer";
import {TradeState} from "core/state/TradeState";

export class TradesService implements Service<TradesEngine, TradeState> {

    private static _instance: TradesService;

    private constructor() {
    }

    public static instance(): TradesService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Trades;
    }

    public reducers(): Array<Reducer<any>> {
        return [TradesReducer.instance(), ClosedTradesReducer.instance()];
    }

    public engine(): TradesEngine {
        return TradesEngine.instance();
    }

    public state(): TradeState {
        return TradeState.instance();
    }
}
