import {ActionCreatorDefinition, createAction} from "redux-ts";
import {News} from "protocol/news/GetNewsResponse";

export const DoFetchNewsType: string = "DoFetchNews";
export const SetNewsType: string =  "SetNews";
export const SetSelectedNewsIdType: string = "SetSelectedNewsIdType";
export const SetNewsLoadingType: string = "SetNewsLoading";

export interface DoFetchNewsPayload {

}

export interface SetNewsPayload {

    news: News[];
}

export interface SetSelectedNewsIdPayload {

    id: string;
}

export interface SetNewsLoadingPayload {

    loading: boolean;
}

export const DoFetchNews: ActionCreatorDefinition<DoFetchNewsPayload, any> = createAction<DoFetchNewsPayload>(DoFetchNewsType);
export const SetNews: ActionCreatorDefinition<SetNewsPayload, any> = createAction<SetNewsPayload>(SetNewsType);
export const SetSelectedNewsId: ActionCreatorDefinition<SetSelectedNewsIdPayload, any> = createAction<SetSelectedNewsIdPayload>(SetSelectedNewsIdType);
export const SetNewsLoading: ActionCreatorDefinition<SetNewsLoadingPayload, any> = createAction<SetNewsLoadingPayload>(SetNewsLoadingType)
