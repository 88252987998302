export enum BIEventType {

    SessionStart = "Session Start",
    SessionEnd = "Session End",
    ViewLoaded = "View loaded",
    MenuInteraction = "Menu Interaction",
    SymbolsViewInteraction = "Symbols View Interaction",
    MyTradesViewInteraction = "My trades View Interaction",
    FollowedAccountsViewInteraction = "Followed accounts View Interaction",
    SignalsViewInteraction = "Signals View Interaction",
    SignalsRequested = "Signals Requested",
    AlertsViewInteraction = "Alerts View Interaction",
    AccountViewInteraction = "Account View Interaction",
    SymbolPanelOpened = "Symbol Panel Opened",
    SymbolPanelClosed = "Symbol Panel Closed",
    SymbolPanelInteraction = "Symbol Panel Interaction",
    SymbolAlertCreated = "Symbol Alert Created",
    OrderDetailsInteraction = "Order Details Interaction",
    PositionOpened = "Position Opened",
    PositionUpdated = "Position Updated",
    PositionClosed = "Position Closed",
    OrderCreated = "Order Created",
    OrderUpdated = "Order Updated",
    OrderCancelled = "Order Cancelled",
    TradeError = "Trade Error",
    TradeErrorUI = "Trade Error UI",
    MarginCall = "Margin call",
    Liquidation = "Liquidation",
    ChartInteraction = "Chart Interaction",
    BannerInteraction = "Banner Interaction",
    WalkThroughStarted = "WalkThrough started",
    WalkThroughCompleted = "WalkThrough completed",
    WatchVideo = "Watch video",
    StopVideo = "Stop video",
    NotificationCenterInteraction = "Notification center interaction",
    WindowOpen = "Window Open",
    GetUrlAttempt = "Get Url attempt",
    GetUrlResult = "Get Url result",
    DepositDecision = "Deposit Decision",
    DepositPageOpened = "Deposit Page Opened",
    DepositPageLoadError = "Deposit Page Load Error",
    NativeInteraction = "Native Interaction",
    NativeSignUp = "Native Sign Up",
    LoginAttempt = "Login Attempt",
    LoginDecision = "Login Decision",
    NativeRatingPopup = "Native Rating Popup",
    AppForceUpdate = "App Force Update",
    EMTEnabled = "EMT Enabled",
    EMTDisabled = "EMT Disabled",
    SocialGotCredentials = "Social got credentials",
    SocialFailed = "Social failed",
    BiometricSetting = "Biometric Setting",
    BiometricAuthenticationFailed = "Biometric Authentication Failed"
}
