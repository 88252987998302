import Utils from "platform/util/Utils";

enum Currency {

    EUR = "EUR",
    USD = "USD",
    PLN = "PLN",
    CHF = "CHF",
    DKK = "DKK",
    GBR = "GBR",
    HKO = "HKO",
    HUF = "HUF",
    ISK = "ISK",
    INR = "INR",
    ILS = "ILS",
    QAR = "QAR",
    RUB = "RUB",
    SAR = "SAR",
    SGD = "SGD",
    ZAR = "ZAR",
    KRW = "KRW",
    LKR = "LKR",
    SEK = "SEK",
    TWD = "TWD",
    THB = "THB",
    TND = "TND",
    TRY = "TRY",
    UAE = "UAE",
    OMR = "OMR",
    JPY = "JPY",
    JOD = "JOD",
    KES = "KES",
    KWD = "KWD",
    LVL = "LVL",
    LTL = "LTL",
    MAD = "MAD",
    NZD = "NZD",
    NOK = "NOK",
    PKR = "PKR",
    PHP = "PHP",
    AUD = "AUD",
    BHD = "BHD",
    BGN = "BGN",
    CAD = "CAD",
    HRK = "HRK",
    CZK = "CZK",
    RON = "RON",
    MUR = "MUR",

}

namespace Currency {

    export function sign(v: string): string {
        if (Utils.isNotEmpty(v)) {
            switch (v.toUpperCase()) {
                case "EUR":
                    return "€";
                case "USD":
                    return "$";
                case "PLN":
                    return "zł";
                case "CHF":
                    return "CHF";
                case "CNY":
                    return "¥";
                case "NOK":
                    return "kr";
                case "NZD":
                    return "$";
                case "TRY":
                    return "₺";
                case "HUF":
                    return "Ft";
                case "AUD":
                    return "$";
                case "NGN":
                    return "₦";
                case "PEN":
                    return "S/.";
                case "AED":
                    return "د.إ";
                case "SGD":
                    return "$";
                case "ILS":
                    return "₪";
                case "SEK":
                    return "kr";
                case "RUB":
                    return "₽";
                case "BRL":
                    return "R$";
                case "ZAR":
                    return "R";
                case "MXN":
                    return "$";
                case "JPY":
                    return "¥";
                case "GBP":
                    return "£";
                case "INR":
                    return "₹";
            }
        }
        return v || "";
    }
}

export {
    Currency
};
