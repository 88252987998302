import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";
import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import {Deal} from "platform/protocol/trading/Deal";
import {LangCode} from "platform/enum/LangCode";
import {OrientationType} from "platform/enum/OrientationType";
import {ThemeType} from "platform/enum/ThemeType";

export class IntegrationChartInit implements IntegrationMessage {

    public classId: number = IntegrationClassId.CHART_INIT;
    public symbol: string;
    public symbolId: number;
    public fallbackSymbol: string;
    public origin: string;
    public accountId: string;
    public ctoken: string;
    public environmentType: NXEnvironmentType;
    public langCode: LangCode;
    public OpenPositions: Deal[];
    public orientation: OrientationType;
    public theme: ThemeType;

    public constructor(symbol: string, symbolId: number, fallbackSymbol: string, origin: string, accountId: string, ctoken: string, environmentType: NXEnvironmentType, langCode: LangCode, OpenPositions: Deal[], orientation: OrientationType, theme: ThemeType) {
        this.symbol = symbol;
        this.symbolId = symbolId;
        this.fallbackSymbol = fallbackSymbol;
        this.origin = origin;
        this.accountId = accountId;
        this.ctoken = ctoken;
        this.environmentType = environmentType;
        this.langCode = langCode;
        this.OpenPositions = OpenPositions;
        this.orientation = orientation;
        this.theme = theme;
    }
}
