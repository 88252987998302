export enum EntryOrderCancellationReason {

    UserRequest = 1,
    OCO = 2,
    LimitExpiration = 3,
    NotEnoughMargin = 4,
    Manual = 5,
    PositionWasClosed = 6,
    ExecutionFailure = 7,
    CloseOnlyEvent = 8,
    StockSplit = 9,
}
