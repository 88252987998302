import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";

export class IntegrationChartDealUpdateSLTPResult implements IntegrationMessage {

    public classId: number = IntegrationClassId.CHART_DEAL_UPDATE_SL_TP_RESULT;
    public success: boolean;

    public constructor(success: boolean) {
        this.success = success;
    }
}
