import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import AccountReducer from "core/redux/account/AccountReducer";
import AccountEngine from "core/engine/AccountEngine";
import {AccountState} from "core/state/AccountState";
import WithdrawalReducer from "core/redux/account/WithdrawalReducer";

export class AccountService implements Service<AccountEngine, AccountState> {

    private static _instance: AccountService;

    private constructor() {
    }

    public static instance(): AccountService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Account;
    }

    public reducers(): Array<Reducer<any>> {
        return [AccountReducer.instance(), WithdrawalReducer.instance()];
    }

    public engine(): AccountEngine {
        return AccountEngine.instance();
    }

    public state(): AccountState {
        return AccountState.instance();
    }
}
