export enum EntryOrderStatus {

    Inactive = 1,
    Active = 2,
    Executed = 3,
    Cancelled = 4,
    Pending =5,
    ReplaceRequest = 6,
    Rejected = 7,
    CancelRequest = 8,
}
