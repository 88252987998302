import {LocaleProvider} from "core/format/LocaleProvider";

export class Locale {

    private static _provider: LocaleProvider;

    private constructor() {
    }

    public static get provider(): LocaleProvider {
        return this._provider;
    }

    public static set provider(value: LocaleProvider) {
        this._provider = value;
    }
}
