enum UrlTarget {

    BLANK = "blank",
    SELF = "self",
    IFRAME = "iframe",
}

export {
    UrlTarget
};
