export enum PopupActionType {

    OpenDeposit = 1,
    OpenPosition = 4,
    OpenSurvey = 6,
    ExecuteDeepLink = 7,
    InApp = 8,
    OpenUrl = 9,
}

export interface PopupNotification {

    Hyperlink: string;
    Id: number;
    Message: string;
    PopupActionType: PopupActionType;
    SymbolId: number;
    SymbolName: string;
}
