enum PromotionOverview {

    ProtectedPositions,
    SpreadCashback,
    InsuredPositions,
    RolloverDiscount,
    RebatePlan,
    Vault,
}
export default PromotionOverview;
