import {StorageKey} from 'enum/StorageKey';
import {Engine} from "platform/engine/Engine";
import {
    ResetFilterPayload,
    SetFilterVisible,
    SetSymbolFilterContinent,
    SetSymbolFilterContinentPayload
} from "core/redux/filter/FilterReduxActions";
import Platform from "platform/Platform";
import Utils from 'platform/util/Utils';
import {RouteType} from "core/router/Routes";

export default class FilterEngine extends Engine {

    private static _instance: FilterEngine;

    public static instance(): FilterEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        const data: string =  await Platform.storage().getItem(StorageKey.SelectedContinents);

        if (Utils.isNotEmpty(data)) {
            const selectedContinents = JSON.parse(data);
            Platform.dispatch(SetSymbolFilterContinent({continents: selectedContinents}))
        }

    }

    public onChangeRoute = (payload): void => {
        Platform.dispatch(SetFilterVisible({visible: false}));
    }

    public doSetSymbolFilterContinent = (payload: SetSymbolFilterContinentPayload): void => {
        Platform.storage().setItem(StorageKey.SelectedContinents, JSON.stringify(payload.continents));
    }

    public doResetFilter = (payload: ResetFilterPayload): void => {
        if (payload.routeName === RouteType.Symbols) {
            Platform.storage().setItem(StorageKey.SelectedContinents, JSON.stringify([]));
        }
    }
}
