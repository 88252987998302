import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {
    OnAccountChanged,
    OnLoggedOut,
    SetAuthStatus,
    SetAuthStatusPayload,
    SetAuthStatusType
} from "core/redux/auth/AuthReduxActions";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {SettingsReduxState} from "core/redux/settings/SettingsReduxState";
import {SettingsTab} from "enum/SettingsTab";
import SettingsEngine from "core/engine/SettingsEngine";
import {
    AcceptDisclaimerForExternalProvidersType,
    DoChangeCloseOutMethodType,
    DoChangeLeverageType,
    DoSkipEducationTradingGroupType,
    FetchCanSkipEducationTradingGroupType,
    FetchDepositLimitsType,
    FetchEMTPercentagesType,
    FetchIsRollingContractsEnabledType,
    FetchSettingsConfigType,
    FetchUserProfileType,
    FetchVaultDetailsType,
    InsertClientPreferenceLSType,
    InsertClientPreferenceType,
    NotifyNotAvailableForPushNotificationsType,
    RegisterPushTokenType,
    SetAccountStatements,
    SetAccountStatementsPayload,
    SetAvailableCloseOutMethods,
    SetAvailableCloseOutMethodsPayload,
    SetAvailableLeverages,
    SetAvailableLeveragesPayload,
    SetCallMeSubmitted,
    SetCanSkipEducationTradingGroup,
    SetClientPreference,
    SetClientPreferencePayload,
    SetCurrentCloseOutMethod,
    SetCurrentCloseOutMethodPayload,
    SetCurrentLeverage,
    SetCurrentLeveragePayload,
    SetDepositLimitsInfo,
    SetDepositLimitsInfoPayload,
    SetDisclaimerAcceptedForExternalProviders,
    SetDisclaimerStatusReceivedForExternalProviders,
    SetEMTEnabled,
    SetEMTPercentages,
    SetEMTPercentagesPayload,
    SetIsRollingContractsEnabled,
    SetIsRollingContractsEnabledPayload,
    SetLegalDataDocuments,
    SetLegalDataDocumentsPayload,
    SetLocalizationData,
    SetLocalizationDataPayload,
    SetPreferencesFetched,
    SetSettingsTab,
    SetSettingsTabPayload,
    SetTransactions,
    SetTransactionsPayload,
    SetTwoFactorAuthenticationEnabled,
    SetTwoFactorAuthenticationMethod,
    SetTwoFactorAuthenticationMethodPayload,
    SetUserAvailableForPushNotifications,
    SetUserProfileInfo,
    SetUserProfileInfoPayload,
    SetVaultDetails,
    SetVaultDetailsPayload,
    SubmitAccountAnnualReportType,
    SubmitEMTPercentageType,
    SubmitLeverageDisclaimerType,
    SetDeclarationRecorded,
    SetTransactionsToSign,
    TransactionsToSignPayload,
    FetchTransactionsToSignType,
    DoSignTransactionsType,
    SetUIConfigurationPayload,
    SetUIConfiguration,
    SetEnabledSkipEducationTradingGroup,
    SetTelegramUrlPayload,
    SetTelegramUrl, RegisterPushToken, RegisterPushTokenPayload, UpdatePlatformLanguageType
} from "core/redux/settings/SettingsReduxActions";
import Utils from "platform/util/Utils";
import {TSMap} from "typescript-map";
import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import {SetThemeType} from "platform/redux/core/CoreActions";
import {StorageKey} from "enum/StorageKey";
import {LSKey} from "platform/storage/Storage";
import {Preference} from "core/util/Preference";
import {SetLDPlatformPropsType} from "core/redux/app/AppReduxActions";
import {Configuration} from "core/configuration/Configuration";
import {BooleanPayload} from "core/redux/StoreActions";
import {LoginAccountType} from "protocol/auth/LoginAccountType";

const initialState = (): SettingsReduxState => {
    const preferences: TSMap<StorageKey | LSKey, any> = new TSMap<StorageKey | LSKey, any>();
    preferences.set(StorageKey.PopupSound, Preference.Of(true));
    preferences.set(StorageKey.ShowChartMobile, Preference.Of(true));
    preferences.set(StorageKey.ShowChartDesktop, Preference.Of(true));
    preferences.set(StorageKey.CompactListView, Preference.Of(true));
    preferences.set(StorageKey.ExpandedView, Preference.Of(false));
    return {
        localization: {},
        preferencesFetched: false,
        preferences,
        userProfile: {},
        transactions: [],
        statements: [],
        closeOutMethods: [],
        leverages: [],
        annualStatementYears: [],
        legalDataDocuments: [],
        disclaimerAcceptedForExternalProviders: !Platform.config<Configuration>().disclaimerForExternalProviders,
        EMTPercentages: [],
        TransactionsToSign: []
    };
};

export default class SettingsReducer extends Reducer<SettingsReduxState> {

    private static _instance: SettingsReducer;

    public static instance(): SettingsReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const settingsEngine: SettingsEngine = Platform.engine(ServiceType.Settings);
        this._middlewareActions.set(UpdatePlatformLanguageType, settingsEngine.doUpdateLanguage);
        this._middlewareActions.set(SetAuthStatusType, settingsEngine.doSetAuthStatus);
        this._middlewareActions.set(SetLDPlatformPropsType, settingsEngine.onSetLDPlatformProps);
        this._middlewareActions.set(FetchSettingsConfigType, settingsEngine.doFetchSettingsConfig);
        this._middlewareActions.set(InsertClientPreferenceType, settingsEngine.doInsertClientPreference);
        this._middlewareActions.set(InsertClientPreferenceLSType, settingsEngine.doInsertClientPreferenceLS);
        this._middlewareActions.set(SetThemeType, settingsEngine.onSetTheme);
        this._middlewareActions.set(SubmitAccountAnnualReportType, settingsEngine.doSubmitAccountAnnualReport);
        this._middlewareActions.set(DoChangeLeverageType, settingsEngine.doChangeLeverage);
        this._middlewareActions.set(DoChangeCloseOutMethodType, settingsEngine.doChangeCloseOutMethod);
        this._middlewareActions.set(SubmitLeverageDisclaimerType, settingsEngine.doSubmitLeverageDisclaimer);
        this._middlewareActions.set(FetchDepositLimitsType, settingsEngine.doFetchDepositLimits);
        this._middlewareActions.set(FetchUserProfileType, settingsEngine.doFetchUserProfile);
        this._middlewareActions.set(FetchVaultDetailsType, settingsEngine.doFetchVaultDetails);
        this._middlewareActions.set(FetchIsRollingContractsEnabledType, settingsEngine.doRollingContractEnabled);
        this._middlewareActions.set(AcceptDisclaimerForExternalProvidersType, settingsEngine.doAcceptDisclaimerForExternalProviders);
        this._middlewareActions.set(FetchCanSkipEducationTradingGroupType, settingsEngine.doFetchCanSkipEducationTradingGroup);
        this._middlewareActions.set(DoSkipEducationTradingGroupType, settingsEngine.doSkipEducationTradingGroup);
        this._middlewareActions.set(NotifyNotAvailableForPushNotificationsType, settingsEngine.doNotifyNotAvailableForPushNotifications);
        this._middlewareActions.set(RegisterPushTokenType, settingsEngine.doRegisterPushToken);
        this._middlewareActions.set(FetchEMTPercentagesType, settingsEngine.doGetEMTPercentage);
        this._middlewareActions.set(SubmitEMTPercentageType, settingsEngine.doSubmitEMTPercentage);
        this._middlewareActions.set(FetchTransactionsToSignType, settingsEngine.doFetchTransactionsToSign);
        this._middlewareActions.set(DoSignTransactionsType, settingsEngine.doSignTransactions);
    }

    public get name(): string {
        return "settings";
    }

    protected setup(builder: ReducerBuilder<SettingsReduxState>): void {
        builder
            .init({
                ...initialState(),
            })
            .handle(OnLoggedOut, (state: SettingsReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    legalDataDocuments: state.legalDataDocuments,
                    preferences: state.preferences
                };
            })
            .handle(OnAccountChanged, (state: SettingsReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    preferences: state.preferences,
                    activeSettingTab: state.activeSettingTab,
                    userProfile: state.userProfile,
                    legalDataDocuments: state.legalDataDocuments,
                    disclaimerStatusReceivedForExternalProviders: state.disclaimerStatusReceivedForExternalProviders,
                    disclaimerAcceptedForExternalProviders: state.disclaimerAcceptedForExternalProviders,
                };
            })
            .handle(SetAuthStatus, (state: SettingsReduxState, {payload}: Action<SetAuthStatusPayload>) => {
                const {accountType, loginAccountType} = payload.authStatus;
                if (accountType && loginAccountType) {
                    return Object.assign({}, state, {
                        activeSettingTab: accountType === NXEnvironmentType.Live && loginAccountType !== LoginAccountType.Quantum ? SettingsTab.Funds : SettingsTab.Profile
                    });
                }
                return state;
            })
            .handle(SetLocalizationData, (state: SettingsReduxState, {payload}: Action<SetLocalizationDataPayload>) => {
                return Object.assign({}, state, {
                    localization: payload.localization
                });
            })
            .handle(SetPreferencesFetched, (state: SettingsReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    preferencesFetched: true
                });
            })
            .handle(SetClientPreference, (state: SettingsReduxState, {payload}: Action<SetClientPreferencePayload<any>>) => {
                const newState: SettingsReduxState = Utils.merge({}, state);
                newState.preferences.set(payload.key, payload.preference);
                return newState;
            })
            .handle(SetSettingsTab, (state: SettingsReduxState, {payload}: Action<SetSettingsTabPayload>) => {
                return Object.assign({}, state, {
                    activeSettingTab: payload.tab
                });
            })
            .handle(SetTransactions, (state: SettingsReduxState, {payload}: Action<SetTransactionsPayload>) => {
                return Object.assign({}, state, {
                    transactions: payload.transactions
                });
            })
            .handle(SetAccountStatements, (state: SettingsReduxState, {payload}: Action<SetAccountStatementsPayload>) => {
                return Object.assign({}, state, {
                    statements: payload.statements
                });
            })
            .handle(SetUIConfiguration, (state: SettingsReduxState, {payload}: Action<SetUIConfigurationPayload>) => {
                return Object.assign({}, state, {
                    annualStatementYears: payload.AnnualStatementYears,
                    ShareInformationEnabled: payload.ShowShareInformation,
                    SwitchToInvestEnabled: payload.ShowSwitchToInvest,
                    CallMeEnabled: payload.CallMeEnabled,
                    UploadedDocumentsSendToEmail: payload.UploadedDocumentsSendToEmail
                });
            })
            .handle(SetAvailableLeverages, (state: SettingsReduxState, {payload}: Action<SetAvailableLeveragesPayload>) => {
                return Object.assign({}, state, {
                    isChangeLeverageAllowed: payload.isAllowed,
                    leverages: payload.leverages,
                    currentLeverage: payload.leverage,
                    shouldAcceptLeverageDisclaimer: payload.shouldAcceptLeverageDisclaimer
                });
            })
            .handle(SetAvailableCloseOutMethods, (state: SettingsReduxState, {payload}: Action<SetAvailableCloseOutMethodsPayload>) => {
                return Object.assign({}, state, {
                    isChangeCloseOutAllowed: payload.isAllowed,
                    closeOutMethods: payload.methods,
                    currentMethod: payload.currentMethod,
                });
            })
            .handle(SetCurrentLeverage, (state: SettingsReduxState, {payload}: Action<SetCurrentLeveragePayload>) => {
                return Object.assign({}, state, {
                    currentLeverage: payload.leverage
                });
            })
            .handle(SetCurrentCloseOutMethod, (state: SettingsReduxState, {payload}: Action<SetCurrentCloseOutMethodPayload>) => {
                return Object.assign({}, state, {
                    currentMethod: payload.currentMethod
                });
            })
            .handle(SetUserProfileInfo, (state: SettingsReduxState, {payload}: Action<SetUserProfileInfoPayload>) => {
                let disclaimerAcceptedForExternalProviders: boolean = state.disclaimerAcceptedForExternalProviders;
                if (Platform.config<Configuration>().disclaimerForExternalProviders && Utils.isNotNull(payload.userProfile?.IsSignalsDisclaimerAgreementAccepted)) {
                    disclaimerAcceptedForExternalProviders = payload.userProfile.IsSignalsDisclaimerAgreementAccepted;
                }
                return Object.assign({}, state, {
                    userProfile: payload.userProfile,
                    disclaimerAcceptedForExternalProviders
                });
            })
            .handle(SetDepositLimitsInfo, (state: SettingsReduxState, {payload}: Action<SetDepositLimitsInfoPayload>) => {
                return Object.assign({}, state, {
                    accountLimit: payload.accountLimit,
                    leftTopUp: payload.leftTopUp
                });
            })
            .handle(SetVaultDetails, (state: SettingsReduxState, {payload}: Action<SetVaultDetailsPayload>) => {
                return Object.assign({}, state, {
                    vault: payload.vault
                });
            })
            .handle(SetIsRollingContractsEnabled, (state: SettingsReduxState, {payload}: Action<SetIsRollingContractsEnabledPayload>) => {
                return Object.assign({}, state, {
                    isRollingContractsEnabled: payload.enabled,
                    rollingContractsExpirationDate: payload.expiration
                });
            })
            .handle(SetLegalDataDocuments, (state: SettingsReduxState, {payload}: Action<SetLegalDataDocumentsPayload>) => {
                return Object.assign({}, state, {
                    legalDataDocuments: payload.documents
                });
            })
            .handle(SetCallMeSubmitted, (state: SettingsReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    CallMeSubmitted: true
                });
            })
            .handle(SetDisclaimerStatusReceivedForExternalProviders, (state: SettingsReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    disclaimerStatusReceivedForExternalProviders: true
                });
            })
            .handle(SetDisclaimerAcceptedForExternalProviders, (state: SettingsReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    disclaimerAcceptedForExternalProviders: true
                });
            })
            .handle(SetEnabledSkipEducationTradingGroup, (state: SettingsReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    SkipEducationEnabled: payload.value
                });
            })
            .handle(SetCanSkipEducationTradingGroup, (state: SettingsReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    canSkipEducationTradingGroup: payload.value
                });
            })
            .handle(SetUserAvailableForPushNotifications, (state: SettingsReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    IsUserAvailableForPushNotifications: payload.value
                });
            })
            .handle(SetTwoFactorAuthenticationEnabled, (state: SettingsReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    TwoFactorAuthenticationEnabled: payload.value
                });
            })
            .handle(SetTwoFactorAuthenticationMethod, (state: SettingsReduxState, {payload}: Action<SetTwoFactorAuthenticationMethodPayload>) => {
                return Object.assign({}, state, {
                    TwoFactorAuthenticationMethod: payload.method
                });
            })
            .handle(SetEMTEnabled, (state: SettingsReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    EMTEnabled: payload.value
                });
            })
            .handle(SetEMTPercentages, (state: SettingsReduxState, {payload}: Action<SetEMTPercentagesPayload>) => {
                return Object.assign({}, state, {
                    EMTPercentages: payload.values
                });
            })
            .handle(RegisterPushToken, (state: SettingsReduxState, {payload}: Action<RegisterPushTokenPayload>) => {
                return Object.assign({}, state, {
                    PushNotificationToken: payload.pushNotificationToken
                });
            })
            .handle(SetDeclarationRecorded, (state: SettingsReduxState, {payload}: Action<BooleanPayload>) => {
                if (payload.value) {
                    setTimeout(() => Platform.dispatch(SetDeclarationRecorded({value: false})), 5000);
                }

                return Object.assign({}, state, {
                    isDeclarationRecorded: payload.value
                });
            })
            .handle(SetTransactionsToSign, (state: SettingsReduxState, {payload}: Action<TransactionsToSignPayload>) => {
                return Object.assign({}, state, {
                    TransactionsToSign: payload.transactions
                });
            })
            .handle(SetTelegramUrl, (state: SettingsReduxState, {payload}: Action<SetTelegramUrlPayload>) => {
                return Object.assign({}, state, {
                    telegramInitUrl: payload.telegramInitUrl
                });
            });
    }
}
