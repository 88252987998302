import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";

export class IntegrationChartDealUpdate implements IntegrationMessage {

    public classId: number = IntegrationClassId.CHART_DEAL_UPDATE;
    public position: any; // Web profit object

    public constructor(position: any) {
        this.position = position;
    }
}
