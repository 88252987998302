import {Engine} from "platform/engine/Engine";
import Platform from "platform/Platform";
import Utils from "platform/util/Utils";
import {TSMap} from "typescript-map";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import {SymbolCategory, TableCategoryLSData} from "enum/SymbolCategory";
import {StorageKey} from "enum/StorageKey";
import {SymbolSignal} from "platform/protocol/trading/SymbolSignal";
import {
    FetchCategorySignalsPayload, FetchSignalByIdPayload,
    FetchSymbolSignalsPayload,
    SelectSignal,
    SetCategorySignals, SetSignalDetails,
    SetSignalsLoading,
    SetSignalsSettings,
    SetSignalsTablesLS,
    SetSignalsTradeColumnPayload,
    SetSortedSignals,
    SetSymbolSignals, SetSymbolSignalsTabView,
    ToggleSignalsColumnSortPayload
} from "core/redux/deal/SignalsReduxActions";
import {GetSymbolSignalsRequest} from "protocol/symbol/GetSymbolSignalsRequest";
import {StoreState} from "core/redux/StoreState";
import {XhrUtil} from "core/util/XhrUtil";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {HttpReject} from "platform/network/http/Http";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import {GetSymbolSignalsResponse} from "protocol/symbol/GetSymbolSignalsResponse";
import {SignalsAddedFilterValues} from "enum/SignalsSettingsType";
import {SetSubscribedSymbolsPayload} from "core/redux/symbols/SymbolsReduxActions";
import {RouteType} from "core/router/Routes";
import {SymbolSubscriptionInfo} from "protocol/symbol/SymbolSubscriptionInfo";
import {SignalPatternType} from "platform/protocol/enum/SignalPatternType";
import {TableSortState} from "core/redux/common/TableSortState";
import {SignalUtil} from "core/util/SignalUtil";
import {SignalSubPatternType} from "platform/protocol/enum/SignalSubPatternType";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {GetSymbolSignalRequest} from "protocol/symbol/GetSymbolSignalRequest";
import {GetSymbolSignalResponse} from "protocol/symbol/GetSymbolSignalResponse";
import {GoingOpenDeal, SetTradeManagerTab} from "core/redux/deal/DealReduxActions";
import {TradeManagerTab} from "enum/TradeManagerTab";
import {SignalsTabView} from "core/redux/deal/SignalsReduxState";

export const SortSignals = (column: TradeColumn, direction: SortDirection) => {
    return (s1: SymbolSignal, s2: SymbolSignal): number => {
        let result: number = 0;
        switch (column) {
            case TradeColumn.Symbol:
                result = Utils.compareString(s1.SymbolName?.toUpperCase(), s2.SymbolName?.toUpperCase());
                break;
            case TradeColumn.Type:
                result = s1 && s2 && Utils.compareNumber(s1.Direction, s2.Direction);
                if (result === 0) {
                    result = s1 && s2 && Utils.compareBoolean(s1.IsComplete, s2.IsComplete);
                }
                break;
            case TradeColumn.Probability:
                result = s1 && s2 && Utils.compareNumber(s1.Probability, s2.Probability);
                break;
            case TradeColumn.Interval:
                result = s1 && s2 && Utils.compareNumber(s1.Interval, s2.Interval);
                break;
            case TradeColumn.Added:
                const s1i = new Date(s1?.IdentifiedDateTime).getTime();
                const s2i = new Date(s2?.IdentifiedDateTime).getTime();
                result = s1 && s2 && Utils.compareNumber(s1i, s2i);
                break;
            case TradeColumn.Target:
                const s1tpv = Number(s1?.TargetPeriod.split(' ')[0]);
                const s2tpv = Number(s2?.TargetPeriod.split(' ')[0]);
                const s1tpm = s1?.TargetPeriod.split(' ')[1].charAt(0);
                const s2tpm = s2?.TargetPeriod.split(' ')[1].charAt(0);
                const s1tp = s1tpm === 'd' ? 24 * 60 * s1tpv : s1tpm === 'h' ? 60 * s1tpv : s1tpv;
                const s2tp = s2tpm === 'd' ? 24 * 60 * s2tpv : s2tpm === 'h' ? 60 * s2tpv : s2tpv;
                result = s1 && s2 && Utils.compareNumber(s1tp, s2tp);
                break;
            case TradeColumn.PatternTypes:
                const s1ssp = Translations.text(TranslationKey[`signalsFilter${SignalSubPatternType[s1.SignalSubPatternTypeId]}`]);
                const s2ssp = Translations.text(TranslationKey[`signalsFilter${SignalSubPatternType[s2.SignalSubPatternTypeId]}`]);
                result = s1 && s2 && Utils.compareString(s1ssp, s2ssp);
                break;
        }
        return direction === SortDirection.Ask ? result : -result;
    };
};

export default class SignalsEngine extends Engine {

    private static _instance: SignalsEngine;

    public static instance(): SignalsEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        const data: TableCategoryLSData = await this.deserializeLS();
        Platform.dispatch(SetSignalsTablesLS(data));
    }

    private deserializeLS = async (): Promise<TableCategoryLSData> => {
        const tables: TSMap<string, TableSortState> = await SymbolCategory.deserialize(StorageKey.SignalsSort);
        return Promise.resolve({tables});
    }

    public onChangeRoute = (payload): void => {
        Platform.dispatch(SetSignalsSettings({visible: false}));
    }

    public doGetCategorySignals = async ({category}: FetchCategorySignalsPayload) => {
        this._logger.debug("Fetch signals for category: ", category);
        const {categories} = Platform.reduxState<StoreState>().symbols;
        const tradeSymbols: TradeSymbol[] = categories.get(category);
        Platform.dispatch(SetSignalsLoading({signalsLoaded: false}));
        const signals: SymbolSignal[] = await this.doFetchSignals((tradeSymbols || []).map((symbol: TradeSymbol) => symbol.SymbolId));
        const {activeCategoryTab, tables} = Platform.reduxState<StoreState>().signals;
        const {activeColumn, sort} = tables.get(activeCategoryTab);
        signals.sort(SortSignals(activeColumn, sort.get(activeColumn)));
        Platform.dispatch(SetSignalsLoading({signalsLoaded: true}));
        Platform.dispatch(SetCategorySignals({category, signals}));
        const {selectedSignals} = Platform.reduxState<StoreState>().signals;
        const selectedSignal: SymbolSignal = selectedSignals.get(category);
        if (Utils.isArrayNotEmpty(signals)) {
            if (Utils.isNull(selectedSignal) || Utils.isArrayEmpty(signals.filter((s: SymbolSignal) => s.SignalId === selectedSignal.SignalId))) {
                Platform.dispatch(SelectSignal({signal: signals[0]}));
            }
        } else {
            Platform.dispatch(SelectSignal({signal: null}));
        }
        Platform.dispatch(SetSignalsSettings({visible: false}));
    }

    public doGetSymbolSignals = async ({symbolId}: FetchSymbolSignalsPayload) => {
        this._logger.debug("Fetch signals for symbol: ", symbolId);
        const signals: SymbolSignal[] = await this.doFetchSignals([symbolId], false);
        Platform.dispatch(SetSymbolSignals({signals}));
    }

    public doSetSubscribedSymbols = async ({symbols}: SetSubscribedSymbolsPayload) => {
        const {router} = Platform.reduxState<StoreState>();
        const {activeCategoryTab} = Platform.reduxState<StoreState>().signals;
        if (router?.route?.name === RouteType.Signals && activeCategoryTab === SymbolCategory.MyWatchlist) {
            const signals: SymbolSignal[] = await this.doFetchSignals(symbols.values().map((ssi: SymbolSubscriptionInfo) => ssi.SymbolId));
            Platform.dispatch(SetCategorySignals({category: SymbolCategory.MyWatchlist, signals}));
            const {selectedSignals} = Platform.reduxState<StoreState>().signals;
            const selectedSignal: SymbolSignal = selectedSignals.get(SymbolCategory.MyWatchlist);
            if (Utils.isArrayNotEmpty(signals)) {
                if (Utils.isNull(selectedSignal) || Utils.isArrayEmpty(signals.filter((s: SymbolSignal) => s.SignalId === selectedSignal.SignalId))) {
                    Platform.dispatch(SelectSignal({signal: signals[0]}));
                }
            } else {
                Platform.dispatch(SelectSignal({signal: null}));
            }
        }
    }

    private doFetchSignals = async (SymbolIds: number[], useSettings: boolean = true): Promise<SymbolSignal[]> => {
        const {signals} = Platform.reduxState<StoreState>();
        const langCode: LangCode = LanguageUtil.languageCode();
        const {patternTypes, useProbability, filter, targetMin, targetMax} = signals.settings;
        const added: number = SignalsAddedFilterValues[filter.added / 5];
        const interval: number[] = filter.interval;
        const target: number[] = filter.target;
        const request: GetSymbolSignalsRequest = {
            LanguageCode: langCode,
            SymbolIds,
            patternTypes: useSettings ?
                patternTypes.filter((el: SignalPatternType, i: number) => patternTypes.indexOf(el) === i) :
                [SignalPatternType.KeyLevel, SignalPatternType.Fibonacci, SignalPatternType.ChartPattern],
            Filter: useSettings ? {
                breakout: filter.breakout,
                emerging: filter.emerging,
                patternSubTypes: filter.patternSubTypes,
                probability: useProbability ? filter.probability : null,
                added: SignalUtil.formAddedFilter(added),
                interval: SignalUtil.formIntervalFilter(interval),
                target: SignalUtil.formTargetFilter(target, targetMin, targetMax),
            } : {}
        };
        const answer: [HttpReject, GetSymbolSignalsResponse] = await Utils.to(this.sendToWebProfitService(request, "GetSignals"));
        if (answer[0]) {
            this._logger.debug("Failed fetch signals");
            return Promise.resolve([]);
        } else {
            const signals: SymbolSignal[] = [];
            const signalsBySymbolId: {[key: number]: SymbolSignal[]} = answer[1]?.SignalsBySymbolId;
            if (!Utils.isObjectEmpty(signalsBySymbolId)) {
                const {symbols} = Platform.reduxState<StoreState>().symbols;
                Object.keys(signalsBySymbolId).forEach((symbolId: string) => {
                    const symbol: TradeSymbol = symbols.get(parseInt(symbolId, 10));
                    const symbolSignals: SymbolSignal[] = signalsBySymbolId[symbolId];
                    if (symbol && Utils.isArrayNotEmpty(symbolSignals)) {
                        symbolSignals.forEach((sSignal: SymbolSignal) => {
                            if (sSignal.SignalPatternTypeId === SignalPatternType.ChartPattern
                                || sSignal.SignalPatternTypeId === SignalPatternType.Fibonacci
                                || sSignal.SignalPatternTypeId === SignalPatternType.KeyLevel) {
                                signals.push({
                                    SymbolId: symbol.SymbolId,
                                    SymbolName: symbol.text,
                                    ...sSignal
                                });
                            }
                        });
                    }
                });
            } else {
                this._logger.debug("Empty signals map");
            }
            return Promise.resolve(signals);
        }
    }

    public doSetSignalsTradeColumn = async (payload: SetSignalsTradeColumnPayload) => {
        const {categories} = Platform.reduxState<StoreState>().signals;
        const {category, tradeColumn} = payload;
        this._logger.debug("Set signal trade column: " + tradeColumn + " for category: " + category);
        const newSignals: SymbolSignal[] = [...categories.get(category)];
        newSignals.sort(SortSignals(tradeColumn, SortDirection.Ask));
        Platform.dispatch(SetSortedSignals({
            category,
            tradeColumn,
            sortDirection: SortDirection.Ask,
            signals: newSignals
        }));
    }

    public doToggleSignalsTradeColumn = async (payload: ToggleSignalsColumnSortPayload) => {
        const {categories, tables} = Platform.reduxState<StoreState>().signals;
        const {category, tradeColumn} = payload;
        const tableState: TableSortState = tables.get(category);
        const sort: TSMap<TradeColumn, SortDirection> = tableState.sort;
        const sortDirection: SortDirection = sort.get(tradeColumn);
        const resultDirection: SortDirection = sortDirection ? SortDirection.reverse(sortDirection) : SortDirection.Ask;
        this._logger.debug("Toggle signals trade column: " + tradeColumn + " for category: " + category + " result direction: " + resultDirection);
        const newSignals: SymbolSignal[] = [...categories.get(category)];
        newSignals.sort(SortSignals(tradeColumn, resultDirection));
        Platform.dispatch(SetSortedSignals({
            category,
            tradeColumn,
            sortDirection: resultDirection,
            signals: newSignals
        }));
    }

    public doFetchSignal = async ({id, action}: FetchSignalByIdPayload) => {
        const langCode: LangCode = LanguageUtil.languageCode();
        const request: GetSymbolSignalRequest = {
            LanguageCode: langCode,
            SignalId: id
        };
        const answer: [HttpReject, GetSymbolSignalResponse] = await Utils.to(this.sendToWebProfitService(request, "GetSignalById"));
        if (answer[0]) {
            this._logger.debug("Failed fetch signal by id " + id);
        } else {
            const {Success, Signal, SymbolId} = answer[1];
            if (Success && Signal?.SignalId && SymbolId) {
                const {symbols} = Platform.reduxState<StoreState>().symbols;
                const symbol: TradeSymbol = symbols.get(SymbolId);
                if (symbol) {
                    Signal.SymbolId = SymbolId;
                    Signal.SymbolName = symbol.text;
                }
                Platform.dispatch(GoingOpenDeal({
                    SymbolId: SymbolId,
                    SignalId: Signal.SignalId,
                    Direction: Signal.Direction,
                    stopLoss: Signal.StopLossRate,
                    takeProfit: Signal.TargetLevelRate,
                    action
                }));
                Platform.dispatch(SetTradeManagerTab({tab: TradeManagerTab.Signals}));
                Platform.dispatch(SetSignalDetails({signal: Signal}));
                Platform.dispatch(SetSymbolSignalsTabView({tabView: SignalsTabView.SignalDetails}));
            } else {
                this._logger.warn("Can't execute dpk for Signal with id " + Signal?.SignalId + " SymbolId " + SymbolId);
            }
        }
    }

    private sendToWebProfitService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "WebProfitServer/WebProfitClientService.svc/json/" + path, null, true);
    }
}
