enum ToolsMenuType {

    News = "news",
    EconomicCalendar = "economicCalendar",
    TradingOpportunities = "tradingOpportunities",
    Signals = "signals",
    Alerts = "alerts",
    Lessons = "lessons",
    AccountSettings= "accountSettings",
    LiveChat = "liveChat",
    Support = "support",
    Logs = "logs",
    FollowedAccounts = "followedAccounts",
}
export default ToolsMenuType;
