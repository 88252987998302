import {ActionCreatorDefinition, createAction} from "redux-ts";
import {PortfolioType} from "enum/PortfolioType";
import {TradeColumn} from "enum/TradeColumn";
import {AccountLog} from "protocol/account/AccountLog";
import {TSMap} from "typescript-map";
import {PortfolioTableState} from "core/redux/portfolio/PortfolioReduxState";

export const SetPortfolioTabType: string =  "SetPortfolioTab";
export const RefreshPortfolioTabType: string =  "RefreshPortfolioTab";
export const SetPortfolioCounterType: string =  "SetPortfolioCounter";
export const SetPortfolioFilterCounterType: string =  "SetPortfolioFilterCounter";
export const SetPortfolioTablesType: string =  "SetPortfolioTables";
export const SetPortfolioTradeColumnType: string =  "SetPortfolioTradeColumn";
export const TogglePortfolioColumnSortType: string =  "TogglePortfolioColumnSort";
export const SetAccountLogsType: string =  "SetAccountLogs";
export const SetAccountLogsPendingType: string =  "SetAccountLogsPending";

export interface SetPortfolioTabPayload {

    portfolioType: PortfolioType;
}

export interface PortfolioCounterPayload {

    portfolioType: PortfolioType;
    value: number;
}

export interface SetPortfolioTablesPayload {

    tables?: TSMap<PortfolioType, PortfolioTableState>;
}

export interface SetPortfolioTradeColumnPayload {

    tradeColumn: TradeColumn;
    routeName?: string;
}

export interface SetAccountLogsPayload {

    logs: AccountLog[];
}

export interface SetAccountLogsPendingPayload {

    pending: boolean;
}

export const SetPortfolioTab: ActionCreatorDefinition<SetPortfolioTabPayload, any> = createAction<SetPortfolioTabPayload>(SetPortfolioTabType);
export const RefreshPortfolioTab: ActionCreatorDefinition<SetPortfolioTabPayload, any> = createAction<SetPortfolioTabPayload>(RefreshPortfolioTabType);
export const SetPortfolioCounter: ActionCreatorDefinition<PortfolioCounterPayload, any> = createAction<PortfolioCounterPayload>(SetPortfolioCounterType);
export const SetPortfolioFilterCounter: ActionCreatorDefinition<PortfolioCounterPayload, any> = createAction<PortfolioCounterPayload>(SetPortfolioFilterCounterType);
export const SetPortfolioTables: ActionCreatorDefinition<SetPortfolioTablesPayload, any> = createAction<SetPortfolioTablesPayload>(SetPortfolioTablesType);
export const SetPortfolioTradeColumn: ActionCreatorDefinition<SetPortfolioTradeColumnPayload, any> = createAction<SetPortfolioTradeColumnPayload>(SetPortfolioTradeColumnType);
export const TogglePortfolioColumnSort: ActionCreatorDefinition<SetPortfolioTradeColumnPayload, any> = createAction<SetPortfolioTradeColumnPayload>(TogglePortfolioColumnSortType);
export const SetAccountLogs: ActionCreatorDefinition<SetAccountLogsPayload, any> = createAction<SetAccountLogsPayload>(SetAccountLogsType);
export const SetAccountLogsPending: ActionCreatorDefinition<SetAccountLogsPendingPayload, any> = createAction<SetAccountLogsPendingPayload>(SetAccountLogsPendingType);
