import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {OnLoggedOut} from "core/redux/auth/AuthReduxActions";
import {BOChatReduxState} from "core/redux/inbox/BOChatReduxState";
import {
    BOChatStart,
    BOChatStartPayload,
    BOChatStartType,
    BOChatEnd,
    BOChatEndType,
    BOChatSendMessageType,
    BOChatAddMessages,
    BOChatAddMessagesPayload,
    BOChatSetVisible,
    BOChatSetVisiblePayload, BOChatSetDisconnected, BOChatSetDisconnectedPayload
} from "core/redux/inbox/BOChatReduxActions";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import InboxEngine from "core/engine/InboxEngine";

const initialState = (): BOChatReduxState => {
    return {
        messages: []
    };
};

export default class BOChatReducer extends Reducer<BOChatReduxState> {

    private static _instance: BOChatReducer;

    public static instance(): BOChatReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "boChat";
    }

    private constructor() {
        super();
        const inboxEngine: InboxEngine = Platform.engine(ServiceType.Inbox);
        this._middlewareActions.set(BOChatStartType, inboxEngine.doBOChatStart);
        this._middlewareActions.set(BOChatEndType, inboxEngine.doBOChatEnd);
        this._middlewareActions.set(BOChatSendMessageType, inboxEngine.doBOChatSendMessage);
    }

    protected setup(builder: ReducerBuilder<BOChatReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: BOChatReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(BOChatStart, (state: BOChatReduxState, {payload}: Action<BOChatStartPayload>) => {
                return Object.assign({}, state, {
                    visible: true,
                    messages: []
                });
            })
            .handle(BOChatEnd, (state: BOChatReduxState, {payload}: Action<any>) => {
                return Object.assign({}, state, {
                    visible: false
                });
            })
            .handle(BOChatAddMessages, (state: BOChatReduxState, {payload}: Action<BOChatAddMessagesPayload>) => {
                return Object.assign({}, state, {
                    messages: [...state.messages, ...payload.messages]
                });
            })
            .handle(BOChatSetVisible, (state: BOChatReduxState, {payload}: Action<BOChatSetVisiblePayload>) => {
                return Object.assign({}, state, {
                    visible: payload.visible
                });
            })
            .handle(BOChatSetDisconnected, (state: BOChatReduxState, {payload}: Action<BOChatSetDisconnectedPayload>) => {
                return Object.assign({}, state, {
                    disconnected: payload.disconnected
                });
            });
    }
}
