export enum UserActivityType {

    ChatRequest = 10,
    EBookNotAllowed = 22,
    RetentionTutorialsNotAllowed = 23,
    AddDisabledTutorial = 24,
    TutorialsExtend = 25,
    OpenPositionByTradingInsider = 52,
    HelpRequested = 62,
    OpenPositionFromChart = 117,
    EditPositionFromChart = 118,
    ClosePositionFromChart = 119,
    CesConnected = 160,
    SignalsViewNotAllowed = 205,
}
