import {SymbolCategory} from "enum/SymbolCategory";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import Platform from "platform/Platform";
import {StoreState} from "core/redux/StoreState";
import {BIEventType} from "enum/BIEventType";
import {BILoginType} from "enum/BILoginType";
import DateTimeFormat from "core/format/DateTimeFormat";
import {AccountState} from "core/state/AccountState";
import {BISessionEndType} from "enum/BISessionEndType";
import {PortfolioType} from "enum/PortfolioType";
import {RouteType} from "core/router/Routes";
import {Route} from "router5";
import {QuoteStatus} from "platform/protocol/enum/QuoteStatus";
import {Deal} from "platform/protocol/trading/Deal";
import {TradeManagerTab} from "enum/TradeManagerTab";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import {Quote} from "platform/protocol/trading/Quote";
import {TSMap} from "typescript-map";
import {SymbolSubscriptionInfo} from "protocol/symbol/SymbolSubscriptionInfo";
import {DealForm} from "core/redux/deal/DealReduxState";
import {PositionDirection, PositionDirection as TradeDirection} from "platform/protocol/enum/PositionDirection";
import {TradeType} from "protocol/trade/TradeType";
import {OrderLimitRequest} from "protocol/trade/OrderLimitRequest";
import Utils from "platform/util/Utils";
import {NotificationTab} from "enum/NotificationTab";
import {MarginEventType} from "enum/MarginEventType";
import {BIDealOperationType} from "enum/BIDealOperationType";
import CardType from "enum/CardType";
import TradingModalType from "enum/TradingModalType";
import {Clarity} from "platform/clarity/Clarity";
import {SymbolAlertType} from "enum/SymbolAlertType";
import {SymbolAlert} from "protocol/alerts/SymbolAlert";
import {SignalSubPatternType} from "platform/protocol/enum/SignalSubPatternType";
import {ChangePercentAlertType} from "enum/ChangePercentAlertType";

interface BISymbolPanelInfo {

    Symbol?: string;
    SymbolType?: string;
    SymbolClass?: string;
    IsInTradingHours?: string;
    PanelTabName?: string;
    InWatchlist?: string;
    OpenedAfterAnotherPanel?: string;
}

export class BIUtil {

    private static _symbolPanelInfo: BISymbolPanelInfo = {};

    private constructor() {
    }

    public static SessionStart = (UserId: number, realAccount: boolean, loginType: BILoginType, device: string): void => {
        AccountState.instance().sessionStartTime = new Date().getTime();
        Clarity.Identify(UserId);
        Platform.bi().identify({
            UserId,
            realAccount,
            device: device,
            theme: Platform.reduxState<StoreState>().core.theme,
        });
        Platform.bi().track(BIEventType.SessionStart, {
            Type: loginType,
            DateTime: DateTimeFormat.formatDateTime(new Date())
        });
    }

    public static SessionEnd = (type: BISessionEndType): void => {
        Platform.bi().track(BIEventType.SessionEnd, {
            Type: type,
            TimeFromSessionStart: (new Date().getTime() - AccountState.instance().sessionStartTime) / 1000 / 60,
            CountOfOpenPositions: Platform.reduxState<StoreState>().trades.OpenPositions?.length
        });
    }

    public static PageName = (route: Route): string => {
        return this.pageName(route?.name);
    }

    public static pageName = (name: string): string => {
        if (name) {
            return (name.charAt(0).toUpperCase() + name.slice(1)).split(/(?=[A-Z])/).join(" ");
        }
        return "";
    }

    public static SymbolTabName = (category: string): string => {
        if (category === SymbolCategory.MyWatchlist) {
            return Translations.TextEn(TranslationKey.myWatchlist);
        }
        return category;
    }

    public static PortfolioTabName = (type: PortfolioType): string => {
        return Translations.TextEn(`myTrades.${type}`);
    }

    public static NotificationTabName = (tab: NotificationTab): string => {
        return Translations.TextEn(`notification.tab.${tab}`);
    }

    public static PageTabName = (route: Route): string => {
        return this.pageTabName(route?.name);
    }

    public static pageTabName = (name: string): string => {
        const {symbols, portfolio, alerts, signals} = Platform.reduxState<StoreState>();
        let TabName: string;
        if (name === RouteType.Symbols) {
            TabName = this.SymbolTabName(symbols.activeCategoryTab);
        } else if (name === RouteType.MyTrades) {
            TabName = this.PortfolioTabName(portfolio.activePortfolioTab);
        } else if (name === RouteType.Alerts) {
            TabName = this.SymbolTabName(alerts.activeCategoryTab);
        } else if (name === RouteType.Signals) {
            TabName = this.SymbolTabName(signals.activeCategoryTab);
        }
        return TabName;
    }

    public static AlertTypeName = (type: SymbolAlertType): string => {
        if (type) {
            switch (type) {
                case SymbolAlertType.Price:
                    return "Price";
                case SymbolAlertType.ChangePercent:
                    return "Change";
                case SymbolAlertType.Signal:
                    return "Signal";
            }
        }
        return null;
    }

    public static AlertTarget = (alert: SymbolAlert): string => {
        if (alert?.SymbolAlertType) {
            switch (alert.SymbolAlertType) {
                case SymbolAlertType.Price:
                    return alert.Direction === PositionDirection.Buy ? "Buy" : "Sell";
                case SymbolAlertType.ChangePercent:
                    return `${alert.ChangePercent}%`;
                case SymbolAlertType.Signal:
                    return SignalSubPatternType[alert.SignalSubPatternType];
            }
        }
        return null;
    }

    public static AlertInterval = (alert: SymbolAlert): string => {
        if (alert?.SymbolAlertType === SymbolAlertType.ChangePercent) {
            switch (alert.ChangePercentAlertType) {
                case ChangePercentAlertType.FromNow:
                    return "From now";
                case ChangePercentAlertType.Daily:
                    return "Daily";
                case ChangePercentAlertType.Hourly:
                    return "Hourly";
            }
        }
        return null;
    }

    public static AlertChannel = (alert: SymbolAlert): string => {
        if (alert?.NotifyByEmail && alert?.NotifyByPush) {
            return "Email and Push Notification";
        } else if (alert?.NotifyByEmail) {
            return "Email";
        } else if (alert?.NotifyByPush) {
            return "Push Notification";
        }
        return null;
    }

    public static FromMarginEvent(type: MarginEventType): BIEventType {
        if (Utils.isNotNull(type)) {
            switch (type) {
                case MarginEventType.MarginCall:
                    return BIEventType.MarginCall;
                case MarginEventType.Liquidation:
                    return BIEventType.Liquidation;
            }
        }
        return null;
    }

    public static ButtonNameCard = (type: CardType): string => {
        if (Utils.isNotNull(type)) {
            switch (type) {
                case CardType.Withdrawals:
                    return "Make a withdrawal";
                case CardType.TopUpLimits:
                    return "Top up limits";
                case CardType.CloseOutMethod:
                    return "Change MCO";
                case CardType.MyLeverage:
                    return "Change leverage";
                case CardType.AnnualStatement:
                    return "Annual statement";
                case CardType.EMT:
                    return "EMT";
            }
        }
        return null;
    }

    public static ButtonName = (key: TranslationKey): string => {
        if (Utils.isNotNull(key)) {
            switch(key) {
                case TranslationKey.settingsProfileDocuments:
                    return this.pageName(TradingModalType.Documents);
            }
        }
        return null;
    }

    public static SymbolPanelOpen = (
        tab: TradeManagerTab,
        route: Route,
        symbol: TradeSymbol,
        quote: Quote,
        subscribedSymbols: TSMap<number, SymbolSubscriptionInfo>,
        OpenPositions: Deal[],
        OpenedAfterAnotherPanel: boolean,
        AllowedSignals: boolean
    ): void => {
        this.symbolPanelOpen(
            tab,
            route?.name,
            symbol,
            quote,
            subscribedSymbols,
            OpenPositions,
            OpenedAfterAnotherPanel,
            AllowedSignals
        );
    }

    public static symbolPanelOpen = (
        tab: TradeManagerTab,
        route: string,
        symbol: TradeSymbol,
        quote: Quote,
        subscribedSymbols: TSMap<number, SymbolSubscriptionInfo>,
        OpenPositions: Deal[],
        OpenedAfterAnotherPanel: boolean,
        AllowedSignals: boolean,
    ): void => {
        this._symbolPanelInfo = {
            Symbol: symbol?.text,
            SymbolType: symbol?.category,
            SymbolClass: BIUtil.SymbolTabName(symbol?.category),
            IsInTradingHours: quote?.Status === QuoteStatus.Tradable ? "yes" : "no",
            PanelTabName: Translations.TextEn(`trade.manager.tab.${tab}`),
            InWatchlist: subscribedSymbols?.has(symbol?.SymbolId) ? "yes" : " no",
            OpenedAfterAnotherPanel: OpenedAfterAnotherPanel ? "yes" : " no",
        };
        Platform.bi().track(BIEventType.SymbolPanelOpened, {
            ...this._symbolPanelInfo,
            View: BIUtil.pageName(route),
            ViewTabName: BIUtil.pageTabName(route),
            CurrentlyOpenTradesForSymbol: OpenPositions.filter((deal: Deal) => deal.SymbolId === symbol.SymbolId)?.length,
            AllowedSignals
        });
    }

    public static SymbolPanelUpdate = (tab: TradeManagerTab, quote: Quote): void => {
        if (tab && quote) {
            this._symbolPanelInfo = {
                ...this._symbolPanelInfo,
                IsInTradingHours: quote?.Status === QuoteStatus.Tradable ? "yes" : "no",
                PanelTabName: Translations.TextEn(`trade.manager.tab.${tab}`),
            };
        }
    }

    public static SymbolPanelTrack = (data: any = {}): void => {
        Platform.bi().track(BIEventType.SymbolPanelInteraction, {
            ...this._symbolPanelInfo,
            ...data
        });
    }

    public static SymbolPanelClose = (
        tab: TradeManagerTab,
        route: Route,
        symbol: TradeSymbol,
        quote: Quote,
        subscribedSymbols: TSMap<number, SymbolSubscriptionInfo>
    ): void => {
        this.symbolPanelClose(tab, route?.name, symbol, quote, subscribedSymbols);
    }

    public static symbolPanelClose = (
        tab: TradeManagerTab,
        route: string,
        symbol: TradeSymbol,
        quote: Quote,
        subscribedSymbols: TSMap<number, SymbolSubscriptionInfo>
    ): void => {
        Platform.bi().track(BIEventType.SymbolPanelClosed, {
            Symbol: symbol?.text,
            SymbolType: symbol?.category,
            SymbolClass: BIUtil.SymbolTabName(symbol?.category),
            IsInTradingHours: quote?.Status === QuoteStatus.Tradable ? "yes" : "no",
            View: BIUtil.pageName(route),
            ViewTabName: BIUtil.pageTabName(route),
            PanelTabName: Translations.TextEn(`trade.manager.tab.${tab}`),
            InWatchlist: subscribedSymbols?.has(symbol?.SymbolId) ? "yes" : " no",
        });
    }

    public static SymbolPanelErrorTrack = (oldErrors: {[key: string]: any}, newErrors?: {[key: string]: any}): void => {
        if (!Utils.isObjectEmpty(newErrors) && (Utils.isObjectEmpty(oldErrors) || !Utils.compareObject(oldErrors, newErrors))) {
            setTimeout(() => {
                const {deal, trades} = Platform.reduxState<StoreState>();
                const form: DealForm = deal.form;
                if (form) {
                    const {PositionId, RateRequestType, StopLossChecked, StopLossValueRate, StopLossValueAmount, TakeProfitChecked, TakeProfitValueRate, TakeProfitValueAmount} = form;
                    const isMarketOrder: boolean = RateRequestType === TradeType.MarketOrder;
                    const dealsMap: {[key: number]: Deal} = isMarketOrder ? trades.OpenPositionsMap : trades.EntryOrdersMap;
                    const deal: Deal = dealsMap[PositionId];
                    this.DealTrack(BIEventType.TradeErrorUI, {
                        StopLossAmount: StopLossChecked && Utils.isNull(StopLossValueRate) ? StopLossValueAmount : null,
                        StopLossRate: StopLossChecked ? StopLossValueRate : null,
                        TakeProfitAmount: TakeProfitChecked && Utils.isNull(TakeProfitValueRate) ? TakeProfitValueAmount : null,
                        TakeProfitRate: TakeProfitChecked ? TakeProfitValueRate : null,
                    }, {
                        PositionID: PositionId,
                        Type: Utils.greaterThen0(PositionId) ? BIDealOperationType.Update : BIDealOperationType.Open,
                        OpenRate: deal?.Rate,
                        MarketRate: deal?.MarketRate,
                        PL: deal?.PL,
                        CreatedDate: DateTimeFormat.formatDateTime(deal?.Date),
                        ErrorFields: newErrors
                    });
                }
            }, 0);
        }
    }

    public static DealTrack = (eventType: BIEventType, request: OrderLimitRequest, data: any = {}): void => {
        const {deal, symbols, router} = Platform.reduxState<StoreState>();
        const form: DealForm = deal.form;
        if (form) {
            const {SymbolId, PositionDirection, Amount, RateRequestType} = form;
            const symbol: TradeSymbol = symbols.symbols.get(SymbolId);
            Platform.bi().track(eventType, {
                Symbol: symbol?.text,
                SymbolType: symbol?.category,
                SymbolClass: BIUtil.SymbolTabName(symbol?.category),
                View: this.PageName(router?.route),
                ViewTabName: this.PageTabName(router?.route),
                InWatchlist : symbols.subscribedSymbols.has(symbol?.SymbolId) ? "yes" : " no",
                Direction: PositionDirection === TradeDirection.Sell  ? "Sell" : "Buy",
                Amount,
                OrderType: RateRequestType === TradeType.MarketOrder ? "Position" : "Entry order",
                AddedLimits: (Utils.isNotNull(request.TakeProfitRate) || Utils.isNotNull(request.TakeProfitAmount)
                    || Utils.isNotNull(request.StopLossRate) || Utils.isNotNull(request.StopLossAmount)) ? "yes" : "no",
                ...request,
                ...data
            });
        }
    }

    public static DealCloseTrack = (eventType: BIEventType, deal: Deal, isMarketOrder: boolean, data: any = {}): void => {
        if (deal) {
            const {symbols, router} = Platform.reduxState<StoreState>();
            const symbol: TradeSymbol = symbols.symbols.get(deal.SymbolId);
            if (isMarketOrder) {
                data.PositionID = deal.Id;
            } else {
                data.EntryOrderID = deal.Id;
            }
            Platform.bi().track(eventType, {
                ...data,
                Symbol: symbol?.text,
                SymbolType: symbol?.category,
                SymbolClass: BIUtil.SymbolTabName(symbol?.category),
                View: this.PageName(router?.route),
                ViewTabName: this.PageTabName(router?.route),
                InWatchlist : symbols.subscribedSymbols.has(symbol?.SymbolId) ? "yes" : " no",
                Direction: deal.PositionDirection === TradeDirection.Sell  ? "Sell" : "Buy",
                Amount: deal.Amount,
                OrderType: isMarketOrder ? "Position" : "Entry order",
                OpenRate: deal.Rate,
                MarketRate: deal.MarketRate,
                PL: deal.PL,
                CreatedDate: DateTimeFormat.formatDateTime(deal.Date),
                StopLossAmount: deal.StopLossAmount,
                StopLossRate: deal.StopLossRate,
                TakeProfitAmount: deal.TakeProfitAmount,
                TakeProfitRate: deal.TakeProfitRate,
                AddedLimits: (Utils.isNotNull(deal.TakeProfitRate) || Utils.isNotNull(deal.TakeProfitAmount)
                    || Utils.isNotNull(deal.StopLossRate) || Utils.isNotNull(deal.StopLossAmount)) ? "yes" : "no",
            });
        }
    }

    public static ChartInteraction = (data: any = {}): void => {
        const {router} = Platform.reduxState<StoreState>();
        Platform.bi().track(BIEventType.ChartInteraction, {
            ...data,
            View: this.PageName(router?.route),
            TabName: this.PageTabName(router?.route),
        });
    }
}
