import {LangCode} from "platform/enum/LangCode";

interface AppCuesIdentifyProps {

    userId: number;
    langCode: LangCode;
}

interface IAppCues {

    setIdentify(value: AppCuesIdentifyProps): void;

    identify(): void;

    show(flowId: string): void;
}

class AppCuesManager {

    private static _appCues: IAppCues;

    private constructor() {}

    public static set AppCues(value: IAppCues) {
        this._appCues = value;
    }

    public static Identify(): void {
        this._appCues?.identify();
    }

    public static SetIdentify(value: AppCuesIdentifyProps): void {
        this._appCues?.setIdentify(value);
    }

    public static Show(flowId: string): void {
        this._appCues?.identify();
        this._appCues?.show(flowId);
    }
}

export {
    AppCuesIdentifyProps,
    IAppCues,
    AppCuesManager
};
