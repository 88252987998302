import {ActionCreatorDefinition, createAction} from "redux-ts";
import {AccountInfo} from "core/redux/account/AccountReduxState";
import PromotionOverview from "enum/PromotionOverview";
import {CreditPromo} from "protocol/account/GetAccountStateResponse";
import {CreditPromoStatus} from "protocol/promo/CreditPromoStatus";
import Parameter from "platform/util/Parameter";
import {UserActivityType} from "enum/UserActivityType";
import {RouteType} from "core/router/Routes";
import {BIEventReferrer} from "enum/BIEventReferrer";
import {StringPayload} from "core/redux/StoreActions";
import {MobilePlatformType} from "protocol/auth/LoginRequest";

export const SetAccountInfoType: string = "SetAccountInfo";
export const SetPromotionsChangedType: string = "SetPromotionsChanged";
export const SetPromotionOverviewType: string = "SetPromotionOverview";
export const TogglePromotionsDetailsType: string = "TogglePromotionsDetails";
export const OnProcessCreateTradeButtonType: string = "OnProcessCreateTradeButton";
export const OnProcessTopUpButtonType: string = "OnProcessTopUpButton";
export const DoAcceptCreditPromoType: string = "DoAcceptCreditPromo";
export const DoRegisterUserActivityType: string = "DoRegisterUserActivity";
export const SendUserFeedbackType: string = "SendUserFeedback";
export const CallMeDoRequestType: string = "CallMeDoRequest";
export const SetShowReviewModalType: string = "SetShowReviewModal";
export const DoRateAppType: string = "DoRateApp";
export const DoActivateBonusCodeType: string = "DoActivateBonusCode";

export interface SetAccountInfoPayload {

    accountInfo: AccountInfo;
}

export interface SetPromotionsChangedPayload {

    changed: boolean;
}

export interface SetPromotionOverviewPayload {

    promotionOverview: PromotionOverview;
}

export interface OnProcessCreateTradeButtonPayload {

    action?: (routeType: RouteType) => void;
}

export interface OnProcessTopUpButtonPayload {

    parameters?: Parameter[];
    referrer?: BIEventReferrer;
    action?: (routeType: RouteType, parameters?: Parameter[]) => void;
}

export interface DoAcceptCreditPromoPayload {

    promo: CreditPromo;
    status: CreditPromoStatus;
}

export interface DoRegisterUserActivityPayload {

    userActivity: UserActivityType | number;
    comment?: string;
}

export interface SendUserFeedbackPayload {

    rate: number;
    comment: string;
    view: string;
    fromDpk: boolean;
    onFail?: () => void;
}

export interface SetShowReviewModalPayload {

    showModal: boolean;
}

export interface DoRateAppPayload {

    rate?: number;
    comment?: string;
    notNow: boolean;
    mobilePlatformType: MobilePlatformType;
}

export const SetAccountInfo: ActionCreatorDefinition<SetAccountInfoPayload, any> = createAction<SetAccountInfoPayload>(SetAccountInfoType);
export const SetPromotionsChanged: ActionCreatorDefinition<SetPromotionsChangedPayload, any> = createAction<SetPromotionsChangedPayload>(SetPromotionsChangedType);
export const SetPromotionOverview: ActionCreatorDefinition<SetPromotionOverviewPayload, any> = createAction<SetPromotionOverviewPayload>(SetPromotionOverviewType);
export const TogglePromotionsDetails: ActionCreatorDefinition<any, any> = createAction<any>(TogglePromotionsDetailsType);
export const OnProcessCreateTradeButton: ActionCreatorDefinition<OnProcessCreateTradeButtonPayload, any> = createAction<OnProcessCreateTradeButtonPayload>(OnProcessCreateTradeButtonType);
export const OnProcessTopUpButton: ActionCreatorDefinition<OnProcessTopUpButtonPayload, any> = createAction<OnProcessTopUpButtonPayload>(OnProcessTopUpButtonType);
export const DoAcceptCreditPromo: ActionCreatorDefinition<DoAcceptCreditPromoPayload, any> = createAction<DoAcceptCreditPromoPayload>(DoAcceptCreditPromoType);
export const DoRegisterUserActivity: ActionCreatorDefinition<DoRegisterUserActivityPayload, any> = createAction<DoRegisterUserActivityPayload>(DoRegisterUserActivityType);
export const SendUserFeedback: ActionCreatorDefinition<SendUserFeedbackPayload, any> = createAction<SendUserFeedbackPayload>(SendUserFeedbackType);
export const CallMeDoRequest: ActionCreatorDefinition<any, any> = createAction<any>(CallMeDoRequestType);
export const SetShowReviewModal: ActionCreatorDefinition<SetShowReviewModalPayload, any> = createAction<SetShowReviewModalPayload>(SetShowReviewModalType);
export const DoRateApp: ActionCreatorDefinition<DoRateAppPayload, any> = createAction<DoRateAppPayload>(DoRateAppType);
export const DoActivateBonusCode: ActionCreatorDefinition<StringPayload, any> = createAction<StringPayload>(DoActivateBonusCodeType);
