import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";
import {OrientationType} from "platform/enum/OrientationType";

export class IntegrationOrientationChange implements IntegrationMessage {

    public classId: number = IntegrationClassId.ORIENTATION_CHANGE;
    public orientation: OrientationType;

    public constructor(orientation: OrientationType) {
        this.orientation = orientation;
    }
}
