import {ActionCreatorDefinition, createAction} from "redux-ts";
import {TSMap} from "typescript-map";
import {TableSortState} from "core/redux/common/TableSortState";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import {SymbolAlert} from "protocol/alerts/SymbolAlert";
import {AlertsCounter} from "core/redux/alerts/AlertsReduxState";

export const FetchCategoryAlertsType: string = "FetchCategoryAlerts";
export const FetchSymbolAlertsType: string = "FetchSymbolAlerts";
export const SetCategoryAlertsType: string = "SetCategoryAlerts";
export const SetSymbolAlertsType: string = "SetSymbolAlerts";
export const SetAlertsTablesLSType: string = "SetAlertsTablesLS";
export const SetAlertsTabType: string = "SetAlertsTab";
export const SetAlertsTradeColumnType: string = "SetAlertsTradeColumn";
export const ToggleAlertsColumnSortType: string = "ToggleAlertsColumnSort";
export const SetSortedAlertsType: string = "SetSortedAlerts";
export const SelectAlertType: string = "SelectAlert";
export const SetNewAlertType: string = "SetNewAlert";
export const AddAlertType: string = "AddAlert";
export const UpdateAlertType: string = "UpdateAlert";
export const ConfirmRemoveAlertType: string = "ConfirmRemoveAlert";
export const RemoveAlertType: string = "RemoveAlert";
export const SetAlertToEditType: string = "SetAlertToEdit";
export const SetAlertsLoadingType: string = "SetAlertsLoading";
export const SetAlertsCategoryLoadingType: string = "SetAlertsCategoryLoading";

export interface FetchCategoryAlertsPayload {

    category: string;
}

export interface FetchSymbolAlertsPayload {

    symbolId: number;
}

export interface SetCategoryAlertsPayload {

    category: string;
    alerts: SymbolAlert[];
    counters?: TSMap<string, AlertsCounter>;
}

export interface SetAlertsTablesLSPayload {

    tables: TSMap<string, TableSortState>;
}

export interface SetAlertsTabPayload {

    category: string;
}

export interface SetAlertsTradeColumnPayload {

    tradeColumn: TradeColumn;
    category: string;
}

export interface ToggleAlertsColumnSortPayload {

    tradeColumn: TradeColumn;
    category: string;
}

export interface SetSortedAlertsPayload {

    alerts: SymbolAlert[];
    category: string;
    tradeColumn: TradeColumn;
    sortDirection: SortDirection;
}

export interface SelectAlertPayload {

    alert: SymbolAlert;
    category?: string;
}

export interface SetSymbolAlertsPayload {

    alerts: SymbolAlert[];
}

export interface SetNewAlertPayload {

    alert: SymbolAlert;
    callback?: () => void;
}

export interface AddAlertPayload {

    alert: SymbolAlert;
    callback?: () => void;
}

export interface UpdateAlertPayload {

    alert: SymbolAlert;
}

export interface ConfirmRemoveAlertPayload {

    alert: SymbolAlert;
}

export interface RemoveAlertPayload {

    alert: SymbolAlert;
}

export interface SetAlertToEditPayload {

    alert: Partial<SymbolAlert>;
}

export interface SetAlertsLoadingPayload {

    alertsLoaded: boolean;
}

export const FetchCategoryAlerts: ActionCreatorDefinition<FetchCategoryAlertsPayload, any> = createAction<FetchCategoryAlertsPayload>(FetchCategoryAlertsType)
export const FetchSymbolAlerts: ActionCreatorDefinition<FetchSymbolAlertsPayload, any> = createAction<FetchSymbolAlertsPayload>(FetchSymbolAlertsType);
export const SetCategoryAlerts: ActionCreatorDefinition<SetCategoryAlertsPayload, any> = createAction<SetCategoryAlertsPayload>(SetCategoryAlertsType);
export const SetAlertsTablesLS: ActionCreatorDefinition<SetAlertsTablesLSPayload, any> = createAction<SetAlertsTablesLSPayload>(SetAlertsTablesLSType);
export const SetAlertsTab: ActionCreatorDefinition<SetAlertsTabPayload, any> = createAction<SetAlertsTabPayload>(SetAlertsTabType);
export const SetAlertsTradeColumn: ActionCreatorDefinition<SetAlertsTradeColumnPayload, any> = createAction<SetAlertsTradeColumnPayload>(SetAlertsTradeColumnType);
export const ToggleAlertsColumnSort: ActionCreatorDefinition<ToggleAlertsColumnSortPayload, any> = createAction<ToggleAlertsColumnSortPayload>(ToggleAlertsColumnSortType);
export const SetSortedAlerts: ActionCreatorDefinition<SetSortedAlertsPayload, any> = createAction<SetSortedAlertsPayload>(SetSortedAlertsType);
export const SelectAlert: ActionCreatorDefinition<SelectAlertPayload, any> = createAction<SelectAlertPayload>(SelectAlertType);
export const SetSymbolAlerts: ActionCreatorDefinition<SetSymbolAlertsPayload, any> = createAction<SetSymbolAlertsPayload>(SetSymbolAlertsType);
export const SetNewAlert: ActionCreatorDefinition<SetNewAlertPayload, any> = createAction<SetNewAlertPayload>(SetNewAlertType);
export const AddAlert: ActionCreatorDefinition<AddAlertPayload, any> = createAction<AddAlertPayload>(AddAlertType);
export const UpdateAlert: ActionCreatorDefinition<UpdateAlertPayload, any> = createAction<UpdateAlertPayload>(UpdateAlertType);
export const ConfirmRemoveAlert: ActionCreatorDefinition<ConfirmRemoveAlertPayload, any> = createAction<ConfirmRemoveAlertPayload>(ConfirmRemoveAlertType);
export const RemoveAlert: ActionCreatorDefinition<RemoveAlertPayload, any> = createAction<RemoveAlertPayload>(RemoveAlertType);
export const SetAlertToEdit: ActionCreatorDefinition<SetAlertToEditPayload, any> = createAction<SetAlertToEditPayload>(SetAlertToEditType);
export const SetAlertsLoading: ActionCreatorDefinition<SetAlertsLoadingPayload, any> = createAction<SetAlertsLoadingPayload>(SetAlertsLoadingType);
export const SetAlertsCategoryLoading: ActionCreatorDefinition<SetAlertsLoadingPayload, any> = createAction<SetAlertsLoadingPayload>(SetAlertsCategoryLoadingType);
