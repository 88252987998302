import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {OnLoggedOut, SetAuthStatusType} from "core/redux/auth/AuthReduxActions";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {NewsReduxState} from "core/redux/news/NewsReduxState";
import NewsEngine from "core/engine/NewsEngine";
import {
    DoFetchNewsType,
    SetNews,
    SetNewsLoading, SetNewsLoadingPayload,
    SetNewsPayload,
    SetSelectedNewsId,
    SetSelectedNewsIdPayload
} from "core/redux/news/NewsReduxActions";

const initialState = (): NewsReduxState => {
    return {
        news: []
    };
};

export default class NewsReducer extends Reducer<NewsReduxState> {

    private static _instance: NewsReducer;

    public static instance(): NewsReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const newsEngine: NewsEngine = Platform.engine(ServiceType.News);
        this._middlewareActions.set(SetAuthStatusType, newsEngine.doSetAuthStatus);
        this._middlewareActions.set(DoFetchNewsType, newsEngine.doFetchNews);
    }

    public get name(): string {
        return "news";
    }

    protected setup(builder: ReducerBuilder<NewsReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: NewsReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(SetNews, (state: NewsReduxState, {payload}: Action<SetNewsPayload>) => {
                this._logger.debug("Set news");
                return Object.assign({}, state, {
                    news: payload.news,
                });
            })
            .handle(SetSelectedNewsId, (state: NewsReduxState, {payload}: Action<SetSelectedNewsIdPayload>) => {
                this._logger.debug(`Set selected news id: ${payload.id}`);
                return Object.assign({}, state, {
                    selectedNewsId: payload.id
                });
            })
            .handle(SetNewsLoading, (state: NewsReduxState, {payload}: Action<SetNewsLoadingPayload>) => {
                this._logger.debug(`Set news loading: ${payload.loading}`);
                return Object.assign({}, state, {
                    loading: payload.loading,
                });
            });
    }
}
