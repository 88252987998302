import {ActionCreatorDefinition, createAction} from "redux-ts";
import {Deal} from "platform/protocol/trading/Deal";
import {PortfolioType} from "enum/PortfolioType";
import {IdPayload} from "core/redux/StoreActions";
import {PaginationDealsTable} from "core/redux/trades/ClosedTradesReduxState";

export const SetClosedTradesType: string =  "SetClosedTrades";
export const SetClosedTradeDetailType: string =  "SetClosedTradeDetail";
export const LoadMoreClosedTradesType: string = "LoadMoreClosedTrades";
export const FetchClosedTradeType: string =  "FetchClosedTrade";
export const FetchClosedPositionsType: string = "FetchClosedPositions";
export const SetOrdersHistoryType: string = "SetOrdersHistory";
export const SetOrderDetailType: string = "SetOrderDetail";
export const LoadMoreHistoryOrdersType: string = "LoadMoreHistoryOrders";
export const FetchOrderByIdType: string =  "FetchOrderById";

export interface SetClosedTradeDetailPayload {

    trade: Deal;
}

export interface FetchClosedPositionsPayload {

    portfolioType: PortfolioType;
}

export interface PaginationDealsTablePayload {

    table: PaginationDealsTable;
}

export interface SetOrderDetailPayload {

    order: Deal;
}

export const SetClosedTrades: ActionCreatorDefinition<PaginationDealsTablePayload, any> = createAction<PaginationDealsTablePayload>(SetClosedTradesType);
export const SetClosedTradeDetail: ActionCreatorDefinition<SetClosedTradeDetailPayload, any> = createAction<SetClosedTradeDetailPayload>(SetClosedTradeDetailType);
export const LoadMoreClosedTrades: ActionCreatorDefinition<any, any> = createAction<any>(LoadMoreClosedTradesType);
export const FetchClosedTrade: ActionCreatorDefinition<IdPayload, any> = createAction<IdPayload>(FetchClosedTradeType);
export const FetchClosedPositions: ActionCreatorDefinition<FetchClosedPositionsPayload, any> = createAction<FetchClosedPositionsPayload>(FetchClosedPositionsType);
export const SetOrdersHistory: ActionCreatorDefinition<PaginationDealsTablePayload, any> = createAction<PaginationDealsTablePayload>(SetOrdersHistoryType);
export const SetOrderDetail: ActionCreatorDefinition<SetOrderDetailPayload, any> = createAction<SetOrderDetailPayload>(SetOrderDetailType);
export const LoadMoreHistoryOrders: ActionCreatorDefinition<any, any> = createAction<any>(LoadMoreHistoryOrdersType);
export const FetchOrderById: ActionCreatorDefinition<IdPayload, any> = createAction<IdPayload>(FetchOrderByIdType);
