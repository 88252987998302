import {Engine} from "platform/engine/Engine";
import {
    BOChatAddMessages,
    BOChatSendMessagePayload,
    BOChatSetDisconnected,
    BOChatStartPayload
} from "core/redux/inbox/BOChatReduxActions";
import Platform from "platform/Platform";
import {ServerType} from "platform/enum/ServerType";
import Utils from "platform/util/Utils";
import {Http, HttpReject} from "platform/network/http/Http";
import WebUtil from "platform/util/WebUtil";
import {BOChatEntriesEvent, BOChatEntry} from "protocol/chat/BOChatEntriesEvent";
import {BOChatEntriesResponse} from "protocol/chat/BOChatEntriesResponse";
import {BOChatMessage} from "core/redux/inbox/BOChatReduxState";
import {StoreState} from "core/redux/StoreState";
import {BOChatInfoResponse} from "protocol/chat/BOChatInfoResponse";
import {RemoveBanner, SetBanner, ToggleAccountDetailsSwiper} from "core/redux/app/AppReduxActions";
import {BannerType} from "enum/BannerType";
import {InboxMessagePayload} from "core/redux/inbox/InboxReduxActions";
import {DpkType} from "enum/DpkType";
import {Dpk} from "platform/dpk/Dpk";
import {ExecuteDpk} from "platform/redux/dpk/DpkActions";
import Parameter from "platform/util/Parameter";
import {DpkHandler} from "core/dpk/DpkHandler";

export default class InboxEngine extends Engine {

    private static _instance: InboxEngine;
    private _conversationEntries: BOChatEntry[];
    private _ticks: number[];
    private _token: string;
    private _tokenChat: string;
    private _handler: any;
    private _disconnected: boolean;

    public static instance(): InboxEngine {
        return this._instance || (this._instance = new this());
    }

    public doExecuteInboxCTA = async ({message}: InboxMessagePayload) => {
        if (message.CTAUrl) {
            const index: number = message.CTAUrl.indexOf("dpk=");
            const dpk: string = index > -1 ? message.CTAUrl.substring(index + 4) : message.CTAUrl;
            const dpkType: DpkType = Dpk.dpkType(dpk) as DpkType;
            if (dpkType) {
                Platform.dispatch(ExecuteDpk({
                    dpk,
                    params: [Parameter.Of("dpk", dpk)],
                    action: DpkHandler.GetAction(dpkType)
                }));
            }
        }
    }

    public doBOChatStart = async ({token}: BOChatStartPayload) => {
        if (token) {
            this._logger.debug("BO chat start with token: " + token);
            this._token = token;
            this._conversationEntries = [];
            this._ticks = [];
            this._disconnected = false;
            Platform.dispatch(BOChatSetDisconnected({disconnected: false}));
            const url: string = `${this.serverUrl()}ChatServer/ChatService.svc/rest/Conversation/${this._token}`;
            const answer: [HttpReject, BOChatInfoResponse] = await Utils.to(Http.getWithCredentials(url));
            this._tokenChat = answer[1]?.ChatConversation?.Token;
            await this.getBOChatEntries(0);
            if (Utils.isNull(this._handler)) {
                this._handler = setInterval(async () => {
                    const entries: BOChatEntry[] = this._conversationEntries || [];
                    const lastEntry: BOChatEntry = entries[entries.length - 1];
                    await this.getBOChatEntries(lastEntry ? lastEntry.Ticks : 0);
                }, 500);
            }
        } else {
            this._logger.debug("BO chat failed start, token empty");
        }
    }

    public doBOChatEnd = async () => {
        if (Utils.isNotNull(this._handler)) {
            this._logger.debug("BO chat end");
            clearInterval(this._handler);
            this._handler = null;
        }
        await this.doBOChatLogoff();
    }

    private doBOChatLogoff = async () => {
        if (this._tokenChat) {
            this._logger.debug("Do BO chat logoff");
            const url: string = `${this.serverUrl()}ChatServer/ChatService.svc/rest/Conversation/End/${this._tokenChat}`;
            const answer: [HttpReject, any] = await Utils.to(Http.postWithCredentials(url));
            if (answer[0]) {
                this._logger.debug("Failed end BO chat conversation");
            }
            this._tokenChat = null;
        }
    }

    public doBOChatSendMessage = async ({message}: BOChatSendMessagePayload) => {
        if (this._token) {
            const url: string = `${this.serverUrl()}ChatServer/ChatService.svc/rest/Conversation/Entries/${this._token}`;
            const answer: [HttpReject, BOChatEntriesResponse] = await Utils.to(Http.postWithCredentials(url, message));
        } else {
            this._logger.debug("BO chat can't send message, token empty");
        }
    }

    private getBOChatEntries = async (ticks: number) => {
        const url: string = WebUtil.addGetParams(`${this.serverUrl()}ChatServer/ChatService.svc/rest/Conversation/Entries/${this._token}/${ticks}`, {
            "_": new Date().getTime().toString()
        });
        const answer: [HttpReject, BOChatEntriesEvent] = await Utils.to(Http.getWithCredentials(url));
        if (answer[0]) {
            await this.doBOChatEnd();
            const date: Date = new Date();
            Platform.dispatch(BOChatSetDisconnected({disconnected: true}));
            if (!this._disconnected) {
                Platform.dispatch(BOChatAddMessages({
                    messages: [
                        {
                            date,
                            time: date.getTime(),
                            disconnected: true
                        }
                    ]
                }));
            }
            this._disconnected = true;
            const {app} = Platform.reduxState<StoreState>();
            if (app.banner?.type === BannerType.BOChatReopen) {
                Platform.dispatch(RemoveBanner({
                    banner: app.banner,
                    block: false
                }));
            }
        } else {
            try {
                this._conversationEntries = answer[1]?.ConversationEntries;
                if (Utils.isArrayNotEmpty(answer[1]?.ConversationEntries)) {
                    const newMessages: BOChatMessage[] = [];
                    const {messages, visible} = Platform.reduxState<StoreState>().boChat;
                    let lastMessage: BOChatMessage = messages[messages.length - 1];
                    answer[1].ConversationEntries.forEach((entry: BOChatEntry) => {
                        if (this._ticks.indexOf(entry.Ticks) < 0) {
                            this._ticks.push(entry.Ticks);
                            const time: number = parseInt(entry.DateTime?.substring(6, entry.DateTime?.length - 7), 10);
                            const date: Date = new Date(time);
                            const message: BOChatMessage = {
                                entry,
                                date,
                                time: date.getTime(),
                                isNewDay: Utils.isNull(lastMessage) || date.getDay() !== lastMessage.date.getDay() || date.getMonth() !== lastMessage.date.getMonth()
                            };
                            lastMessage = message;
                            newMessages.push(message);
                        }
                    });
                    if (Utils.isArrayNotEmpty(newMessages)) {
                        Platform.dispatch(BOChatAddMessages({messages: newMessages}));
                        if (!visible) {
                            Platform.dispatch(ToggleAccountDetailsSwiper({value: false}));
                            Platform.dispatch(SetBanner({
                                banner: {
                                    type: BannerType.BOChatReopen
                                }
                            }));
                        }
                    }
                }
            } catch (e) {
                this._logger.debug("BO chat failed parse messages");
            }
        }
    }

    private serverUrl = (): string => {
        return Platform.config().servers[ServerType.Chat];
    }
}
