import {Engine} from "platform/engine/Engine";
import {DoLogout, SetAuthStatus, SetAuthStatusPayload} from "core/redux/auth/AuthReduxActions";
import Platform from "platform/Platform";
import {StoreState} from "core/redux/StoreState";
import Utils from "platform/util/Utils";
import {GetAccountStateRequest} from "protocol/account/GetAccountStateRequest";
import {HttpReject} from "platform/network/http/Http";
import {BadRequestResponse} from "protocol/BadRequestResponse";
import {
    ChatNotification,
    CreditPromo,
    GetAccountStateResponse,
    MarginEvent,
    RebatePlanData,
    VaultInfo
} from "protocol/account/GetAccountStateResponse";
import {GetAccountStateAndRangesResponse} from "protocol/account/GetAccountStateAndRangesResponse";
import {
    DoAcceptCreditPromoPayload, DoRateAppPayload,
    DoRegisterUserActivityPayload,
    OnProcessCreateTradeButtonPayload,
    OnProcessTopUpButton,
    OnProcessTopUpButtonPayload,
    SendUserFeedbackPayload,
    SetAccountInfo,
    SetPromotionsChanged
} from "core/redux/account/AccountReduxActions";
import {XhrUtil} from "core/util/XhrUtil";
import AuthEngine from "core/engine/AuthEngine";
import {ServiceType} from "enum/ServiceType";
import {SetPositionsOrders} from "core/redux/trades/TradesReduxActions";
import {SetQuotes} from "core/redux/quotes/QuotesReduxActions";
import {AccountState} from "core/state/AccountState";
import {TSMap} from "typescript-map";
import {Quote} from "platform/protocol/trading/Quote";
import ChartEngine from "core/engine/ChartEngine";
import {ChartState} from "core/state/ChartState";
import {TradeUtil} from "core/util/TradeUtil";
import {GoingOpenDeal, SetDealRanges} from "core/redux/deal/DealReduxActions";
import {
    DoCancelWithdrawalPayload,
    DoWithdrawalPayload,
    SetPendingWithdrawal
} from "core/redux/account/WithdrawalReduxActions";
import {GetPendingWithdrawalRequest} from "protocol/withdrawal/GetPendingWithdrawalRequest";
import {ServerType} from "platform/enum/ServerType";
import {GetPendingWithdrawalResponse} from "protocol/withdrawal/GetPendingWithdrawalResponse";
import {CreateWithdrawalRequest} from "protocol/withdrawal/CreateWithdrawalRequest";
import {HideLoader, SetLoader} from "platform/redux/core/CoreActions";
import {LoaderType} from "platform/enum/LoaderType";
import {HidePopupPayload, ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupAction, PopupActionType, PopupIconType, PopupType} from "platform/redux/popups/PopupsReduxState";
import {LoginAccountInfo} from "protocol/auth/LoginAccountInfo";
import {AuthState} from "core/state/AuthState";
import {CancelWithdrawalRequest} from "protocol/withdrawal/CancelWithdrawalRequest";
import {WithdrawalPending} from "core/redux/account/WithdrawalReduxState";
import {GetCurrentChartQuotesRequest} from "platform/protocol/trading/chart/GetCurrentChartQuotesRequest";
import {RouteType} from "core/router/Routes";
import {BannerPayload, DoGetURL, RemoveBanner, SetBanner, SetTradingModal, ToggleAccountDetailsSwiper} from "core/redux/app/AppReduxActions";
import TradingModalType from "enum/TradingModalType";
import {BOChatSetVisible, BOChatStart} from "core/redux/inbox/BOChatReduxActions";
import Parameter from "platform/util/Parameter";
import {TranslationKey} from "enum/TranslationKey";
import {TranslationParam} from "enum/TranslationParam";
import {BannerType} from "enum/BannerType";
import {SymbolsState} from "core/state/SymbolsState";
import {QuotesState} from "core/state/QuotesState";
import {PopupActionType as PopupNotificationActionType, PopupNotification} from "protocol/account/PopupNotification";
import {PositionDirection} from "platform/protocol/enum/PositionDirection";
import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import {AccountOption} from "protocol/account/AccountOption";
import {PromotionUtil} from "core/util/PromotionUtil";
import {PositionPromotion} from "platform/protocol/trading/PositionPromotion";
import {AccountReduxState} from "core/redux/account/AccountReduxState";
import {DepositButtonActionType} from "protocol/sso/DepositButtonActionType";
import {CreditPromoAcceptRequest} from "protocol/promo/CreditPromoAcceptRequest";
import {CreditPromoStatus} from "protocol/promo/CreditPromoStatus";
import {Currency} from "platform/enum/Currency";
import {NumberFormat} from "core/format/NumberFormat";
import {ExecuteDpk} from "platform/redux/dpk/DpkActions";
import {Configuration} from "core/configuration/Configuration";
import SessionInactivity from "core/util/SessionInactivity";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import Translations from "platform/translation/Translations";
import {RegisterUserActivityRequest} from "protocol/account/RegisterUserActivityRequest";
import {Win} from "platform/integration/win/Win";
import {UrlType} from "platform/enum/UrlType";
import WebUtil from "platform/util/WebUtil";
import {BrandType} from "platform/enum/BrandType";
import {IsTradingApprovalNeededResponse} from "protocol/account/IsTradingApprovalNeededResponse";
import {DealingRoomAction, DealingRoomActionTpe} from "protocol/account/DealsApprovalOkRequest";
import {UserFeedbackRequest} from "protocol/account/UserFeedbackRequest";
import {UserFeedbackResponse} from "protocol/account/UserFeedbackResponse";
import {UserActivityType} from "enum/UserActivityType";
import {RegisterUserActivityResponse} from "protocol/account/RegisterUserActivityResponse";
import {SetCallMeSubmitted} from "core/redux/settings/SettingsReduxActions";
import {EnvType} from "platform/enum/EnvType";
import {DpkType} from "enum/DpkType";
import {Dpk} from "platform/dpk/Dpk";
import {InboxScheduler} from "core/inbox/InboxScheduler";
import {DpkHandler} from "core/dpk/DpkHandler";
import {ReadyState} from "core/state/ReadyState";
import {BIEventType} from "enum/BIEventType";
import {BIUtil} from "core/util/BIUtil";
import {BIDepositDecisionType} from "enum/BIDepositDecisionType";
import {UrlTarget} from "platform/enum/UrlTarget";
import {DoRateAppRequest} from "protocol/account/DoRateAppRequest";
import {DoRateAppResponse} from "protocol/account/DoRateAppResponse";
import {ActivateBonusCodeRequest} from "protocol/promo/ActivateBonusCodeRequest";
import {StringPayload} from "core/redux/StoreActions";
import {AppState} from "core/state/AppState";
import {StorageKey} from "enum/StorageKey";
import {Preference} from "core/util/Preference";
import { LoginAccountType } from "protocol/auth/LoginAccountType";
import {ActionFromData} from "protocol/account/ActionFromData";

export default class AccountEngine extends Engine {

    private static _instance: AccountEngine;
    private _accountInterval: any;
    private _promotionsInterval: any;
    private _isFirstAccountState: boolean = true;
    // Last handled events
    private _lastPopupNotificationId: number;
    private _lastInAppPopupNotificationId: number;
    private _lastMarginEventId: number;
    private _lastCreditPromoId: number;
    private _lastChatNotificationId: string;
    private _lastPopupNotification: PopupNotification;
    private _lastInAppPopupNotification: PopupNotification;
    private _lastMarginEvent: MarginEvent;
    private _lastCreditPromo: CreditPromo;
    private _lastChatNotification: ChatNotification;

    public static instance(): AccountEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        this._isFirstAccountState = true;
    }

    public doSetAuthStatus = (payload: SetAuthStatusPayload): void => {
        const {connected, authenticated} = Platform.reduxState<StoreState>().auth;
        if (connected) {
            if (!authenticated && payload.authStatus.authenticated && Utils.isNull(this._accountInterval)) {
                this.doGetAccountInfo();
                this.doFetchPendingWithdrawal().catch(() => {
                });
                this.doFetchPendingTradingApproval().catch(() => {
                });
                const interval: number = Platform.config<Configuration>().getAccountStateInterval;
                this._logger.debug("Start get account state tracker. Interval: ", interval);
                this._accountInterval = setInterval(this.doGetAccountInfo, interval);
            }
        }
    }

    public onAccountChanged = (): void => {
        this._isFirstAccountState = true;
        this.doFetchPendingWithdrawal().catch(() => {
        });
    }

    public onLoggedOut = () => {
        this._isFirstAccountState = true;
        if (Utils.isNotNull(this._accountInterval)) {
            this._logger.debug("On logged out. Stop get account state tracker.");
            clearInterval(this._accountInterval);
            clearInterval(this._promotionsInterval);
            this._accountInterval = null;
            this._promotionsInterval = null;
        }
    }

    private doGetAccountInfo = (): void => {
        if (!Platform.reduxState<StoreState>().auth.connected) {
            return;
        }
        const symbolsState: SymbolsState = Platform.state(ServiceType.Symbols);
        const chartState: ChartState = Platform.state(ServiceType.Chart);
        const chartSubscriptions: GetCurrentChartQuotesRequest[] = chartState.getSubscriptions();
        const actionFormData: ActionFromData = TradeUtil.mapActionFormData();
        const request: GetAccountStateRequest = {
            actionFormData,
            lastHandledChatNotification: this._lastChatNotificationId,
            lastHandledCreditPromo: this._lastCreditPromoId,
            lastHandledMarginEvent: this._lastMarginEventId,
            lastHandledPopupNotification: this._lastPopupNotificationId,
            lastHandledInAppPopupNotification: this._lastInAppPopupNotificationId,
            lastSelectedSymbolId: null,
            GetCurrentChartQuotesRequests: Utils.isArrayNotEmpty(chartSubscriptions) ? chartSubscriptions : null,
            AdditionalSymbolsIds: [...symbolsState.additionalSymbolsIds, ...chartSubscriptions.map((cs: GetCurrentChartQuotesRequest) => cs.SymbolId)],
            LastSelectedSymbolsIds: [...symbolsState.symbolsGridIds, ...symbolsState.symbolsChartIds],
            LoadAdditionalData: this._isFirstAccountState
        };
        const path: string = Utils.isNotNull(request.actionFormData) ? "GetAccountStateAndRanges" : "GetAccountState";
        XhrUtil.sendToAccountService(request, path)
            .then((response: any) => {
                if (Platform.reduxState<StoreState>().auth.authenticated) {
                    if (Utils.isNotNull(response.Item2)) {
                        this.doProcessAccountStateAndRange(actionFormData.SymbolId, response);
                    } else {
                        this.doProcessAccountState(response);
                    }
                }
            })
            .catch((error: HttpReject) => {
                if (error) {
                    if (error.status === 0) {
                        this._logger.debug("Disconnected");
                        Platform.dispatch(SetAuthStatus({
                            authStatus: {connected: false}
                        }));
                        const authEngine: AuthEngine = Platform.engine(ServiceType.Auth);
                        authEngine.startPing();
                    } else if (Utils.isNotNull(error.response)) {
                        let response: BadRequestResponse;
                        try {
                            response = JSON.parse(error.response);
                        } catch (e) {
                            this._logger.warn("Can't parse get account state failed response");
                        }
                        if (response && response.IsSecurityException) {
                            this._logger.warn("Security exception fetch account info. Stop interval");
                            if (Utils.isNotNull(this._accountInterval)) {
                                clearInterval(this._accountInterval);
                                clearInterval(this._promotionsInterval);
                                this._accountInterval = null;
                                this._promotionsInterval = null;
                            }
                            if (!Platform.reduxState<StoreState>().auth.loggedOff) {
                                Platform.dispatch(DoLogout({skipNotifyServer: true}));
                            }
                        }
                    }
                }
            });
    }

    private doProcessAccountState = (response: GetAccountStateResponse) => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        if (response.AccountId === accountState.accountId && Utils.isNotNull(response.AggregateData)) {
            const chartEngine: ChartEngine = Platform.engine(ServiceType.Chart);
            accountState.accountId = response.AccountId;
            accountState.depositButtonType = response.DepositButtonActionType;
            accountState.isFractionalAmountAllowed = Utils.isNotNull(response.IsFractionalAmountAllowed) ? response.IsFractionalAmountAllowed : true;
            const {
                AccountRebateData,
                Vault,
                PositionPromotionSettings,
                notCachedAdditionalData
            } = Platform.reduxState<StoreState>().account;
            Platform.dispatch(SetAccountInfo({
                accountInfo: {
                    ...response.AggregateData,
                    AccountId: response.AccountId,
                    ProfitTradingGroupId: response.ProfitTradingGroupId,
                    ComplianceType: response.ComplianceType,
                    EMTPercentage: response.ExposureManagementToolPercentage,
                    MarginCallPercentage: response.MarginCallPercentage,
                    ConversionFeePercentage: response.ConversionFeePercentage,
                    RolloverConversionFeePercentage: response.RolloverConversionFeePercentage,
                    AccountRebateData: response.AccountRebateData,
                    Vault: response.Vault,
                    notCachedAdditionalData: response.AdditionalData || notCachedAdditionalData,
                    PositionPromotionSettings: response.PositionPromotionSettings,
                    IsUserAllowedToTrade: response.IsUserAllowedToTrade,
                    ShouldShowKYC: response.ShouldShowKYC,
                    AreSignalsAllowed: response.AreSignalsAllowed,
                    ShouldShowInappropriatePopup: response.ShouldShowInappropriatePopup,
                    ShouldAcceptNonProtectedTradeDeclaration: response.ShouldAcceptNonProtectedTradeDeclaration,
                    ShownMenuItems: response.ShownMenuItems,
                    QuantumAccountExpirationDate: response.QuantumAccountExpirationDate,
                    MaxExposure: response.MaxExposure
                }
            }));
            const quotesState: QuotesState = Platform.state(ServiceType.Quotes);
            const quotes: TSMap<number, Quote> = Platform.reduxState<StoreState>().quotes.quotes.clone();
            if (Utils.isArrayNotEmpty(response.Quotes)) {
                response.Quotes.forEach((quote: Quote) => {
                    quotesState.setQuote(quote);
                    quotes.set(quote.SymbolId, quote);
                    chartEngine.doUpdateQuoteState(quote);
                });
            }
            Platform.dispatch(SetQuotes({quotes}));
            Platform.dispatch(SetPositionsOrders({
                OpenPositions: response.OpenPositions,
                EntryOrders: response.EntryOrders,
                Exposures: response.Exposures
            }));
            chartEngine.doUpdateChart(response.Quotes, response.CurrentChartQuotes, response.OpenPositions);
            this.doProcessRebateData(AccountRebateData, response);
            this.doProcessMarginEvents(response);
            this.doProcessCreditPromos(response);
            this.doProcessPopupNotifications(response);
            this.doProcessInAppNotifications(response);
            this.doProcessChatNotifications(response);
            this.doProcessAccountOptions(response);
            this.doProcessPromotions(AccountRebateData, Vault, PositionPromotionSettings, response);
            if (this._isFirstAccountState) {
                const hasMoney: boolean = Utils.greaterThen0(response.AggregateData?.Balance);
                const {
                    inActiveTraderIdleTimeBeforeLogoff,
                    activeTraderIdleTimeBeforeLogoff
                } = Platform.config<Configuration>();
                SessionInactivity.setThreshold(hasMoney ? activeTraderIdleTimeBeforeLogoff : inActiveTraderIdleTimeBeforeLogoff);
                SessionInactivity.reset();
                ReadyState.hasAccountState = true;
            }
            this._isFirstAccountState = false;
        }
    }

    private doProcessAccountStateAndRange = (symbolId: number, response: GetAccountStateAndRangesResponse) => {
        this.doProcessAccountState(response.Item1);
        if (response.Item2) {
            Platform.dispatch(SetDealRanges({
                symbolId,
                ranges: response.Item2,
                isInvestAccount:  Platform.reduxState<StoreState>().auth.loginAccountType  === LoginAccountType.Invest
            }));
        }
    }

    private doProcessRebateData = (AccountRebateData: RebatePlanData, response: GetAccountStateResponse) => {
        if (response.AccountRebateData) {
            const accountReduxState: AccountReduxState = Platform.reduxState<StoreState>().account;
            if (AccountRebateData?.CurrentRebateLevelId && response.AccountRebateData.CurrentRebateLevelId !== AccountRebateData?.CurrentRebateLevelId) {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.INFO,
                        icon: {type: PopupIconType.INFO},
                        showClose: true,
                        message: {
                            trKey: response.AccountRebateData.IsFinalLevel ? TranslationKey.promotionModalRebatePlanInfoReachedLastLevel : TranslationKey.promotionModalRebatePlanInfoReachedNextLevel,
                            params: [
                                Parameter.Of(TranslationParam.amount, NumberFormat.formatMoney(response.AccountRebateData.CurrentRebate, accountReduxState.Currency)),
                                Parameter.Of(TranslationParam.value, NumberFormat.formatMoney(response.AccountRebateData.VolumeGapFromNextLevelInRequestedAsset, accountReduxState.Currency))
                            ]
                        },
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        }
    }

    private doProcessMarginEvents = (response: GetAccountStateResponse) => {
        if (Utils.isArrayNotEmpty(response.MarginEvents) && Utils.isNull(this._lastMarginEvent)) {
            let lastEvent: MarginEvent = response.MarginEvents[0];
            for (let i = 1; i < response.MarginEvents.length; i++) {
                if (lastEvent.EventType === response.MarginEvents[i].EventType) {
                    lastEvent = response.MarginEvents[i];
                }
            }
            this._lastMarginEvent = lastEvent;
            this._lastMarginEventId = lastEvent.Id;
            const bannerType: BannerType = BannerType.fromMarginEvent(lastEvent.EventType);
            if (bannerType) {
                const {banner} = Platform.reduxState<StoreState>().app;
                if (banner?.type === BannerType.BOChat) {
                    this.doDisplayOpenBOChatPopup();
                } else if (banner?.type === BannerType.BOChatReopen) {
                    Platform.dispatch(ShowPopup({
                        popup: {
                            type: PopupType.INFO,
                            icon: {type: PopupIconType.INFO},
                            message: {
                                trKey: TranslationKey.bannerBoChatReopenNewMessages
                            },
                            actions: [
                                {
                                    type: PopupActionType.OK,
                                    text: {trKey: TranslationKey.bannerBoChatReopenBtn},
                                    action: () => {
                                        Platform.dispatch(BOChatSetVisible({visible: true}));
                                    }
                                }
                            ]
                        }
                    }));
                }
                if (BannerType.IsLiquidation(bannerType)) {
                    Platform.dispatch(ToggleAccountDetailsSwiper({value: false}));
                }
                Platform.dispatch(SetBanner({
                    banner: {
                        type: bannerType,
                        remoteId: lastEvent.Id,
                        payload: lastEvent
                    }
                }));
            }
            const eventType: BIEventType = BIUtil.FromMarginEvent(lastEvent.EventType);
            if (eventType) {
                const {account, trades, router} = Platform.reduxState<StoreState>();
                const {Balance, Equity, MarginPercentage, UnrealizedPL, Currency} = account;
                Platform.bi().track(eventType, {
                    AccountBalance: `${Balance} ${Currency}`,
                    AccountEquity: `${Equity} ${Currency}`,
                    MarginPercent: `${MarginPercentage} %`,
                    UnrealizedPL,
                    View: BIUtil.PageName(router?.route),
                    ViewTabName: BIUtil.PageTabName(router?.route),
                    CountOfOpenPositions: trades.OpenPositions?.length
                });
            }
        }
    }

    private doProcessCreditPromos = (response: GetAccountStateResponse) => {
        if (Utils.isArrayNotEmpty(response.CreditPromos) && Utils.isNull(this._lastCreditPromo)) {
            const creditPromo: CreditPromo = response.CreditPromos[0];
            this._lastCreditPromo = creditPromo;
            this._lastCreditPromoId = creditPromo.Id;
            this._logger.debug("Display credit promo: " + this._lastCreditPromo.Id);
            Platform.dispatch(
                SetTradingModal({
                    tradingModalType: TradingModalType.CreditPromo,
                    info: {
                        visible: true,
                        params: {
                            creditPromo
                        }
                    },
                })
            );
            TradeUtil.playPopupSound();
        }
    }

    public doActivateBonusCode = async ({value}: StringPayload) => {
        this._logger.debug(`Send activate bonus code. JWT: ${value}`);
        const request: ActivateBonusCodeRequest = {
            BonusToken: value
        };
        const answer: [HttpReject, any] = await Utils.to(this.sendToMobileBillingService(request, "ActivateBonusCode"));
        if (answer[0]) {
            this._logger.warn(`Failed activate bonus code: ${value}`);
        }
    }

    public doAcceptCreditPromo = async ({promo, status}: DoAcceptCreditPromoPayload) => {
        const request: CreditPromoAcceptRequest = {
            Id: promo.Id,
            CreditPromoStatus: status
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, any] = await Utils.to(this.sendToBillingService(request, "ModifyUICreditPromo"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            Platform.dispatch(
                SetTradingModal({
                    tradingModalType: TradingModalType.CreditPromo,
                    info: {
                        visible: false
                    },
                })
            );
            Platform.dispatch(ShowPopup({
                popup: {
                    remoteId: promo.Id,
                    type: PopupType.INFO,
                    icon: {type: PopupIconType.INFO},
                    showClose: true,
                    message: {
                        trKey: status === CreditPromoStatus.Accepted ? TranslationKey.promotionCreditReceived : TranslationKey.promotionCreditRejected,
                        params: [Parameter.Of(TranslationParam.amount, `${promo.Amount}${Currency.sign(promo.AssetName)}`)]
                    },
                    actions: [{type: PopupActionType.OK}]
                }
            }));
        }
    }

    private doProcessChatNotifications = (response: GetAccountStateResponse) => {
        if (Utils.isArrayNotEmpty(response.ChatNotifications)) {
            const chatNotification: ChatNotification = response.ChatNotifications[0];
            if (Utils.isNull(this._lastChatNotification) || this._lastChatNotification.Token !== chatNotification.Token) {
                this._lastChatNotification = chatNotification;
                this._lastChatNotificationId = chatNotification.Token;
                const {banner} = Platform.reduxState<StoreState>().app;
                if (Utils.isNull(banner) || !BannerType.IsLiquidation(banner.type)) {
                    Platform.dispatch(ToggleAccountDetailsSwiper({value: false}));
                    Platform.dispatch(SetBanner({
                        banner: {
                            type: BannerType.BOChat,
                            remoteId: chatNotification.Token,
                            payload: chatNotification
                        }
                    }));
                } else {
                    this.doDisplayOpenBOChatPopup();
                }
            }
        }
    }

    private doDisplayOpenBOChatPopup = (): void => {
        Platform.dispatch(ShowPopup({
            popup: {
                type: PopupType.INFO,
                icon: {type: PopupIconType.INFO},
                message: {
                    trKey: TranslationKey.chatInit
                },
                actions: [
                    {
                        type: PopupActionType.OK,
                        action: () => {
                            Platform.dispatch(BOChatStart({token: this._lastChatNotification?.Token}));
                        }
                    }
                ]
            }
        }));
    }

    private doProcessInAppNotifications = (response: GetAccountStateResponse) => {
        if (Utils.isArrayNotEmpty(response.InAppPopupNotifications)) {
            const inAppNotification: PopupNotification = response.InAppPopupNotifications[0];
            if (Utils.greaterThen0(inAppNotification?.Id) && this._lastInAppPopupNotification?.Id !== inAppNotification.Id) {
                this._lastInAppPopupNotification = inAppNotification;
                this._lastInAppPopupNotificationId = inAppNotification.Id;
                InboxScheduler.AddInbox(inAppNotification);
            }
        }
    }

    private doProcessPopupNotifications = (response: GetAccountStateResponse) => {
        if (Utils.isArrayNotEmpty(response.PopupNotifications) && Utils.isNull(this._lastPopupNotification)) {
            const popupNotification: PopupNotification = response.PopupNotifications[0];
            this._lastPopupNotification = popupNotification;
            this._lastPopupNotificationId = popupNotification.Id;
            const isDpk: boolean = popupNotification.PopupActionType === PopupNotificationActionType.ExecuteDeepLink;
            const isOpenPosition: boolean = popupNotification.PopupActionType === PopupNotificationActionType.OpenPosition;
            const isOpenUrl: boolean = popupNotification.PopupActionType === PopupNotificationActionType.OpenUrl;
            const actions: PopupAction[] = [];
            if (isOpenPosition) {
                const {symbols} = Platform.reduxState<StoreState>().symbols;
                const symbol: TradeSymbol = symbols?.get(popupNotification.SymbolId);
                actions.push(
                    {
                        type: PopupActionType.OK,
                        text: {
                            customValue: `${Translations.text(TranslationKey.sell)} ${symbol?.text}`
                        },
                        action: () => {
                            Platform.dispatch(GoingOpenDeal({
                                SymbolId: popupNotification.SymbolId,
                                Direction: PositionDirection.Sell,
                                comments: "Created from DPK",
                                action: DpkHandler.GetAction(DpkType.OpenPosition)
                            }));
                        }
                    },
                    {
                        type: PopupActionType.OK,
                        text: {
                            customValue: `${Translations.text(TranslationKey.buy)} ${symbol?.text}`
                        },
                        action: () => {
                            Platform.dispatch(GoingOpenDeal({
                                SymbolId: popupNotification.SymbolId,
                                Direction: PositionDirection.Buy,
                                comments: "Created from DPK",
                                action: DpkHandler.GetAction(DpkType.OpenPosition)
                            }));
                        }
                    }
                );
            } else {
                actions.push(
                    {
                        type: PopupActionType.OK, action: () => {
                            if (popupNotification.PopupActionType) {
                                switch (popupNotification.PopupActionType) {
                                    case PopupNotificationActionType.ExecuteDeepLink:
                                        const index: number = popupNotification.Hyperlink?.indexOf("dpk=");
                                        const dpk: string = index > -1 ? popupNotification.Hyperlink?.substring(index + 4) : popupNotification.Hyperlink;
                                        const dpkType: DpkType = Dpk.dpkType(dpk) as DpkType;
                                        if (dpkType) {
                                            Platform.dispatch(ExecuteDpk({
                                                dpk,
                                                params: [Parameter.Of("dpk", dpk)],
                                                action: DpkHandler.GetAction(dpkType)
                                            }));
                                        }
                                        break;
                                    case PopupNotificationActionType.OpenDeposit:
                                        Platform.dispatch(OnProcessTopUpButton({
                                            action: DpkHandler.GetAction(DpkType.OpenDeposit)
                                        }));
                                        break;
                                    case PopupNotificationActionType.OpenUrl:
                                        Platform.environment().openWindow(popupNotification.Hyperlink);
                                        break;
                                }
                            }
                        }
                    }
                );
            }
            Platform.dispatch(ShowPopup({
                popup: {
                    remoteId: popupNotification.Id,
                    type: PopupType.INFO,
                    message: {
                        customValue: popupNotification.Message
                    },
                    body: {
                        hyperlink: isDpk || isOpenUrl ? null : popupNotification.Hyperlink
                    },
                    showClose: true,
                    clazz: WebUtil.hasHtmlContent(popupNotification.Message) ? "inner-html" : null,
                    actions
                }
            }));
            TradeUtil.playPopupSound();
        }
    }

    private doProcessAccountOptions = (response: GetAccountStateResponse) => {
        const {ShownMenuItems, IsTransactionsSignatureRequired, QuantumAccountExpirationDate} = response;
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const {app, account, withdrawal, settings} = Platform.reduxState<StoreState>();
        const isCanFinalizeRegistration: boolean = (ShownMenuItems & AccountOption.FinalizeRegistrationLink) > 0;
        const isCanUploadRetailDocuments: boolean = Utils.isNotNull(account.notCachedAdditionalData?.IsRequiredDocumentUploaded) && !account.notCachedAdditionalData?.IsRequiredDocumentUploaded;
        let bannerRemoved: boolean;
        if (app.banner) {
            if (
                (app.banner.type === BannerType.WithdrawalCancel && Utils.isNull(withdrawal.withdrawal))
                || (app.banner.type === BannerType.FinalizeRegistration && !isCanFinalizeRegistration)
                || (app.banner.type === BannerType.VerifyIdentity && !isCanUploadRetailDocuments)
                || (app.banner.type === BannerType.SignDeclaration && !IsTransactionsSignatureRequired)
                || (app.banner.type === BannerType.QuantumAccount && Utils.isEmpty(QuantumAccountExpirationDate))
            ) {
                bannerRemoved = true;
                Platform.dispatch(RemoveBanner({
                    banner: app.banner,
                    block: false
                }));
            }
        }
        let bannerType: BannerType;
        if (accountState.accountType === NXEnvironmentType.Live) {
            const blockedBanners: BannerType[] = AppState.instance().getBlockedBanners();
            if (Utils.isNotEmpty(QuantumAccountExpirationDate)) {
                bannerType = BannerType.QuantumAccount;
            } else if (IsTransactionsSignatureRequired && blockedBanners.indexOf(BannerType.SignDeclaration) < 0) {
                bannerType = BannerType.SignDeclaration;
            } else if (isCanFinalizeRegistration && blockedBanners.indexOf(BannerType.FinalizeRegistration) < 0) {
                bannerType = BannerType.FinalizeRegistration;
            } else if (isCanUploadRetailDocuments && blockedBanners.indexOf(BannerType.VerifyIdentity) < 0) {
                bannerType = BannerType.VerifyIdentity;
            } else if (withdrawal.withdrawal && blockedBanners.indexOf(BannerType.WithdrawalCancel) < 0) {
                bannerType = BannerType.WithdrawalCancel;
            }
        }

        if (Utils.isNotNull(bannerType) && (Utils.isNull(app.banner) || (app.banner.type !== bannerType && !BannerType.IsTopLevel(app.banner.type)))) {
            Platform.dispatch(ToggleAccountDetailsSwiper({value: false}));
            Platform.dispatch(SetBanner({
                banner: {
                    type: bannerType,
                    payload: withdrawal.withdrawal
                }
            }));
        }
        if (Utils.isNull(bannerType) && bannerRemoved) {
            const preference: Preference<boolean> = settings.preferences.get(StorageKey.ShowFinancialInfoPanel);
            if (preference?.value) {
                Platform.dispatch(ToggleAccountDetailsSwiper({value: true}));
            }
        }
    }

    private doProcessPromotions = (AccountRebateData: RebatePlanData, Vault: VaultInfo, PositionPromotionSettings: PositionPromotion[], response: GetAccountStateResponse) => {
        if (!this._isFirstAccountState) {
            if (
                PromotionUtil.isRebatePlanChanged(AccountRebateData, response.AccountRebateData)
                || PromotionUtil.isVaultChanged(Vault, response.Vault)
                || PromotionUtil.isPromotionsChanged(PositionPromotionSettings, response.PositionPromotionSettings)
            ) {
                Platform.dispatch(SetPromotionsChanged({changed: true}));
            }
        } else {
            PromotionUtil.setNextPromotionOverview(response.AccountRebateData, response.Vault, response.PositionPromotionSettings);
            if (Utils.isNull(this._promotionsInterval)) {
                this._promotionsInterval = setInterval(() => {
                    const account: AccountReduxState = Platform.reduxState<StoreState>().account;
                    PromotionUtil.setNextPromotionOverview(account.AccountRebateData, account.Vault, account.PositionPromotionSettings);
                }, 5000);
            }
        }
    }

    public onHidePopup = ({popup}: HidePopupPayload) => {
        if (popup && this._lastCreditPromo && popup.remoteId === this._lastCreditPromo.Id) {
            this._lastCreditPromo = null;
        }
        if (popup && this._lastPopupNotification && popup.remoteId === this._lastPopupNotification.Id) {
            this._lastPopupNotification = null;
        }
    }

    public onRemoveBanner = ({banner}: BannerPayload) => {
        if (banner && this._lastMarginEvent && banner.remoteId === this._lastMarginEvent.Id) {
            this._lastMarginEvent = null;
        }
    }

    public onChangeRoute = (payload): void => {
        const {authenticated} = Platform.reduxState<StoreState>().auth;
        const routeType: RouteType = payload.route.name;
        if (authenticated && routeType === RouteType.AccountSettings) {
            this.doFetchPendingWithdrawal().catch(() => {
            });
        }
    }

    public doProcessCreateTrade = (payload: OnProcessCreateTradeButtonPayload): void => {
        const {
            IsUserAllowedToTrade,
            ShouldShowKYC,
            Balance,
            ShouldShowInappropriatePopup
        } = Platform.reduxState<StoreState>().account;
        const OpenFn = () => {
            if (payload?.action) {
                payload.action(RouteType.SymbolSearch);
            } else {
                if (WebUtil.isMobile()) {
                    Platform.dispatch(
                        SetTradingModal({
                            tradingModalType: TradingModalType.SymbolSearch,
                            info: {
                                visible: true,
                            },
                        })
                    )
                } else {
                    /*Platform.dispatch(NavigateTo({
                                route: RouteType.Symbols,
                                params: {
                                    searchSymbol: true
                                }
                            }));*/
                    Platform.dispatch(SetTradingModal({
                        tradingModalType: TradingModalType.CreateTrade,
                        info: {
                            visible: true
                        }
                    }));
                }
            }
        };
        if (Balance > 0) {
            if (ShouldShowInappropriatePopup) {
                Platform.dispatch(SetTradingModal({
                    tradingModalType: TradingModalType.KYC,
                    info: {visible: true}
                }));
            } else if (ShouldShowKYC) {
                if (payload?.action) {
                    payload.action(RouteType.Kyc);
                } else {
                    this.doOpenKyc();
                }
            } else if (IsUserAllowedToTrade) {
                OpenFn();
            } else {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.ERROR,
                        message: {
                            trKey: TranslationKey.notAllowedToTradeError
                        },
                        showClose: true,
                        icon: {type: PopupIconType.ERROR},
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        } else {
            OpenFn();
        }
    }

    public doOpenKyc = (): void => {
        const win: Win = Platform.environment().openWindow("empty", "_blank");
        Platform.bi().track(BIEventType.WindowOpen, {
            Target: "blank",
            UrlType: UrlType.Kyc,
            HasTargetWindow: Utils.isNotNull(win)
        });
        Platform.dispatch(DoGetURL({
            urlType: UrlType.Kyc,
            parameters: [Parameter.Of("win", win)]
        }));
    }

    public doProcessTopUp = (payload: OnProcessTopUpButtonPayload): void => {
        const brand: BrandType = Platform.reduxState<StoreState>().core.brand;
        const isV2Mode: boolean = BrandType.V2Mode(brand);
        const accountState: AccountState = Platform.state(ServiceType.Account);
        let btnType: DepositButtonActionType = accountState.getDepositButtonType();
        if (isV2Mode) {
            // NOTE: solution to skip KYC
            btnType = DepositButtonActionType.Deposit;
        }
        if (Utils.isNotNull(btnType)) {
            switch (btnType) {
                case DepositButtonActionType.Kyc:
                    Platform.bi().track(BIEventType.DepositDecision, {
                        Target: BIDepositDecisionType.KYC
                    });
                    if (payload?.action) {
                        payload.action(RouteType.Kyc);
                    } else {
                        this.doOpenKyc();
                    }
                    break;
                case DepositButtonActionType.Deposit:
                    Platform.bi().track(BIEventType.DepositDecision, {
                        Target: BIDepositDecisionType.Deposit
                    });
                    if (payload?.action) {
                        payload.action(RouteType.Deposit, payload.parameters);
                    } else {
                        const {depositTarget} = Platform.config<Configuration>();
                        const isSelfTarget: boolean = depositTarget === UrlTarget.SELF;
                        if (Platform.environment().type() === EnvType.Web
                                && ((WebUtil.isSafari() && !isV2Mode) || (WebUtil.isMobile() && isV2Mode)
                                    || depositTarget === UrlTarget.BLANK || isSelfTarget)) {
                            const win: Win = isSelfTarget || isV2Mode ? WebUtil.window() : Platform.environment().openWindow("empty", "_blank");
                            Platform.bi().track(BIEventType.WindowOpen, {
                                Target: depositTarget || "blank",
                                UrlType: UrlType.Deposit,
                                HasTargetWindow: Utils.isNotNull(win)
                            });
                            Platform.dispatch(DoGetURL({
                                urlType: UrlType.Deposit,
                                parameters: [Parameter.Of("win", win), ...(payload?.parameters ? payload.parameters : [])]
                            }));
                            const {account, trades, router} = Platform.reduxState<StoreState>();
                            if (Utils.isNotNull(win)) {
                                Platform.bi().track(BIEventType.DepositPageOpened, {
                                    AccountBalance: `${account?.Balance} ${account?.Currency}`,
                                    AccountEquity: `${account?.Equity} ${account?.Currency}`,
                                    View: BIUtil.PageName(router?.route),
                                    ViewTabName: BIUtil.PageTabName(router?.route),
                                    CountOfOpenPositions: trades.OpenPositions?.length,
                                    Referrer: payload?.referrer
                                });
                            } else {
                                Platform.bi().track(BIEventType.DepositPageLoadError, {
                                    View: BIUtil.PageName(router?.route),
                                    ViewTabName: BIUtil.PageTabName(router?.route),
                                    Referrer: payload?.referrer,
                                    Message: "Target window NULL"
                                });
                            }
                        } else {
                            Platform.dispatch(SetTradingModal({
                                tradingModalType: TradingModalType.Deposit,
                                info: {
                                    visible: true,
                                    params: payload?.parameters,
                                    referrer: payload?.referrer
                                }
                            }));
                        }
                    }
                    break;
                case DepositButtonActionType.Inappropriate:
                    const {
                        IsUserAllowedToTrade,
                        ShouldShowInappropriatePopup
                    } = Platform.reduxState<StoreState>().account;
                    if (!IsUserAllowedToTrade && ShouldShowInappropriatePopup) {
                        Platform.bi().track(BIEventType.DepositDecision, {
                            Target: BIDepositDecisionType.KYCInAppropriate
                        });
                        if (payload?.action) {
                            payload.action(RouteType.Kyc);
                        } else {
                            Platform.dispatch(SetTradingModal({
                                tradingModalType: TradingModalType.KYC,
                                info: {visible: true}
                            }));
                        }
                    } else {
                        Platform.bi().track(BIEventType.DepositDecision, {
                            Target: BIDepositDecisionType.InAppropriate
                        });
                        Platform.dispatch(ShowPopup({
                            popup: {
                                type: PopupType.ERROR,
                                message: {
                                    trKey: TranslationKey.notAllowedToTradeError
                                },
                                showClose: true,
                                icon: {type: PopupIconType.ERROR},
                                actions: [{type: PopupActionType.OK}]
                            }
                        }));
                    }
                    break;
            }
        } else {
            Platform.bi().track(BIEventType.DepositDecision, {
                Target: BIDepositDecisionType.DoNothing
            });
        }
        return null;
    }

    public doFetchPendingTradingApproval = async () => {
        const answer: [HttpReject, IsTradingApprovalNeededResponse] = await Utils.to(this.sendToWebProfitService(null, "IsTradingApprovalNeeded"));
        if (answer[1] && answer[1].ShowDealsApprovalPopup) {
            const {CreatedPositionsIds, CreatedOrdersIds, UpdatedPositionsIds, UpdatedOrdersIds} = answer[1];
            const msgFn = (): string => {
                const hasOpenedPositions: boolean = Utils.isArrayNotEmpty(CreatedPositionsIds);
                const hasOpenedOrders: boolean = Utils.isArrayNotEmpty(CreatedOrdersIds);
                const hasUpdatedPositions: boolean = Utils.isArrayNotEmpty(UpdatedPositionsIds);
                const hasUpdatedOrders: boolean = Utils.isArrayNotEmpty(UpdatedOrdersIds);
                const message: string[] = [];
                if (hasOpenedPositions || hasOpenedOrders) {
                    message.push(Translations.text(TranslationKey.dealingDealsWereCreated));
                    if (hasOpenedPositions) {
                        message.push("<br/>");
                        message.push(Translations.text(TranslationKey.positions));
                        message.push(": <span>");
                        message.push(CreatedPositionsIds.join(", "));
                        message.push("</span>");
                    }
                    if (hasOpenedOrders) {
                        message.push("<br/>");
                        message.push(Translations.text(TranslationKey.orders));
                        message.push(": <span>");
                        message.push(CreatedOrdersIds.join(", "));
                        message.push("</span>");
                    }
                }
                if (hasUpdatedPositions || hasUpdatedOrders) {
                    if (hasOpenedPositions || hasOpenedOrders) {
                        message.push("<br/><br/>");
                    }
                    message.push(Translations.text(TranslationKey.dealingDealsWereUpdated));
                    if (hasUpdatedPositions) {
                        message.push("<br/>");
                        message.push(Translations.text(TranslationKey.positions));
                        message.push(": <span>");
                        message.push(UpdatedPositionsIds.join(", "));
                        message.push("</span>");
                    }
                    if (hasUpdatedOrders) {
                        message.push("<br/>");
                        message.push(Translations.text(TranslationKey.orders));
                        message.push(": <span>");
                        message.push(UpdatedOrdersIds.join(", "));
                        message.push("</span>");
                    }
                }
                message.push("<br/><br/>");
                message.push(Translations.text(TranslationKey.dealingApprovingAgreement));
                return message.join("");
            };
            Platform.dispatch(ShowPopup({
                popup: {
                    type: PopupType.INFO,
                    icon: {type: PopupIconType.INFO},
                    title: {trKey: TranslationKey.dealingApprovingActions},
                    message: {
                        fn: msgFn
                    },
                    clazz: "dealing-approving",
                    actions: [{
                        type: PopupActionType.OK, action: async () => {
                            const dealingRoomActions: DealingRoomAction[] = [];
                            CreatedPositionsIds.forEach((TargetId: number) => {
                                dealingRoomActions.push({TargetId, DealingRoomActionTarget: DealingRoomActionTpe.MarketOrder});
                            });
                            CreatedOrdersIds.forEach((TargetId: number) => {
                                dealingRoomActions.push({TargetId, DealingRoomActionTarget: DealingRoomActionTpe.EntryOrder});
                            });
                            UpdatedPositionsIds.forEach((TargetId: number) => {
                                dealingRoomActions.push({TargetId, DealingRoomActionTarget: DealingRoomActionTpe.MarketOrder});
                            });
                            UpdatedOrdersIds.forEach((TargetId: number) => {
                                dealingRoomActions.push({TargetId, DealingRoomActionTarget: DealingRoomActionTpe.EntryOrder});
                            });
                            await this.sendToTradeService(dealingRoomActions, "AproveDealingRoomAction");
                        }
                    }]
                }
            }));
        }
    }

    public doFetchPendingWithdrawal = async () => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        if (accountState.accountType === NXEnvironmentType.Live) {
            this._logger.debug("Fetching pending withdrawal");
            const request: GetPendingWithdrawalRequest = {};
            const answer: [HttpReject, GetPendingWithdrawalResponse] = await Utils.to(this.sendToMobileBillingService(request, "GetPendingWithdrawalRequest"));
            if (answer[0]) {
                this._logger.debug("Failed fetch pending withdrawal");
            } else {
                const response: GetPendingWithdrawalResponse = answer[1];
                if (response && response.RequestId) {
                    Platform.dispatch(SetPendingWithdrawal({
                        withdrawal: response
                    }));
                } else {
                    this._logger.debug("Doesn't have pending withdrawal");
                }
            }
        }
    }

    public doWithdrawal = async (payload: DoWithdrawalPayload) => {
        this._logger.debug("Try to withdrawal: " + payload.amount);
        const authState: AuthState = Platform.state(ServiceType.Auth);
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const account: LoginAccountInfo = authState.getAccount(accountState.accountType);
        const request: CreateWithdrawalRequest = {
            Amount: payload.amount,
            AssetId: account.BaseAssetId,
            AccountId: accountState.accountId,
            Reason: payload.reason
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, any] = await Utils.to(this.sendToMobileBillingService(request, "CreateWithdrawalRequest"));
        Platform.dispatch(HideLoader({}));
        if (payload?.action) {
            payload.action(RouteType.AccountSettings);
        }
        Platform.dispatch(SetTradingModal({
            tradingModalType: TradingModalType.Withdrawal,
            info: {
                visible: false
            }
        }));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            await this.doFetchPendingWithdrawal();
            Platform.dispatch(SetTradingModal({
                tradingModalType: TradingModalType.WithdrawalSuccess,
                info: {
                    visible: true
                }
            }));
        }
    }

    public doCancelWithdrawal = async (payload: DoCancelWithdrawalPayload) => {
        this._logger.debug("Try cancel withdrawal");
        const withdrawal: WithdrawalPending = payload.withdrawal;
        const request: CancelWithdrawalRequest = {RequestId: withdrawal.RequestId};
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, any] = await Utils.to(this.sendToMobileBillingService(request, "CancelWithdrawalRequest"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            Platform.dispatch(SetPendingWithdrawal({
                withdrawal: null
            }));
        }
    }

    public doRegisterUserActivity = async (payload: DoRegisterUserActivityPayload): Promise<boolean> => {
        this._logger.debug(`Do Register activity: ${payload.userActivity}`);
        const request: RegisterUserActivityRequest = {
            ActivityTypeKey: payload.userActivity,
            AssociatedId: null,
            Comments: payload.comment
        };
        const answer: [HttpReject, RegisterUserActivityResponse] = await Utils.to(this.sendToWebProfitService(request, "RegisterActivity"));
        return Promise.resolve(answer[1]?.Success);
    }

    public sendUserFeedback = async ({
                                         rate,
                                         comment,
                                         view,
                                         fromDpk,
                                         onFail
                                     }: SendUserFeedbackPayload): Promise<void> => {
        this._logger.debug("Try to send feedback with the score: " + rate);
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const request: UserFeedbackRequest = {
            score: rate,
            message: comment,
            view,
            dpk: fromDpk
        };
        const answer: [HttpReject, UserFeedbackResponse] = await Utils.to(this.sendToWebProfitService(request, "SendFeedback"));
        Platform.dispatch(HideLoader({}));
        if (answer[1]?.Success) {
            Platform.dispatch(SetTradingModal({
                tradingModalType: TradingModalType.Feedback,
                info: {
                    visible: false,
                }
            }));
            Platform.dispatch(ShowPopup({
                popup: {
                    type: PopupType.SUCCESS,
                    title: {trKey: TranslationKey.feedbackSuccess},
                    message: {
                        trKey: TranslationKey.feddbackSuccessMessage,
                        params: [Parameter.Of("id", "_wt_feedbackSuccess")]
                    },
                    showClose: true,
                    icon: {type: PopupIconType.SUCCESS},
                    actions: [{type: PopupActionType.OK}]
                }
            }));
        } else {
            this._logger.debug("Failed send feedback");
            Platform.dispatch(ShowPopup({
                popup: {
                    type: PopupType.INFO,
                    title: {trKey: TranslationKey.info},
                    message: {
                        trKey: TranslationKey.feedbackFailedMessage,
                        params: [Parameter.Of("id", "_wt_feedbackFail")]
                    },
                    showClose: true,
                    icon: {type: PopupIconType.INFO},
                    actions: [{
                        type: PopupActionType.CANCEL, action: async () => {
                            Platform.dispatch(SetTradingModal({
                                tradingModalType: TradingModalType.Feedback,
                                info: {
                                    visible: false,
                                }
                            }));
                        }
                    },
                        {type: PopupActionType.OK, text: {trKey: TranslationKey.feedbackTryAgain}, action: onFail}]
                }
            }));
        }
    }

    public doCallMeRequest = async (): Promise<void> => {
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [any, boolean] = await Utils.to(this.doRegisterUserActivity({userActivity: UserActivityType.HelpRequested}));
        Platform.dispatch(HideLoader({}));
        if (answer[1]) {
            Platform.dispatch(SetCallMeSubmitted({}));
            Platform.dispatch(SetTradingModal({
                tradingModalType: TradingModalType.Support,
                info: {visible: false},
            }));
            Platform.dispatch(ShowPopup({
                popup: {
                    type: PopupType.INFO,
                    title: {trKey: TranslationKey.info},
                    message: {
                        trKey: TranslationKey.callMeRequested,
                    },
                    showClose: true,
                    icon: {type: PopupIconType.INFO},
                    actions: [{type: PopupActionType.OK}]
                }
            }));
        } else {
            Platform.dispatch(ShowPopup({
                popup: {
                    type: PopupType.ERROR,
                    message: {
                        trKey: TranslationKey.errorGeneral
                    },
                    showClose: true,
                    icon: {type: PopupIconType.ERROR},
                    actions: [{type: PopupActionType.OK}]
                }
            }));
        }
    }

    public doRateApp = async ({rate, notNow, mobilePlatformType}: DoRateAppPayload): Promise<void> => {
        this._logger.debug(`Do rate app: ${rate}. Not now: ${notNow}`);
        const request: DoRateAppRequest = {
            IsSkipped: notNow,
            Rate: rate,
            Message: null,
            MobilePlatformType: mobilePlatformType,
            IsNativeRateAppShown: notNow ? null : Utils.nullTo0(rate) > 3
        };
        const answer: [HttpReject, DoRateAppResponse] = await Utils.to(this.sendToWebProfitService(request, "SaveRate"));
        if (answer[0]) {
            this._logger.debug("Failed save app rate");
        } else {
            const {Success, Error} = answer[1];
            if (!Success) {
                this._logger.warn(`UnSuccess save app rate: ${Error}`);
            }
        }
    }

    private sendToWebProfitService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "WebProfitServer/WebProfitClientService.svc/json/" + path, null, true);
    }

    private sendToMobileBillingService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticatedTo(ServerType.Billing, request, "BillingServer/MobileBillingService2.svc/json/" + path);
    }

    private sendToBillingService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticatedTo(ServerType.Billing, request, "BillingServer/BillingService.svc/json/" + path);
    }

    private sendToTradeService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "TradeServer/TradeService.svc/json/" + path);
    }
}
