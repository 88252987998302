import {SymbolSignal} from "platform/protocol/trading/SymbolSignal";
import {TSMap} from "typescript-map";
import {SignalsFilter} from "protocol/symbol/GetSymbolSignalsRequest";
import {SignalPatternType} from "platform/protocol/enum/SignalPatternType";
import {TableSortState} from "core/redux/common/TableSortState";

export enum SignalsTabView {

    List,
    SignalDetails,
}

export interface SignalsReduxState {

    activeCategoryTab?: string;
    categories: TSMap<string, SymbolSignal[]>;
    counters: TSMap<string, SignalsCounter>;
    tables?: TSMap<string, TableSortState>;
    selectedSignals: TSMap<string, SymbolSignal>;
    tabView?: SignalsTabView;
    symbolSignals?: SymbolSignal[];
    signalDetails?: SymbolSignal;
    settings: SignalsSettings;
    settingsVisible?: boolean;
    signalsLoaded?: boolean;
}

export interface SignalsCounter {

    categoryName?: string;
    count?: number;
}

export interface SignalsSettings {

    useProbability?: boolean;
    probabilityMin?: number;
    probabilityMax?: number;
    addedMin?: number;
    addedMax?: number;
    intervalMin?: number;
    intervalMax?: number;
    targetMin?: number;
    targetMax?: number;
    patternTypes?: SignalPatternType[],
    filter?: SignalsFilter;
}
