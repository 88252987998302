import Utils from "platform/util/Utils";

enum SortDirection {

    Ask = "ask",
    Desk = "desk",
}

namespace SortDirection {

    export function reverse(sortDirection: SortDirection): SortDirection {
        if (Utils.isNotNull(sortDirection)) {
            switch (sortDirection) {
                case SortDirection.Ask:
                    return SortDirection.Desk;
                case SortDirection.Desk:
                    return SortDirection.Ask;
            }
        }
        return null;
    }
}

export {
    SortDirection
};
