import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import AlertsEngine from "core/engine/AlertsEngine";
import AlertsReducer from "core/redux/alerts/AlertsReducer";

export class AlertsService implements Service<AlertsEngine, any> {

    private static _instance: AlertsService;

    private constructor() {
    }

    public static instance(): AlertsService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Alerts;
    }

    public reducers(): Array<Reducer<any>> {
        return [AlertsReducer.instance()];
    }

    public engine(): AlertsEngine {
        return AlertsEngine.instance();
    }

    public state(): any {
        return undefined;
    }
}
