import {Engine} from "platform/engine/Engine";
import {
    ConfirmRemoveAlertPayload,
    FetchCategoryAlertsPayload,
    FetchSymbolAlertsPayload,
    RemoveAlertPayload,
    SelectAlert, SetAlertsCategoryLoading,
    SetAlertsLoading,
    SetAlertsTab,
    SetAlertsTablesLS,
    SetAlertsTradeColumnPayload,
    SetCategoryAlerts,
    SetNewAlertPayload,
    SetSortedAlerts,
    SetSymbolAlerts,
    ToggleAlertsColumnSortPayload,
    UpdateAlertPayload
} from "core/redux/alerts/AlertsReduxActions";
import Platform from "platform/Platform";
import {StoreState} from "core/redux/StoreState";
import {TradeColumn} from "enum/TradeColumn";
import {SortDirection} from "platform/enum/SortDirection";
import Utils from "platform/util/Utils";
import {Quote} from "platform/protocol/trading/Quote";
import {TSMap} from "typescript-map";
import {SymbolCategory, TableCategoryLSData} from "enum/SymbolCategory";
import {TableSortState} from "core/redux/common/TableSortState";
import {StorageKey} from "enum/StorageKey";
import {XhrUtil} from "core/util/XhrUtil";
import {HttpReject} from "platform/network/http/Http";
import {CreateSymbolAlertRequest} from "protocol/alerts/CreateSymbolAlertRequest";
import {DeleteSymbolAlertRequest} from "protocol/alerts/DeleteSymbolAlertRequest";
import {CreateSymbolAlertResponse} from "protocol/alerts/CreateSymbolAlertResponse";
import {DeleteSymbolAlertResponse} from "protocol/alerts/DeleteSymbolAlertResponse";
import {UpdateSymbolAlertRequest} from "protocol/alerts/UpdateSymbolAlertRequest";
import {UpdateSymbolAlertResponse} from "protocol/alerts/UpdateSymbolAlertResponse";
import {ServiceType} from "enum/ServiceType";
import {GetSymbolsAlertsResponse} from "protocol/alerts/GetSymbolsAlertsResponse";
import {SymbolAlert} from "protocol/alerts/SymbolAlert";
import {SymbolAlertType} from "enum/SymbolAlertType";
import {SymbolsState} from "core/state/SymbolsState";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";
import {HideLoader, SetLoader} from "platform/redux/core/CoreActions";
import {LoaderType} from "platform/enum/LoaderType";
import Parameter from "platform/util/Parameter";
import {TranslationKey} from "enum/TranslationKey";
import WebUtil from "platform/util/WebUtil";
import {SetTradingModal} from "core/redux/app/AppReduxActions";
import TradingModalType from "enum/TradingModalType";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType, PopupType} from "platform/redux/popups/PopupsReduxState";
import Translations from "platform/translation/Translations";
import {TranslationParam} from "enum/TranslationParam";
import {UiUtil} from "core/util/UiUtil";
import {SetUserAvailableForPushNotifications} from "core/redux/settings/SettingsReduxActions";
import {AlertsCounter} from "core/redux/alerts/AlertsReduxState";
import {BIEventType} from "enum/BIEventType";
import {BIUtil} from "core/util/BIUtil";

export const SortAlerts = (column: TradeColumn, direction: SortDirection, quotes: TSMap<number, Quote>) => {
    return (a1: SymbolAlert, a2: SymbolAlert): number => {
        const q1: Quote = quotes.get(a1.SymbolId);
        const q2: Quote = quotes.get(a2.SymbolId);
        const ast1: SymbolAlertType = a1?.SymbolAlertType || SymbolAlertType.Price;
        const ast2: SymbolAlertType = a2?.SymbolAlertType || SymbolAlertType.Price;
        let result: number = 0;
        switch (column) {
            case TradeColumn.Symbol:
            case TradeColumn.Alert:
                const syn1 = Platform.state<SymbolsState>(ServiceType.Symbols).getSymbol(a1.SymbolId)?.text;
                const syn2 = Platform.state<SymbolsState>(ServiceType.Symbols).getSymbol(a2.SymbolId)?.text;
                result = Utils.compareString(syn1?.toUpperCase(), syn2?.toUpperCase());
                break;
            case TradeColumn.AlertType:
                result = Utils.compareNumber(ast1, ast2);
                if (result === 0) {
                    switch (ast1) {
                        case SymbolAlertType.Price:
                            result = Utils.compareNumber(a1?.Direction, a2?.Direction);
                            break;
                        case SymbolAlertType.ChangePercent:
                            result = Utils.compareNumber(a1?.ChangePercentAlertType, a2?.ChangePercentAlertType);
                            break;
                        case SymbolAlertType.Signal:
                            result = Utils.compareNumber(a1?.SignalSubPatternType, a2?.SignalSubPatternType);
                            break;
                    }
                }
                break;
            case TradeColumn.AlertAmount:
            case TradeColumn.Trigger:
                result = Utils.compareNumber(ast1, ast2);
                if (result === 0) {
                    result = Utils.compareNumber(a1?.TargetRate, a2?.TargetRate);
                }
                break;
            case TradeColumn.AlertSell:
                result = q1 && q2 && Utils.compareNumber(q1.Bid, q2.Bid);
                break;
            case TradeColumn.AlertBuy:
                result = q1 && q2 && Utils.compareNumber(q1.Ask, q2.Ask);
                break;
        }
        return direction === SortDirection.Ask ? result : -result;
    };
};

export default class AlertsEngine extends Engine {

    private static _instance: AlertsEngine;

    public static instance(): AlertsEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        const data: TableCategoryLSData = await this.deserializeLS();
        Platform.dispatch(SetAlertsTablesLS(data));
    }

    private deserializeLS = async (): Promise<TableCategoryLSData> => {
        const tables: TSMap<string, TableSortState> = await SymbolCategory.deserialize(StorageKey.AlertsSort);
        return Promise.resolve({tables});
    }

    public doGetCategoryAlerts = async ({category}: FetchCategoryAlertsPayload) => {
        const {categories, counters, symbols, subscribedSymbols} = Platform.reduxState<StoreState>().symbols;
        const tradeSymbols: TradeSymbol[] = category === SymbolCategory.All ? symbols.values() : categories.get(category);
        Platform.dispatch(SetAlertsCategoryLoading({alertsLoaded: false}));
        const alerts: SymbolAlert[] = await this.doFetchAlerts((tradeSymbols || []).map((symbol: TradeSymbol) => symbol.SymbolId));
        Platform.dispatch(SetAlertsCategoryLoading({alertsLoaded: true}));
        let AlertCounters: TSMap<string, AlertsCounter>;
        if (category === SymbolCategory.All) {
            AlertCounters = new TSMap<string, AlertsCounter>();
            AlertCounters.set(SymbolCategory.All, {categoryName: SymbolCategory.All, count: 0});
            counters.keys().forEach((categoryName: string) => {
                AlertCounters.set(categoryName, {categoryName, count: 0});
            });
            alerts.forEach((alert: SymbolAlert) => {
                AlertCounters.get(SymbolCategory.All).count++;
                if (subscribedSymbols.has(alert?.SymbolId)) {
                    AlertCounters.get(SymbolCategory.MyWatchlist).count++;
                }
                const categoryName = symbols.get(alert.SymbolId).category;
                AlertCounters.get(categoryName).count++;
            });
        }
        Platform.dispatch(SetCategoryAlerts({category, alerts, counters: AlertCounters}));
        const {selectedAlerts} = Platform.reduxState<StoreState>().alerts;
        const selectedAlert: SymbolAlert = selectedAlerts.get(category);
        if (Utils.isArrayNotEmpty(alerts)) {
            if (Utils.isNull(selectedAlert) || Utils.isArrayEmpty(alerts.filter((s: SymbolAlert) => s.Id === selectedAlert.Id))) {
                Platform.dispatch(SelectAlert({alert: alerts[0]}));
            }
        } else {
            Platform.dispatch(SelectAlert({alert: null}));
        }
    }

    public doFetchSymbolAlerts = async ({symbolId}: FetchSymbolAlertsPayload) => {
        if (symbolId) {
            const alerts: SymbolAlert[] = await this.doFetchAlerts([symbolId]);
            Platform.dispatch(SetSymbolAlerts({alerts}));
        } else {
            Platform.dispatch(SetSymbolAlerts({alerts: []}));
        }
    }

    private doFetchAlerts = async (symbolIds: number[]): Promise<SymbolAlert[]> => {
        Platform.dispatch(SetAlertsLoading({alertsLoaded: false}));
        const answer: [HttpReject, GetSymbolsAlertsResponse] = await Utils.to(this.sendToWebProfitService(symbolIds, "GetAllSymbolsAlertsConfigurations"));
        Platform.dispatch(SetAlertsLoading({alertsLoaded: true}));
        Platform.dispatch(SetUserAvailableForPushNotifications({
            value: answer[1]?.IsUserAvailableForPushNotifications
        }));
        if (answer[0]) {
            this._logger.debug("Failed fetch alerts");
            return Promise.resolve([]);
        } else {
            return Promise.resolve(answer[1]?.SymbolAlerts || []);
        }
    }

    public doAddAlert = async ({alert, callback}: SetNewAlertPayload) => {
        const languageCode: LangCode = LanguageUtil.languageCode();
        const {
            SymbolId,
            SymbolAlertType,
            Direction,
            CurrentRate,
            TargetRate,
            ChangePercent,
            ChangePercentAlertType,
            ChangePercentCurrentDateTime,
            SignalSubPatternType,
            IsRecurring,
            NotifyByEmail,
            NotifyByPush,
        } = alert;
        const request: CreateSymbolAlertRequest = {
            languageCode,
            symbolId: SymbolId,
            symbolAlertType: SymbolAlertType,
            positionDirection: Direction,
            currentRate: CurrentRate,
            targetRate: TargetRate,
            changePercentage: ChangePercent,
            changePercentAlertType: ChangePercentAlertType,
            changePercentFromDateTime: ChangePercentCurrentDateTime,
            signalSubPatternType: SignalSubPatternType,
            isRecurring: IsRecurring,
            notifyByEmail: NotifyByEmail,
            notifyByPush: NotifyByPush,
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, CreateSymbolAlertResponse] = await Utils.to(this.sendToWebProfitService(request, "CreateSymbolAlertConfiguration"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this._logger.debug("Failed add alert");
            XhrUtil.notifyReject(answer[0]);
        } else {
            const {SymbolAlertConfiguration: newSymbolAlert, Success, LocalizedMessage} = answer[1];
            if (Success && Utils.isNotNull(newSymbolAlert)) {
                const {symbols, subscribedSymbols} = Platform.reduxState<StoreState>().symbols;
                const {categories, symbolAlerts, activeCategoryTab} = Platform.reduxState<StoreState>().alerts;
                const {category, text} = symbols.get(alert?.SymbolId);
                const inMyWatchlist: boolean = subscribedSymbols.has(alert?.SymbolId);
                if (inMyWatchlist) {
                    Platform.dispatch(SetCategoryAlerts({
                        category: SymbolCategory.MyWatchlist,
                        alerts: [...categories.get(SymbolCategory.MyWatchlist), newSymbolAlert]
                    }));
                }
                Platform.dispatch(SetCategoryAlerts({
                    category,
                    alerts: [...categories.get(category), newSymbolAlert]
                }));
                Platform.dispatch(SetCategoryAlerts({
                    category: SymbolCategory.All,
                    alerts: [...categories.get(SymbolCategory.All), newSymbolAlert]
                }));
                Platform.dispatch(SetSymbolAlerts({alerts: [...symbolAlerts, newSymbolAlert]}));
                if (activeCategoryTab !== category && !(activeCategoryTab === SymbolCategory.MyWatchlist && inMyWatchlist)) {
                    Platform.dispatch(SetAlertsTab({category: SymbolCategory.All}));
                }
                Platform.dispatch(SelectAlert({alert: newSymbolAlert}));
                Platform.bi().track(BIEventType.SymbolAlertCreated, {
                    Type: BIUtil.AlertTypeName(SymbolAlertType),
                    Target: BIUtil.AlertTarget(alert),
                    Interval: BIUtil.AlertInterval(alert),
                    Recurring: alert.IsRecurring,
                    CommunicationChannel: BIUtil.AlertChannel(alert),
                    SymbolName: text
                });
                if (callback) {
                    callback();
                } else {
                    if (WebUtil.isMobile()) {
                        Platform.dispatch(SetTradingModal({
                            tradingModalType: TradingModalType.CreateEditTrade,
                            info: {
                                visible: false
                            }
                        }));
                    }
                }
            } else {
                this.notifyFail(LocalizedMessage);
            }
        }
    }

    private notifyFail = (LocalizeMessage: string) => {
        Platform.dispatch(ShowPopup({
            popup: {
                type: PopupType.ERROR,
                message: {
                    customValue: LocalizeMessage || Translations.text(TranslationKey.errorGeneral)
                },
                showClose: true,
                icon: {type: PopupIconType.ERROR},
                actions: [{type: PopupActionType.OK}]
            }
        }));
    }

    public doUpdateAlert = async ({alert}: UpdateAlertPayload) => {
        const languageCode: LangCode = LanguageUtil.languageCode();
        const {
            Id,
            Direction,
            CurrentRate,
            TargetRate,
            ChangePercent,
            ChangePercentAlertType,
            ChangePercentCurrentDateTime,
            SignalSubPatternType,
            IsRecurring,
            NotifyByEmail,
            NotifyByPush,
        } = alert;
        const request: UpdateSymbolAlertRequest = {
            languageCode,
            id: Id,
            positionDirection: Direction,
            currentRate: CurrentRate,
            targetRate: TargetRate,
            changePercentage: ChangePercent,
            changePercentAlertType: ChangePercentAlertType,
            changePercentFromDateTime: ChangePercentCurrentDateTime,
            signalSubPatternType: SignalSubPatternType,
            isRecurring: IsRecurring,
            notifyByEmail: NotifyByEmail,
            notifyByPush: NotifyByPush,
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, UpdateSymbolAlertResponse] = await Utils.to(this.sendToWebProfitService(request, "UpdateSymbolAlertConfiguration"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this._logger.debug("Failed update alert");
            XhrUtil.notifyReject(answer[0]);
        } else {
            const {SymbolAlertConfiguration: newSymbolAlert, Success, LocalizedMessage} = answer[1];
            if (Success) {
                const {symbols, subscribedSymbols} = Platform.reduxState<StoreState>().symbols;
                const {categories, symbolAlerts} = Platform.reduxState<StoreState>().alerts;
                const {category} = symbols.get(alert?.SymbolId);
                if (subscribedSymbols.has(alert?.SymbolId)) {
                    Platform.dispatch(SetCategoryAlerts({
                        category: SymbolCategory.MyWatchlist,
                        alerts: categories.get(SymbolCategory.MyWatchlist).map((al: SymbolAlert) => al.Id === alert.Id ? alert : al)
                    }));
                }
                Platform.dispatch(SetCategoryAlerts({
                    category,
                    alerts: categories.get(category).map((al: SymbolAlert) => al.Id === alert.Id ? alert : al)
                }));
                Platform.dispatch(SetCategoryAlerts({
                    category: SymbolCategory.All,
                    alerts: categories.get(SymbolCategory.All).map((al: SymbolAlert) => al.Id === alert.Id ? alert : al)
                }));
                Platform.dispatch(SetSymbolAlerts({
                    alerts: symbolAlerts.map((al: SymbolAlert) => al.Id === alert.Id ? newSymbolAlert : al)
                }));
            } else {
                this.notifyFail(LocalizedMessage);
            }
        }
    }

    public doConfirmRemoveAlert = async ({alert}: ConfirmRemoveAlertPayload) => {
        const {symbols} = Platform.reduxState<StoreState>().symbols;
        const symbol = symbols.get(alert.SymbolId);
        Platform.dispatch(ShowPopup({
            popup: {
                type: PopupType.INFO,
                icon: {type: PopupIconType.INFO},
                showClose: true,
                message: {
                    customValue: Translations.text(TranslationKey.areYouSureYouWantDelete,
                                                    Parameter.Of(TranslationParam.alertType, UiUtil.alertTabTitle(alert.SymbolAlertType).toLowerCase() + (alert.SymbolAlertType === SymbolAlertType.ChangePercent ? "%" : "")),
                                                    Parameter.Of(TranslationParam.symbolDisplayName, symbol.text)),
                },
                actions: [
                    {
                        type: PopupActionType.CANCEL,
                        text: {trKey: TranslationKey.yes},
                        action: () => {
                            this.doRemoveAlert({alert});
                        }
                    },
                    {
                        type: PopupActionType.OK,
                        text: {trKey: TranslationKey.no},
                    }]
            }
        }));
    }

    public doRemoveAlert = async ({alert}: RemoveAlertPayload) => {
        const languageCode: LangCode = LanguageUtil.languageCode();
        const request: DeleteSymbolAlertRequest = {
            languageCode,
            symbolAlertConfigurationId: alert.Id
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, DeleteSymbolAlertResponse] = await Utils.to(this.sendToWebProfitService(request, "DeleteSymbolAlertConfiguration"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            this._logger.debug("Failed delete alert");
            XhrUtil.notifyReject(answer[0]);
        } else {
            const {Success, LocalizedMessage} = answer[1];
            if (Success) {
                const {symbols, subscribedSymbols} = Platform.reduxState<StoreState>().symbols;
                const {symbolAlerts} = Platform.reduxState<StoreState>().alerts;
                const {category} = symbols.get(alert?.SymbolId);
                if (subscribedSymbols.has(alert?.SymbolId)) {
                    this.doRemoveAlertInternal(SymbolCategory.MyWatchlist, alert);
                }
                this.doRemoveAlertInternal(SymbolCategory.All, alert);
                this.doRemoveAlertInternal(category, alert);
                Platform.dispatch(SetSymbolAlerts({alerts: symbolAlerts.filter(al => al.Id !== alert.Id)}));
            } else {
                this.notifyFail(LocalizedMessage);
            }
        }
    }

    private doRemoveAlertInternal = (category: string, alert: SymbolAlert): void => {
        const {categories, selectedAlerts} = Platform.reduxState<StoreState>().alerts;
        const alerts: SymbolAlert[] = categories.get(category).filter(al => al.Id !== alert.Id)
        Platform.dispatch(SetCategoryAlerts({
            category,
            alerts
        }));
        const selectedAlert: SymbolAlert = selectedAlerts.get(category);
        if (Utils.isNull(selectedAlert) || Utils.isArrayNotEmpty(alerts)) {
            Platform.dispatch(SelectAlert({
                alert: alerts[0],
                category
            }));
        } else {
            Platform.dispatch(SelectAlert({alert: null, category}));
        }
    }

    public doSetAlertsTradeColumn = async (payload: SetAlertsTradeColumnPayload) => {
        const quotes = Platform.reduxState<StoreState>().quotes.quotes;
        const {categories} = Platform.reduxState<StoreState>().alerts;
        const {category, tradeColumn} = payload;
        this._logger.debug("Set alert trade column: " + tradeColumn + " for category: " + category);
        const newAlerts: SymbolAlert[] = [...categories.get(category)];
        newAlerts.sort(SortAlerts(tradeColumn, SortDirection.Ask, quotes));
        Platform.dispatch(SetSortedAlerts({
            category,
            tradeColumn,
            sortDirection: SortDirection.Ask,
            alerts: newAlerts
        }));
    }

    public doToggleAlertsTradeColumn = async (payload: ToggleAlertsColumnSortPayload) => {
        const quotes = Platform.reduxState<StoreState>().quotes.quotes;
        const {categories, tables} = Platform.reduxState<StoreState>().alerts;
        const {category, tradeColumn} = payload;
        const tableState: TableSortState = tables.get(category);
        const sort: TSMap<TradeColumn, SortDirection> = tableState.sort;
        const sortDirection: SortDirection = sort.get(tradeColumn);
        const resultDirection: SortDirection = sortDirection ? SortDirection.reverse(sortDirection) : SortDirection.Ask;
        this._logger.debug("Toggle alerts trade column: " + tradeColumn + " for category: " + category + " result direction: " + resultDirection);
        const newAlerts: SymbolAlert[] = [...categories.get(category)];
        newAlerts.sort(SortAlerts(tradeColumn, resultDirection, quotes));
        Platform.dispatch(SetSortedAlerts({
            category,
            tradeColumn,
            sortDirection: resultDirection,
            alerts: newAlerts
        }));
    }

    private sendToWebProfitService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "WebProfitServer/WebProfitClientService.svc/json/" + path, null, true);
    }
}
