import Utils from "platform/util/Utils";
import {MarginEventType} from "enum/MarginEventType";

enum BannerType {

    FinalizeRegistration = "FinalizeRegistration",
    ApplyForProfessional = "Professional",
    VerifyIdentity = "VerifyIdentity",
    Deposit = "Deposit",
    AccountVerification = "AccountVerification",
    InAppropriate = "InAppropriate",
    WithdrawalCancel = "WithdrawalCancel",
    UploadDocuments = "UploadDocuments",
    PendingWithdrawal = "PendingWithdrawal",
    MarginCall = "MarginCall",
    Liquidation = "Liquidation",
    BOChat = "BOChat",
    BOChatReopen = "BOChatReopen",
    SignDeclaration = "SignDeclaration",
    QuantumAccount = "QuantumAccount",
}

namespace BannerType {

    export function fromMarginEvent(type: MarginEventType): BannerType {
        if (Utils.isNotNull(type)) {
            switch (type) {
                case MarginEventType.MarginCall:
                    return BannerType.MarginCall;
                case MarginEventType.Liquidation:
                    return BannerType.Liquidation;
            }
        }
        return null;
    }

    export function IsLiquidation(type: BannerType): boolean {
        if (type) {
            switch (type) {
                case BannerType.Liquidation:
                case BannerType.MarginCall:
                    return true;
            }
        }
        return false;
    }

    export function IsTopLevel(type: BannerType): boolean {
        if (type) {
            switch (type) {
                case BannerType.BOChat:
                case BannerType.BOChatReopen:
                    return true;
            }
        }
        return IsLiquidation(type);
    }
}

export {
    BannerType
};
