import {ActionCreatorDefinition, createAction} from "redux-ts";
import {TradeType} from "protocol/trade/TradeType";
import {DealForm} from "core/redux/deal/DealReduxState";
import {Ranges} from "protocol/trade/Ranges";
import {PositionDirection} from "platform/protocol/enum/PositionDirection";
import DealEditTarget from "enum/DealEditTarget";
import TradingModalType from "enum/TradingModalType";
import {TradeManagerTab} from "enum/TradeManagerTab";
import {SymbolDetails} from "protocol/symbol/GetSymbolDetailsResponse";
import {RouteType} from "core/router/Routes";
import {SkipAbleValidation} from "protocol/trade/SkippableValidation";

export const SetTradeManagerTabType: string =  "SetTradeManagerTab";
export const SetSymbolDetailsType: string =  "SetSymbolDetails";
export const SetDealRangesType: string =  "SetDealRanges";
export const ClearDealFormRangesType: string =  "ClearDealFormRanges";
export const DealOpenEditInitializationType: string =  "DealOpenEditInitialization";
export const GoingOpenDealType: string =  "GoingOpenDeal";
export const GoingEditDealType: string =  "GoingEditDeal";
export const UpdateDealFormType: string =  "UpdateDealForm";
export const OpenDealType: string =  "OpenDeal";
export const EditDealType: string =  "EditDeal";
export const SetDealServerErrorType: string =  "SetDealServerError";
export const SetDealFormInProgressType: string =  "SetDealFormInProgress";
export const CloseDealsType: string =  "CloseDeals";
export const RollPositionsType: string =  "RollPositions";
export const SetPositionTabDealIdType: string =  "SetPositionTabDealId";

export interface SetTradeManagerTabPayload {

    tab: TradeManagerTab;
}

export interface SetSymbolDetailsPayload {

    details: SymbolDetails;
}

export interface SetDealRangesPayload {

    symbolId: number;
    ranges: Ranges;
    isInvestAccount: boolean;
}

export interface GoingOpenDealPayload {

    SymbolId: number;
    SignalId?: number;
    Direction: PositionDirection;
    tradeType?: TradeType;
    orderRate?: number;
    stopLoss?: number;
    takeProfit?: number;
    comments?: string;
    isSetAlert?: boolean;
    action?: (routeType?: RouteType) => void;
}

export interface GoingEditDealPayload {

    DealId: number;
    tradeType: TradeType;
    dealEditTarget?: DealEditTarget;
    action?: () => void;
}

export interface DealOpenEditInitializationPayload {

    pending: boolean;
}

export interface UpdateDealFormPayload {

    form: DealForm;
}

export interface OpenDealPayload {

    action?: () => void;
    actionInsufficientFunds?: (routeType: RouteType) => void;
}

export interface EditDealPayload {

    callback?: (DealId: number) => void;
}

export interface SetDealServerErrorPayload {

    error: string;
}

export interface SetDealFormInProgressPayload {

    formInProgress: boolean;
}

export interface CloseDealsPayload {

    iDs: number[];
    tradeType: TradeType;
    modalType: TradingModalType;
    ValidationsToSkip?: SkipAbleValidation[];
    callback?: () => void;
}

export interface RollPositionsPayload {

    SymbolId: number;
    symbol: string;
    nextSymbol: string;
}

export interface SetPositionTabDealIdPayload {

    DealId: number;
}

export const SetTradeManagerTab: ActionCreatorDefinition<SetTradeManagerTabPayload, any> = createAction<SetTradeManagerTabPayload>(SetTradeManagerTabType);
export const SetSymbolDetails: ActionCreatorDefinition<SetSymbolDetailsPayload, any> = createAction<SetSymbolDetailsPayload>(SetSymbolDetailsType);
export const SetDealRanges: ActionCreatorDefinition<SetDealRangesPayload, any> = createAction<SetDealRangesPayload>(SetDealRangesType);
export const ClearDealFormRanges: ActionCreatorDefinition<any, any> = createAction<any>(ClearDealFormRangesType);
export const DealOpenEditInitialization: ActionCreatorDefinition<DealOpenEditInitializationPayload, any> = createAction<DealOpenEditInitializationPayload>(DealOpenEditInitializationType);
export const GoingOpenDeal: ActionCreatorDefinition<GoingOpenDealPayload, any> = createAction<GoingOpenDealPayload>(GoingOpenDealType);
export const GoingEditDeal: ActionCreatorDefinition<GoingEditDealPayload, any> = createAction<GoingEditDealPayload>(GoingEditDealType);
export const UpdateDealForm: ActionCreatorDefinition<UpdateDealFormPayload, any> = createAction<UpdateDealFormPayload>(UpdateDealFormType);
export const OpenDeal: ActionCreatorDefinition<OpenDealPayload, any> = createAction<OpenDealPayload>(OpenDealType);
export const EditDeal: ActionCreatorDefinition<EditDealPayload, any> = createAction<EditDealPayload>(EditDealType);
export const SetDealServerError: ActionCreatorDefinition<SetDealServerErrorPayload, any> = createAction<SetDealServerErrorPayload>(SetDealServerErrorType);
export const SetDealFormInProgress: ActionCreatorDefinition<SetDealFormInProgressPayload, any> = createAction<SetDealFormInProgressPayload>(SetDealFormInProgressType);
export const CloseDeals: ActionCreatorDefinition<CloseDealsPayload, any> = createAction<CloseDealsPayload>(CloseDealsType);
export const RollPositions: ActionCreatorDefinition<RollPositionsPayload, any> = createAction<RollPositionsPayload>(RollPositionsType);
export const SetPositionTabDealId: ActionCreatorDefinition<SetPositionTabDealIdPayload, any> = createAction<SetPositionTabDealIdPayload>(SetPositionTabDealIdType);
