import {ActionCreatorDefinition, createAction} from "redux-ts";
import {AuthStatus} from "core/redux/auth/AuthReduxState";
import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import {LoginResponse} from "protocol/auth/LoginResponse";
import {LoginAccountType} from "protocol/auth/LoginAccountType";
import {LoginToAccountResponse} from "protocol/auth/LoginToAccountResponse";
import {TradingAccountAction} from "enum/TradingAccountAction";
import {VerificationMethodType} from "enum/VerificationMethodType";
import {VerificationMethod} from "protocol/auth/VerificationMethod";
import {TranslationUnit} from "platform/translation/TranslationUnit";

export const HandleLoginResponseType: string =  "HandleLoginResponse";
export const SetAuthStatusType: string =  "SetAuthStatus";
export const DoLogoutConfirmationType: string =  "DoLogoutConfirmation";
export const DoLogoutType: string =  "DoLogout";
export const DoLogOffType: string =  "DoLogOff";
export const OnLoggedOutType: string =  "OnLoggedOut";
export const DoSwitchAccountType: string =  "DoSwitchAccount";
export const OnAccountChangedType: string =  "OnAccountChanged";
export const DoChangePasswordType: string =  "DoChangePassword";
export const SetChangePasswordErrorType: string =  "SetChangePasswordError";
export const DoCreateRelatedLeadType: string =  "DoCreateRelatedLead";
export const DoLoginToAccountType: string =  "DoLoginToAccount";
export const SetLoginAccountActionType: string =  "SetLoginAccountAction";
export const SetRelatedLeadUsernameType: string =  "SetRelatedLeadUsername";
export const GetSecuredDataType: string = "GetSecuredData";
export const GetUnmaskingSecuredFieldVerificationCodeType: string = "GetUnmaskingSecuredFieldVerificationCode";
export const SetT2FaVerificationEnabledType: string =  "SetT2FaVerificationEnabled"
export const SetVerificationMethodsType: string = "SetVerificationMethods";
export const SetVerificationMethodType: string = "SetVerificationMethod";
export const SetVerificationCodeIssuedType: string = "SetVerificationCodeIssued";
export const SetVerificationIsLimitReachedType: string = "SetVerificationIsLimitReached";
export const SetVerificationCodeErrorType: string = "SetVerificationCodeError";

export interface HandleLoginResponsePayload {

    response: LoginResponse;
    username: string;
}

export interface SetAuthStatusPayload {

    authStatus: AuthStatus;
}

export interface DoLogoutPayload {

    skipNotifyServer?: boolean;
}

export interface DoSwitchAccountPayload {

    accountType: NXEnvironmentType;
}

export interface DoChangePasswordPayload {

    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    onSuccess?: () => void;
}

export interface SetChangePasswordErrorPayload {

    errorMsg: string;
}

export interface DoCreateRelatedLeadPayload {

    action?: () => void;
    accountType: LoginAccountType;
}

export interface DoLoginToAccountPayload {

    accountType: LoginAccountType;
    action?: (response: LoginToAccountResponse) => void;
}

export interface SetLoginAccountActionPayload {

    action: TradingAccountAction;
}

export interface SetRelatedLeadUsernamePayload {

    username: string;
}

export interface GetSecuredDataPayload {

    VerificationCode: string;
    action?: (value: string) => void;
}

export interface GetUnmaskingSecuredFieldVerificationCodePayload {

    SendChannelType: VerificationMethodType;
    action?: () => void;
}

export interface SetT2FaVerificationEnabledPayload {

    enable2FVerification: boolean;
}

export interface SetVerificationMethodsPayload {

    methods: VerificationMethod[];
}

export interface VerificationMethodPayload {

    method: VerificationMethodType;
    ResendVerificationCode?: boolean;
}

export interface SetVerificationCodeIssuedPayload {

    issued: boolean;
}

export interface SetVerificationIsLimitReachedPayload {

    IsLimitReached: boolean;
}

export interface SetVerificationCodeErrorPayload {

    error: TranslationUnit;
}

export const HandleLoginResponse: ActionCreatorDefinition<HandleLoginResponsePayload, any> = createAction<HandleLoginResponsePayload>(HandleLoginResponseType);
export const SetAuthStatus: ActionCreatorDefinition<SetAuthStatusPayload, any> = createAction<SetAuthStatusPayload>(SetAuthStatusType);
export const DoLogoutConfirmation: ActionCreatorDefinition<any, any> = createAction<any>(DoLogoutConfirmationType);
export const DoLogout: ActionCreatorDefinition<DoLogoutPayload, any> = createAction<DoLogoutPayload>(DoLogoutType);
export const DoLogOff: ActionCreatorDefinition<any, any> = createAction<any>(DoLogOffType);
export const OnLoggedOut: ActionCreatorDefinition<any, any> = createAction<any>(OnLoggedOutType);
export const DoSwitchAccount: ActionCreatorDefinition<DoSwitchAccountPayload, any> = createAction<DoSwitchAccountPayload>(DoSwitchAccountType);
export const OnAccountChanged: ActionCreatorDefinition<any, any> = createAction<any>(OnAccountChangedType);
export const DoChangePassword: ActionCreatorDefinition<DoChangePasswordPayload, any> = createAction<DoChangePasswordPayload>(DoChangePasswordType);
export const SetChangePasswordError: ActionCreatorDefinition<SetChangePasswordErrorPayload, any> = createAction<SetChangePasswordErrorPayload>(SetChangePasswordErrorType);
export const DoCreateRelatedLead: ActionCreatorDefinition<DoCreateRelatedLeadPayload, any> = createAction<DoCreateRelatedLeadPayload>(DoCreateRelatedLeadType);
export const DoLoginToAccount: ActionCreatorDefinition<DoLoginToAccountPayload, any> = createAction<DoLoginToAccountPayload>(DoLoginToAccountType);
export const SetLoginAccountAction: ActionCreatorDefinition<SetLoginAccountActionPayload, any> = createAction<SetLoginAccountActionPayload>(SetLoginAccountActionType);
export const SetRelatedLeadUsername: ActionCreatorDefinition<SetRelatedLeadUsernamePayload, any> = createAction<SetRelatedLeadUsernamePayload>(SetRelatedLeadUsernameType);
export const GetSecuredData: ActionCreatorDefinition<GetSecuredDataPayload, any> = createAction<GetSecuredDataPayload>(GetSecuredDataType);
export const GetUnmaskingSecuredFieldVerificationCode: ActionCreatorDefinition<GetUnmaskingSecuredFieldVerificationCodePayload, any> = createAction<GetUnmaskingSecuredFieldVerificationCodePayload>(GetUnmaskingSecuredFieldVerificationCodeType);
export const SetT2FaVerificationEnabled: ActionCreatorDefinition<SetT2FaVerificationEnabledPayload, any> = createAction<SetT2FaVerificationEnabledPayload>(SetT2FaVerificationEnabledType);
export const SetVerificationMethods: ActionCreatorDefinition<SetVerificationMethodsPayload, any> = createAction<SetVerificationMethodsPayload>(SetVerificationMethodsType);
export const SetVerificationMethod: ActionCreatorDefinition<VerificationMethodPayload, any> = createAction<VerificationMethodPayload>(SetVerificationMethodType);
export const SetVerificationCodeIssued: ActionCreatorDefinition<SetVerificationCodeIssuedPayload, any> = createAction<SetVerificationCodeIssuedPayload>(SetVerificationCodeIssuedType);
export const SetVerificationIsLimitReached: ActionCreatorDefinition<SetVerificationIsLimitReachedPayload, any> = createAction<SetVerificationIsLimitReachedPayload>(SetVerificationIsLimitReachedType);
export const SetVerificationCodeError: ActionCreatorDefinition<SetVerificationCodeErrorPayload, any> = createAction<SetVerificationCodeErrorPayload>(SetVerificationCodeErrorType);
