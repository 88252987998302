export enum ProfitMessageType {

    Ping = "ping",
    Pong = "pong",

    MultiChartsReady = "onMultiChartsReady",
    MultiChartsInit = "onMultiChartsInit",
    MetricsChanged = "onMetricsChanged",
    StateChanged = "onStateChange",
}
