import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";
import {Deal} from "platform/protocol/trading/Deal";

export class IntegrationChartDealDisplay implements IntegrationMessage {

    public classId: number = IntegrationClassId.CHART_DEAL_DISPLAY;
    public deal: Deal;

    public constructor(deal: Deal) {
        this.deal = deal;
    }
}
