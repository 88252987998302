import Utils from "platform/util/Utils";

export class Clarity {

    private static _detectTimeoutHandler: any;
    private static _detectIntervalHandler: any;

    private constructor() {
    }

    public static Identify = (userId: number): void => {
        if (Utils.isNull(this._detectTimeoutHandler)) {
            this._detectTimeoutHandler = setTimeout(() => {
                clearInterval(this._detectIntervalHandler);
            }, 10000);
            this._detectIntervalHandler = setInterval(() => {
                const clarity = (window as any).clarity;
                if (Utils.isNotNull(clarity)) {
                    clearInterval(this._detectIntervalHandler);
                    clearTimeout(this._detectTimeoutHandler);
                    clarity("identify", userId?.toString(10));
                    clarity("set", "userId", userId?.toString(10));
                }
            }, 500);
        }
    }
}
