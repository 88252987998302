import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import LessonsEngine from "core/engine/LessonsEngine";
import LessonsReducer from "core/redux/lessons/LessonsReducer";

export class LessonsService implements Service<LessonsEngine, any> {

    private static _instance: LessonsService;

    private constructor() {
    }

    public static instance(): LessonsService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
    }

    public name(): string {
        return ServiceType.Lessons;
    }

    public reducers(): Array<Reducer<any>> {
        return [LessonsReducer.instance()];
    }

    public engine(): LessonsEngine {
        return LessonsEngine.instance();
    }

    public state(): any {
        return undefined;
    }
}
