import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {OnAccountChanged, OnLoggedOut} from "core/redux/auth/AuthReduxActions";
import {TSMap} from "typescript-map";
import {QuotesReduxState} from "core/redux/quotes/QuotesReduxState";
import {SetQuotes, SetQuotesPayload} from "core/redux/quotes/QuotesReduxActions";

const initialState = (): QuotesReduxState => {
    return {
        quotes: new TSMap()
    };
};

export default class QuotesReducer extends Reducer<QuotesReduxState> {

    private static _instance: QuotesReducer;

    public static instance(): QuotesReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "quotes";
    }

    protected setup(builder: ReducerBuilder<QuotesReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: QuotesReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(OnAccountChanged, (state: QuotesReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(SetQuotes, (state: QuotesReduxState, {payload}: Action<SetQuotesPayload>) => {
                return Object.assign({}, state, {
                    quotes: payload.quotes
                });
            });
    }
}
