import {BiometryType} from "react-native-biometrics";
import {ActionCreatorDefinition, createAction} from "redux-ts";
import {TranslationUnit} from "platform/translation/TranslationUnit";
import {
    ChangePasswordFormState,
    ForgotPasswordFormState,
    LoginFormState, ResetPasswordFormState,
    SignUpFormState,
    TcAgreementState
} from "core/redux/login/LoginReduxState";
import {SignUpCountry} from "protocol/auth/GetSignUpCountriesResponse";
import {RouteType} from "core/router/Routes";
import {SignUpErrorCode} from "protocol/auth/SignUpResponse";
import {LoginBiometric, MobilePlatformType} from "protocol/auth/LoginRequest";
import {VerificationMethodType} from "enum/VerificationMethodType";
import {SignUpSocialDetails} from "protocol/auth/SignUpRequest";
import {BooleanPayload} from "core/redux/StoreActions";
import {VerificationMethodPayload} from "core/redux/auth/AuthReduxActions";

export const SetLoginRouteType: string = "SetLoginRoute";
export const SaveSignUpSocialDetailsType: string = "SaveSignUpSocialDetails";
export const SaveSignUpFormType: string = "SaveSignUpForm";
export const SaveLoginFormType: string = "SaveLoginForm";
export const SaveForgotPasswordFormType: string = "SaveForgotPasswordForm";
export const SaveResetPasswordFormType: string = "SaveResetPasswordForm";
export const SaveChangePasswordFormType: string = "SaveChangePasswordForm";
export const DoSubmitSignUpFormType: string =  "DoSubmitSignUpForm";
export const DoSubmitLoginFormType: string =  "DoSubmitLoginForm";
export const DoSubmitForgotPasswordFormType: string =  "DoSubmitForgotPasswordForm";
export const DoSubmitResetPasswordFormType: string =  "DoSubmitResetPasswordForm";
export const DoSubmitChangePasswordFormType: string =  "DoSubmitChangePasswordForm";
export const DoLoginNotifyUserAgreementType: string =  "DoLoginNotifyUserAgreement";
export const FetchSignUpCountriesType: string =  "FetchSignUpCountries";
export const SetSignUpCountriesType: string =  "SetSignUpCountries";
export const SetSignUpErrorType: string =  "SetSignUpError";
export const SetLoginErrorType: string =  "SetLoginError";
export const SetForgotPasswordErrorType: string =  "SetForgotPasswordError";
export const SetResetPasswordErrorType: string =  "SetResetPasswordError";
export const SetChangePasswordErrorType: string =  "SetChangePasswordError";
export const SetChangePasswordRequiredType: string =  "SetChangePasswordRequired";
export const SetTCLoginAgreementType: string =  "SetTCLoginAgreement";
export const GetResetPasswordVerificationCodeType: string =  "GetResetPasswordVerificationCode";
export const DoVerifyResetPasswordType: string =  "DoVerifyResetPassword";
export const SetBiometricConfiguredType: string = "SetBiometricConfigured";
export const SetBiometricEnabledType: string = "SetBiometricEnabled";
export const SetBiometricNotificationMessageType: string = "SetBiometricNotificationMessage";

export interface SetLoginRoutePayload {

    route: RouteType;
}

export interface SaveSignUpSocialDetailsPayload {

    details: SignUpSocialDetails;
}

export interface SaveSignUpFormPayload {

    form: SignUpFormState;
}

export interface SaveLoginFormPayload {

    form: LoginFormState;
}

export interface SaveForgotPasswordFormPayload {

    form: ForgotPasswordFormState;
}

export interface SaveResetPasswordFormPayload {

    form: ResetPasswordFormState;
}

export interface SaveChangePasswordFormPayload {

    form: ChangePasswordFormState;
}

export interface DoSubmitSignUpFormPayload {

    form: SignUpFormState;
    socialDetails: SignUpSocialDetails;
    action: (routeType: RouteType) => void;
}

export interface DoSubmitLoginFormPayload {

    form: LoginFormState;
    mobilePlatformType: MobilePlatformType;
    biometric?: LoginBiometric;
    action: () => void;
}

export interface DoLoginNotifyUserAgreementPayload {

    mobilePlatformType: MobilePlatformType;
}

export interface DoSubmitForgotPasswordFormPayload {

    form: ForgotPasswordFormState;
    verificationMethodType: VerificationMethodType
    action: () => void;
}

export interface DoSubmitResetPasswordFormPayload {

    form: ResetPasswordFormState;
    mobilePlatformType: MobilePlatformType;
    action: () => void;
}

export interface DoSubmitChangePasswordFormPayload {

    form: ChangePasswordFormState;
    mobilePlatformType: MobilePlatformType;
    action: () => void;
}

export interface SetSignUpCountriesPayload {

    countryCodeByIP: string;
    countries: SignUpCountry[];
}

export interface SetSignUpErrorPayload {

    error: {
        errorCode?: SignUpErrorCode;
        trUnit?: TranslationUnit;
    }
}

export interface SetLoginErrorPayload {

    error: TranslationUnit;
}

export interface SetChangePasswordRequiredPayload {

    required: boolean;
}

export interface SetTCLoginAgreementPayload {

    tcAgreement: TcAgreementState;
}

export interface DoVerifyResetPasswordPayload {

    code: string;
    action: () => void;
}

export interface SetBiometricEnabledPayload {

    biometryType: BiometryType;
    biometryEnabled: boolean;
}

export const SetLoginRoute: ActionCreatorDefinition<SetLoginRoutePayload, any> = createAction<SetLoginRoutePayload>(SetLoginRouteType);
export const SaveSignUpSocialDetails: ActionCreatorDefinition<SaveSignUpSocialDetailsPayload, any> = createAction<SaveSignUpSocialDetailsPayload>(SaveSignUpSocialDetailsType);
export const SaveSignUpForm: ActionCreatorDefinition<SaveSignUpFormPayload, any> = createAction<SaveSignUpFormPayload>(SaveSignUpFormType);
export const SaveLoginForm: ActionCreatorDefinition<SaveLoginFormPayload, any> = createAction<SaveLoginFormPayload>(SaveLoginFormType);
export const SaveForgotPasswordForm: ActionCreatorDefinition<SaveForgotPasswordFormPayload, any> = createAction<SaveForgotPasswordFormPayload>(SaveForgotPasswordFormType);
export const SaveResetPasswordForm: ActionCreatorDefinition<SaveResetPasswordFormPayload, any> = createAction<SaveResetPasswordFormPayload>(SaveResetPasswordFormType);
export const SaveChangePasswordForm: ActionCreatorDefinition<SaveChangePasswordFormPayload, any> = createAction<SaveChangePasswordFormPayload>(SaveChangePasswordFormType);
export const DoSubmitSignUpForm: ActionCreatorDefinition<DoSubmitSignUpFormPayload, any> = createAction<DoSubmitSignUpFormPayload>(DoSubmitSignUpFormType);
export const DoSubmitLoginForm: ActionCreatorDefinition<DoSubmitLoginFormPayload, any> = createAction<DoSubmitLoginFormPayload>(DoSubmitLoginFormType);
export const DoSubmitForgotPasswordForm: ActionCreatorDefinition<DoSubmitForgotPasswordFormPayload, any> = createAction<DoSubmitForgotPasswordFormPayload>(DoSubmitForgotPasswordFormType);
export const DoSubmitResetPasswordForm: ActionCreatorDefinition<DoSubmitResetPasswordFormPayload, any> = createAction<DoSubmitResetPasswordFormPayload>(DoSubmitResetPasswordFormType);
export const DoSubmitChangePasswordForm: ActionCreatorDefinition<DoSubmitChangePasswordFormPayload, any> = createAction<DoSubmitChangePasswordFormPayload>(DoSubmitChangePasswordFormType);
export const DoLoginNotifyUserAgreement: ActionCreatorDefinition<DoLoginNotifyUserAgreementPayload, any> = createAction<DoLoginNotifyUserAgreementPayload>(DoLoginNotifyUserAgreementType);
export const FetchSignUpCountries: ActionCreatorDefinition<any, any> = createAction<any>(FetchSignUpCountriesType);
export const SetSignUpCountries: ActionCreatorDefinition<SetSignUpCountriesPayload, any> = createAction<SetSignUpCountriesPayload>(SetSignUpCountriesType);
export const SetSignUpError: ActionCreatorDefinition<SetSignUpErrorPayload, any> = createAction<SetSignUpErrorPayload>(SetSignUpErrorType);
export const SetLoginError: ActionCreatorDefinition<SetLoginErrorPayload, any> = createAction<SetLoginErrorPayload>(SetLoginErrorType);
export const SetForgotPasswordError: ActionCreatorDefinition<SetLoginErrorPayload, any> = createAction<SetLoginErrorPayload>(SetForgotPasswordErrorType);
export const SetResetPasswordError: ActionCreatorDefinition<SetLoginErrorPayload, any> = createAction<SetLoginErrorPayload>(SetResetPasswordErrorType);
export const SetChangePasswordError: ActionCreatorDefinition<SetLoginErrorPayload, any> = createAction<SetLoginErrorPayload>(SetChangePasswordErrorType);
export const SetChangePasswordRequired: ActionCreatorDefinition<SetChangePasswordRequiredPayload, any> = createAction<SetChangePasswordRequiredPayload>(SetChangePasswordRequiredType);
export const SetTCLoginAgreement: ActionCreatorDefinition<SetTCLoginAgreementPayload, any> = createAction<SetTCLoginAgreementPayload>(SetTCLoginAgreementType);
export const GetResetPasswordVerificationCode: ActionCreatorDefinition<VerificationMethodPayload, any> = createAction<VerificationMethodPayload>(GetResetPasswordVerificationCodeType);
export const DoVerifyResetPassword: ActionCreatorDefinition<DoVerifyResetPasswordPayload, any> = createAction<DoVerifyResetPasswordPayload>(DoVerifyResetPasswordType);
export const SetBiometricConfigured: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetBiometricConfiguredType);
export const SetBiometricEnabled: ActionCreatorDefinition<SetBiometricEnabledPayload, any> = createAction<SetBiometricEnabledPayload>(SetBiometricEnabledType);
export const SetBiometricNotificationMessage: ActionCreatorDefinition<BooleanPayload, any> = createAction<BooleanPayload>(SetBiometricNotificationMessageType);
