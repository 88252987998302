import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import InboxReducer from "core/redux/inbox/InboxReducer";
import BOChatReducer from "core/redux/inbox/BOChatReducer";
import InboxEngine from "core/engine/InboxEngine";

export class InboxService implements Service<InboxEngine, any> {

    private static _instance: InboxService;

    private constructor() {
    }

    public static instance(): InboxService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Inbox;
    }

    public reducers(): Array<Reducer<any>> {
        return [InboxReducer.instance(), BOChatReducer.instance()];
    }

    public engine(): InboxEngine {
        return InboxEngine.instance();
    }

    public state(): any {
        return undefined;
    }
}
