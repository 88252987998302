import moment from "moment-mini";
import {ActionCreatorDefinition, createAction} from "redux-ts";
import {PortfolioType} from "enum/PortfolioType";
import {FilterDateType} from "core/redux/filter/FilterReduxState";
import {RouteType} from "core/router/Routes";
import {Continent} from "enum/Continent";
import {QuoteStatus} from "platform/protocol/enum/QuoteStatus";
import {EntryOrderStatus} from "platform/protocol/trading/EntryOrderStatus";

export const SetFilterVisibleType: string =  "SetFilterVisible";
export const SetPortfolioDateFilterType: string =  "SetPortfolioDateFilter";
export const UpdatePortfolioDateFilterType: string =  "UpdatePortfolioDateFilter";
export const ResetFilterType: string =  "ResetFilter";
export const OnFilterResetType: string =  "OnFilterReset";
export const SetSymbolFilterContinentType: string = "SetSymbolFilterContinent";
export const SetMarketsStatusType: string = "SetMarketsStatusType";
export const SetClosedTradesDefaultFilterType: string = "SetClosedTradesDefaultFilter";
export const SetHistoryOrderStatusType: string = "SetHistoryOrderStatus";

export interface SetFilterVisiblePayload {

    visible: boolean;
    route?: RouteType;
    activePortfolioTab?: PortfolioType;
}

export interface PortfolioDateFilterPayload {

    routeName: string;
    portfolioType: PortfolioType;
    dateType: FilterDateType;
    from?: moment.Moment;
    to?: moment.Moment;
    isMobile?: boolean;
}

export interface ResetFilterPayload {

    routeName: string;
    activePortfolioTab: PortfolioType;
    activeSymbolsCategory: string;
}

export interface SetSymbolFilterContinentPayload {

    continents: Continent[];
}

export interface SetMarketsStatusPayload {

    marketStatus: QuoteStatus;
}

export interface SetClosedTradesDefaultFilterPayload {

    dateType: FilterDateType;
}

export interface SetHistoryOrderStatusPayload {

    routeName: string;
    orderStatus: EntryOrderStatus[];
}

export const SetFilterVisible: ActionCreatorDefinition<SetFilterVisiblePayload, any> = createAction<SetFilterVisiblePayload>(SetFilterVisibleType);
export const SetPortfolioDateFilter: ActionCreatorDefinition<PortfolioDateFilterPayload, any> = createAction<PortfolioDateFilterPayload>(SetPortfolioDateFilterType);
export const UpdatePortfolioDateFilter: ActionCreatorDefinition<PortfolioDateFilterPayload, any> = createAction<PortfolioDateFilterPayload>(UpdatePortfolioDateFilterType);
export const ResetFilter: ActionCreatorDefinition<ResetFilterPayload, any> = createAction<ResetFilterPayload>(ResetFilterType);
export const OnFilterReset: ActionCreatorDefinition<PortfolioDateFilterPayload, any> = createAction<PortfolioDateFilterPayload>(OnFilterResetType);
export const SetSymbolFilterContinent: ActionCreatorDefinition<SetSymbolFilterContinentPayload, any> = createAction<SetSymbolFilterContinentPayload>(SetSymbolFilterContinentType);
export const SetMarketsStatus: ActionCreatorDefinition<SetMarketsStatusPayload, any> = createAction<SetMarketsStatusPayload>(SetMarketsStatusType);
export const SetClosedTradesDefaultFilter: ActionCreatorDefinition<SetClosedTradesDefaultFilterPayload, any> = createAction<SetClosedTradesDefaultFilterPayload>(SetClosedTradesDefaultFilterType);
export const SetHistoryOrderStatus: ActionCreatorDefinition<SetHistoryOrderStatusPayload, any> = createAction<SetHistoryOrderStatusPayload>(SetHistoryOrderStatusType);
