import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import {DepositButtonActionType} from "protocol/sso/DepositButtonActionType";
import Utils from "platform/util/Utils";
import Platform from "platform/Platform";
import {StorageKey} from "enum/StorageKey";

export class AccountState {

    private static _instance: AccountState;
    private _username: string;
    private _userId: number;
    private _accountId: number;
    private _accountType: NXEnvironmentType;
    private _depositButtonType: DepositButtonActionType;
    private _disclaimerAcceptedForExternalProvidersLiveAccount: boolean;
    private _isFractionalAmountAllowed: boolean;
    private _sessionStartTime: number = 0;
    private _signalsCustomerSupportInActivityHandler: any;

    private constructor() {
    }

    public static instance(): AccountState {
        return this._instance || (this._instance = new this());
    }

    public setSignalsCustomerSupportInActivity(timeout: number, onTimeout: () => void): void {
        Platform.storage().setItem(StorageKey.SignalsUnlockTime, new Date().getTime().toString());
        this.setSignalsCustomerSupportInActivityInternal(timeout, onTimeout);
    }

    private setSignalsCustomerSupportInActivityInternal(timeout: number, onTimeout: () => void): void {
        clearTimeout(this._signalsCustomerSupportInActivityHandler);
        this._signalsCustomerSupportInActivityHandler = setTimeout(() => {
            Platform.storage().removeItem(StorageKey.SignalsUnlockTime);
            this._signalsCustomerSupportInActivityHandler = null;
            if (onTimeout) {
                onTimeout();
            }
        }, timeout);
    }

    public async setSignalsCustomerSupportInActivityIfNeed(timeout: number, onTimeout: () => void): Promise<boolean> {
        const v: string = await Platform.storage().getItem(StorageKey.SignalsUnlockTime);
        const UnlockTime: number = parseInt(v);
        if (Utils.greaterThen0(UnlockTime)) {
            const delta: number = (UnlockTime + timeout) - new Date().getTime();
            if (delta > 0) {
                this.setSignalsCustomerSupportInActivityInternal(timeout, onTimeout);
                return true;
            }
        }
        return false;
    }

    public hasSignalsCustomerSupportInActivity(): boolean {
        return Utils.isNotNull(this._signalsCustomerSupportInActivityHandler);
    }

    public get username(): string {
        return this._username;
    }

    public set username(value: string) {
        this._username = value;
    }

    public get userId(): number {
        return this._userId;
    }

    public set userId(value: number) {
        this._userId = value;
    }

    public get accountId(): number {
        return this._accountId;
    }

    public set accountId(value: number) {
        this._accountId = value;
    }

    public get accountType(): NXEnvironmentType {
        return this._accountType;
    }

    public set accountType(value: NXEnvironmentType) {
        this._accountType = value;
    }

    public set depositButtonType(value: DepositButtonActionType) {
        this._depositButtonType = value;
    }

    public getDepositButtonType() : DepositButtonActionType {
        return this._depositButtonType;
    }

    public get disclaimerAcceptedForExternalProvidersLiveAccount(): boolean {
        return this._disclaimerAcceptedForExternalProvidersLiveAccount;
    }

    public set disclaimerAcceptedForExternalProvidersLiveAccount(value: boolean) {
        this._disclaimerAcceptedForExternalProvidersLiveAccount = value;
    }

    public get isFractionalAmountAllowed(): boolean {
        return this._isFractionalAmountAllowed;
    }

    public set isFractionalAmountAllowed(value: boolean) {
        this._isFractionalAmountAllowed = value;
    }

    public get sessionStartTime(): number {
        return this._sessionStartTime;
    }

    public set sessionStartTime(value: number) {
        this._sessionStartTime = value;
    }

    public clear(): void {
        clearTimeout(this._signalsCustomerSupportInActivityHandler);
        this._signalsCustomerSupportInActivityHandler = null;
        this._userId = null;
        this._accountId = null;
        this._accountType = null;
        this._depositButtonType = null;
        this._disclaimerAcceptedForExternalProvidersLiveAccount = null;
        this._isFractionalAmountAllowed = null;
    }
}
