import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import ChartReducer from "core/redux/chart/ChartReducer";
import {ChartState} from "core/state/ChartState";
import ChartEngine from "core/engine/ChartEngine";

export class ChartService implements Service<ChartEngine, ChartState> {

    private static _instance: ChartService;

    private constructor() {
    }

    public static instance(): ChartService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Chart;
    }

    public reducers(): Array<Reducer<any>> {
        return [ChartReducer.instance()];
    }

    public engine(): ChartEngine {
        return ChartEngine.instance();
    }

    public state(): ChartState {
        return ChartState.instance();
    }
}
