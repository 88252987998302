import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {OnAccountChanged, OnLoggedOut} from "core/redux/auth/AuthReduxActions";
import {Trade, TradesReduxState} from "core/redux/trades/TradesReduxState";
import {
    SelectDeal,
    SelectDealPayload,
    SetPositionsOrders,
    SetPositionsOrdersPayload,
    SetPositionsOrdersType,
    SetTrades,
    SetTradesPayload,
    SortTrades,
    SortTradesPayload
} from "core/redux/trades/TradesReduxActions";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import TradesEngine, {SortDeals, SortTrades as EngineSortTrades} from "core/engine/TradesEngine";
import {PortfolioType} from "enum/PortfolioType";
import Utils from "platform/util/Utils";
import {Deal} from "platform/protocol/trading/Deal";
import {TSMap} from "typescript-map";
import {Routes} from "core/router/Routes";

const initialState = (): TradesReduxState => {
    return {
        OpenPositionsMap: {},
        EntryOrdersMap: {},
        OverviewPositions: [],
        OpenPositions: [],
        EntryOrders: [],
        Trades: [],
        Exposures: [],
        selectedDeals: new TSMap<PortfolioType, Deal>()
    };
};

export default class TradesReducer extends Reducer<TradesReduxState> {

    private static _instance: TradesReducer;

    public static instance(): TradesReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const tradesEngine: TradesEngine = Platform.engine(ServiceType.Trades);
        this._middlewareActions.set(SetPositionsOrdersType, tradesEngine.onSetPositionsOrders);
    }

    public get name(): string {
        return "trades";
    }

    protected setup(builder: ReducerBuilder<TradesReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: TradesReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(OnAccountChanged, (state: TradesReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(SetPositionsOrders, (state: TradesReduxState, {payload}: Action<SetPositionsOrdersPayload>) => {
                return Object.assign({}, state, {
                    OpenPositions: payload.OpenPositions,
                    Exposures: payload.Exposures
                });
            })
            .handle(SetTrades, (state: TradesReduxState, {payload}: Action<SetTradesPayload>) => {
                return Object.assign({}, state, {
                    OpenPositionsMap: payload.OpenPositionsMap,
                    EntryOrdersMap: payload.EntryOrdersMap,
                    EntryOrders: payload.EntryOrders,
                    OverviewPositions: payload.OverviewPositions,
                    Trades: payload.trades,
                    selectedDeals: payload.selectedDeals
                });
            })
            .handle(SelectDeal, (state: TradesReduxState, {payload}: Action<SelectDealPayload>) => {
                const deals: {[key: number]: Deal} = payload.portfolioType === PortfolioType.OpenTrades ? state.OpenPositionsMap
                    : payload.portfolioType === PortfolioType.EntryOrders ? state.EntryOrdersMap : null;
                if (deals) {
                    const deal: Deal = deals[payload.id];
                    if (deal) {
                        const newState: TradesReduxState = Utils.merge({}, state);
                        newState.selectedDeals.set(payload.portfolioType, deal);
                        return newState;
                    }
                }
                return state;
            })
            .handle(SortTrades, (state: TradesReduxState, {payload}: Action<SortTradesPayload>) => {
                if (!Routes.IsPartnerRoute(payload.routeName)) {
                    if (payload.portfolioType === PortfolioType.OpenTrades) {
                        const Trades: Trade[] = [...state.Trades];
                        Trades.sort(EngineSortTrades(payload.column, payload.sortDirection));
                        Trades.forEach((trade: Trade) => {
                            if (Utils.isArrayNotEmpty(trade.trades)) {
                                trade.trades = [...trade.trades];
                                trade.trades.sort(EngineSortTrades(payload.column, payload.sortDirection));
                            }
                        });
                        return Object.assign({}, state, {
                            Trades
                        });
                    } else if (payload.portfolioType === PortfolioType.EntryOrders) {
                        return Object.assign({}, state, {
                            EntryOrders: [...state.EntryOrders].sort(SortDeals(payload.column, payload.sortDirection))
                        });
                    }
                }
                return state;
            });
    }
}
