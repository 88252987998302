import {ProfitMessage} from "core/chart/integration/message/profit/ProfitMessage";
import {ProfitMessageType} from "core/chart/integration/message/profit/ProfitMessageType";
import {LangCode} from "platform/enum/LangCode";

export class ProfitChartsInit implements ProfitMessage {

    public type: ProfitMessageType = ProfitMessageType.MultiChartsInit;
    public origin: string;
    public accountId: string;
    public ctoken: string;
    public environmentType: string | number;
    public language: LangCode;

    constructor(origin: string, accountId: string, ctoken: string, language: LangCode, environmentType: string | number) {
        this.origin = origin;
        this.accountId = accountId;
        this.ctoken = ctoken;
        this.language = language;
        this.environmentType = environmentType;
    }
}
