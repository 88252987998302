export enum DealingLogType {

    Transactions = 1,
    PositionHistory = 2,
    EntryOrderCreation = 3,
    EntryOrderCancellation = 4,
    MarginEvents = 5,
    TradeValidationErrors = 6,
    PendingWithdrawalRequests = 7,
    RejectedWithdrawalRequests = 8,
    FailedDeposits = 9,
    SessionEvents = 10,
    WithdrawableEquity = 11,
    ApprovingOfflinePositions = 12,
    ApprovingOfflineEntryOrders = 13,
    CanceledPosition = 14
}
