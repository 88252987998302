enum TradeManagerTab {

    Trade = "trade",
    Info = "info",
    Chart = "chart",
    Signals = "signals",
    Alerts = "alerts",
    News = "news",
    Events = "events",
    Promotions = "promotions",
    Positions = "positions"
}
export {
    TradeManagerTab
};
