import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";
import {ThemeType} from "platform/enum/ThemeType";

export class IntegrationSetTheme implements IntegrationMessage {

    public classId: number = IntegrationClassId.SET_THEME;
    public themeType: ThemeType;

    public constructor(themeType: ThemeType) {
        this.themeType = themeType;
    }
}
