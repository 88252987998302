import {StorageKey} from "enum/StorageKey";
import Utils from "platform/util/Utils";
import {LSKey} from "platform/storage/Storage";
import {ThemeType} from "platform/enum/ThemeType";
import {SignalsSettingsType} from "enum/SignalsSettingsType";
import {NotificationTab} from "enum/NotificationTab";

export class Preference<T> {

    private _value: T;

    public set value(value: T) {
        this._value = value;
    }

    public get value(): T {
        return this._value;
    }

    public static Of = <T>(value: T): Preference<T> => {
        const preference: Preference<T> = new Preference();
        preference.value = value;
        return preference;
    }

    public static serialize = <T>(key: StorageKey | LSKey, preference: Preference<T>): string => {
        if (
            key === StorageKey.AcademyWatchedVideos
            || key === StorageKey.AcademyPassedWalkThroughS
            || key === StorageKey.SignalsSettings
        ) {
            return JSON.stringify(preference.value);
        }
        return preference.value.toString();
    }

    public static deserialize = <T>(key: StorageKey | LSKey, value: string): Preference<T> => {
        const preference: Preference<T> = new Preference<T>();
        if (key === LSKey.Theme) {
            preference.value = ThemeType.deserialize(value) as any;
        } else if (key === StorageKey.InboxTab) {
            preference.value = NotificationTab.deserialize(value) as any;
        } else if (
            key === StorageKey.CompactListView ||
            key === StorageKey.PopupSound ||
            key === StorageKey.Emails ||
            key === StorageKey.PhoneCalls ||
            key === StorageKey.PushNotifications ||
            key === StorageKey.SMS ||
            key === StorageKey.ShowFinancialInfoPanel
        ) {
            preference.value = Utils.parseBoolean(value) as any;
        } else if (key === StorageKey.AcademyWatchedVideos || key === StorageKey.AcademyPassedWalkThroughS) {
            try {
                preference.value = JSON.parse(value);
            } catch (e) {
            }
        } else if (key === StorageKey.SignalsSettings) {
            preference.value = SignalsSettingsType.deserialize(value) as any;
        }
        return preference;
    }
}
