import {ProfitMessage} from "core/chart/integration/message/profit/ProfitMessage";
import {ProfitMessageType} from "core/chart/integration/message/profit/ProfitMessageType";
import {Quote} from "platform/protocol/trading/Quote";
import {Deal} from "platform/protocol/trading/Deal";
import {GetCurrentChartQuotesResponse} from "platform/protocol/trading/chart/GetCurrentChartQuotesResponse";

export class ProfitStateChanged implements ProfitMessage {

    public type: ProfitMessageType = ProfitMessageType.StateChanged;
    public quotes: Quote[];
    public positions: Deal[];
    public currentChartQuotes: GetCurrentChartQuotesResponse[];

    constructor(quotes: Quote[], positions: Deal[], currentChartQuotes: GetCurrentChartQuotesResponse[]) {
        this.quotes = quotes;
        this.positions = positions;
        this.currentChartQuotes = currentChartQuotes;
    }
}
