import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {OnAccountChanged, OnLoggedOut} from "core/redux/auth/AuthReduxActions";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {AlertsCounter, AlertsReduxState} from "core/redux/alerts/AlertsReduxState";
import AlertsEngine from "core/engine/AlertsEngine";
import {
    AddAlertType,
    ConfirmRemoveAlertType,
    FetchCategoryAlertsType,
    FetchSymbolAlertsType,
    RemoveAlertType,
    SelectAlert,
    SelectAlertPayload, SetAlertsCategoryLoading,
    SetAlertsLoading,
    SetAlertsLoadingPayload,
    SetAlertsTab,
    SetAlertsTablesLS,
    SetAlertsTablesLSPayload,
    SetAlertsTabPayload,
    SetAlertsTradeColumnType,
    SetAlertToEdit,
    SetAlertToEditPayload,
    SetCategoryAlerts,
    SetCategoryAlertsPayload,
    SetSortedAlerts,
    SetSortedAlertsPayload,
    SetSymbolAlerts,
    SetSymbolAlertsPayload,
    ToggleAlertsColumnSortType,
    UpdateAlertType,
} from "core/redux/alerts/AlertsReduxActions";
import {SetSymbols, SetSymbolsPayload} from "core/redux/symbols/SymbolsReduxActions";
import {TSMap} from "typescript-map";
import {SymbolCategory} from "enum/SymbolCategory";
import {TableSortState} from "core/redux/common/TableSortState";
import Utils from "platform/util/Utils";
import {StorageKey} from "enum/StorageKey";
import {SymbolAlert} from "protocol/alerts/SymbolAlert";
import {SetTradeManagerTab, SetTradeManagerTabPayload} from "core/redux/deal/DealReduxActions";

const initialState = (): AlertsReduxState => {
    return {
        activeCategoryTab: SymbolCategory.All,
        categories: new TSMap<string, SymbolAlert[]>(),
        counters: new TSMap<string, AlertsCounter>(),
        tables: new TSMap<string, TableSortState>(),
        selectedAlerts: new TSMap<string, SymbolAlert>(),
        symbolAlerts: [],
        alertsLoaded: true,
    };
};

export default class AlertsReducer extends Reducer<AlertsReduxState> {

    private static _instance: AlertsReducer;

    public static instance(): AlertsReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const alertsEngine: AlertsEngine = Platform.engine(ServiceType.Alerts);
        this._middlewareActions.set(FetchSymbolAlertsType, alertsEngine.doFetchSymbolAlerts);
        this._middlewareActions.set(FetchCategoryAlertsType, alertsEngine.doGetCategoryAlerts);
        this._middlewareActions.set(AddAlertType, alertsEngine.doAddAlert);
        this._middlewareActions.set(UpdateAlertType, alertsEngine.doUpdateAlert);
        this._middlewareActions.set(ConfirmRemoveAlertType, alertsEngine.doConfirmRemoveAlert);
        this._middlewareActions.set(RemoveAlertType, alertsEngine.doRemoveAlert);
        this._middlewareActions.set(SetAlertsTradeColumnType, alertsEngine.doSetAlertsTradeColumn);
        this._middlewareActions.set(ToggleAlertsColumnSortType, alertsEngine.doToggleAlertsTradeColumn);
    }

    public get name(): string {
        return "alerts";
    }

    protected setup(builder: ReducerBuilder<AlertsReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: AlertsReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    activeCategoryTab: state.activeCategoryTab,
                    tables: state.tables,
                };
            })
            .handle(OnAccountChanged, (state: AlertsReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    activeCategoryTab: state.activeCategoryTab,
                    tables: state.tables,
                };
            })
            .handle(SetSymbols, (state: AlertsReduxState, {payload}: Action<SetSymbolsPayload>) => {
                const newState: AlertsReduxState = Utils.merge({}, state);
                const categories: TSMap<string, SymbolAlert[]> = new TSMap<string, SymbolAlert[]>();
                const counters: TSMap<string, AlertsCounter> = new TSMap<string, AlertsCounter>();
                const tables: TSMap<string, TableSortState> = state.tables.clone();
                newState.categories = categories;
                newState.counters = counters;
                newState.tables = tables;
                const countersKeys: string[] = payload.counters.keys() || [];
                countersKeys.unshift(SymbolCategory.All);
                countersKeys.forEach((category: string) => {
                    const symbolAlerts = state.symbolAlerts;
                    categories.set(category, symbolAlerts);
                    counters.set(category, {categoryName: category, count: 0});
                    if (!tables.has(category)) {
                        tables.set(category, SymbolCategory.defaultTableState());
                    }
                });
                return newState;
            })
            .handle(SetCategoryAlerts, (state: AlertsReduxState, {payload}: Action<SetCategoryAlertsPayload>) => {
                const {category, alerts, counters} = payload;
                const newState: AlertsReduxState = Utils.merge({}, state);
                newState.categories.set(category, alerts);
                if (Utils.isMapNotEmpty(counters)) {
                    newState.counters = counters;
                } else {
                    newState.counters.set(category, {categoryName: category, count: alerts.length});
                }
                return newState;
            })
            .handle(SetAlertsTab, (state: AlertsReduxState, {payload}: Action<SetAlertsTabPayload>) => {
                return Object.assign({}, state, {
                    activeCategoryTab: payload.category
                });
            })
            .handle(SetAlertsTablesLS, (state: AlertsReduxState, {payload}: Action<SetAlertsTablesLSPayload>) => {
                return Object.assign({}, state, {
                    tables: payload.tables
                });
            })
            .handle(SetSortedAlerts, (state: AlertsReduxState, {payload}: Action<SetSortedAlertsPayload>) => {
                const {category, tradeColumn, sortDirection, alerts} = payload;
                const newState: AlertsReduxState = Utils.merge({}, state);
                const tables: TSMap<string, TableSortState> = newState.tables;
                const tableState: TableSortState = tables.get(category);
                tableState.activeColumn = tradeColumn;
                tableState.sort.set(tradeColumn, sortDirection);
                SymbolCategory.serialize(StorageKey.AlertsSort, tables);
                const map: TSMap<string, SymbolAlert[]> = newState.categories;
                map.set(category, alerts);
                return newState;
            })
            .handle(SelectAlert, (state: AlertsReduxState, {payload}: Action<SelectAlertPayload>) => {
                const {alert} = payload;
                const newState: AlertsReduxState = Utils.merge({}, state);
                newState.selectedAlerts.set(payload.category || state.activeCategoryTab, alert);
                return newState;
            })
            .handle(SetSymbolAlerts, (state: AlertsReduxState, {payload}: Action<SetSymbolAlertsPayload>) => {
                return Object.assign({}, state, {
                    symbolAlerts: payload.alerts
                });
            })
            .handle(SetAlertToEdit, (state: AlertsReduxState, {payload}: Action<SetAlertToEditPayload>) => {
                return Object.assign({}, state, {
                    alertToEdit: payload.alert
                });
            })
            .handle(SetTradeManagerTab, (state: AlertsReduxState, {payload}: Action<SetTradeManagerTabPayload>) => {
                return Object.assign({}, state, {
                    alertToEdit: null
                });
            })
            .handle(SetAlertsLoading, (state: AlertsReduxState, {payload}: Action<SetAlertsLoadingPayload>) => {
                return Object.assign({}, state, {
                    alertsLoaded: payload.alertsLoaded
                });
            })
            .handle(SetAlertsCategoryLoading, (state: AlertsReduxState, {payload}: Action<SetAlertsLoadingPayload>) => {
                return Object.assign({}, state, {
                    alertsCategoryLoaded: payload.alertsLoaded
                });
            });
    }
}
