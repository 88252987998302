export interface DealingRoomAction {

    TargetId: number;
    DealingRoomActionTarget: DealingRoomActionTpe;
}

export enum DealingRoomActionTpe {

    MarketOrder = "Position",
    EntryOrder = "EntryOrder"
}
