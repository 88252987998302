import {TSMap} from "typescript-map";
import {Deal} from "platform/protocol/trading/Deal";

export class TradeState {

    private static _instance: TradeState;
    private _orders: TSMap<number, Deal> = new TSMap<number, Deal>();

    private constructor() {

    }

    public static instance(): TradeState {
        return this._instance || (this._instance = new this());
    }

    public setPositionPerId(order: Deal): void {
        this._orders.set(order.RelatedPositionId, order);
    }

    public getPositionPerId(positionId: number): Deal {
        return this._orders.get(positionId);
    }
}
