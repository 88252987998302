import {AbstractIntegration} from "platform/integration/AbstractIntegration";
import {Win} from "platform/integration/win/Win";
import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import Utils from "platform/util/Utils";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";
import {IntegrationWinInit} from "platform/integration/message/handshake/IntegrationWinInit";
import {WinType} from "platform/integration/win/WinType";
import {WinCloseParams, WinCloseReason, WinHandshakeType, WinManageParams} from "platform/integration/win/Windows";
import WebUtil from "platform/util/WebUtil";
import {IntegrationPageReady} from "platform/integration/message/IntegrationPageReady";
import {IntegrationWinClose} from "platform/integration/message/IntegrationWinClose";
import {EventType} from "platform/enum/EventType";

export class WinManager extends AbstractIntegration {

    private readonly _manageParams: WinManageParams;
    private readonly _handshake: WinHandshakeType;
    private _timeout: any;
    private _handleReady: boolean;

    constructor(win: Win, manageParams: WinManageParams) {
        Utils.checkNotNull(win);
        Utils.checkNotNull(manageParams);
        super(manageParams.provider, win, manageParams.uid);
        this._manageParams = manageParams;
        this._handshake = manageParams.handshakeType || WinHandshakeType.POST_MESSAGE;
        const isIframe: boolean = win.type() === WinType.FRAME;
        if (isIframe) {
            if (Utils.isNotNull(manageParams.onReadyTimeout)) {
                this._timeout = setTimeout(() => {
                    this._logger.warn("Timed out handshake for frame: " + manageParams.uid + " closing it.");
                    manageParams.onReadyTimeout();
                }, 30000);
            }
        } else {
            win.addEventListener(EventType.BeforeUnload, () => {
                this._logger.info("Win beforeunload for uid " + manageParams.uid);
                this.close();
            });
        }
        if (this._handshake === WinHandshakeType.ORIGIN) {
            this.win().addEventListener(isIframe ? EventType.Load : EventType.OnLoad, () => {
                this._logger.debug("Win loaded: " + this.uId());
                if (Utils.isNotNull(manageParams.onReady)) {
                    manageParams.onReady();
                }
            });
        }
        this.connect();
    }

    public close(params?: WinCloseParams): void {
        super.close();
        this.clearReadyTimeout();
        if (Utils.isNotNull(this._manageParams.onClose)) {
            this._manageParams.onClose(params || {
                reason: WinCloseReason.PLATFORM
            });
        }
    }

    private uidMatch(message: IntegrationMessage, origin: string): boolean {
        if (this._handshake === WinHandshakeType.POST_MESSAGE) {
            return this.uId() === message.uid;
        } else {
            return origin === WebUtil.domainUrl(this.win().href());
        }
    }

    private clearReadyTimeout(): void {
        if (Utils.isNotNull(this._timeout)) {
            clearTimeout(this._timeout);
            this._timeout = null;
        }
    }

    public onMessage(message: IntegrationMessage, origin: string): void {
        const classId: number = message.classId;
        if (Utils.isNotNull(classId) && this.uidMatch(message, origin)) {
            switch (classId) {
                case IntegrationClassId.PAGE_READY:
                    if (this._handshake === WinHandshakeType.POST_MESSAGE) {
                        const pageReady: IntegrationPageReady = message as IntegrationPageReady;
                        if (!this._handleReady) {
                            this._logger.debug("Win ready: " + this.uId() + " Page type: " + pageReady.pageType);
                            this.clearReadyTimeout();
                            this._handleReady = true;
                            if (Utils.isNotNull(this._manageParams.onReady)) {
                                this._manageParams.onReady();
                            }
                            this.sendMessage(new IntegrationWinInit({}));
                            super.consume(message);
                        }
                    }
                    break;
                case IntegrationClassId.WIN_INITIALIZED:
                    if (this._handshake === WinHandshakeType.POST_MESSAGE) {
                        this._logger.debug("Win initialized: " + this.uId());
                        this._handleReady = false;
                    }
                    break;
                case IntegrationClassId.WIN_CLOSE:
                    const winClose: IntegrationWinClose = message as IntegrationWinClose;
                    this._logger.debug("Asked close win. Parameters: " + JSON.stringify(winClose.parameters));
                    if (Utils.isNotNull(winClose.parameters) && winClose.parameters.confirm && Utils.isNotNull(this._manageParams.confirmClose)) {
                        this._manageParams.confirmClose().then(() => {
                            this.close({
                                ...winClose.parameters,
                                reason: WinCloseReason.CONFIRM_POST_MESSAGE
                            });
                        }).catch(() => {
                            this._logger.debug("Cancel close win: " + this.uId());
                        });
                    } else {
                        this.close({
                            ...(winClose.parameters || {}),
                            reason: WinCloseReason.POST_MESSAGE
                        });
                    }
                    break;
                default:
                    super.consume(message);
                    break;
            }
        }
    }
}
