import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {OnAccountChanged, OnLoggedOut} from "core/redux/auth/AuthReduxActions";
import {SortTrades, SortTradesPayload} from "core/redux/trades/TradesReduxActions";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import TradesEngine, {SortDeals} from "core/engine/TradesEngine";
import {ClosedTradesReduxState, PaginationDealsTable} from "core/redux/trades/ClosedTradesReduxState";
import {
    FetchClosedPositionsType,
    FetchClosedTradeType,
    SetClosedTradeDetail, SetClosedTradeDetailPayload,
    SetClosedTrades,
    SetOrdersHistory, PaginationDealsTablePayload,
    SetOrderDetail, SetOrderDetailPayload, FetchOrderByIdType, LoadMoreHistoryOrdersType, LoadMoreClosedTradesType
} from "core/redux/trades/ClosedTradesReduxActions";
import {RefreshPortfolioTabType, SetPortfolioTabType} from "core/redux/portfolio/PortfolioReduxActions";
import {
    SetHistoryOrderStatusType,
    SetPortfolioDateFilterType,
    OnFilterResetType
} from "core/redux/filter/FilterReduxActions";
import Utils from "platform/util/Utils";
import {PortfolioType} from "enum/PortfolioType";
import {Routes} from "core/router/Routes";

const DefaultPaginationDealsTable: PaginationDealsTable = {
    deals: [],
    page: 0,
    hasMore: false
};

const initialState = (): ClosedTradesReduxState => {
    return {
        ClosedTradesTable: Utils.deepCopy(DefaultPaginationDealsTable),
        OrdersHistoryTable: Utils.deepCopy(DefaultPaginationDealsTable)
    };
};

const MergePaginationTables = (t1: PaginationDealsTable, t2: PaginationDealsTable): PaginationDealsTable => {
    return {
        deals: Utils.isArrayNotEmpty(t2.deals) ? [...t1.deals, ...t2.deals] : Utils.isNotNull(t2.deals) ? t2.deals : t1.deals,
        page: Utils.isNotNull(t2.page) ? t2.page : t1.page,
        pending: Utils.isNotNull(t2.pending) ? t2.pending : t1.pending,
        hasMore: Utils.isNotNull(t2.hasMore) ? t2.hasMore : t1.hasMore
    };
}

export default class ClosedTradesReducer extends Reducer<ClosedTradesReduxState> {

    private static _instance: ClosedTradesReducer;

    public static instance(): ClosedTradesReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const tradesEngine: TradesEngine = Platform.engine(ServiceType.Trades);
        this._middlewareActions.set(SetPortfolioTabType, tradesEngine.onSetPortfolioTab);
        this._middlewareActions.set(RefreshPortfolioTabType, tradesEngine.onSetPortfolioTab);
        this._middlewareActions.set(SetPortfolioDateFilterType, tradesEngine.onSetPortfolioDateFilter);
        this._middlewareActions.set(OnFilterResetType, tradesEngine.onSetPortfolioDateFilter);
        this._middlewareActions.set(FetchClosedTradeType, tradesEngine.doFetchClosedTrade);
        this._middlewareActions.set(FetchClosedPositionsType, tradesEngine.onSetPortfolioTab);
        this._middlewareActions.set(SetHistoryOrderStatusType, tradesEngine.onSetHistoryOrderStatus);
        this._middlewareActions.set(FetchOrderByIdType, tradesEngine.doFetchOrderById);
        this._middlewareActions.set(LoadMoreClosedTradesType, tradesEngine.onLoadMoreClosedTrades);
        this._middlewareActions.set(LoadMoreHistoryOrdersType, tradesEngine.onLoadMoreHistoryOrders);
    }

    public get name(): string {
        return "closedTrades";
    }

    protected setup(builder: ReducerBuilder<ClosedTradesReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: ClosedTradesReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(OnAccountChanged, (state: ClosedTradesReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(SetClosedTrades, (state: ClosedTradesReduxState, {payload}: Action<PaginationDealsTablePayload>) => {
                return Object.assign({}, state, {
                    ClosedTradesTable: MergePaginationTables(state.ClosedTradesTable, payload.table)
                });
            })
            .handle(SortTrades, (state: ClosedTradesReduxState, {payload}: Action<SortTradesPayload>) => {
                if (!Routes.IsPartnerRoute(payload.routeName)) {
                    if (payload.portfolioType === PortfolioType.ClosedTrades) {
                        const ClosedTradesTable: PaginationDealsTable = Utils.merge({}, state.ClosedTradesTable);
                        ClosedTradesTable.deals = [...ClosedTradesTable.deals].sort(SortDeals(payload.column, payload.sortDirection))
                        return Object.assign({}, state, {
                            ClosedTradesTable
                        });
                    } else if (payload.portfolioType === PortfolioType.OrdersHistory) {
                        const OrdersHistoryTable: PaginationDealsTable = Utils.merge({}, state.OrdersHistoryTable);
                        OrdersHistoryTable.deals = [...OrdersHistoryTable.deals].sort(SortDeals(payload.column, payload.sortDirection))
                        return Object.assign({}, state, {
                            OrdersHistoryTable
                        });
                    }
                }
                return state;
            })
            .handle(SetClosedTradeDetail, (state: ClosedTradesReduxState, {payload}: Action<SetClosedTradeDetailPayload>) => {
                return Object.assign({}, state, {
                    ClosedTradeDetail: payload.trade
                });
            })
            .handle(SetOrdersHistory, (state: ClosedTradesReduxState, {payload}: Action<PaginationDealsTablePayload>) => {
                return Object.assign({}, state, {
                    OrdersHistoryTable: MergePaginationTables(state.OrdersHistoryTable, payload.table)
                });
            })
            .handle(SetOrderDetail, (state: ClosedTradesReduxState, {payload}: Action<SetOrderDetailPayload>) => {
                return Object.assign({}, state, {
                    OrderDetail: payload.order
                });
            });
    }
}
