import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";

export interface WinInitMeta {

}

export class IntegrationWinInit implements IntegrationMessage {

    public classId: number = IntegrationClassId.WIN_INIT;
    public meta: WinInitMeta;

    constructor(meta: WinInitMeta) {
        this.meta = meta;
    }
}
