import {Engine} from "platform/engine/Engine";
import Utils from "platform/util/Utils";
import {ChartState} from "core/state/ChartState";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {
    ChartDealUpdatePayload,
    CreateChartPayload,
    RemoveChartPayload,
    SetChartDealPayload,
    SetChartFullHeight,
    SetChartSubscriptionPayload, SetChartVersion
} from "core/redux/chart/ChartReduxActions";
import {Windows} from "platform/integration/win/Windows";
import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol"
import {StoreState} from "core/redux/StoreState";
import {IntegrationChartInit} from "platform/integration/message/chart/IntegrationChartInit";
import {TSMap} from "typescript-map";
import {IntegrationChartDealDisplay} from "platform/integration/message/chart/IntegrationChartDealDisplay";
import {Deal} from "platform/protocol/trading/Deal";
import {AccountState} from "core/state/AccountState";
import {AuthState} from "core/state/AuthState";
import {ServerType} from "platform/enum/ServerType";
import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import {IntegrationChartNewQuotes} from "platform/integration/message/chart/IntegrationChartNewQuotes";
import {Quote} from "platform/protocol/trading/Quote";
import {IntegrationChartQuoteState} from "platform/integration/message/chart/IntegrationChartQuoteState";
import WebUtil from "platform/util/WebUtil";
import {Win} from "platform/integration/win/Win";
import DealEngine from "core/engine/DealEngine";
import {IntegrationChartDealUpdateSLTPResult} from "platform/integration/message/chart/IntegrationChartDealUpdateSLTPResult";
import {EventType} from "platform/enum/EventType";
import {SymbolCategory} from "enum/SymbolCategory";
import {Configuration} from "core/configuration/Configuration";
import {GetCurrentChartQuotesResponse} from "platform/protocol/trading/chart/GetCurrentChartQuotesResponse";
import {GetCurrentChartQuotesRequest} from "platform/protocol/trading/chart/GetCurrentChartQuotesRequest";
import {ProfitMessage} from "core/chart/integration/message/profit/ProfitMessage";
import {ProfitMessageType} from "core/chart/integration/message/profit/ProfitMessageType";
import {ProfitChartsInit} from "core/chart/integration/message/profit/ProfitChartsInit";
import {ProfitMetricsChanged} from "core/chart/integration/message/profit/ProfitMetricsChanged";
import {ProfitStateChanged} from "core/chart/integration/message/profit/ProfitStateChanged";
import {ProfitPong} from "core/chart/integration/message/profit/ProfitPong";
import {OpenDealResponse} from "protocol/trade/OpenDealResponse";
import {IntegrationChartDealUpdate} from "platform/integration/message/chart/IntegrationChartDealUpdate";
import {SetOrientationPayload, SetThemeTypePayload} from "platform/redux/core/CoreActions";
import {IntegrationOrientationChange} from "platform/integration/message/IntegrationOrientationChange";
import {IntegrationSetTheme} from "platform/integration/message/IntegrationSetTheme";
import {SymbolsState} from "core/state/SymbolsState";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {IntegrationSymbolsReload} from "platform/integration/message/IntegrationSymbolsReload";
import {Http} from "platform/network/http/Http";

export default class ChartEngine extends Engine {

    private static _instance: ChartEngine;
    private multiWin: Win;

    public static instance(): ChartEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        const {chartUrl} = Platform.config<Configuration>();
        const fallback = () => {
            Platform.dispatch(SetChartVersion({
                version: "9.5.2"
            }));
        };
        Http.getJson(`${chartUrl}resources/config.json`).then((data) => {
            if (data?.version) {
                Platform.dispatch(SetChartVersion({
                    version: data?.version
                }));
            } else {
                fallback();
            }
        }).catch(fallback);
    }

    public onLoggedOut = async () => {
        if (this.multiWin) {
            this.multiWin.origin().close();
        }
    }

    public onChangeRoute = ({route}): void => {
        Platform.dispatch(SetChartFullHeight({fullHeight: false}));
    }

    public onOrientationChange = ({orientation}: SetOrientationPayload) => {
        const chartState: ChartState = Platform.state(ServiceType.Chart);
        chartState.ids().forEach((chartId: string) => {
            Windows.sendMessage(new IntegrationOrientationChange(orientation), chartId);
        });
    }

    public onSetTheme = ({themeType}: SetThemeTypePayload) => {
        const chartState: ChartState = Platform.state(ServiceType.Chart);
        chartState.ids().forEach((chartId: string) => {
            Windows.sendMessage(new IntegrationSetTheme(themeType), chartId);
        });
    }

    public onTradingGroupChange = () => {
        const chartState: ChartState = Platform.state(ServiceType.Chart);
        chartState.ids().forEach((chartId: string) => {
            Windows.sendMessage(new IntegrationSymbolsReload(), chartId);
        });
    }

    public doCreateChart = async (payload: CreateChartPayload) => {
        const {ChartId, SymbolId} = payload;
        this._logger.debug("Create chart for symbol: " + SymbolId + " Id: " + ChartId);
        const chartState: ChartState = Platform.state(ServiceType.Chart);
        chartState.addChart(ChartId);
        const storeState: StoreState = Platform.store<StoreState>().getState();
        const symbolsState: SymbolsState = Platform.state<SymbolsState>(ServiceType.Symbols);
        const symbol: TradeSymbol = symbolsState.getSymbol(SymbolId);
        const authState: AuthState = Platform.state(ServiceType.Auth);
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const serverType: ServerType = accountState.accountType === NXEnvironmentType.Live ? ServerType.TradingReal : ServerType.TradingDemo;
        const languageCode: LangCode = LanguageUtil.languageCode();
        Windows.sendMessage(new IntegrationChartInit(
            symbol.symbol,
            symbol.SymbolId,
            null,
            Platform.config().servers[serverType],
            accountState.accountId.toString(),
            authState.token,
            accountState.accountType,
            languageCode,
            storeState.trades.OpenPositions,
            storeState.core.orientation,
            storeState.core.theme
        ), ChartId);
    }

    public doSetChartDeal = async (payload: SetChartDealPayload) => {
        const {ChartId, DealId} = payload;
        this._logger.debug("Set chart " + ChartId + " Deal " + DealId);
        if (Utils.greaterThen0(DealId)) {
            const storeState: StoreState = Platform.store<StoreState>().getState();
            const deal: Deal = storeState.trades.OpenPositionsMap[DealId];
            if (deal) {
                const chartState: ChartState = Platform.state(ServiceType.Chart);
                chartState.setDeal(ChartId, DealId);
                Windows.sendMessage(new IntegrationChartDealDisplay(deal), ChartId);
            } else {
                this._logger.debug("Can't find deal with id: " + DealId + " to set it on chart: " + ChartId);
            }
        }
    }

    public doSetChartSubscription = async (payload: SetChartSubscriptionPayload) => {
        const {ChartId, Subscription} = payload;
        const chartState: ChartState = Platform.state(ServiceType.Chart);
        const request: GetCurrentChartQuotesRequest = {
            ...Subscription,
            RequestId: Subscription.RequestId + "-period-" + Subscription.Periodicity
        };
        chartState.setSubscription(ChartId, request);
    }

    public doUpdateDeal = (DealId: number, position: OpenDealResponse) => {
        const chartState: ChartState = Platform.state(ServiceType.Chart);
        const chartIds: string[] = chartState.getChartIds(DealId);
        if (chartIds) {
            chartIds.forEach((ChartId: string) => {
                Windows.sendMessage(new IntegrationChartDealUpdate(position), ChartId);
            });
        }
    }

    public doUpdateDealFromChart = async ({ChartId, DealId, StopLoss, TaleProfit}: ChartDealUpdatePayload) => {
        const dealEngine: DealEngine = Platform.engine(ServiceType.Deal);
        const answer: [boolean, OpenDealResponse] = await Utils.to(dealEngine.doEditDealFromChart(DealId, StopLoss, TaleProfit));
        Windows.sendMessage(new IntegrationChartDealUpdateSLTPResult(Utils.isNull(answer[0])), ChartId);
    }

    public doRemoveChart = async (payload: RemoveChartPayload) => {
        const {ChartId} = payload;
        this._logger.debug("Remove chart. Id: " + ChartId);
        const chartState: ChartState = Platform.state(ServiceType.Chart);
        chartState.removeChart(ChartId);
    }

    public doUpdateQuoteState = (quote: Quote) => {
        const chartState: ChartState = Platform.state(ServiceType.Chart);
        chartState.iterate(quote.SymbolId, (chartId: string) => {
            Windows.sendMessage(new IntegrationChartQuoteState(quote), chartId);
        });
    }

    public doUpdateChart = (quotes: Quote[], responses: GetCurrentChartQuotesResponse[], OpenPositions: Deal[]) => {
        if (Utils.isArrayNotEmpty(responses)) {
            const chartState: ChartState = Platform.state(ServiceType.Chart);
            responses.forEach((response: GetCurrentChartQuotesResponse) => {
                const chartId: string = chartState.getChartId(response);
                if (chartId) {
                    Windows.sendMessage(new IntegrationChartNewQuotes(response.RequestHistorical, response.ChartQuotes, OpenPositions), chartId);
                    chartState.updateSubscription(chartId, response.ChartQuotes[response.ChartQuotes.length - 1]);
                }
            });
        }
        if (this.multiWin) {
            this.multiWin.origin().postMessage(new ProfitStateChanged(quotes, null, responses), "*");
        }
    }

    public doCreateMultiChart = () => {
        if (this.multiWin) {
            this.multiWin.origin().focus();
        } else {
            const symbols: string[] = [];
            const storeState: StoreState = Platform.store<StoreState>().getState();
            const categories: TSMap<string, TradeSymbol[]> = storeState.symbols.categories;
            const myWatchlistSymbols: TradeSymbol[] = categories.get(SymbolCategory.MyWatchlist);
            if (Utils.isArrayNotEmpty(myWatchlistSymbols)) {
                for (let i = 0; i < Math.min(4, myWatchlistSymbols.length); i++) {
                    symbols.push((myWatchlistSymbols[i].symbol || "").trim());
                }
            }
            if (symbols.length < 4) {
                const categoryNames: string[] = categories.keys().filter(cName => cName !== SymbolCategory.MyWatchlist);
                let categoryIndex: number = 0;
                for (let i = symbols.length; i < 4; i++) {
                    const category: string = categoryNames[categoryIndex];
                    if (category) {
                        categoryIndex++;
                        symbols.push((categories.get(category)[0]?.symbol || "").trim());
                    } else {
                        categoryIndex = 0;
                    }
                }
            }
            const {brandId, chartUrl} = Platform.config<Configuration>();
            this.multiWin = Platform.environment().openWindow(WebUtil.addGetParams(chartUrl + "ChartMulti.html", {
                brandId: brandId.toString(),
                theme: storeState.core.theme,
                symbols: symbols.join(",")
            }), "_blank", WebUtil.winCenter(1280, 720));
            if (this.multiWin) {
                Platform.environment().addEventListener(EventType.Message, this.onMultiChartEvent);
                const handler: any = setInterval(() => {
                    if (this.multiWin && this.multiWin.origin().closed) {
                        Platform.environment().removeEventListener(EventType.Message, this.onMultiChartEvent);
                        clearInterval(handler);
                        this.multiWin = null;
                        const chartState: ChartState = Platform.state(ServiceType.Chart);
                        chartState.ids().forEach((id: string) => {
                            if (id.startsWith("multi-chart")) {
                                this.doRemoveChart({ChartId: id}).catch(() => {});
                            }
                        });
                    }
                }, 1000);
            }
        }
    }

    private onMultiChartEvent = (event: MessageEvent) => {
        try {
            const data: ProfitMessage = event.data;
            if (data.type) {
                const storeState: StoreState = Platform.store<StoreState>().getState();
                switch (data.type) {
                    case ProfitMessageType.Ping:
                        this.multiWin.origin().postMessage(new ProfitPong(), "*");
                        break;
                    case ProfitMessageType.MultiChartsReady:
                        this._logger.debug("Multi chart window ready");
                        const authState: AuthState = Platform.state(ServiceType.Auth);
                        const accountState: AccountState = Platform.state(ServiceType.Account);
                        const serverType: ServerType = accountState.accountType === NXEnvironmentType.Live ? ServerType.TradingReal : ServerType.TradingDemo;
                        this.multiWin.origin().postMessage(new ProfitChartsInit(Platform.config().servers[serverType], accountState.accountId.toString(), authState.token, storeState.translation.langCode, accountState.accountType), "*");
                        break;
                    case ProfitMessageType.MetricsChanged:
                        const chartState: ChartState = Platform.state(ServiceType.Chart);
                        const {metrics} = event.data as ProfitMetricsChanged;
                        const ChartId: string = metrics.RequestId.substring(0, metrics.RequestId.indexOf("-period-"));
                        this._logger.debug("Add metrics for chart: " + ChartId + " SymbolId: " + metrics.SymbolId);
                        chartState.addChart(ChartId);
                        chartState.setSubscription(ChartId, metrics);
                        break;
                }
            }
        } catch (e) {
            this._logger.warn("Failed parse multy chart post message");
        }
    }
}
