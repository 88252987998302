import {ActionCreatorDefinition, createAction} from "redux-ts";
import {WithdrawalPending} from "core/redux/account/WithdrawalReduxState";
import {RouteType} from "core/router/Routes";

export const FetchPendingWithdrawalType: string =  "FetchPendingWithdrawals";
export const SetPendingWithdrawalType: string =  "SetPendingWithdrawal";
export const DoWithdrawalType: string = "DoWithdrawal";
export const SetWithdrawalResultType: string =  "SetWithdrawalResult";
export const DoCancelWithdrawalType: string =  "DoCancelWithdrawal";

export interface SetPendingWithdrawalPayload {

    withdrawal: WithdrawalPending;
}

export interface DoWithdrawalPayload {

    amount: number;
    reason: string;
    action?: (routeType: RouteType) => void;
}

export interface SetWithdrawalResultPayload {

    success: boolean;
    withdrawalNumber: string;
}

export interface DoCancelWithdrawalPayload {

    withdrawal: WithdrawalPending;
}

export const FetchPendingWithdrawal: ActionCreatorDefinition<any, any> = createAction<any>(FetchPendingWithdrawalType);
export const SetPendingWithdrawal: ActionCreatorDefinition<SetPendingWithdrawalPayload, any> = createAction<SetPendingWithdrawalPayload>(SetPendingWithdrawalType);
export const DoWithdrawal: ActionCreatorDefinition<DoWithdrawalPayload, any> = createAction<DoWithdrawalPayload>(DoWithdrawalType);
export const SetWithdrawalResult: ActionCreatorDefinition<SetWithdrawalResultPayload, any> = createAction<SetWithdrawalResultPayload>(SetWithdrawalResultType);
export const DoCancelWithdrawal: ActionCreatorDefinition<DoCancelWithdrawalPayload, any> = createAction<DoCancelWithdrawalPayload>(DoCancelWithdrawalType);
