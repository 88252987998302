import {PopupNotification} from "protocol/account/PopupNotification";
import {InAppNotificationStatus, UIInAppNotification} from "protocol/notification/UIInAppNotification";
import Platform from "platform/Platform";
import {AddInboxMessage, RemoveInboxMessage} from "core/redux/inbox/InboxReduxActions";
import {Logger} from "platform/logger/Logger";
import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {Increment} from "platform/util/Increment";

interface InboxMeta {

    interval?: any;
    timePassed?: number;
    timeLeft?: number;
    progressLeft?: number;
    hasTrigger?: boolean;
    listeners?: Array<(progress: number) => void>;
}

const TimeBetweenInbox: number = 1000;
const TimeToShow: number = 5000;
const UpdateInterval: number = 250;

class InboxScheduler {

    private static _logger: Logger = Logger.Of("InboxScheduler");
    private static _ids: Increment = Increment.from(0);
    private static _inboxes: TSMap<number, InboxMeta> = new TSMap();
    private static _pool: UIInAppNotification[] = [];
    private static _pause: boolean;

    private constructor() {
    }

    public static Pause = (): void => {
        this._pause = true;
    }

    public static Resume = (): void => {
        this._pause = false;
    }

    public static AddInbox = (notification: PopupNotification): void => {
        if (notification) {
            try {
                const message: UIInAppNotification = JSON.parse(notification.Message);
                message.Id = message.Id || notification.Id || this._ids.next();
                message.StatusType = InAppNotificationStatus.Unread;
                const metas: InboxMeta[] = this._inboxes.values();
                if ((metas.length === 0 || metas[metas.length - 1].timePassed > TimeBetweenInbox ) && this._inboxes.size() < 3) {
                    this.AddInboxInternal(message);
                } else {
                    this._logger.debug(`Push to the pool: ${message.Id}`);
                    this._pool.push(message);
                }
            } catch (e) {
                this._logger.warn("Failed parse UIInAppNotification");
            }
        }
    }

    private static AddInboxInternal = (message: UIInAppNotification): void => {
        this._logger.debug(`Show inbox: ${message.Id}`);
        const meta: InboxMeta = {
            listeners: [],
            interval: setInterval(() => {
                meta.timePassed += UpdateInterval;
                if (meta.hasTrigger && meta.timePassed > TimeBetweenInbox && this._inboxes.size() < 3) {
                    meta.hasTrigger = false;
                    this.PushFromPool();
                }
                if (!this._pause) {
                    meta.timeLeft = meta.timeLeft - UpdateInterval;
                    meta.progressLeft = meta.progressLeft - UpdateInterval - 25;
                    const progress: number = Math.min((TimeToShow - meta.progressLeft) * 100 / TimeToShow, 100);
                    meta.listeners.forEach(listener => listener(progress));
                    if (!Utils.greaterThen0(meta.timeLeft)) {
                        this.RemoveInbox(message);
                    }
                }
            }, UpdateInterval),
            timePassed: 0,
            timeLeft: TimeToShow,
            progressLeft: TimeToShow,
            hasTrigger: true
        };
        this._inboxes.set(message.Id, meta);
        Platform.dispatch(AddInboxMessage({message}));
    }

    public static RemoveInbox = (message: UIInAppNotification): void => {
        this._logger.debug(`Remove inbox: ${message.Id}`);
        const meta: InboxMeta = this._inboxes.get(message.Id);
        clearInterval(meta?.interval);
        this._inboxes.delete(message.Id);
        Platform.dispatch(RemoveInboxMessage({message}));
        this.PushFromPool();
    }

    private static PushFromPool = (): void => {
        if (Utils.isArrayNotEmpty(this._pool)) {
            this.AddInboxInternal(this._pool.splice(0, 1)[0]);
        }
    }

    public static AddListener = (Id: number, listener: (progress: number) => void): () => void => {
        const meta: InboxMeta = this._inboxes.get(Id);
        if (listener && meta) {
            meta.listeners.push(listener);
        }
        return () => {
            if (listener && meta) {
                Utils.remove(meta.listeners, listener);
            }
        };
    }
}

export {
    InboxScheduler
};
