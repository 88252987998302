import {ActionCreatorDefinition, createAction} from "redux-ts";
import {Quote} from "platform/protocol/trading/Quote";
import {TSMap} from "typescript-map";

export const SetQuotesType: string =  "SetQuotes";

export interface SetQuotesPayload {

    quotes: TSMap<number, Quote>;
}

export const SetQuotes: ActionCreatorDefinition<SetQuotesPayload, any> = createAction<SetQuotesPayload>(SetQuotesType);
