export enum BIEventReferrer {

    Organic = "Organic",
    SearchBox = "Search box",
    NextVideoButton = "Next video button",
    External = "External",
    CloseButton = "Close button",
    RandomClick = "Random click",
    MarginCall = "Margin call",
}
