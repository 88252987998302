import Utils from "platform/util/Utils";

enum NotificationTab {

    All = "all",
    Unread = "unread"
}

namespace NotificationTab {

    export function deserialize(sNotificationTab: string): NotificationTab {
        if (Utils.isNotEmpty(sNotificationTab)) {
            switch (sNotificationTab) {
                case NotificationTab.Unread:
                    return NotificationTab.Unread;
            }
        }
        return NotificationTab.All;
    }
}

export {
    NotificationTab
};
