enum SignalPatternType {

    KeyLevel = 1,
    BigMovement = 2,
    ConsecutiveCandles = 3,
    Fibonacci = 4,
    ChartPattern = 5,
}

export {
    SignalPatternType
};
