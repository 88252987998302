import {Engine} from "platform/engine/Engine";
import {XhrUtil} from "core/util/XhrUtil";
import {HttpReject} from "platform/network/http/Http";
import Utils from "platform/util/Utils";
import {SetNews, SetNewsLoading} from "core/redux/news/NewsReduxActions";
import {GetNewsRequest} from "protocol/news/GetNewsRequest";
import {GetNewsResponse, News} from "protocol/news/GetNewsResponse";
import Platform from "platform/Platform";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {SetAuthStatusPayload} from "core/redux/auth/AuthReduxActions";
import {StoreState} from "core/redux/StoreState";

export default class NewsEngine extends Engine {

    private static _instance: NewsEngine;
    private needFetchNews: boolean;

    public static instance(): NewsEngine {
        return this._instance || (this._instance = new this());
    }

    public doSetAuthStatus = async (payload: SetAuthStatusPayload) => {
        const {connected, authenticated} = Platform.reduxState<StoreState>().auth;
        if (connected) {
            if (!authenticated && payload.authStatus.authenticated && this.needFetchNews) {
                this.needFetchNews = false;
                this.doFetchNewsInternal().catch(() => {
                });
            }
        }
    }

    public doFetchNews = async () => {
        Platform.dispatch(SetNewsLoading({loading: true}));
        const {authenticated} = Platform.reduxState<StoreState>().auth;
        if (authenticated) {
            await this.doFetchNewsInternal();
        } else {
            this.needFetchNews = true;
        }
    }

    private doFetchNewsInternal = async () => {
        this._logger.debug("Fetching news");
        const langCode: LangCode = LanguageUtil.languageCode();
        const request: GetNewsRequest = {languageCode: langCode};
        const answer: [HttpReject, GetNewsResponse] = await Utils.to(this.sendToNewsService(request, "GetLatestNews"));
        if (answer[0]) {
            this._logger.debug("Failed fetch news");
        } else {
            this._logger.debug("News fetched.");
            Platform.dispatch(SetNews({
                news: answer[1]?.News.sort((n1: News, n2: News) => Utils.compareNumber(n2.DateTime, n1.DateTime))
            }))
        }
        Platform.dispatch(SetNewsLoading({loading: false}));
    }

    private sendToNewsService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "WebProfitServer/WebProfitClientService.svc/json/" + path, null, true);
    }
}
