export enum FormFieldType {

    // Deal form
    SymbolId = "SymbolId",
    Amount = "Amount",
    OrderRate = "OrderRate",
    PositionDirection = "PositionDirection",
    RateRequestType = "RateRequestType",
    StopLossChecked = "StopLossChecked",
    StopLossValueRate = "StopLossValueRate",
    StopLossValueAmount = "StopLossValueAmount",
    TakeProfitChecked = "TakeProfitChecked",
    TakeProfitValueRate = "TakeProfitValueRate",
    TakeProfitValueAmount = "TakeProfitValueAmount",
    OCOChecked = "OCOChecked",
    OCOValueRate = "OCORate",
    ExpirationChecked = "ExpirationChecked",
    ExpirationDay = "ExpirationDay",
    ExpirationMonth = "ExpirationMonth",
    ExpirationYear = "ExpirationYear",
    Reason = "Reason",
    TakeProfit = "TakeProfit",
    StopLoss = "StopLoss",
    CurrentPassword = "CurrentPassword",
    NewPassword = "NewPassword",
    ConfirmPassword = "ConfirmPassword",
    CloseOutMethod = "CloseOutMethod",
    CloseOutChecked = "CloseOutChecked",
    MyLeverageValue = "MyLeverageValue",
    MyLeverageChecked = "MyLeverageChecked",
    AnnualStatementYear = "AnnualStatementYear",
    Price = "Price",
    PriceType = "PriceType",
    SignalSubPatternType = "SignalSubPatternType",
    NotifyByEmail = "NotifyByEmail",
    NotifyByPushNotifications = "NotifyByPushNotifications",
    Recuring = "Recuring",
    PriceDirection = "PriceDirection",
    FeedbackRating = "FeedbackRating",
    FeedbackComment = "FeedbackComment",
    Email = "Email",
    PhoneNumber = "PhoneNumber",
    PhoneNumberCountryCode = "PhoneNumberCountryCode",
    FullName = "FullName",
    FirstName = "FirstName",
    LastName = "LastName",
    Password = "Password",
    ReferralCode = "ReferralCode",
    HasReferralCode = "HasReferralCode",
    Username = "Username"
}
