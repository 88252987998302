import {Logger} from "platform/logger/Logger";
import Utils from "platform/util/Utils";
import Platform from "platform/Platform";
import {DoLogOff} from "core/redux/auth/AuthReduxActions";
import {EventType} from "platform/enum/EventType";
import {EnvType} from "platform/enum/EnvType";

export default class SessionInactivity {

    private static readonly _logger: Logger = Logger.Of("SessionInactivity");
    private static _startIdleDateTime: number;
    private static _threshold: number;
    private static _timeLeft: number;
    private static _pause: boolean;
    private static _handler: any;

    public static setThreshold = (threshold: number): void => {
        SessionInactivity._logger.info("Set threshold to: " + threshold);
        SessionInactivity._threshold = threshold;
    }

    public static start = (): void => {
        if (Platform.environment().type() === EnvType.Web) {
            if (Utils.greaterThen0(SessionInactivity._threshold)) {
                if (Utils.isNull(SessionInactivity._handler)) {
                    Platform.environment().addEventListener(EventType.Click, SessionInactivity.reset);
                    SessionInactivity._startIdleDateTime = new Date().getTime();
                    SessionInactivity.reset();
                    SessionInactivity._logger.info("Start track session inactivity. Period: " + SessionInactivity._threshold);
                    SessionInactivity._handler = setInterval(() => {
                        if (!SessionInactivity._pause) {
                            SessionInactivity._timeLeft = SessionInactivity._timeLeft - 1000;
                        }
                        if (SessionInactivity._timeLeft <= 0) {
                            SessionInactivity._logger.info("Session expired do to inactivity from UI interaction. Perform logout");
                            SessionInactivity.stop();
                            Platform.dispatch(DoLogOff({}));
                        }
                    }, 1000);
                }
            } else {
                SessionInactivity._logger.warn("Can't start track session with negative or 0 threshold: " + SessionInactivity._threshold);
            }
        }
    }

    public static stop = (): void => {
        if (Utils.isNotNull(SessionInactivity._handler)) {
            SessionInactivity._logger.info("Stop track session inactivity.");
            Platform.environment().removeEventListener(EventType.Click, SessionInactivity.reset);
            clearInterval(SessionInactivity._handler);
            SessionInactivity._handler = null;
            SessionInactivity._startIdleDateTime = null;
        }
    }

    public static reset = (): void => {
        SessionInactivity._startIdleDateTime = new Date().getTime();
        SessionInactivity._timeLeft = SessionInactivity._threshold;
    }

    public static onSendingData = (path: string): void => {
        if (SessionInactivity._startIdleDateTime) {
            if (path?.indexOf("GetAccountState") > 0) {
                if (new Date().getTime() > SessionInactivity._startIdleDateTime + SessionInactivity._threshold) {
                    SessionInactivity._logger.info("Session expired do to network idle. Perform logout");
                    SessionInactivity.stop();
                    Platform.dispatch(DoLogOff({}));
                }
            } else {
                SessionInactivity.reset();
            }
        }
    }

    public static pause = (): void => {
        SessionInactivity._pause = true;
    }

    public static resume = (): void => {
        SessionInactivity._pause = false;
    }
}
