import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {DefaultSignalsSettings, SignalsAddedFilterValues, SignalsIntervalFilterValues} from "enum/SignalsSettingsType";
import {SignalsFilter} from "protocol/symbol/GetSymbolSignalsRequest";
import {ThemeType} from "platform/enum/ThemeType";
import WebUtil from "platform/util/WebUtil";

export class SignalUtil {

    public static probabilityLabelsFormat = (value: number): string => {
        return `${value}%${value === 100 ? "" : " +"}`;
    };

    public static addedLabelsFormat = (value: number, valueMin: number, valueMax: number): string => {
        const addedLabels: string[] = ['1m', '10m', '20m', '30m', '1h', '2h', '4h', '8h', '12h', '1D', '2D', '3D', '4D', '5D', '6D', '1W', '2W', '3W', '1M+'];
        if (value === valueMin) return `${Translations.text(TranslationKey.upTo)} ${addedLabels[0]}`;
        if (value === valueMax) return addedLabels[addedLabels.length - 1];
        return `${Translations.text(TranslationKey.upTo)} ${addedLabels[Math.floor((value)/5)]}`;
    };

    public static intervalLabelsFormat = (value: number, valueMin: number, valueMax: number): string => {
        const intervalLabels: string[] = ['15m', '30m', '1h', '2h', '4h', '8h', '1D+'];
        if (value === valueMin) return intervalLabels[0];
        if (value === valueMax) return intervalLabels[intervalLabels.length - 1];
        return `${intervalLabels[(value)]}`;
    };

    public static targetLabelsFormat = (value: number,  valueMin: number, valueMax: number): string => {
        if (value === valueMin) return '1h';
        if (value === valueMax) return '72h+';
        return `${value}h`;
    };

    public static formAddedFilter = (addedValue: number): number | null => {
        return addedValue !== SignalsAddedFilterValues[SignalsAddedFilterValues.length - 1] ? addedValue : null;
    };

    public static formIntervalFilter = (filterInterval: number[]): number[] | null => {
        const intervalValues: number[] = [SignalsIntervalFilterValues[filterInterval[0]], SignalsIntervalFilterValues[filterInterval[1]]]
        const minIntervalFilter: number = SignalsIntervalFilterValues[0];
        const maxIntervalFilter: number = SignalsIntervalFilterValues[SignalsIntervalFilterValues.length - 1];
        if (intervalValues[0] === minIntervalFilter && intervalValues[1] === minIntervalFilter) {
            return [0, minIntervalFilter];
        }
        if (intervalValues[0] === minIntervalFilter && intervalValues[1] === maxIntervalFilter) {
            return null;
        }
        if (intervalValues[0] === maxIntervalFilter && intervalValues[1] === maxIntervalFilter) {
            return [maxIntervalFilter]
        }
        return intervalValues;
    };

    public static formTargetFilter = (filterTarget: number[], targetMin: number, targetMax: number): number[] | null => {
        const targetValues: number[] = [filterTarget[0] * 60, filterTarget[1] * 60];
        const minTargetFilter: number = targetMin * 60;
        const maxTargetFilter: number = targetMax * 60;
        if (targetValues[0] === minTargetFilter && targetValues[1] === minTargetFilter) {
            return [0, 60];
        }
        if (targetValues[0] === minTargetFilter && targetValues[1] === maxTargetFilter) {
            return null;
        }
        if (targetValues[0] === maxTargetFilter && targetValues[1] === maxTargetFilter) {
            return [maxTargetFilter]
        }
        return targetValues;
    };

    public static isFilterDefault = (useProbability: boolean, filter: SignalsFilter): boolean => {
        if (useProbability !== DefaultSignalsSettings.useProbability) {
            return true;
        }
        const defaultFilter: SignalsFilter = DefaultSignalsSettings.filter;
        for (const key in defaultFilter) {
            if (!(key in filter)) {
                return true;
            } else {
                if (Array.isArray(filter[key])){
                    if (JSON.stringify(defaultFilter[key].sort()) !== JSON.stringify(filter[key].sort())) {
                        return true;
                    }
                } else if (defaultFilter[key] !== filter[key] && key !== "probability") {
                    return true;
                }
            }
        }
        return false;
    }

    public static signalImgUrl = (url: string, theme: ThemeType): string => {
        if (theme === ThemeType.Dark) {
            return WebUtil.addGetParams(url, {
                chart_style: "AAn_JSg3______8zN0r_JSg3________________________1wD_ALWs______9ycnL______wC1rP______rq6u____________AAAA_1qwaf8kSiz__4BA_7M8AAAJTS9kIEhIOm1tAAZkL00veXkACiMsIyMwLjAwMDAACU9wZW4gU2Fuc_8AAP_______wC1rP__________AQEB_0h2sf9ksYr__4BAAAAAAAAAAAAAAEAgAAAAAD-AAAAAAD-AAAAAAAAAAAAAAAAQYXV0b2NoYXJ0aXN0LmNvbf__gEAAAAABAAAAAAH__4BA__-AQP__gED_AAAAP4AAAD-AAAA_gAAAP4AAAD-AAAA_gAAAP4AAAD-AAAA_gAAAP4AAAD-AAAA_gAAAP4AAAD-AAAA_gAAAP4AAAD-AAAAAAAAAAAAACgAAAAoAAAAAAAAA"
            });
        }
        return url;
    }
}

export namespace SignalUtil {

    export enum FormatFunctions {
        "probabilityLabelsFormat",
        "addedLabelsFormat",
        "intervalLabelsFormat",
        "targetLabelsFormat",
        "formAddedFilter",
        "formIntervalFilter",
        "formTargetFilter",
    }
}
