import Utils from "platform/util/Utils";

export enum PositionDirection {

    Buy = 1,
    Sell = 2
}

export const opposite = (direction: PositionDirection): PositionDirection => {
    if (Utils.isNotNull(direction)) {
        switch (direction) {
            case PositionDirection.Sell:
                return PositionDirection.Buy;
            case PositionDirection.Buy:
                return PositionDirection.Sell;
        }
    }
    return null;
};

export const from = (type: string): PositionDirection => {
    if (Utils.isNotEmpty(type)) {
        switch (type) {
            case "buy":
                return PositionDirection.Buy;
            case "sell":
                return PositionDirection.Sell;
        }
    }
    return null;
};
