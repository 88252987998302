import Utils, {UNDEFINED_VALUE} from "platform/util/Utils";
import {Currency} from "platform/enum/Currency";
import {Locale} from "core/format/Locale";

export class NumberFormat {

    private constructor() {
    }

    public static formatNumber(value: number, fraction?: number, isRTL?: boolean, currency?: string): string { // isRTL used only in React native for Stop Loss & Take Profit placeholder text
        return this.format(value, fraction, fraction, isRTL, currency ?? "");
    }

    public static formatPercent(value: number): string {
        return this.format(value, 0, 2) + "%";
    }

    public static formatMoney(value: number, currency?: string, maxFraction?: number, hideSign?: boolean): string { // hideSign used only in React native for Realized/Unrealized PL
        if (Utils.isNotNull(value)) {
            const sign: string = Currency.sign(currency);
            const v = hideSign ? Math.abs(value) : value;
            const n = this.format(v, maxFraction ? maxFraction : 0, 2);
            const isNegative: boolean = n.charAt(0) === "-";
            return `${isNegative ? "- " : ""}${Utils.isNotEmpty(sign) ? sign : ""} ${isNegative ? n.substring(1) : n}`;
        }
        return UNDEFINED_VALUE;
    }

    public static round(num: number, scale: number): number {
        if (!("" + num).includes("e")) {
            return +(Math.round((num + "e+" + scale) as any) + "e-" + scale);
        } else {
            const arr = ("" + num).split("e");
            let sig = ""
            if (+arr[1] + scale > 0) {
                sig = "+";
            }
            return +(Math.round((+arr[0] + "e" + sig + (+arr[1] + scale)) as any) + "e-" + scale);
        }
    }

    public static toFixed(v: number, fraction?: number): number {
        return Utils.isNotNull(v) ? parseFloat(v.toFixed(Utils.isNotNull(fraction) ? fraction : 2)) : v;
    }

    private static fillDecimalPartZeros(decimalPart: string , minFraction: number): string {
        while (decimalPart.length < minFraction) {
            decimalPart += "0";
        }
        return decimalPart;
    }

    public static format(value: number, minFraction?: number, maxFraction?: number, isRTL?: boolean, currency?: string): string {
        currency = Utils.isNotEmpty(currency) ? currency : "";
        if (Utils.isNotNull(value)) {
            const numbersConfig: {thousandDelimiter: string, decimalDelimiter: string} = Locale.provider.config().formats.numbers;
            const negative: boolean = value < 0;
            const parts: string[] = Math.abs(value).toString().split(".");
            let result = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, numbersConfig.thousandDelimiter);
            let decimalPart: string = parts[1];
            if (decimalPart) {
                decimalPart = decimalPart.replace(/0+$/, "");
                if (maxFraction > 0 && maxFraction >= minFraction && decimalPart.length > maxFraction) {
                    decimalPart = decimalPart.substring(0, maxFraction);
                }
                const minF: number = minFraction > 0 ? minFraction : 2;
                if (decimalPart) {
                    decimalPart = this.fillDecimalPartZeros(decimalPart, minF);
                    result = result + numbersConfig.decimalDelimiter + decimalPart;
                }
            }
            if (parseFloat(result.replace(numbersConfig.decimalDelimiter, ".")) > 0) {
                result = isRTL ? result + currency + (negative ? "-" : "") : (negative ? "- " : "") + currency + result;
            }
            if (!decimalPart && minFraction) {
                decimalPart = this.fillDecimalPartZeros("", minFraction);
                result = result + numbersConfig.decimalDelimiter + decimalPart;
            }
            return result;
        }
        return UNDEFINED_VALUE;
    }

    public static parse(value: string, maxFraction?: number, decimalDelimiter?: string): number {
        if (Utils.isNotNull(value)) {
            const numbersConfig: {thousandDelimiter: string, decimalDelimiter: string} = Locale.provider.config().formats.numbers;
            const parts: string[] = value.split(decimalDelimiter || numbersConfig.decimalDelimiter);
            let result = parts[0].replace(numbersConfig.thousandDelimiter, "");
            let decimalPart: string = parts[1];
            if (Utils.isNotEmpty(decimalPart)) {
                if (maxFraction > 0) {
                    decimalPart = decimalPart.substring(0, maxFraction);
                }
                result = result + "." + decimalPart;
            }
            return parseFloat(result);
        }
        return null;
    }

    public static Abbr = (value: number): string => {
        if (Utils.isNotNull(value)) {
            if (value < 1000) {
                return `${value}`;
            }
            if (value >= 1000 && value < 1000000) {
                return `${(value / 1000).toFixed(2)}K`;
            }
            if (value >= 1000000) {
                return `${(value / 1000000).toFixed(2)}M`;
            }
        }
        return "";
    }
}
