import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import QuotesReducer from "core/redux/quotes/QuotesReducer";
import {QuotesState} from "core/state/QuotesState";

export class QuotesService implements Service<any, QuotesState> {

    private static _instance: QuotesService;

    private constructor() {
    }

    public static instance(): QuotesService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
    }

    public name(): string {
        return ServiceType.Quotes;
    }

    public reducers(): Array<Reducer<any>> {
        return [QuotesReducer.instance()];
    }

    public engine(): any {
        return undefined;
    }

    public state(): QuotesState {
        return QuotesState.instance();
    }
}
