import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {GetCurrentChartQuotesResponse} from "platform/protocol/trading/chart/GetCurrentChartQuotesResponse";
import {GetCurrentChartQuotesRequest} from "platform/protocol/trading/chart/GetCurrentChartQuotesRequest";
import {ChartQuote} from "platform/protocol/trading/chart/ChartQuote";

export class ChartState {

    private static _instance: ChartState;
    private _charts: TSMap<string, GetCurrentChartQuotesRequest> = new TSMap();
    private _deals: TSMap<number, string[]> = new TSMap();

    private constructor() {
    }

    public static instance(): ChartState {
        return this._instance || (this._instance = new this());
    }

    public ids(): string[] {
        return [...this._charts.keys()];
    }

    public addChart(ChartId: string): void {
        if (!this._charts.has(ChartId)) {
            this._charts.set(ChartId, null);
        }
    }

    public removeChart(ChartId: string): void {
        this._charts.delete(ChartId);
        for (let i = 0; i < this._deals.length; i++) {
            const dealId: number = this._deals.keys()[i];
            const chartIds: string[] = this._deals.values()[i];
            if (chartIds) {
                this._deals.set(dealId, chartIds.filter((chartId: string) => chartId !== ChartId));
            }
        }
    }

    public setDeal(ChartId: string, DealId: number): void {
        const chartIds: string[] = this._deals.get(DealId) || [];
        chartIds.push(ChartId);
        this._deals.set(DealId, chartIds);
    }

    public setSubscription(ChartId: string, subscription: GetCurrentChartQuotesRequest): void {
        this._charts.set(ChartId, subscription);
    }

    public getChartIds(DealId: number): string[] {
        return this._deals.get(DealId);
    }

    public getChartId(response: GetCurrentChartQuotesResponse): string {
        for (let i = 0; i < this._charts.size(); i++) {
            const chartId: string = this._charts.keys()[i];
            const request: GetCurrentChartQuotesRequest = this._charts.values()[i];
            if (request && request.RequestId === response.RequestId) {
                return chartId;
            }
        }
        return null;
    }

    public updateSubscription(ChartId: string, lastQuote: ChartQuote): void {
        const subscription: GetCurrentChartQuotesRequest = this._charts.get(ChartId);
        if (subscription && lastQuote) {
            this._charts.set(ChartId, {
                ...subscription,
                LastReceivedChartQuoteTime: lastQuote.MD
            });
        }
    }

    public getSubscriptions(): GetCurrentChartQuotesRequest[] {
        return this._charts.values().filter((subscription: GetCurrentChartQuotesRequest) => Utils.isNotNull(subscription));
    }

    public iterate(symbolId: number, consumer: (id: string) => void): void {
        this._charts.forEach((subscription: GetCurrentChartQuotesRequest, id: string) => {
            if (subscription && symbolId === subscription.SymbolId) {
                consumer(id);
            }
        });
    }
}
