import {TSMap} from "typescript-map";
import {Quote} from "platform/protocol/trading/Quote";

export class QuotesState {

    private static _instance: QuotesState;
    private _quotes: TSMap<number, Quote> = new TSMap<number, Quote>();

    private constructor() {
    }

    public static instance(): QuotesState {
        return this._instance || (this._instance = new this());
    }

    public getQuote(symbolId: number): Quote {
        return this._quotes.get(symbolId);
    }

    public setQuote(quote: Quote) {
        this._quotes.set(quote.SymbolId, quote);
    }
}
