import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {OnAccountChanged, OnLoggedOut} from "core/redux/auth/AuthReduxActions";
import {DealForm, DealReduxState} from "core/redux/deal/DealReduxState";
import {
    ClearDealFormRanges,
    CloseDeals,
    CloseDealsPayload,
    CloseDealsType,
    EditDeal,
    EditDealPayload,
    EditDealType,
    GoingEditDeal,
    GoingEditDealPayload,
    GoingEditDealType,
    GoingOpenDeal,
    GoingOpenDealPayload,
    GoingOpenDealType,
    OpenDeal,
    OpenDealPayload,
    OpenDealType,
    RollPositionsType,
    SetDealFormInProgress,
    SetDealFormInProgressPayload,
    SetDealRanges,
    SetDealRangesPayload,
    SetDealServerError,
    SetDealServerErrorPayload,
    DealOpenEditInitialization,
    DealOpenEditInitializationPayload,
    SetSymbolDetails,
    SetSymbolDetailsPayload,
    SetTradeManagerTab,
    SetTradeManagerTabPayload,
    UpdateDealForm,
    UpdateDealFormPayload,
    SetPositionTabDealId, SetPositionTabDealIdPayload,
} from "core/redux/deal/DealReduxActions";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import DealEngine from "core/engine/DealEngine";
import {Validation} from "core/util/Validation";
import {TradeManagerTab} from "enum/TradeManagerTab";
import {PromotionUtil} from "core/util/PromotionUtil";
import {PromotionType} from "platform/protocol/enum/PromotionType";
import {ToggleMenuType} from "core/redux/app/AppReduxActions";
import {FormFieldType} from "enum/FormFieldType";
import Utils from "platform/util/Utils";
import {BIUtil} from "core/util/BIUtil";

const ValidationFields: FormFieldType[] = [
    FormFieldType.SymbolId,
    FormFieldType.Amount,
    FormFieldType.OrderRate,
    FormFieldType.PositionDirection,
    FormFieldType.RateRequestType,
    FormFieldType.OCOChecked,
    FormFieldType.OCOValueRate,
    FormFieldType.StopLossChecked,
    FormFieldType.StopLossValueRate,
    FormFieldType.StopLossValueAmount,
    FormFieldType.TakeProfitChecked,
    FormFieldType.TakeProfitValueRate,
    FormFieldType.TakeProfitValueAmount,
    FormFieldType.ExpirationChecked,
    FormFieldType.ExpirationDay,
    FormFieldType.ExpirationMonth,
    FormFieldType.ExpirationYear,
];

const initialState = (): DealReduxState => {
    return {
        ranges: {},
        errors: {},
        uiErrors: {}
    };
};

export default class DealReducer extends Reducer<DealReduxState> {

    private static _instance: DealReducer;

    public static instance(): DealReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "deal";
    }

    private constructor() {
        super();
        const dealEngine: DealEngine = Platform.engine(ServiceType.Deal);
        this._middlewareActions.set(ToggleMenuType, dealEngine.onToggleMenu);
        this._middlewareActions.set(GoingOpenDealType, dealEngine.doGoingOpenDeal);
        this._middlewareActions.set(GoingEditDealType, dealEngine.doGoingEditDeal);
        this._middlewareActions.set(OpenDealType, dealEngine.doOpenDeal);
        this._middlewareActions.set(EditDealType, dealEngine.doEditDeal);
        this._middlewareActions.set(CloseDealsType, dealEngine.doCloseDeals);
        this._middlewareActions.set(RollPositionsType, dealEngine.doRollPositions);
    }

    protected setup(builder: ReducerBuilder<DealReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: DealReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(OnAccountChanged, (state: DealReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(SetTradeManagerTab, (state: DealReduxState, {payload}: Action<SetTradeManagerTabPayload>) => {
                return Object.assign({}, state, {
                    tradeManagerTab: payload.tab
                });
            })
            .handle(SetSymbolDetails, (state: DealReduxState, {payload}: Action<SetSymbolDetailsPayload>) => {
                return Object.assign({}, state, {
                    symbolDetails: payload.details
                });
            })
            .handle(SetDealRanges, (state: DealReduxState, {payload}: Action<SetDealRangesPayload>) => {
                let errors: {[key: string]: any} = {};
                let uiErrors: {[key: string]: any} = {};
                let form: DealForm = state.form;
                if (form && form?.SymbolId === payload.symbolId) {
                    const {
                        AvailablePromotions,
                        StopLossValueRate,
                        StopLossValueAmount,
                        StopLossMinimum,
                        StopLossMaximum,
                        TakeProfitValueRate,
                        TakeProfitValueAmount,
                        TakeProfitMinimum,
                        TakeProfitMaximum,
                        EntryLimitMaximum
                    } = payload.ranges;

                    if (payload.isInvestAccount && Utils.isObjectEmpty(state.ranges) &&  Utils.isNotNull(EntryLimitMaximum)) {
                        form = {...form};
                        form.OrderRate = Math.trunc(EntryLimitMaximum * 10) / 10;
                    }
                    if (form.UseSpreadCashBack || form.UsePositionInsurance) {
                        const hasCashBackPromo: boolean = PromotionUtil.hasPromotion(PromotionType.SPREAD_CASHBACK, AvailablePromotions);
                        const hasInsurancePromo: boolean = PromotionUtil.hasPromotion(PromotionType.POSITION_INSURANCE, AvailablePromotions);
                        if ((form.UseSpreadCashBack && !hasCashBackPromo) || (form.UsePositionInsurance && !hasInsurancePromo)) {
                            form = {...form};
                            form.UseSpreadCashBack = form.UseSpreadCashBack && hasCashBackPromo;
                            form.UsePositionInsurance = form.UsePositionInsurance && hasInsurancePromo;
                        }
                    }
                    if (Utils.isObjectEmpty(state.ranges) && Utils.isNull(form.PositionId) && Utils.greaterThen0(form.SignalId)) {
                        if (form.StopLossValueRate && (form.StopLossValueRate < StopLossMinimum || form.StopLossValueRate > StopLossMaximum)) {
                            form = {...form};
                            form.StopLossChecked = false;
                            form.StopLossValueRate = null;
                        }
                        if (form.TakeProfitValueRate && (form.TakeProfitValueRate < TakeProfitMinimum || form.TakeProfitValueRate > TakeProfitMaximum)) {
                            form = {...form};
                            form.TakeProfitChecked = false;
                            form.TakeProfitValueRate = null;
                        }
                    }
                    if (form.StopLossAutomatic) {
                        if (Utils.isNull(form.StopLossValueRate) && Utils.isNotNull(StopLossValueRate)) {
                            form = {...form};
                            form.StopLossChecked = true;
                            form.StopLossValueRate = StopLossValueRate;
                        }
                        if (Utils.isNull(form.StopLossValueAmount) && Utils.isNotNull(StopLossValueAmount)) {
                            form = {...form};
                            form.StopLossChecked = true;
                            form.StopLossValueAmount = StopLossValueAmount;
                        }
                    }
                    if (form.TakeProfitAutomatic) {
                        if (Utils.isNull(form.TakeProfitValueRate) && Utils.isNotNull(TakeProfitValueRate)) {
                            form = {...form};
                            form.TakeProfitChecked = true;
                            form.TakeProfitValueRate = TakeProfitValueRate;
                        }
                        if (Utils.isNull(form.TakeProfitValueAmount) && Utils.isNotNull(TakeProfitValueAmount)) {
                            form = {...form};
                            form.TakeProfitChecked = true;
                            form.TakeProfitValueAmount = TakeProfitValueAmount;
                        }
                    }
                    const Errors: {errors: {[key: string]: any}, uiErrors: {[key: string]: any}} =
                        Validation.validateDeal(ValidationFields, form, payload.ranges, state.symbolDetails);
                    errors = Errors.errors;
                    uiErrors = Errors.uiErrors;
                    BIUtil.SymbolPanelErrorTrack(state.uiErrors, uiErrors);
                }
                return Object.assign({}, state, {
                    form,
                    ranges: form?.SymbolId !== payload?.symbolId ? {} : payload.ranges,
                    errors,
                    uiErrors
                });
            })
            .handle(UpdateDealForm, (state: DealReduxState, {payload}: Action<UpdateDealFormPayload>) => {
                const form: DealForm = payload.form ? Object.assign({}, state.form, payload.form) : null;
                let errors: {[key: string]: any} = {};
                let uiErrors: {[key: string]: any} = {};
                if (form) {
                    const Errors: {errors: {[key: string]: any}, uiErrors: {[key: string]: any}} =
                        Validation.validateDeal(ValidationFields, form, state.ranges, state.symbolDetails);
                    errors = Errors.errors;
                    uiErrors = Errors.uiErrors;
                    BIUtil.SymbolPanelErrorTrack(state.uiErrors, uiErrors);
                }
                return Object.assign({}, state, {
                    form,
                    errors,
                    uiErrors
                });
            })
            .handle(GoingOpenDeal, (state: DealReduxState, {payload}: Action<GoingOpenDealPayload>) => {
                return Object.assign({}, state, {
                    tradeManagerTab: TradeManagerTab.Trade,
                    positionTabDealId: null
                });
            })
            .handle(GoingEditDeal, (state: DealReduxState, {payload}: Action<GoingEditDealPayload>) => {
                return Object.assign({}, state, {
                    tradeManagerTab: TradeManagerTab.Trade,
                    positionTabDealId: null
                });
            })
            .handle(DealOpenEditInitialization, (state: DealReduxState, {payload}: Action<DealOpenEditInitializationPayload>) => {
                return Object.assign({}, state, {
                    openEditInitialization: payload.pending
                });
            })
            .handle(OpenDeal, (state: DealReduxState, action: Action<OpenDealPayload>) => {
                return Object.assign({}, state, {
                    formInProgress: true
                });
            })
            .handle(EditDeal, (state: DealReduxState, action: Action<EditDealPayload>) => {
                return Object.assign({}, state, {
                    formInProgress: true
                });
            })
            .handle(CloseDeals, (state: DealReduxState, action: Action<CloseDealsPayload>) => {
                return Object.assign({}, state, {
                    formInProgress: true
                });
            })
            .handle(SetDealFormInProgress, (state: DealReduxState, {payload}: Action<SetDealFormInProgressPayload>) => {
                return Object.assign({}, state, {
                    formInProgress: payload.formInProgress
                });
            })
            .handle(SetDealServerError, (state: DealReduxState, {payload}: Action<SetDealServerErrorPayload>) => {
                return Object.assign({}, state, {
                    serverError: payload.error
                });
            })
            .handle(ClearDealFormRanges, (state: DealReduxState, action: Action<any>) => {
                this._logger.debug("Clear deal form and ranges");
                return Object.assign({}, state, {
                    form: null,
                    ranges: {},
                    errors: {},
                    uiErrors: {},
                    formInProgress: false,
                    serverError: null
                });
            })
            .handle(SetPositionTabDealId, (state: DealReduxState, {payload}: Action<SetPositionTabDealIdPayload>) => {
                return Object.assign({}, state, {
                    positionTabDealId: payload.DealId
                });
            });
    }
}
