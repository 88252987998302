import {IntegrationMessage} from "platform/integration/message/IntegrationMessage";
import {IntegrationClassId} from "platform/integration/message/IntegrationClassId";
import {Deal} from "platform/protocol/trading/Deal";
import {ChartQuote} from "platform/protocol/trading/chart/ChartQuote";

export class IntegrationChartNewQuotes implements IntegrationMessage {

    public uid: string;
    public classId: number = IntegrationClassId.CHART_NEW_QUOTES;
    public historicalReload: boolean;
    public quotes: ChartQuote[];
    public OpenPositions: Deal[];

    public constructor(historicalReload: boolean, quotes: ChartQuote[], OpenPositions: Deal[]) {
        this.historicalReload = historicalReload;
        this.quotes = quotes;
        this.OpenPositions = OpenPositions;
    }
}
