import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import FilterReducer from "core/redux/filter/FilterReducer";
import FilterEngine from "core/engine/FilterEngine";

export class FilterService implements Service<FilterEngine, any> {

    private static _instance: FilterService;

    private constructor() {
    }

    public static instance(): FilterService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Filter;
    }

    public reducers(): Array<Reducer<any>> {
        return [FilterReducer.instance()];
    }

    public engine(): FilterEngine {
        return FilterEngine.instance();
    }

    public state(): any {
        return undefined;
    }
}
