import {TradeSymbol} from "platform/protocol/trading/symbol/TradeSymbol";

export class SymbolUtil {

    public static decimals = (symbol: TradeSymbol): number => {
        if (symbol) {
            return parseInt(symbol.decimals, 10);
        }
        return 4;
    }

    public static step = (symbol: TradeSymbol): number => {
        const fraction: number = SymbolUtil.decimals(symbol);
        if (fraction === 3) {
            return 0.001;
        } else if (fraction === 2) {
            return 0.01;
        } else if (fraction === 1) {
            return 0.1;
        } else if (fraction === 0) {
            return 1;
        }
        return 0.0001;
    }
}
