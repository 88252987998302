import {ActionCreatorDefinition, createAction} from "redux-ts";
import {BOChatMessage} from "core/redux/inbox/BOChatReduxState";

export const BOChatStartType: string = "BOChatStart";
export const BOChatEndType: string = "BOChatEnd";
export const BOChatSendMessageType: string =  "BOChatSendMessage";
export const BOChatAddMessagesType: string =  "BOChatAddMessages";
export const BOChatSetVisibleType: string =  "BOChatSetVisible";
export const BOChatSetDisconnectedType: string =  "BOChatSetDisconnected";

export interface BOChatStartPayload {

    token: string;
}

export interface BOChatSendMessagePayload {

    message: string;
}

export interface BOChatAddMessagesPayload {

    messages: BOChatMessage[];
}

export interface BOChatSetVisiblePayload {

    visible: boolean;
}

export interface BOChatSetDisconnectedPayload {

    disconnected: boolean;
}

export const BOChatStart: ActionCreatorDefinition<BOChatStartPayload, any> = createAction<BOChatStartPayload>(BOChatStartType);
export const BOChatEnd: ActionCreatorDefinition<any, any> = createAction<any>(BOChatEndType);
export const BOChatSendMessage: ActionCreatorDefinition<BOChatSendMessagePayload, any> = createAction<BOChatSendMessagePayload>(BOChatSendMessageType);
export const BOChatAddMessages: ActionCreatorDefinition<BOChatAddMessagesPayload, any> = createAction<BOChatAddMessagesPayload>(BOChatAddMessagesType);
export const BOChatSetVisible: ActionCreatorDefinition<BOChatSetVisiblePayload, any> = createAction<BOChatSetVisiblePayload>(BOChatSetVisibleType);
export const BOChatSetDisconnected: ActionCreatorDefinition<BOChatSetDisconnectedPayload, any> = createAction<BOChatSetDisconnectedPayload>(BOChatSetDisconnectedType);
