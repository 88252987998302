import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {WithdrawalReduxState} from "core/redux/account/WithdrawalReduxState";
import {
    DoCancelWithdrawalType,
    DoWithdrawal,
    DoWithdrawalPayload,
    DoWithdrawalType,
    SetPendingWithdrawal,
    SetPendingWithdrawalPayload,
    SetWithdrawalResult,
    SetWithdrawalResultPayload,
} from "core/redux/account/WithdrawalReduxActions";
import AccountEngine from "core/engine/AccountEngine";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {OnAccountChanged, OnLoggedOut} from "core/redux/auth/AuthReduxActions";

const initialState = (): WithdrawalReduxState => {
    return {
    };
};

export default class WithdrawalReducer extends Reducer<WithdrawalReduxState> {

    private static _instance: WithdrawalReducer;

    public static instance(): WithdrawalReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const accountEngine: AccountEngine = Platform.engine(ServiceType.Account);
        this._middlewareActions.set("@@router5/TRANSITION_START", accountEngine.onChangeRoute);
        this._middlewareActions.set(DoWithdrawalType, accountEngine.doWithdrawal);
        this._middlewareActions.set(DoCancelWithdrawalType, accountEngine.doCancelWithdrawal);
    }

    public get name(): string {
        return "withdrawal";
    }

    protected setup(builder: ReducerBuilder<WithdrawalReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: WithdrawalReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(OnAccountChanged, (state: WithdrawalReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(SetPendingWithdrawal, (state: WithdrawalReduxState, {payload}: Action<SetPendingWithdrawalPayload>) => {
                return Object.assign({}, state, {
                    withdrawal: payload.withdrawal,
                });
            })
            .handle(DoWithdrawal, (state: WithdrawalReduxState, {payload}: Action<DoWithdrawalPayload>) => {
                return Object.assign({}, state, {
                    pending: true,
                });
            })
            .handle(SetWithdrawalResult, (state: WithdrawalReduxState, {payload}: Action<SetWithdrawalResultPayload>) => {
                return Object.assign({}, state, {
                    pending: false,
                    withdrawalNumber: payload.withdrawalNumber,
                    success: payload.success
                });
            });
    }
}
