import {Deal} from "platform/protocol/trading/Deal";
import {Trade} from "core/redux/trades/TradesReduxState";
import {Exposure} from "protocol/trade/Exposure";
import {PositionDirection} from "platform/protocol/enum/PositionDirection";
import {ActionFromData} from "protocol/account/ActionFromData";
import {DealForm} from "core/redux/deal/DealReduxState";
import Platform from "platform/Platform";
import {StoreState} from "core/redux/StoreState";
import {Quote} from "platform/protocol/trading/Quote";
import {AccountState} from "core/state/AccountState";
import {ServiceType} from "enum/ServiceType";
import Utils from "platform/util/Utils";
import {PortfolioCounter} from "core/redux/portfolio/PortfolioReduxState";
import {TradeType} from "protocol/trade/TradeType";
import {OpenDealResponse} from "protocol/trade/OpenDealResponse";
import {TSMap} from "typescript-map";
import {StorageKey} from "enum/StorageKey";
import {Preference} from "core/util/Preference";
import {PlaySound} from "platform/redux/audio/AudioActions";
import {CategoryCounterState} from "core/redux/symbols/SymbolReduxState";
import {SymbolDetails} from "protocol/symbol/GetSymbolDetailsResponse";
import {LSKey} from "platform/storage/Storage";
import {GoingOpenDealPayload} from "core/redux/deal/DealReduxActions";
import {SymbolSignal} from "platform/protocol/trading/SymbolSignal";
import {SignalPatternType} from "platform/protocol/enum/SignalPatternType";
import {RouteType} from "core/router/Routes";
import DateTimeFormat from "core/format/DateTimeFormat";
import {TradingAccountAction} from "enum/TradingAccountAction";
import {LoginAccountInfo} from "protocol/auth/LoginAccountInfo";
import {LoginAccountType} from "protocol/auth/LoginAccountType";
import {EntryOrderStatus} from "platform/protocol/trading/EntryOrderStatus";
import {OrderType} from "platform/protocol/trading/OrderType";

export class TradeUtil {

    public static mapDeal = (deal: Deal): Trade => {
        return {
            Id: deal.Id,
            SymbolId: deal.SymbolId,
            SymbolDisplayName: deal.SymbolDisplayName,
            SymbolRICCode: deal.SymbolRICCode,
            SymbolRoundingDigits: deal.SymbolRoundingDigits,
            Amount: deal.Amount,
            PositionDirections: [deal.PositionDirection],
            PL: deal.PL,
            PositionPromotions: [...deal.PositionPromotions],
            IsEducational: deal.IsEducational,
            StopLossRate: deal.StopLossRate,
            StopLossAmount: deal.StopLossAmount,
            TakeProfitRate: deal.TakeProfitRate,
            TakeProfitAmount: deal.TakeProfitAmount,
            Date: deal.Date,
            Rate: deal.Rate,
            MarketRate: deal.MarketRate,
            MarketValue: deal.MarketValue,
            Volume: deal.Volume,
            IsInvestExhangeUpdating: deal.IsInvestExhangeUpdating,
            Type: deal.Type,
            Status: deal.Status,
            trades: null,
            origin: deal
        };
    }

    public static mapExposure = (exposure: Exposure): Trade => {
        return {
            Id: null,
            SymbolId: exposure.SymbolId,
            SymbolDisplayName: exposure.SymbolDisplayName,
            SymbolRICCode: null,
            SymbolRoundingDigits: null,
            Amount: exposure.NetValue,
            PositionDirections: [],
            IsEducational: null,
            PL: exposure.OpenPL,
            PositionPromotions: [],
            StopLossRate: null,
            StopLossAmount: null,
            TakeProfitRate: null,
            TakeProfitAmount: null,
            MarketRate: null,
            MarketValue: null,
            Date: null,
            Rate: exposure.AverageOpenRate,
            trades: [],
            origin: null
        };
    }

    public static recalculateExposure = (exposureTrade: Trade, filteredTrades: Trade[]): Trade => {
        const exposure: Trade = {
            ...exposureTrade,
            Amount: 0,
            PL: 0,
            PositionDirections: [],
            trades: filteredTrades
        };
        filteredTrades.forEach((trade: Trade) => {
            const direction: PositionDirection = trade.PositionDirections[0];
            if (exposure.PositionDirections.indexOf(direction) < 0) {
                exposure.PositionDirections.push(direction);
            }
            if (direction === PositionDirection.Buy) {
                exposure.Amount += trade.Amount;
            } else {
                exposure.Amount -= trade.Amount;
            }
            exposure.PL += trade.PL;
        });
        return exposure;
    }

    public static createDate = (trade: Trade): number => {
        if (!trade.Date) {
            let maxDate: number = null;
            if (Utils.isArrayNotEmpty(trade.trades)) {
                trade.trades.forEach((t: Trade) => {
                    maxDate = Math.max(maxDate, t.Date);
                });
            }
            return maxDate;
        }
        return trade.Date;
    }

    public static mapActionFormData = (): ActionFromData => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const {quotes, deal} = Platform.reduxState<StoreState>();
        const form: DealForm = deal.form;
        if (form) {
            const isEntryOrder: boolean = form.RateRequestType === TradeType.EntryOrder;
            const isOCO: boolean = Utils.greaterThen0(form.OCOId);
            const quote: Quote = quotes.quotes.get(form.SymbolId);
            // NOTE: can't send action form data without quote ID
            if (quote) {
                return {
                    AccountId: accountState.accountId.toString(),
                    PositionId: form.PositionId,
                    QuoteId: quote.Id,
                    SymbolId: form.SymbolId,
                    Amount: form.Amount ? form.Amount.toString() : "0",
                    OCORate: isOCO ? form.OCORate : null,
                    OrderRate: isEntryOrder ? form.OrderRate : null,
                    PositionDirection: form.PositionDirection,
                    RateRequestType: form.RateRequestType,
                    StopLossValueAmount: Utils.isNull(form.HasUserEnteredStopLossAmount) || form.HasUserEnteredStopLossAmount || Utils.isNull(form.StopLossValueRate) ? form.StopLossValueAmount : null,
                    StopLossValueRate: Utils.isNull(form.HasUserEnteredStopLossAmount) || !form.HasUserEnteredStopLossAmount ? form.StopLossValueRate : null,
                    TakeProfitValueAmount: Utils.isNull(form.HasUserEnteredTakeProfitAmount) || form.HasUserEnteredTakeProfitAmount || Utils.isNull(form.TakeProfitValueRate) ? form.TakeProfitValueAmount : null,
                    TakeProfitValueRate: Utils.isNull(form.HasUserEnteredTakeProfitAmount) || !form.HasUserEnteredTakeProfitAmount ? form.TakeProfitValueRate : null
                };
            }
        }
        return null;
    }

    public static formatCounter(counter: CategoryCounterState | PortfolioCounter, filterOnly?: boolean): string {
        if (counter) {
            return TradeUtil.FormatCounter(counter.filterCount, counter.count, filterOnly);
        }
        return "";
    }

    public static FormatCounter(filterCount: number, count: number, filterOnly?: boolean): string {
        if (Utils.isNotNull(filterCount) && Utils.isNotNull(count) && filterCount < count) {
            if (filterOnly) {
                return " (" + filterCount + ")";
            } else {
                return " (" + filterCount + "/" + count + ")";
            }
        } else if (count) {
            return " (" + count + ")";
        }
        return "";
    }

    public static mapResponseToDeal = (response: OpenDealResponse): Deal => {
        if (response) {
            let direction: PositionDirection;
            let openRate: number;
            let stopLoss: number;
            let takeProfit: number;

            if (response.P3 === 1) { // if true this is Buy and openRate is in property P33
                direction = PositionDirection.Buy;
                openRate = response.P33;
            } else { // else this is Sell and openRate is in property P32
                direction = PositionDirection.Sell;
                openRate = response.P32;
            }
            // If P19 is null there is no SL
            if (response.P19) {
                // There is SL if this is true
                if (response.P19.P15) {
                    stopLoss = response.P19.P15.P3;
                    if (response.P18) {
                        takeProfit = response.P18.P15.P3;
                    }
                } else { // If P19 is an empty object it means we have both TP&SL
                    stopLoss = response.P18.P21.P15.P3;
                    takeProfit = response.P18.P15.P3;
                }
            } else {
                if (response.P18) {
                    takeProfit = response.P18.P15.P3;
                }
            }

            const deal: Deal = {
                Id: response.P1, // id -> P1
                Amount: response.P7, // amount -> P7
                PositionDirection: direction, // direction -> P3
                Rate: openRate, // openRate/marketRate(switched in some cases) -> P32/P33
                StopLossRate: stopLoss,
                TakeProfitRate: takeProfit,
                SymbolId: response.P4, // symbolId -> P4,
                PL: response.P17,
                Date: DateTimeFormat.parseServer(response.P12?.P6)?.toDate().getTime(),
                CloseRate: response.P15?.P3,
                CloseDate: DateTimeFormat.parseServer(response.P15?.P6)?.toDate().getTime()
            };
            return deal;
        }
        return {};
    }

    public static playPopupSound = (): void => {
        const preferences: TSMap<StorageKey | LSKey, any> = Platform.reduxState<StoreState>().settings.preferences;
        const popupSound: Preference<boolean> = preferences.get(StorageKey.PopupSound);
        if (Utils.isNull(popupSound) || popupSound.value) {
            Platform.dispatch(PlaySound({name: "audio/pop_up_sound.mp3"}));
        }
    }

    public static openDealAmounts = (symbolDetails: SymbolDetails): number[] => {
        const amounts: number[] = [];
        if (symbolDetails) {
            if (Utils.isNotNull(symbolDetails.MinimalAmount)) {
                amounts.push(symbolDetails.MinimalAmount);
            }
            if (Utils.isArrayNotEmpty(symbolDetails.Amounts)) {
                amounts.push(
                    ...symbolDetails.Amounts.filter(amount => amount > symbolDetails.MinimalAmount && amount < symbolDetails.MaximalAmount)
                        .sort((a1: number, a2: number) => Utils.compareNumber(a1, a2))
                );
            }
            if (Utils.isNotNull(symbolDetails.MaximalAmount)) {
                amounts.push(symbolDetails.MaximalAmount);
            }
        }
        return amounts;
    }

    public static signalOpenDeal = (signal: SymbolSignal, action?: (routeType?: RouteType) => void): GoingOpenDealPayload => {
        const isEntryOrder: boolean = signal.SignalPatternTypeId === SignalPatternType.Fibonacci && !signal.IsComplete;
        return {
            SymbolId: signal.SymbolId,
            SignalId: signal.SignalId,
            Direction: signal.Direction,
            tradeType: isEntryOrder ? TradeType.EntryOrder : TradeType.MarketOrder,
            orderRate: isEntryOrder ? signal.EntryLevelRate : null,
            stopLoss: signal.StopLossRate,
            takeProfit: signal.TargetLevelRate,
            action
        };
    }

    public static tradingAccountAction = (account: LoginAccountInfo): TradingAccountAction => {
        if (account?.RelatedAccountType === LoginAccountType.Invest) {
            if (Utils.greaterThen0(account.RelatedAccountId)) {
                return TradingAccountAction.Switch;
            }
            return TradingAccountAction.SignUp;
        }
        return TradingAccountAction.None;
    }

    public static canNotCloseCancel = (dealId: number, tradeType: TradeType): boolean => {
        const {OpenPositionsMap, EntryOrdersMap} = Platform.reduxState<StoreState>().trades;
        const isMarketOrder: boolean = tradeType === TradeType.MarketOrder;
        const trades: { [key: number]: Deal } = isMarketOrder ? OpenPositionsMap : EntryOrdersMap;
        const deal: Deal = trades[dealId];
        return TradeUtil.CanNotCloseCancel(deal);
    }

    public static CanNotCloseCancel = (deal: Deal): boolean => {
        if (deal) {
            let canCancelOrder: boolean = true;
            if (Utils.isNotNull(deal.Status) && Utils.isNotNull(deal.Type)) {
                canCancelOrder = deal.Status === EntryOrderStatus.Inactive || (deal.Type !== OrderType.Market && deal.Status === EntryOrderStatus.Active);
            }
            return (Utils.isNotNull(deal.IsInvestExhangeUpdating) && deal.IsInvestExhangeUpdating)
                || !canCancelOrder;
        }
        return false;
    }
}
