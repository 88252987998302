import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "enum/ServiceType";
import PortfolioReducer from "core/redux/portfolio/PortfolioReducer";
import PortfolioEngine from "core/engine/PortfolioEngine";

export class PortfolioService implements Service<PortfolioEngine, any> {

    private static _instance: PortfolioService;

    private constructor() {
    }

    public static instance(): PortfolioService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Portfolio;
    }

    public reducers(): Array<Reducer<any>> {
        return [PortfolioReducer.instance()];
    }

    public engine(): PortfolioEngine {
        return PortfolioEngine.instance();
    }

    public state(): any {
        return undefined;
    }
}
