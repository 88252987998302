import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import {
    OnAccountChanged, OnLoggedOut,
    OnLoggedOutType
} from "core/redux/auth/AuthReduxActions";
import {ChartReduxState, IChart} from "core/redux/chart/ChartReduxState";
import ChartEngine from "core/engine/ChartEngine";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {
    ChartDealUpdateType,
    CreateChartType,
    CreateMultiChartType, DisplayChart, DisplayChartPayload, RemoveChart, RemoveChartPayload,
    RemoveChartType,
    SetChartDealType, SetChartFullHeight, SetChartFullHeightPayload, SetChartLoader, SetChartLoaderPayload,
    SetChartSubscriptionType, SetChartVersion, SetChartVersionPayload
} from "core/redux/chart/ChartReduxActions";
import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {SetOrientationType, SetThemeType} from "platform/redux/core/CoreActions";

const initialState = (): ChartReduxState => {
    return {
        charts: new TSMap<string, IChart>(),
        chartFullHeight: false
    };
};

export default class ChartReducer extends Reducer<ChartReduxState> {

    private static _instance: ChartReducer;

    public static instance(): ChartReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "chart";
    }

    private constructor() {
        super();
        const chartEngine: ChartEngine = Platform.engine(ServiceType.Chart);
        this._middlewareActions.set("@@router5/TRANSITION_START", chartEngine.onChangeRoute);
        this._middlewareActions.set(SetOrientationType, chartEngine.onOrientationChange);
        this._middlewareActions.set(SetThemeType, chartEngine.onSetTheme);
        this._middlewareActions.set(OnLoggedOutType, chartEngine.onLoggedOut);
        this._middlewareActions.set(CreateChartType, chartEngine.doCreateChart);
        this._middlewareActions.set(SetChartDealType, chartEngine.doSetChartDeal);
        this._middlewareActions.set(SetChartSubscriptionType, chartEngine.doSetChartSubscription);
        this._middlewareActions.set(ChartDealUpdateType, chartEngine.doUpdateDealFromChart);
        this._middlewareActions.set(RemoveChartType, chartEngine.doRemoveChart);
        this._middlewareActions.set(CreateMultiChartType, chartEngine.doCreateMultiChart);
    }

    protected setup(builder: ReducerBuilder<ChartReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: ChartReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    version: state.version,
                };
            })
            .handle(OnAccountChanged, (state: ChartReduxState, action: Action<any>) => {
                return {
                    ...initialState(),
                    version: state.version,
                };
            })
            .handle(SetChartVersion, (state: ChartReduxState, {payload}: Action<SetChartVersionPayload>) => {
                this._logger.debug("Set chart version to: " + payload.version);
                return Object.assign({}, state, {
                    version: payload.version
                });
            })
            .handle(DisplayChart, (state: ChartReduxState, {payload}: Action<DisplayChartPayload>) => {
                const {ChartId, SymbolId, DealId, SelectedSymbolId} = payload.chart;
                this._logger.debug("Display chart for: " + ChartId + " SymbolId: " + SymbolId + " DealId: " + DealId);
                const newCharts = new TSMap<string, IChart>();
                state.charts.forEach((chart: IChart) => {
                    const newChart: IChart = {
                        ...chart,
                        SelectedSymbolId
                    };
                    newCharts.set(chart.ChartId, newChart);
                });
                newCharts.set(ChartId, payload.chart);
                return Object.assign({}, state, {
                    charts: newCharts
                });
            })
            .handle(SetChartLoader, (state: ChartReduxState, {payload}: Action<SetChartLoaderPayload>) => {
                const {ChartId, visible} = payload;
                this._logger.debug("Set chart: " + ChartId + " Loader: " + visible);
                const newState: ChartReduxState = Utils.merge({}, state);
                const chart: IChart = newState.charts.get(ChartId);
                newState.charts.set(ChartId, {
                    ...chart,
                    Loader: visible
                });
                return newState;
            })
            .handle(RemoveChart, (state: ChartReduxState, {payload}: Action<RemoveChartPayload>) => {
                const newState: ChartReduxState = Utils.merge({}, state);
                newState.charts.delete(payload.ChartId);
                return newState;
            })
            .handle(SetChartFullHeight, (state: ChartReduxState, {payload}: Action<SetChartFullHeightPayload>) => {
                this._logger.debug("Set chart view mode to full height: " + payload.fullHeight);
                return Object.assign({}, state, {
                    chartFullHeight: payload.fullHeight
                });
            });
    }
}
