import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import Utils from "platform/util/Utils";
import {OnLoggedOut} from "core/redux/auth/AuthReduxActions";
import {InboxReduxState} from "core/redux/inbox/InboxReduxState";
import {
    AddInboxMessage,
    RemoveInboxMessage,
    InboxMessagePayload,
    ResetInboxUnreadCount,
    SetNotificationTab,
    SetNotificationTabPayload,
    SetInboxMessages,
    SetInboxMessagesPayload,
    ExecuteInboxCTAType,
    SetInboxMessagesLoading,
    SetInboxMessagesLoadingPayload,
    UpdateInboxMessage,
    UpdateInboxMessagePayload,
    SetInboxMessagesOpened,
    SetInboxUnreadCount, SetInboxUnreadCountPayload,
    SetShowNotificationMessage,
    SetShowNotificationMessagePayload,
} from "core/redux/inbox/InboxReduxActions";
import {NotificationTab} from "enum/NotificationTab";
import {UIInAppNotification} from "protocol/notification/UIInAppNotification";
import InboxEngine from "core/engine/InboxEngine";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {TSMap} from "typescript-map";
import {BooleanPayload} from "core/redux/StoreActions";

const initialState = (): InboxReduxState => {

    return {
        messages: [],
        messagesLoading: false,
        all: [],
        allMessages: new TSMap(),
        unreadCount: 0,
        totalCount: 0,
        notificationTab: NotificationTab.All,
        messagesOpened: false,
    };
};

export default class InboxReducer extends Reducer<InboxReduxState> {

    private static _instance: InboxReducer;

    public static instance(): InboxReducer {
        return this._instance || (this._instance = new this());
    }

    public get name(): string {
        return "inbox";
    }

    private constructor() {
        super();
        const inboxEngine: InboxEngine = Platform.engine(ServiceType.Inbox);
        this._middlewareActions.set(ExecuteInboxCTAType, inboxEngine.doExecuteInboxCTA);
    }

    protected setup(builder: ReducerBuilder<InboxReduxState>): void {
        builder
            .init(initialState())
            .handle(OnLoggedOut, (state: InboxReduxState, action: Action<any>) => {
                return {
                    ...initialState()
                };
            })
            .handle(SetNotificationTab, (state: InboxReduxState, {payload}: Action<SetNotificationTabPayload>) => {
                return Object.assign({}, state, {
                    notificationTab: payload.tab
                });
            })
            .handle(SetInboxMessages, (state: InboxReduxState, {payload}: Action<SetInboxMessagesPayload>) => {
                const messages: UIInAppNotification[] = payload.messages;
                const newState: InboxReduxState = Utils.merge({}, state);
                const all: number[] = payload.isInit ? [] : newState.all;
                const allMessages: TSMap<number, UIInAppNotification> = payload.isInit ? new TSMap() : newState.allMessages;
                messages.forEach((message: UIInAppNotification) => {
                    all.push(message.Id);
                    allMessages.set(message.Id, message);
                });

                return Object.assign({}, state, {
                    all,
                    allMessages,
                    totalCount: payload.total,
                });
            })
            .handle(SetInboxMessagesLoading, (state: InboxReduxState, {payload}: Action<SetInboxMessagesLoadingPayload>) => {
                return Object.assign({}, state, {
                    messagesLoading: payload.loading
                });
            })
            .handle(UpdateInboxMessage, (state: InboxReduxState, {payload}: Action<UpdateInboxMessagePayload>) => {
                const newState: InboxReduxState = Utils.merge({}, state);
                const {allMessages} = newState;
                allMessages.set(payload.message.Id, payload.message);
                return Object.assign({}, state, {
                    allMessages,
                });
            })
            .handle(AddInboxMessage, (state: InboxReduxState, {payload}: Action<InboxMessagePayload>) => {
                const newState: InboxReduxState = Utils.merge({}, state);
                newState.messages = [...state.messages, payload.message];
                newState.all = [payload.message.Id, ...state.all];
                newState.allMessages.set(payload.message.Id, payload.message);
                newState.unreadCount = state.unreadCount + 1;
                return newState;
            })
            .handle(RemoveInboxMessage, (state: InboxReduxState, {payload}: Action<InboxMessagePayload>) => {
                const id: number = payload.message.Id;
                const newState: InboxReduxState = Utils.merge({}, state);
                newState.messages = state.messages.filter((message: UIInAppNotification) => message.Id !== id);
                return newState;
            })
            .handle(SetInboxUnreadCount, (state: InboxReduxState, {payload}: Action<SetInboxUnreadCountPayload>) => {
                return Object.assign({}, state, {
                    unreadCount: payload.count
                });
            })
            .handle(ResetInboxUnreadCount, (state: InboxReduxState, action: Action<any>) => {
                return Object.assign({}, state, {
                    unreadCount: 0
                });
            })
            .handle(SetInboxMessagesOpened, (state: InboxReduxState, {payload}: Action<BooleanPayload>) => {
                return Object.assign({}, state, {
                    messagesOpened: payload.value
                });
            })
            .handle(SetShowNotificationMessage, (state: InboxReduxState, {payload}: Action<SetShowNotificationMessagePayload>) => {
                const {visible, trKey, value} = payload;
                if (visible) {
                    setTimeout(() => Platform.dispatch(SetShowNotificationMessage({visible: false})), 5000);
                }
                return Object.assign({}, state, {
                    showNotificationMessage: {
                        visible,
                        trKey,
                        value
                    }
                });
            });
    }
}
