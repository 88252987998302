import {ActionCreatorDefinition, createAction} from "redux-ts";
import {GetCurrentChartQuotesRequest} from "platform/protocol/trading/chart/GetCurrentChartQuotesRequest";
import {IChart} from "core/redux/chart/ChartReduxState";

export const SetChartVersionType: string =  "SetChartVersion";
export const DisplayChartType: string =  "DisplayChart";
export const CreateChartType: string =  "CreateChart";
export const SetChartLoaderType: string =  "SetChartLoader";
export const SetChartDealType: string =  "SetChartDeal";
export const SetChartSubscriptionType: string =  "SetChartSubscription";
export const ChartDealUpdateType: string =  "ChartDealUpdate";
export const RemoveChartType: string =  "RemoveChart";
export const CreateMultiChartType: string =  "CreateMultiChart";
export const SetChartFullHeightType: string =  "SetChartFullHeight";

export interface SetChartVersionPayload {

    version: string;
}

export interface DisplayChartPayload {

    chart: IChart;
}

export interface CreateChartPayload {

    ChartId: string;
    SymbolId: number;
}

export interface SetChartLoaderPayload {

    ChartId: string;
    visible: boolean;
}

export interface SetChartDealPayload {

    ChartId: string;
    DealId: number;
}

export interface SetChartSubscriptionPayload {

    ChartId: string;
    Subscription: GetCurrentChartQuotesRequest;
}

export interface ChartDealUpdatePayload {

    ChartId: string;
    DealId: number;
    StopLoss: number;
    TaleProfit: number;
}

export interface RemoveChartPayload {

    ChartId: string;
}

export interface SetChartFullHeightPayload {

    fullHeight: boolean;
}

export const SetChartVersion: ActionCreatorDefinition<SetChartVersionPayload, any> = createAction<SetChartVersionPayload>(SetChartVersionType);
export const DisplayChart: ActionCreatorDefinition<DisplayChartPayload, any> = createAction<DisplayChartPayload>(DisplayChartType);
export const CreateChart: ActionCreatorDefinition<CreateChartPayload, any> = createAction<CreateChartPayload>(CreateChartType);
export const SetChartLoader: ActionCreatorDefinition<SetChartLoaderPayload, any> = createAction<SetChartLoaderPayload>(SetChartLoaderType);
export const SetChartDeal: ActionCreatorDefinition<SetChartDealPayload, any> = createAction<SetChartDealPayload>(SetChartDealType);
export const SetChartSubscription: ActionCreatorDefinition<SetChartSubscriptionPayload, any> = createAction<SetChartSubscriptionPayload>(SetChartSubscriptionType);
export const ChartDealUpdate: ActionCreatorDefinition<ChartDealUpdatePayload, any> = createAction<ChartDealUpdatePayload>(ChartDealUpdateType);
export const RemoveChart: ActionCreatorDefinition<RemoveChartPayload, any> = createAction<RemoveChartPayload>(RemoveChartType);
export const CreateMultiChart: ActionCreatorDefinition<any, any> = createAction<any>(CreateMultiChartType);
export const SetChartFullHeight: ActionCreatorDefinition<SetChartFullHeightPayload, any> = createAction<SetChartFullHeightPayload>(SetChartFullHeightType);
