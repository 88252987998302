export enum AccountOption {

    None = 0,
    CanSwitchToDemo = 1,
    ChangeCloseOutMethod = 2,
    ManageDepositLimits = 4,
    ProfessionalAccount = 8,
    ChangeLeverage = 16,
    FinalizeRegistrationLink = 64,
    DocumentUploader = 128,
    PhoneVerification = 256,
    TradingSimulator = 512,
    EnableSmsNotificationForMargin = 1024,
    PlayPresenterVideo = 2048,
    AnnualReport = 4096,
    DisablePopupSound = 8192,
    ChangePassword = 16394,
    EbookNotificationMessage = 32768,
    BrandVideoTutorials = 65536,
    LearningCenter = 131072,
    WithdrawableEquity = 262144,
    RollingContracts = 524288,
    FinalizeRegistrationLinkMyAccount = 1048576,
}
