import Platform from "platform/Platform";
import {Http} from "platform/network/http/Http";
import {Logger} from "platform/logger/Logger";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {Configuration, LocaleConfig} from "core/configuration/Configuration";
import {LocaleProvider} from "core/format/LocaleProvider";
import {TSMap} from "typescript-map";

export class LocaleProviderImpl implements LocaleProvider {

    private static _instance: LocaleProvider;
    private readonly _logger: Logger = Logger.Of("Locale");
    private readonly _locales: TSMap<LangCode | string, LocaleConfig> = new TSMap<LangCode | string, LocaleConfig>();
    private _config: LocaleConfig;

    private constructor() {
    }

    public static instance(): LocaleProvider {
        return this._instance || (this._instance = new this());
    }

    public config(): LocaleConfig {
        return this._config;
    }

    public async load(): Promise<void> {
        this._config = Platform.config<Configuration>().locale;
        let langCode: string;
        if (navigator && navigator.language) {
            langCode = navigator.language;
        } else {
            langCode = LanguageUtil.languageCode();
        }
        Http.getJson("resources/locales/" + langCode + ".json").then((data: LocaleConfig) => {
            this._config = data;
            this._locales.set(langCode, data);
        }).catch((e) => {
            this._logger.debug("Failed load locale for language: " + langCode);
        });
    }

    public async getLocale(langCode: LangCode): Promise<LocaleConfig> {
        const locale: LocaleConfig = this._locales.get(langCode);
        if (locale) {
            return Promise.resolve(locale);
        } else {
            return new Promise((resolve, reject) => {
                Http.getJson("resources/locales/" + langCode + ".json").then((data: LocaleConfig) => {
                    this._locales.set(langCode, data);
                    resolve(data);
                }).catch((e) => {
                    resolve(Platform.config<Configuration>().locale);
                });
            });
        }
    }

    public locale(langCode: LangCode): LocaleConfig {
        return this._locales.get(langCode);
    }
}
