import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import Platform from "platform/Platform";
import {ServerType} from "platform/enum/ServerType";
import {AuthState} from "core/state/AuthState";
import {ServiceType} from "enum/ServiceType";
import {Xhr} from "platform/network/xhr/Xhr";
import {AccountState} from "core/state/AccountState";
import Utils from "platform/util/Utils";
import {HttpHeader, HttpReject} from "platform/network/http/Http";
import {BadRequestResponse} from "protocol/BadRequestResponse";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType, PopupType} from "platform/redux/popups/PopupsReduxState";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {GetAccountStateResponse} from "protocol/account/GetAccountStateResponse";
import SessionInactivity from "core/util/SessionInactivity";
import {ErrorCode} from "enum/ErrorCode";

export class XhrUtil {

    public static sendToLogin = (request: any, path: string, headers?: HttpHeader[]): Promise<any> => {
        const authState: AuthState = Platform.state(ServiceType.Auth);
        return Xhr.sendTo(ServerType.Login, request, path, authState.token, false, headers);
    }

    public static SendToLogin = <Response>(server: string, path: string, request: any, timeout?: number): Promise<Response> => {
        return Xhr.send(server, request, `loginService.svc/json/${path}`, null, false, null, false, timeout);
    }

    public static sendToAccountService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "WebProfitServer/AccountStatesService.svc/json/" + path, null, true);
    }

    public static sendAuthenticated = (request: any, path: string, headers?: HttpHeader[], withCredentials?: boolean): Promise<any> => {
        return XhrUtil.SendAuthenticated(request, path, false, headers, withCredentials);
    }

    public static SendAuthenticated = (request: any, path: string, plain: boolean, headers?: HttpHeader[], withCredentials?: boolean): Promise<any> => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        if (Utils.isNotNull(accountState.accountType)) {
            const serverType: ServerType = accountState.accountType === NXEnvironmentType.Live ? ServerType.TradingReal : ServerType.TradingDemo;
            return XhrUtil.SendAuthenticatedTo(serverType, request, path, plain, headers, withCredentials);
        }
        return Promise.reject("Account type not detected");
    }

    public static sendAuthenticatedTo = (serverType: ServerType, request: any, path: string, headers?: HttpHeader[], withCredentials?: boolean, timeout?: number): Promise<any> => {
        return XhrUtil.SendAuthenticatedTo(serverType, request, path, false, headers, withCredentials, timeout);
    }

    public static SendAuthenticatedTo = (serverType: ServerType, request: any, path: string, plain: boolean, headers?: HttpHeader[], withCredentials?: boolean, timeout?: number): Promise<any> => {
        SessionInactivity.onSendingData(path);
        let rHeaders: HttpHeader[] = [];
        if (headers) {
            rHeaders = [...headers];
        }
        rHeaders.push({name: "lcode", value: LanguageUtil.languageCode()});
        rHeaders.push({name: "Content-Type", value: "application/json"});
        const authState: AuthState = Platform.state(ServiceType.Auth);
        return Xhr.sendTo(serverType, request, path, authState.token, plain, rHeaders, withCredentials, timeout);
    }

    public static pingAccountState = (server: string, token: string): Promise<GetAccountStateResponse> => {
        return Xhr.send(server, {}, "WebProfitServer/AccountStatesService.svc/json/GetAccountState", token, false, null, true, 10000);
    }

    public static getServerRejectReason = (error: HttpReject): string =>{
        let errMsg: string;
        if (Utils.isNotNull(error.response)) {
            try {
                const response: BadRequestResponse = JSON.parse(error.response);
                if (!response.IsSecurityException) {
                    errMsg = response.LocalizeMessage || response.Message;
                }
            } catch (e) {
            }
        }
        return errMsg;
    }

    public static getServerErrorCode = (error: HttpReject): ErrorCode =>{
        if (Utils.isNotNull(error.response)) {
            try {
                const response: BadRequestResponse = JSON.parse(error.response);
                return response.ErrorCode;
            } catch (e) {
            }
        }
        return null;
    }

    public static getRejectReason = (error: HttpReject): string =>{
        return this.getServerRejectReason(error) || Translations.text(TranslationKey.errorGeneral);
    }

    public static notifyReject = (error: HttpReject, action?: () => void): void =>{
        Platform.dispatch(ShowPopup({
            popup: {
                type: PopupType.ERROR,
                message: {
                    customValue: XhrUtil.getRejectReason(error)
                },
                showClose: true,
                icon: {type: PopupIconType.ERROR},
                actions: [{type: PopupActionType.OK, action}]
            }
        }));
    }
}
